/* eslint-disable react/prop-types */
import './styles.scss';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import { ReactComponent as LinkedInIcon } from '../../../../assets/icons/Linkedin.svg';
import { ReactComponent as GithubIcon } from '../../../../assets/icons/Github.svg';
import { ReactComponent as StackoverflowIcon } from '../../../../assets/icons/Stackoverflow.svg';
import { ReactComponent as FacebookIcon } from '../../../../assets/icons/Facebook.svg';
import { useEffect, useMemo, useState } from 'react';
import CurrencyRupeeIcon from '@mui/icons-material/CurrencyRupee';
import GroupStrick from '../../../../assets/images/default-avatar.png'
import { ReactComponent as ReasonIcon } from '../../../../assets/icons/reject-reason.svg'
import { Avatar, Box, Table, TableBody, TableCell, TableHead, TableRow, Tooltip } from '@mui/material';
import { convertToDisplayDateTime } from '../../../../utils/date-utils';
import { getMandateAuditLogs } from '../../../../services/MandateService';
type Tprop = {
    candidateDetailsData: any;
}
type prop ={
    linkedIn: any;
}
const LinkedIn:React.FC<prop>=({linkedIn}) =>{
    return(linkedIn.indexOf("https://") > -1 ? <a target='_blank' rel="noreferrer" href={linkedIn}><LinkedInIcon className='linkedin'/></a> : <LinkedInIcon className='linkedin'/>)
}
const Facebook = ({facebook}:any) =>{
    return(facebook.indexOf("https://") > -1 ? <a target='_blank' rel="noreferrer" href={facebook}><FacebookIcon className='linkedin'/></a> : <FacebookIcon className='linkedin'/>)
}
const Github=({github}:any) =>{
    return(github.indexOf("https://") > -1 ? <a target='_blank' rel="noreferrer" href={github}><GithubIcon className='linkedin'/></a>: <GithubIcon className='linkedin'/>)
}
const Stackoverflow=({stackoverflow}:any) =>{
    return(stackoverflow.indexOf("https://") > -1 ? <a target='_blank' rel="noreferrer" href={stackoverflow}><StackoverflowIcon className='linkedin'/></a>: <StackoverflowIcon className='linkedin'/>)
}

const BasicDetails: React.FC<Tprop> = ({ candidateDetailsData }) => {
    const linkedin = useMemo(() =>candidateDetailsData?.linkedindata?.profileLink , []) 
    const edu = useMemo(() =>  candidateDetailsData?.educationDetails?.trim()?.split(' '), [])
    const [showRejectReasonPopup, setShowRejectReasonPopup]= useState(false)
    const [manAuditHistory, setManAuditHistory] = useState<any[]>([])
    const [rejectReason, setRejectReason]= useState('')
    const academicExperienceList = candidateDetailsData?.detailedEducation != undefined ? JSON.parse(JSON.stringify(Array.isArray(candidateDetailsData?.detailedEducation) ? candidateDetailsData?.detailedEducation : [candidateDetailsData?.detailedEducation])).reverse() : [];

    const getStatusLog = async () => {
        const reqObj = {
          filters: [
            {
              field: "eventId",
              operator: "in",
              type: "string",
              value: ["CANDIDATE_ENTITY"]
            },
            {
              field: "entityId",
              operator: "equals",
              type: "string",
              value: String(candidateDetailsData?.id)
            }
          ],
          page: {
            pageNo: -1,
            pageSize: 10
          },
          sorting: {
              direction: "DESC",
              field: "createdAt"
          }
        }
        try{
          const res = await getMandateAuditLogs(reqObj)
          if(res?.status === 200){
            setManAuditHistory(res?.data?.result?.list[0] ?? [])
          }
        }catch(error){
          console.log(error,"error")
        }
      }

    const Logs = () => {
        return (
          <Table className="audit-log-table">
            <TableHead>
              <TableRow>
                <TableCell style={{width:"50%"}} >Action By</TableCell>
                {/* <TableCell style={{width:"20%"}}>Action</TableCell> */}
                <TableCell style={{width:"30%"}}>Status</TableCell>
                <TableCell style={{width:"20%"}}>Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {manAuditHistory?.map((item:any, index:number) => (
                <TableRow key={index} >
                  <TableCell style={{width:"40%"}}>
                    <Box className="d-flex" >
                      <Box>
                        <Avatar
                          alt='no-group'
                          src={GroupStrick}
                          sx={{ width: 30, height: 30 }}
                        />
                      </Box>
                      <Box className='user-detail' >
                        <Tooltip title={item?.userEntity?.firstName} placement="bottom-start">
                          <span className='primary-text ellipsisText1'>{item?.userEntity?.firstName}</span>
                        </Tooltip>
                        <span className='secondary-text'>{item?.userEntity?.roleName || "-"}</span>
                      </Box>
                    </Box>
                  </TableCell>
                  <TableCell style={{width:"30%"}}>
                    <Tooltip title={item?.eventType} placement="bottom-start" >
                      <span className="primary-text text-ellips" >{item?.eventType || "-"}</span>
                    </Tooltip>
                  </TableCell>
                  {/* <TableCell style={{width:"15%"}}>
                    <Box style={{paddingLeft:"15px", cursor: "pointer"}} >
                      {item?.auditReason ? (
                        <ReasonIcon
                          onClick={() => {
                            setShowRejectReasonPopup(true)
                            setRejectReason(item?.auditReason)
                          }}
                        />
                      ) : (
                        <span>-</span>
                      )}
                    </Box>
                  </TableCell> */}
                  <TableCell style={{width:"20%"}}>
                    <div className="primary-text" >{convertToDisplayDateTime(item?.createdAt)}</div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        )
      }
    const clg = useMemo(() => {
      if (edu) {
        const eduData = edu?.slice(1, edu.length - 1)?.filter((item: string) => item != '...')
        return eduData?.join(' ')
      }
      return ''
    }, [edu])

    useEffect(() => {
        getStatusLog()
    },[])
    return (
        <div className='basic-detail-main'>
            <div className='basic-detail-sec-main'>
                <p className='detail-header'>Personal Details</p>
                <div className='details'>
                    <div className='details-every'>
                        <p className='label'>Date of Birth</p>
                        <p className='value'>{candidateDetailsData?.dateOfBirth ? candidateDetailsData?.dateOfBirth :'-'}</p>
                    </div>
                    <div className='details-every'>
                        <p className='label'>Gender</p>
                        <p className='value'>{candidateDetailsData?.personalDetails?.[0]?.gender}</p>

                    </div>
                    <div className='details-every'>
                        <p className='label'>Physically Challenged</p>
                        <p className='value'>{candidateDetailsData?.personalDetails?.[0]?.physically_challenged ? candidateDetailsData?.personalDetails?.[0]?.physically_challenged : "-"}</p>

                    </div>
                    <div className='details-every'>
                        <p className='label'>Address</p>
                        <p className='value'>{candidateDetailsData?.address ? candidateDetailsData?.address :'-' }</p>

                    </div>
                    <div className='details-every'>
                        <p className='label'>Marital Status</p>
                        <p className='value'>{candidateDetailsData?.personalDetails?.[0]?.marital_status ? candidateDetailsData?.personalDetails?.[0]?.marital_status : "-"}</p>

                    </div>
                    <div className='details-every'>
                        <p className='label'>Profile Source</p>
                        <p className='value'>{candidateDetailsData?.sourceFrom ? candidateDetailsData?.sourceFrom : "-"}</p>

                    </div>
                    <div className='details-every'>
                        <p className='label'>Total Experience</p>
                        <p className='value'>{candidateDetailsData?.experience ? candidateDetailsData?.experience : "-"}</p>

                    </div>
                    <div className='details-every'>
                        <p className='label'>Alternate number</p>
                        <p className='value'>{candidateDetailsData?.personalDetails?.[0]?.alternate_phone_number ? candidateDetailsData?.personalDetails?.[0]?.alternate_phone_number : "-"}</p>

                    </div>
                    
                    <div className='details-every'>
                        <p className='label'>Duplicate Profile URL</p>
                        <p className='value'>{candidateDetailsData?.duplicatProfile ? candidateDetailsData?.duplicatProfile : "-"}</p>

                    </div>
                </div>
            </div>
            <div className='basic-detail-sec-main'>
                <p className='detail-header'>Desired Job detail</p>
                <div className='details'>
                    <div className='details-every'>
                        <p className='label'>Current CTC</p>
                        <p className='value ctc'><CurrencyRupeeIcon />{candidateDetailsData?.ctcInLacs ? candidateDetailsData?.ctcInLacs : '-'} Lacs (expects: <CurrencyRupeeIcon />{candidateDetailsData?.expectedCtc ? candidateDetailsData?.expectedCtc : '-'} Lacs)</p>

                    </div>
                    {/* <div className='details-every'>
                        <p className='label'>Expcted CTC</p>
                        <p className='value'>7.5L/ Annum</p>

                    </div> */}
                    <div className='details-every'>
                        <p className='label'>Current Location</p>
                        <p className='value'>{candidateDetailsData?.currentLocation ? candidateDetailsData?.currentLocation : '-'}</p>

                    </div>
                    <div className='details-every'>
                        <p className='label'>Preferred Location</p>
                        <p className='value'>{candidateDetailsData?.preferredLocations}</p>

                    </div>
                    <div className='details-every'>
                        <p className='label'>Notice Period</p>
                        <p className='value'>{candidateDetailsData?.noticePeriod ? candidateDetailsData?.noticePeriod :'-'}</p>

                    </div>
                    <div className='details-every'>
                        <p className='label'>Employment status</p>
                        <p className='value'>{candidateDetailsData?.desiredJobDetails?.[0]?.employment_status ? candidateDetailsData?.desiredJobDetails?.[0]?.employment_status :'-'}</p>
                    </div>
                </div>
            </div>
            <div className='basic-detail-sec-main'>
                <p className='detail-header'>Academics</p>
                { (candidateDetailsData?.status == 'complete') ? 
                <>
                {academicExperienceList?.map((data:any,index:number)=>{
                    return <div className='academics' key={index}>
                    <p className='degree'>{data?.designation ? `${data?.designation?.split(',')[0]} ${data?.designation?.split(',')[1].length > 0 ? `(${data?.designation?.split(',')[1]})`:""}`:''}</p>
                    <p className='clg'>{data?.institute}</p>
                    <p className='year'>Year of passing - {data?.designation ? data?.designation?.split(',')[2]?.split('-')[0]:''}</p>
                </div>
                })
                }
                </>:<>{
                }
                 <div className='academics' >
                    <p className='degree'>{edu?.[0]}</p>
                    <p className='clg'>{clg}</p>
                    <p className='year'>Year of passing - {edu?.[edu.length-1]}</p>
                </div>
                </>
                }
            </div>
            <div className='basic-detail-sec-main'>
                <p className='detail-header'>Public profile</p>
                <Box className="socialLinkList">
                    {candidateDetailsData?.linkedin?.length > 0 && <Box><LinkedIn linkedIn={candidateDetailsData.linkedin}/></Box>}
                    {candidateDetailsData?.faceBook?.length > 0 && <Box><Facebook facebook={candidateDetailsData.faceBook}/></Box>}
                    {candidateDetailsData?.twitter?.length > 0 && <Box><Github github={candidateDetailsData.twitter}/></Box>}
                    {candidateDetailsData?.social?.length > 0 && <Box><Stackoverflow stackoverflow={candidateDetailsData.social}/></Box>}
                </Box>                
                <div>
                </div>
            </div>
            <div className='basic-detail-sec-main'>
                <p className='detail-header'>Attached CV</p>
                {candidateDetailsData?.candidateResume != null && <a className='detail-resume' href={candidateDetailsData?.candidateResume} download={candidateDetailsData?.candidateResume?.split('/')[4]}>
                    <InsertDriveFileOutlinedIcon className='insert-file' />
                    <p>{candidateDetailsData?.candidateResume.substring(candidateDetailsData?.candidateResume.lastIndexOf("/") + 1, candidateDetailsData?.candidateResume.length)}</p>
                    <FileDownloadOutlinedIcon className='download-file' />
                </a>}
            </div>
            <div className='basic-detail-sec-main'>
                <p className='detail-header'>Remarks</p>
                <p>{candidateDetailsData?.remarks}</p>
            </div>
            <div className='basic-detail-sec-main'>
                <p className='detail-header'>Audit Log</p>
                <Logs />
            </div>           
        </div>
    )
}

export default BasicDetails;
