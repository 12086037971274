import React, { useState, useEffect } from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { Table, TableBody, TableCell, TableContainer, Box, TableHead, TableRow, Paper, Checkbox, FormControl, InputLabel, Input, InputAdornment, OutlinedInput, MenuItem, Select, Pagination, Tooltip } from "@mui/material"
import TablePagination from '@mui/material/TablePagination';
import { models } from 'powerbi-client';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import { ReactComponent as SortUpDown } from '../../../../assets/icons/sort-up-down.svg'
import { ReactComponent as SortUp } from '../../../../assets/icons/sort-up.svg'
import { ReactComponent as SortDown } from '../../../../assets/icons/sort-down.svg'
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg'
import './DashboardFilter.scss'
import { getPowerBiToken } from '../../../../services/CandidateService';
import { AccountCircle } from "@mui/icons-material";
import {ReportkManagementService} from "../../../../services/ReportListService"
import { convertToDisplayDate, convertToDisplayDateTime, convertToDisplayDateReport } from '../../../../utils/date-utils'
import { MandateService, dropDownData, dropDownLocation, getAllListMandates, getDepartmentRoles, getIndustryData, getMandateLocation, getbranchlocation, getprimaryskill } from "../../../../services/MandateService";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../../../store/hooks";
import { IMaskInput } from 'react-imask';
import { useLocation, useNavigate } from "react-router";
import { reportFilterLoadData } from "../../../../features/filters/FilterReducer";
import { dashoardClient, MandateSPOCapi } from "../../../../services/DashboardService";
import { getBussinessUnit, getSystemConfig, userRoleHierarchyAPI } from "../../../../services/UserService";
import DashboardFilter from "./DashboardFilter";
import { toast } from "react-toastify";
import { setMandateTypeValue } from "../../../../features/mandateDetail/profilePersistReducer";


interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
    function TextMaskCustom(props, ref) {
      const { onChange, ...other } = props;
      return (
        <IMaskInput
          {...other}
          mask="0000-00-00"
          definitions={{
            '#': /[1-9]/,
          }}
          inputRef={ref}
          onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
          overwrite
        />
      );
    },
  );

const DashboardFilterIndex = ({setReportSubmissionAPIData,setDashboardFilterDatas,setreportSubmitdatalabels,HirningDemandParamsDetails,setHirningDemandParamsDetails,reportSubmissionAPIData,hideFieldsList,headerTitle,downloadName,datePickerType,dateYearEnable,setUserRoleObjectSelected,setUserDependendObj,exportdatareqest,filterType,hideForm,screenType,hiringDemandTabDetail}:any) => {
    
    const [reportData, setReportData] = useState<any>([])
    const [pageCount, setPageCount] = useState(0);
    const [pageSize, setPageSize] = useState(10)
    const [defaultPageCount, setDefaultPageCount] = useState(1)
    const [tableKeyWordSearch, setTableKeyWordSearch] = useState("");
    const [tableHeadKeyWordSearch, setTableHeadKeyWordSearch] = useState("");
    const [mLocationList, setMLocationList] = useState([])
    const dispatch = useAppDispatch()
    const navigate =useNavigate()
    const [reportFilterPreload, setReportFilterPreload] = useState({})
    const location = useLocation()
    const candidaterouteState = location?.state
    const reportDataCandidateDetails = useAppSelector((state) => state.filtersReducer.reportFiler)
    const [resetFilter, setResetFilter] = useState(false)

    const [reportClientAPIList,setReportClientAPIList] = useState([])
    
    const [Coelist,setCoelist] = useState<any>([]);
    const [ClientList, setClientList] = useState<any>([]);
    const [businessUnitList, setBusinessUnitList] = useState<any>([]);
    const [jobModeList, setjobModeList] = useState<any>([]);
    const [buheadList, setbuheadList] = useState<any>([]);
    const [industryList, setindustryList] = useState<any>([]);
    // const [mandateSpocList, setMandateSpocList] = useState<any>([]);
    const [mandateTypeList, setMandateTypeList] = useState<any>([]);
    const [JobtitleList, setJobtitleList] = useState<any>([]);
    const [JobLocationList, setJobLocationList] = useState<any>([]);
    const [rolesdataList, setrolesdataList] = useState<any>([]);
    const [primaryskillList,setprimaryskillList] = useState<any>([]);
    const [apiCount, setApiCount] = useState(0)
    
    const [industryData, setIndustryData] = useState<any>([])
    const [deptData, setDeptData] = useState<any>([])
    const userDetaiId = useAppSelector((state) => state.UserDetail.userId)
    const userDetailBuId = useAppSelector((state) => state.UserDetail.businessUnitId)
    const [userRoleListObject, setUserRoleListObject] = useState<any>(HirningDemandParamsDetails?.state != null ? HirningDemandParamsDetails?.state?.userFilterObj ?? [] : [])
      function prepend(value:any, array:any) {
        const newArray = array.slice();
        newArray.unshift(value);
        return newArray;
      }

      const getClientList = async () => {
        await MandateService.getClientname({"buIds": HirningDemandParamsDetails.state != null ? ("clientDependedObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails.state.clientDependedObj.buId : []) : (userDetailBuId != null && userDetailBuId !=  0 ? [userDetailBuId.toString()] : [])})
        .then((res: any) => {
          let options = _.map(res.data.result.list[0], (data: any) => {
            return {
              label: _.get(data, 'clientName'),
              value: _.get(data, 'id'),
            }
          }
        ) 
          options = prepend({label: "All",value: "All"}, options)
          const uniqueData = options.filter((item:any, index:any, self:any) =>
              index === self.findIndex((t:any) => t.label === item.label)
          );
          setReportClientAPIList(uniqueData)
        })
        .catch((error: any) => {
          console.log(error.message)
        })  
      }


      // const fetchbranchLocations = async () => {
      //   await getbranchlocation()
      //   .then((res:any)=>{
      //       const options = _.map(res.data, (data: any) => {
      //           return {
      //             label: _.get(data, 'branchName'),
      //             value: _.get(data, 'id'),
      //           }
      //         })
      //         // options = prepend({label: "All",value: "All"}, options)
      //         setCoelist(options);
      //         console.log("My COE : ",options)          
      //   })
      //   .catch((error: any)=>{
      //       console.log(error.message)
      //   })
      // }


        const fetchbranchLocations = async () => {
         await getbranchlocation()
         .then((res: any) => {
             const filteredData = _.filter(res.data, (data: any) => {
                return data.branchName !== "Participated" && data.branchName !== "Assigned" ;
             });
    
             const options = _.map(filteredData, (data: any) => {
                 return {
                      label: _.get(data, 'branchName'),
                      value: _.get(data, 'id'),
                  }
              });
    
            // You can prepend an "All" option if needed
            // options = [{ label: "All", value: "All" }, ...options];
    
             setCoelist(options);
             console.log("My COE:", options);
         })
         .catch((error: any) => {
            console.log(error.message);
         });
     }


    

      

//   const getClientDataApi = async () => {
//     try {
//         const response = await dashoardClient();
//         if (response?.data?.result && response?.data?.result.list.length > 0) {
//             const clients = response.data.result.list[0].map((client: any) => client.clientName);
//             console.log("clients",clients)
//             setClientList(clients);
//         }
//     } catch (error: any) {
//         console.log(error);
//     }
// };

const getBussinessUnitDetailsApi = async () => {
    try {
        // const res: any = await getBussinessUnit();
        // const businessUnits = res?.data?.map((item: any) => item?.businessUnitName);
        // setBusinessUnitList(businessUnits);
        await getBussinessUnit({"buIds": (userDetailBuId != null && userDetailBuId !=  0) ? [userDetailBuId?.toString()] : []})
        .then((res: any) => {
            const Bussnussuinitoptions = _.map(res?.data, (data: any) => {
              return {
                label: _.get(data, 'businessUnitName'),
                value: _.get(data, 'id'),
              }
            })
            
            // Bussnussuinitoptions = prepend({label: "All",value: "All"}, Bussnussuinitoptions)
            setBusinessUnitList(Bussnussuinitoptions)
        })
        
    } catch (error: any) {
        console.log(error);
    }
};


const getprimaryskillDetailsApi = async () => {

    const reqObj = {
        "keyword": null,
        "pageNumber": 0,
        "pageSize" : Number.MAX_SAFE_INTEGER
    } 

    try {
      await getprimaryskill(reqObj)
        .then((res: any) => {
            const primaryskilloptions = _.map(res?.data, (data: any) => {
              return {
                label: data,
                value: data,
              }
            })
            const uniqueData = primaryskilloptions.filter((item:any, index:any, self:any) =>
                index === self.findIndex((t:any) => t.label === item.label)
            );
            // primaryskilloptions = prepend({label: "All",value: "All"}, primaryskilloptions)
            setprimaryskillList(uniqueData);
        })
      

    } catch (error) {
      console.log(error);
    }
  };
  



const getJobtitleListApi = async () => {
    try {
        await getAllListMandates().then((res: any) => {
            const dataItems = res?.data?.result?.list || []
            if (dataItems) {
                const filterData = dataItems.filter((item: any) => item?.status !== 'draft')
                
                // Use a Set to track unique job titles
                const uniqueJobTitles = new Set();
                const options :any = [];

                filterData.forEach((data: any) => {
                    const jobTitle = _.get(data, 'jobtitle');
                    const mandateCode = _.get(data, 'mandatecode');

                    // Check if the job title is already in the set
                    if (!uniqueJobTitles.has(jobTitle)) {
                        uniqueJobTitles.add(jobTitle);
                        options.push({
                            label: jobTitle,
                            value: mandateCode,
                        });
                    }
                });
                
                setJobtitleList(options);
            } else {
                setJobtitleList([]);
            }
        });
    } catch (error: any) {
        console.log(error);
    }
};


const fetchLocations = async () => {
    const reqObj = {
      "filters": [
          {
              "field": "parentId",
              "operator": "equals",
              "type": "string",
              "value": "0"
          }
      ],
      "page": {
          "pageNo": -1,
          "pageSize": 10
      },
      "sorting": {
          "direction": "ASC",
          "field": "createdAt"
      }
  }
    try{
      const res:any = await dropDownLocation(reqObj)
      const options = _.map(res.data.result.list, (data: any) => {
        return {
          label: _.get(data, 'locationName'),
          value: _.get(data, 'id')
        }})
        setJobLocationList(options);

    } catch {
        setJobLocationList([]);
    }
  }


  const getindustrylistApi = async () => {
    const reqObj = {
      "filters": [],
      "page": {
        "pageNo": 0,
        "pageSize": 10
      },
      "sorting": {
        "direction": "ASC",
        "field": "createdAt"
      }
    }
    try {
      await getIndustryData(reqObj)
      .then((res: any) => {
        let industrrtlistoptions = _.map(res?.data?.result?.list, (data: any) => {
          return {
            label: _.get(data, 'industryCategoryName'),
            value: _.get(data, 'id'),
          }
        })
        industrrtlistoptions = prepend({label: "All",value: "All"}, industrrtlistoptions)
        setindustryList(industrrtlistoptions)
    });

    //   setindustryList(industRes.data.result.list);

      await getDepartmentRoles(reqObj)
      .then((res: any) => {
        console.log("job option :",res?.data?.result?.list)
        let rolelistoptions = _.map(res?.data?.result?.list?.map((departmentRoles:any)=>departmentRoles), (data: any) => {
          return {
            label: _.get(data, 'depName'),
            value: _.get(data, 'id'),
          }
        })
        rolelistoptions = prepend({label: "All",value: "All"}, rolelistoptions)
        // setrolesdataList(rolelistoptions)
    })

    //   setDeptData(departRes.data.result.list);
    } catch {
      setIndustryData([]);  
    }
  }
  





const getsomedropdownlist = async () => {
    const reqObj = {
        businessUnit: false,
        appConfiguration: false,
        dropdownRequest: [
            { drpCode: "XPN0001", drpName: "MANDATE_TYPE" },
            { drpCode: "XPN0002", drpName: "SHIFT_TYPE" },
            { drpCode: "XPN0003", drpName: "Job_Type" },
            { drpCode: "XPN0004", drpName: "Job_Mode" },
            { drpCode: "XPN0005", drpName: "SPOC_Type" },
            { drpCode: "XPN0006", drpName: "Permissions" }
        ]
    };
    await dropDownData(reqObj)
    .then((res: any) => {
      const Jobmodeoptions = _.map(res?.data?.result?.dropdownData?.Job_Mode, (data: any) => {
        return {
          label: _.get(data, 'domValue'),
          value: _.get(data, 'id'),
        }
      })
      
      // Jobmodeoptions = prepend({label: "All",value: "All"}, Jobmodeoptions)
      setjobModeList(Jobmodeoptions)


      const Mandatetypeoptions = _.map(res?.data?.result?.dropdownData?.MANDATE_TYPE, (data: any) => {
        return {
          label: _.get(data, 'domValue'),
          value: _.get(data, 'id'),
        }
      })
      setMandateTypeList(Mandatetypeoptions)
      dispatch(setMandateTypeValue(Mandatetypeoptions))
    })
    .catch((error: any) => {
      console.log(error.message)
    })  
};


const mandatespocapicall = async () =>{
  const reqObj = {
    "filter":"",
    "pageNumber":0,
    "pageSize":Number.MAX_SAFE_INTEGER,
    "clientId":[]
}

  try {
    const res = await MandateSPOCapi(reqObj);
    console.log("mandateiiiing",res)

    const Mandatespocoptions = _.map(res?.data, (data: any,index:number) => {
      return {
        label: data,
        value: index,
      }
    })

    // Mandatespocoptions = prepend({label: "All",value: "All"}, Mandatespocoptions)
    // setMandateSpocList(Mandatespocoptions)
  } catch (error) {
    console.log(error, "config api error");
  }
}



const getAppConfig = async () => {
  const reqObj = {
    businessUnit: true,
    appConfiguration: true,
    dropdownRequest: []
  }

  try {
    const res = await getSystemConfig(reqObj);
    
    if (res.status === 200) {
      // Transform the response data to extract roleName and id
      const rolelistoptions = res?.data?.result?.roles?.map((item: any,index:number) => {
        console.log("roleistdataList", item?.roleName);
        return {
          label: item?.roleName, // Extract roleName for the label
          value: item?.id,        // Extract id for the value
        }
      });
      setrolesdataList(rolelistoptions);
    }
  } catch (error) {
    console.log(error, "config api error");
  }
}

  const handleApiCall = (callStatus:any) => {
    if(callStatus && apiCount == 0){
      if(!hideFieldsList.includes("Business Unit"))getBussinessUnitDetailsApi()
      if(!hideFieldsList.includes("Job Mode"))getsomedropdownlist()
      if(!hideFieldsList.includes("Role"))getAppConfig()
      if(!hideFieldsList.includes("Job Title"))getJobtitleListApi()
      if(!hideFieldsList.includes("JO Location")) fetchLocations()
      if(!hideFieldsList.includes("COE"))fetchbranchLocations()
      if(!hideFieldsList.includes("Primary Skill"))getprimaryskillDetailsApi()
      if(!hideFieldsList.includes("Client"))getClientList()
      setApiCount(apiCount + 1)
    }    
  }



      const fetchData = async () => {
        const reqObj = {
          "filters": [],
          "page": {
            "pageNo": 0,
            "pageSize": 10
          },
          "sorting": {
            "direction": "ASC",
            "field": "createdAt"
          }
        }
        try {
          const industRes: any = await getIndustryData(reqObj)  
          setIndustryData(industRes.data.result.list);
          const departRes:any = await getDepartmentRoles(reqObj)
          setDeptData(departRes.data.result.list);
        } catch {
          setIndustryData([]);  
        }
      }
  

      const renderEmptySearchBox = () => {;
        return (
            <Box className="empty-data-card">
                <Box>
                    <NoResultFound />
                </Box>
                <Box className="empty-search-message">
                    No data found
                </Box>
            </Box>
        )
    }

    const [values, setValues] = React.useState({
        textmask: '',
        numberformat: '1320',
      });

    const reportCandidateName = (candidateId:string) => {
        dispatch(reportFilterLoadData({reportFiler: {...reportDataCandidateDetails, ...{reportList: reportData,pageCount: pageCount,defaultPageCount: defaultPageCount, pageSize: pageSize}}}))
        navigate(`/candidate/${candidateId}`, {state:"submissionReportCandidate"})
    }

    useEffect(() => {
        if(candidaterouteState == "submissionReportCandidate"){
            navigate(location.pathname, {});
            setReportData(reportDataCandidateDetails?.reportList)
            setReportFilterPreload(reportDataCandidateDetails)
            setPageCount(reportDataCandidateDetails?.pageCount);
            setPageSize(reportDataCandidateDetails?.pageSize)
            setDefaultPageCount(reportDataCandidateDetails?.defaultPageCount)
        }else{
            // dispatch(reportFilterLoadData({reportFiler: {}}))
        }
    },[candidaterouteState])

    useEffect(() => {
        if(resetFilter){
            setPageCount(0);
            setPageSize(10)
            setDefaultPageCount(1)
            setResetFilter(false)
        }       
    },[resetFilter])

    const loadUserRoleObject = async (userList: any, type:string, buId:any) => {
      const requestBody:any = {
        "users": userList,
        "self": false,
        "buId" : buId
      }  
      if(type == "initial")requestBody["buId"] = buId.length > 0 ? buId : HirningDemandParamsDetails.state != null ? ("clientDependedObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails.state.clientDependedObj != undefined ? HirningDemandParamsDetails.state.clientDependedObj.buId.map((item:any) => parseInt(item)) : [] : []) : (userDetailBuId != null && userDetailBuId !=  0) ? [userDetailBuId] : []
      if(type == "reset")requestBody["buId"] = buId.length > 0 ? buId : HirningDemandParamsDetails.state != null ? ("clientDependedObj" in HirningDemandParamsDetails.state ?HirningDemandParamsDetails.state.clientDependedObj !=  undefined ? HirningDemandParamsDetails.state.clientDependedObj.buId.map((item:any) => parseInt(item)) : [] : []) : (userDetailBuId != null && userDetailBuId !=  0) ? [userDetailBuId] : []
      try {
        const res = await userRoleHierarchyAPI(requestBody)
        if (res?.status === 200) {
        const roleData = res.data.data
        if (roleData.length > 0) {
          const groupByRoleName = (data: any) => {
          return data.reduce((result: any, item: any) => {
            const roleName = item.role_name;
            if (!result[roleName]) {
            result[roleName] = [];
            }
            result[roleName].push(item);
            return result;
          }, {});
          };
          const groupedData = groupByRoleName(roleData);
          const getKeys: any = Object.keys(groupedData)
          const renderObj = {
          role: {
            selectedValue: "",
            option: getKeys,
            groupedKeys: groupedData
          },
          user: {
            selectedValue: [],
            option: []
          }
          }
          console.log("renderObj", renderObj)
          if(type == "reset" || type.length == 0){
            setUserRoleListObject([renderObj])
          }else{
            setUserRoleListObject((prevState: any) => [
              ...prevState,
              renderObj
            ])
          }		
        } else {
          if(type != "initial" && type != "reset"){
            toast.warning('There is no heirarchy for selected user')
          }else{
            setUserRoleListObject([])
          }
        }
        }
      } catch (error) {
        console.log(error)
      }
      }
  

    useEffect(() => {
      if(HirningDemandParamsDetails?.state == null || HirningDemandParamsDetails?.state?.userFilterObj == undefined || HirningDemandParamsDetails?.state?.userFilterObj.length == 0){
        loadUserRoleObject([userDetaiId],"initial",[])
      }else{
        setUserRoleListObject(HirningDemandParamsDetails?.state?.userFilterObj)
      }
    },[])
    useEffect(() => {
      setUserRoleObjectSelected(userRoleListObject)
    },[userRoleListObject])

    return (
        < >
            <Box>
              {(Coelist && reportClientAPIList && jobModeList && mandateTypeList && businessUnitList && JobtitleList && JobLocationList && primaryskillList &&  rolesdataList) && <DashboardFilter HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} Coelist={Coelist} reportClientAPIList={reportClientAPIList} jobModeList={jobModeList} mandateTypeList={mandateTypeList} businessUnitList={businessUnitList} JobtitleList={JobtitleList} JobLocationList={JobLocationList} rolesdataList={rolesdataList} primaryskillList={primaryskillList} setReportSubmissionAPIData={setReportSubmissionAPIData} setDashboardFilterDatas={setDashboardFilterDatas} setreportSubmitdatalabels={setreportSubmitdatalabels} setResetFilter={setResetFilter} hideFields={hideFieldsList} handleApiCall={handleApiCall} reportSubmissionAPIData={reportSubmissionAPIData} headerTitle={headerTitle} downloadName={downloadName} datePickerType={datePickerType} dateYearEnable={dateYearEnable} userRoleListObject={userRoleListObject} setUserRoleListObject={setUserRoleListObject} userRoleChange={(e:any, type:string, buIdValue:any) => {loadUserRoleObject(e,type,buIdValue)}} setUserDependendObj={setUserDependendObj} exportdatareqest={exportdatareqest} filterType={filterType} hideForm={hideForm} screenType={screenType} hiringDemandTabDetail={hiringDemandTabDetail}/>}
            </Box>

        </>
    )

}

export default DashboardFilterIndex