import React, { useState, useEffect, useCallback } from "react";
import { PowerBIEmbed } from 'powerbi-client-react';
import { Table, TableBody, TableCell, TableContainer, Box, TableHead, TableRow, Paper, Checkbox, FormControl, InputLabel, Input, InputAdornment, OutlinedInput, MenuItem, Select, Pagination, Tooltip, debounce } from "@mui/material"
import TablePagination from '@mui/material/TablePagination';
import { models } from 'powerbi-client';
import { ReactComponent as SearchIcon } from '../../assets/icons/search.svg'
import { ReactComponent as SortUpDown } from '../../assets/icons/sort-up-down.svg'
import { ReactComponent as SortUp } from '../../assets/icons/sort-up.svg'
import { ReactComponent as SortDown } from '../../assets/icons/sort-down.svg'
import { ReactComponent as NoResultFound } from '../../assets/icons/no-result-found.svg'
import './Reports.scss'
import {Filter} from "./Filters/Filter"
import { getPowerBiToken } from '../../services/CandidateService';
import { AccountCircle } from "@mui/icons-material";
import {ReportkManagementService} from "../../services/ReportListService"
import { convertToDisplayDate, convertToDisplayDateTime, convertToDisplayDateReport } from '../../utils/date-utils'
import { MandateService, getCandidateStageList, getCandidateStatusList, getDepartmentRoles, getIndustryData, getMandateLocation } from "../../services/MandateService";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { IMaskInput } from 'react-imask';
import { useLocation, useNavigate } from "react-router";
import { reportFilterLoadData } from "../../features/filters/FilterReducer";
import { getBussinessUnit, userRoleHierarchyAPI } from "../../services/UserService";
import { toast } from "react-toastify";
import moment from "moment";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }

const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
    function TextMaskCustom(props, ref) {
      const { onChange, ...other } = props;
      return (
        <IMaskInput
          {...other}
          mask="0000-00-00"
          definitions={{
            '#': /[1-9]/,
          }}
          inputRef={ref}
          onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
          overwrite
        />
      );
    },
  );

const Report = () => {
    const [sortColumn, setSortColumn] = useState<any>()
    const referAPIObjKeys:any = {
        "S.NO":"",
        "Name of the candidate": "candidateName",
        "Job Title": "jobTitle",
        "Client": "client",
        "Current Organization": "currentOrganization",
        "Total exp": "totalExperience",
        "Relevant Exp": "relevantExperience",
        "Current Location": "currentLocation",
        "Preferred Location": "preferredLocation",
        "Notice Period": "noticePeriod",
        "Contact Number": "contactNumber",
        "e-Mail": "email",
        "Current CTC": "currentCTC",
        "Expected CTC": "expectedCTC",
        "Source": "source",     
        "CV Send Date": "cvSentDate",       
        "Stage": "stage",
        "Status": "status",
        "Recruiter Name": "recruiterName",
        "Profile entry date in the Artemis": "profileEntryDate",
        "Profile last modified date": "profileLastModifiedDate",
        "Profile last active date": "profileLastActiveDate",
            
    }
    const [searchColumnKeys, setSearchColumnKeys] = useState<any>({})
    const [columnFields, setColumnFields] = useState<any>({
            "Job Title": true,
            "Client": true,
            "Current Organization": false,
            "Total exp": true,
            "Relevant Exp": false,
            "Current Location": true,
            "Preferred Location": false,
            "Notice Period": true,
            "Contact Number": false,
            "e-Mail": false,
            "Current CTC": true,
            "Expected CTC": false,
            "Source": false,
            "CV Send Date": true,
            "Stage": true,
            "Status": true,
            "Recruiter Name": true,
            "Profile entry date in the Artemis": false,
            "Profile last modified date": false,
            "Profile last active date": false
    })
    const [reportData, setReportData] = useState<any>([])
    const [pageCount, setPageCount] = useState(0);
    const [pageSize, setPageSize] = useState(10)
    const [defaultPageCount, setDefaultPageCount] = useState(1)
    const [tableKeyWordSearch, setTableKeyWordSearch] = useState("");
    const [tableHeadKeyWordSearch, setTableHeadKeyWordSearch] = useState("");
    const [mLocationList, setMLocationList] = useState([])
    const dispatch = useAppDispatch()
    const navigate =useNavigate()
    const [reportFilterPreload, setReportFilterPreload] = useState({})
    const location = useLocation()
    const candidaterouteState = location?.state
    const reportDataCandidateDetails = useAppSelector((state) => state.filtersReducer.reportFiler)
    const [resetFilter, setResetFilter] = useState(false)

    const [reportClientAPIList,setReportClientAPIList] = useState([])
    const [reportBussinessUnitAPIList,setReportBussinessUnitAPIList] = useState([])
    const [reportBussinessAPIList,setReportBussinessAPIList] = useState([])
    const [industryData, setIndustryData] = useState<any>([])
    const [deptData, setDeptData] = useState<any>([])
    const userDetaiId = useAppSelector((state) => state.UserDetail.userId)
    const userDetailBuId = useAppSelector((state) => state.UserDetail.businessUnitId)
    const [stageStatusObjData, setStageStatusObjData] = useState({})
    const [reportSubmissionAPIData, setReportSubmissionAPIData] = useState<any>({
        "candidateName": "",
        "jobTitle": "",
        "client": "",
        "currentOrganization": "",
        "totalExperience": "",
        "relevantExperience": "",
        "currentLocation": "",
        "preferredLocation": "",
        "noticePeriod": "",
        "contactNumber": "",
        "email": "",
        "currentCTC": "",
        "expectedCTC": "",
        "cvSentDate": "",
        "source": "",
        "createdBy": "",
        "createdAt": "",
        "stage": "",
        "status": "",       
        "profileEntryDate": "",
        "profileLastModifiedDate": "",
        "profileLastActiveDate": "",
        "pageSize": 10,
        "pageNo": 1,
        "clientName": "",
        // "buHead": "",
        // "deliveryManager": "",
        // "teamLead": "",
        // "accountsManager": "",
        // "recruiterName": "",
        "userId":userDetaiId,
        "phoneNumber": "",
        "industry": [],
        "department":[],
        "mandateId":"",
        "locationId":"",
        "recruiter":"",
        "sortColumn":"",
        "sortDirection":"",
        "buId":""
})
    const [userRoleListObject, setUserRoleListObject] = useState<any[]>([])
    const [selectedFilterObjectValue,setSelectedFilterObjectValue] = useState({})
    const [reportHeader,setReportHeader] = useState<any>(Object.keys(referAPIObjKeys))
    const getReportListAPI = async () => {  
        const reportSubmissionAPIDataCopy = JSON.parse(JSON.stringify(reportSubmissionAPIData));
        reportSubmissionAPIDataCopy["pageNo"] = defaultPageCount
        await ReportkManagementService.getReportList(reportSubmissionAPIDataCopy)       
        .then((res: any) => {
            setReportData(res?.data?.result)
            setPageSize(res?.data?.result.inputPageSize)
            setPageCount(res?.data?.result.pageSize)
          })
          .catch((error: any) => {
            console.log(error.message)
          })        
    }

    const debouncedSearch = useCallback(    
        debounce((nextValue) => {
          setReportSubmissionAPIData(nextValue)
        }, 500),
        []
      );
      
    const reportQueryParmsHandler = (filterObj:any) => {
        const APIObj = JSON.parse(JSON.stringify(reportSubmissionAPIData))
        Object.keys(filterObj).map((item:any) => {
            APIObj[item == "recruiterName" ? "recruiter" : item] = filterObj[item]
            setSearchColumnKeys((prev:any) => ({
                ...prev,
                [item]: filterObj[item],
              }));
        })
        debouncedSearch(APIObj)
    }
    
    useEffect(() => {
        if(candidaterouteState?.source != "submissionReportCandidate")getReportListAPI()
        console.log("reportSubmissionAPIData",reportSubmissionAPIData)
    },[reportSubmissionAPIData])
    
    const updateSorting = (column: string) => {
        const reportAPIObj = JSON.parse(JSON.stringify(reportSubmissionAPIData));
        let sortDirection = "asce"
        if (sortColumn) {
            if (sortColumn.label == column) {
                if (sortColumn.sort == "asc") {
                    setSortColumn((prev: any) => {
                        return { ...prev, sort: "dec" }
                    })
                    sortDirection = "desc"
                    
                } else if (sortColumn.sort == "dec") {
                    setSortColumn((prev: any) => {
                        return {}
                    })
                    sortDirection = ""
                }
            } else {
                setSortColumn({ label: column, sort: "asc" })
                sortDirection = "asce"
            }
        } else {
            setSortColumn({ label: column, sort: "asc" })
            sortDirection = "asce"
        }
        if(sortDirection.length == 0){
            reportAPIObj["sortColumn"] = ""
            reportAPIObj["sortDirection"] = ""
        }else{
            reportAPIObj["sortColumn"] = referAPIObjKeys[column]
            reportAPIObj["sortDirection"] = sortDirection
        }       
        setReportSubmissionAPIData(reportAPIObj)
    }



      function camelize(str:string) {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function(word, index) {
          return index === 0 ? word.toLowerCase() : word.toUpperCase();
        }).replace(/\s+/g, '');
      }

      function prepend(value:any, array:any) {
        const newArray = array.slice();
        newArray.unshift(value);
        return newArray;
      }


      const bussinessUnitHierarchy = async () => {
        const response = await ReportkManagementService.getBussinessHierarchy()
        setReportBussinessAPIList(response?.data)
      }
      const getBussinessUnitList = async () => {
        await getBussinessUnit({"buIds": userDetailBuId != null ? [userDetailBuId?.toString()] : []})
        .then((res: any) => {
          let options = _.map(res.data, (data: any) => {
            return {
              label: _.get(data, 'businessUnitName'),
              value: _.get(data, 'id'),
            }
          })
          options = prepend({label: "All",value: "All"}, options)
          setReportBussinessUnitAPIList(options)
        })
        .catch((error: any) => {
          console.log(error.message)
        })  
      }
      const getClientList = async () => {
        await MandateService.getClientname({"buIds": candidaterouteState != null ? ("selectedFilterObjectValue" in candidaterouteState ? candidaterouteState?.selectedFilterObjectValue?.bussinessUnit?.value ? [candidaterouteState?.selectedFilterObjectValue?.bussinessUnit?.value] : [] : []) : (userDetailBuId != null && userDetailBuId !=  0 ? [userDetailBuId.toString()] : [])})
        .then((res: any) => {
          let options = _.map(res.data.result.list[0], (data: any) => {
            return {
              label: _.get(data, 'clientName'),
              value: _.get(data, 'id'),
            }
          })
          options = prepend({label: "All",value: "All"}, options)
          setReportClientAPIList(options)
        })
        .catch((error: any) => {
          console.log(error.message)
        })  
      }
      const getCandidateStageListAPI = async () => {
            const response = await getCandidateStageList()      
            if(response?.status === 200){
              console.log("getCandidateStageListAPI",response.data)
              const stageOption =_.map(response?.data, (data: any) => {
                return {
                  label: _.get(data, 'stage_name'),
                  value: _.get(data, 'stage_id'),
                }
              })
              setStageStatusObjData((prev: any) => ({
                ...prev,  
                stageObjData: stageOption
              }));
            }
          }
          const getCandidateStatusListAPI = async () => {
            const response = await getCandidateStatusList({
                "stageIds" : []
            })      
            if(response?.status === 200){
              console.log("getCandidateStatusListAPI",response.data)
              const stageOption =_.map(response?.data, (data: any) => {
                return {
                  label: _.get(data, 'status_name'),
                  value: _.get(data, 'id'),
                }
              })
              setStageStatusObjData((prev: any) => ({
                ...prev,  
                statusObjData: stageOption
              }));
            }
          }
      const fetchData = async () => {
        const reqObj = {
          "filters": [],
          "page": {
            "pageNo": 0,
            "pageSize": 10
          },
          "sorting": {
            "direction": "ASC",
            "field": "createdAt"
          }
        }
        try {
          const industRes: any = await getIndustryData(reqObj)  
          setIndustryData(industRes.data.result.list);
          const departRes:any = await getDepartmentRoles(reqObj)
          setDeptData(departRes.data.result.list);
        } catch {
          setIndustryData([]);  
        }
      }
      const loadUserRoleObject = async (userList: any, type:string, buId:any) => {
            const requestBody:any = {
              "users": userList,
              "self": false,
              "buId" : buId
            }  
            // if(type == "initial")requestBody["buId"] = buId.length > 0 ? buId : HirningDemandParamsDetails.state != null ? ("clientDependedObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails.state.clientDependedObj != undefined ? HirningDemandParamsDetails.state.clientDependedObj.buId.map((item:any) => parseInt(item)) : [] : []) : (userDetailBuId != null && userDetailBuId !=  0) ? [userDetailBuId] : []
            // if(type == "reset")requestBody["buId"] = buId.length > 0 ? buId : HirningDemandParamsDetails.state != null ? ("clientDependedObj" in HirningDemandParamsDetails.state ?HirningDemandParamsDetails.state.clientDependedObj !=  undefined ? HirningDemandParamsDetails.state.clientDependedObj.buId.map((item:any) => parseInt(item)) : [] : []) : (userDetailBuId != null && userDetailBuId !=  0) ? [userDetailBuId] : []
            if(type == "initial")requestBody["buId"] = buId.length > 0 ? buId : []
            if(type == "reset")requestBody["buId"] = buId.length > 0 ? buId : []
            try {
              const res = await userRoleHierarchyAPI(requestBody)
              if (res?.status === 200) {
              const roleData = res.data.data
              if (roleData.length > 0) {
                const groupByRoleName = (data: any) => {
                return data.reduce((result: any, item: any) => {
                  const roleName = item.role_name;
                  if (!result[roleName]) {
                  result[roleName] = [];
                  }
                  result[roleName].push(item);
                  return result;
                }, {});
                };
                const groupedData = groupByRoleName(roleData);
                const getKeys: any = Object.keys(groupedData)
                const renderObj = {
                role: {
                  selectedValue: "",
                  option: getKeys,
                  groupedKeys: groupedData
                },
                user: {
                  selectedValue: [],
                  option: []
                }
                }
                console.log("renderObj", renderObj)
                if(type == "reset" || type.length == 0){
                  setUserRoleListObject([renderObj])
                }else{
                  setUserRoleListObject((prevState: any) => [
                    ...prevState,
                    renderObj
                  ])
                }       
              } else {
                setUserRoleListObject(userRoleListObject.length > 0 ? userRoleListObject : [])
                if(type != "initial" && type != "reset")toast.warning('There is no heirarchy for selected user')
              }
              }
            } catch (error) {
              console.log(error)
            }
            }
  
  
      useEffect(() => {
        getClientList()
        getBussinessUnitList()
        getCandidateStageListAPI()
        getCandidateStatusListAPI()
        // bussinessUnitHierarchy()
        if(candidaterouteState?.source != "submissionReportCandidate")loadUserRoleObject([userDetaiId],"",userDetailBuId != null ? [userDetailBuId] : [])
        // fetchData()
      },[])

      const renderEmptySearchBox = () => {;
        return (
            <Box className="empty-data-card">
                <Box>
                    <NoResultFound />
                </Box>
                <Box className="empty-search-message">
                    No data found
                </Box>
            </Box>
        )
    }

    const [values, setValues] = React.useState({
        textmask: '',
        numberformat: '1320',
      });

    const reportCandidateName = (candidateId:string) => {
        // dispatch(reportFilterLoadData({reportFiler: {...reportDataCandidateDetails, ...{reportList: reportData,pageCount: pageCount,defaultPageCount: defaultPageCount, pageSize: pageSize}}}))
        navigate(`/candidate/${candidateId}`, {state:{source:"submissionReportCandidate", reportList: reportData,pageCount: pageCount,defaultPageCount: defaultPageCount, pageSize: pageSize, userRoleFilter: userRoleListObject,reportSubmissionAPIData: reportSubmissionAPIData,selectedFilterObjectValue: selectedFilterObjectValue,searchColumnKeys:searchColumnKeys}})
    }

    useEffect(() => {
        // console.log("candidaterouteState",candidaterouteState)
        if(candidaterouteState?.source == "submissionReportCandidate" && candidaterouteState != null){
            const navigatorCandidateData = JSON.parse(JSON.stringify(candidaterouteState))          
            setReportData(candidaterouteState?.reportList)
            setReportFilterPreload(navigatorCandidateData)
            setPageCount(candidaterouteState?.pageCount);
            setPageSize(candidaterouteState?.pageSize)
            setDefaultPageCount(candidaterouteState?.defaultPageCount)
            setUserRoleListObject(candidaterouteState?.userRoleFilter)
            setReportSubmissionAPIData(candidaterouteState.reportSubmissionAPIData)
            setSelectedFilterObjectValue(candidaterouteState.selectedFilterObjectValue)
            setTimeout(() => {
                navigate(location.pathname, {});
            },500)
            setSearchColumnKeys(candidaterouteState.searchColumnKeys)
        }else{
            // dispatch(reportFilterLoadData({reportFiler: {}}))
        }
    },[candidaterouteState])

    useEffect(() => {
        if(resetFilter){
            setPageCount(0);
            setPageSize(10)
            setDefaultPageCount(1)
            setResetFilter(false)
            setSelectedFilterObjectValue({})
        }       
    },[resetFilter])

    

    return (
        < >
            {/* <PowerBIEmbed
                        embedConfig = {reportConfig}
                        eventHandlers = {eventHandlersMap}
                        cssClassName = { "Report-container" }
                    /> */}
            <Box>
                <Filter filteredColumns={setColumnFields} reportData={reportData} reportClientAPIList={reportClientAPIList} setReportSubmissionAPIData={setReportSubmissionAPIData} reportSubmissionAPIData={reportSubmissionAPIData} referAPIObjKeys={referAPIObjKeys} mLocationList={mLocationList} setMLocationList={setMLocationList} reportFilterPreload={reportFilterPreload} setResetFilter={setResetFilter} reportBussinessUnitAPIList={reportBussinessUnitAPIList} userRoleListObject={userRoleListObject} setUserRoleListObject={setUserRoleListObject} userRoleChange={(e:any,type:string,buIdValue:any) => {loadUserRoleObject(e,type,buIdValue)}} setSelectedFilterObjectValue={setSelectedFilterObjectValue} userTrigger={(e:any) => {setDefaultPageCount(1)}} stageStatusObjData={stageStatusObjData}/>
                <Box className="reportTableContSect">
                    <TableContainer className="submissionReportContainer" component={Paper}>
                        <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {/* <TableCell>
                                        <Checkbox
                                            // onChange={}
                                            checked={false}
                                            className='search-result-item-checkbox'
                                        />
                                    </TableCell> */}
                                    {reportHeader.map((head:any, index:number) => {
                                        return (columnFields && columnFields[head] == false ? null : 
                                            <TableCell className={`reportTableHead-${camelize(head)}`} key={index} onClick={() => head !== "S.NO" ? updateSorting(head) : ""}>
                                                <div>
                                                    {head}
                                                    {head !== "S.NO" && <>{sortColumn && sortColumn.label == head ? (sortColumn.sort == "asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}</>}
                                                </div>
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                                <TableRow>
                                    {/* <TableCell>
                                    </TableCell> */}
                                    {reportHeader.map((head:any, index:number) => {
                                        const dateField = ["Source","CV Send Date","Profile entry date in the Artemis","Profile last modified date","Profile last active date"]
                                         const disableFields = ["CV Send Date","Source","Relevant Exp","Expected CTC"]
                                        return (
                                            columnFields && columnFields[head] == false ? null :
                                            <TableCell key={index}>
                                                {head !== "S.NO" && <FormControl className="reportTableSearchField" variant="standard">
                                                    {!dateField.includes(head) ? <OutlinedInput
                                                        className={`${disableFields.includes(head) ? "reportTableSearchFieldMaskDisable" :""}`}
                                                        disabled={(disableFields.includes(head)) ? true :false}
                                                        onChange={(event) => {setDefaultPageCount(1);const referenceKey = referAPIObjKeys[head];reportQueryParmsHandler({[referenceKey]:event.target.value})}}
                                                        value={searchColumnKeys[referAPIObjKeys[head]]}
                                                        startAdornment={
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        }
                                                    /> : 
                                                    <>
                                                    <FormControl className={`reportTableSearchFieldMask ${disableFields.includes(head) ? "reportTableSearchFieldMaskDisable" :""}`} variant="standard">
                                                        <Input
                                                        disabled={(disableFields.includes(head)) ? true :false}
                                                        onChange={(event) => {const regex:any = /[0-9]{4}-[0-9]{2}-[0-9]{2}/;const referenceKey = referAPIObjKeys[head]; if(regex.test(event.target.value)){setDefaultPageCount(1);reportQueryParmsHandler({[referenceKey]:event.target.value})}else{if(event.target.value.length == 0)reportQueryParmsHandler({[referenceKey]:""})}}}
                                                        name="textmask"
                                                        id="formatted-text-mask-input"
                                                        inputComponent={TextMaskCustom as any}
                                                        startAdornment={
                                                            <InputAdornment position="start">
                                                                <SearchIcon />
                                                            </InputAdornment>
                                                        }
                                                        />
                                                    </FormControl>
                                                    </>
                                                    }
                                                </FormControl>}
                                            </TableCell>
                                        )
                                    })}
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {(reportData?.data && reportData?.data.length > 0) ? reportData?.data.map((row:any, index:number) => {
                                    return(
                                        <>
                                    <TableRow
                                        key={index}
                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                    >
                                        {/* <TableCell>
                                            <Checkbox
                                                // onChange={}
                                                checked={false}
                                                className='search-result-item-checkbox'
                                            />
                                        </TableCell> */}
                                        <TableCell scope="row">
                                            {index+1}
                                        </TableCell>
                                        {/* <TableCell><Tooltip title={row?.candidate_id}><div>{row?.candidate_id}</div></Tooltip></TableCell> */}
                                        <TableCell><Tooltip title={row?.candidate_name}><div className="reportCandidateNameNavigate" onClick={(e:any) => {reportCandidateName(row?.candidate_id)}}>{row?.candidate_name}</div></Tooltip></TableCell>
                                        {columnFields && columnFields["Job Title"] == false ? "" : <TableCell><Tooltip title={row?.job_title}><div>{row?.job_title}</div></Tooltip></TableCell>}
                                        {columnFields && columnFields["Client"] == false ? "" :<TableCell><Tooltip title={row?.clientname}><div>{row?.clientname}</div></Tooltip></TableCell>}
                                        {columnFields && columnFields["Current Organization"] == false ? "" :<TableCell><Tooltip title={row?.current_company}><div>{row?.current_company}</div></Tooltip></TableCell>}
                                        {columnFields && columnFields["Total exp"] == false ? "" :<TableCell><Tooltip title={row?.experience}><div>{row?.experience}</div></Tooltip></TableCell>}
                                        {columnFields && columnFields["Relevant Exp"] == false ? "" :<TableCell><div style={{textAlign:"center"}}>{"-"}</div></TableCell>}
                                        {columnFields && columnFields["Current Location"] == false ? "" :<TableCell><Tooltip title={row?.current_location}><div>{row?.current_location}</div></Tooltip></TableCell>}
                                        {columnFields && columnFields["Preferred Location"] == false ? "" :<TableCell className="reportTbodyPreferredLoc"><Tooltip title={row?.current_location}><div>{row?.preferred_locations}</div></Tooltip></TableCell>}
                                        {columnFields && columnFields["Notice Period"] == false ? "" :<TableCell><Tooltip title={row?.notice_period}><div>{row?.notice_period}</div></Tooltip></TableCell>}
                                        {columnFields && columnFields["Contact Number"] == false ? "" :<TableCell><Tooltip title={row?.phone_number}><div>{row?.phone_number}</div></Tooltip></TableCell>}
                                        {columnFields && columnFields["e-Mail"] == false ? "" :<TableCell><Tooltip title={row?.e_mail}><div>{row?.e_mail}</div></Tooltip></TableCell>}
                                        {columnFields && columnFields["Current CTC"] == false ? "" :<TableCell><Tooltip title={row?.current_ctc}><div>{row?.current_ctc}</div></Tooltip></TableCell>}
                                        {columnFields && columnFields["Expected CTC"] == false ? "" :<TableCell><Tooltip title={row?.exptected_ctc}><div>{row?.exptected_ctc}</div></Tooltip></TableCell>}
                                        {columnFields && columnFields["Source"] == false ? "" :<TableCell><div style={{textAlign:"center"}}>{row?.source_from}</div></TableCell>}
                                        {columnFields && columnFields["CV Send Date"] == false ? "" :<TableCell><div style={{textAlign:"center"}}>{moment(row?.cv_sent_date).format("DD MMM YYYY, HH:mm")}</div></TableCell>}
                                        {columnFields && columnFields["Stage"] == false ? "" :<TableCell><Tooltip title={row?.stage_name}><div>{row?.stage_name}</div></Tooltip></TableCell>}
                                        {columnFields && columnFields["Status"] == false ? "" :<TableCell className="reportStatus"><Tooltip title={row?.status_name}><div>{row?.status_name}</div></Tooltip></TableCell>}
                                        {columnFields && columnFields["Recruiter Name"] == false ? "" :<TableCell><Tooltip title={row?.recruiter_name}><div>{row?.recruiter_name}</div></Tooltip></TableCell>}
                                        {columnFields && columnFields["Profile entry date in the Artemis"] == false ? "" :<TableCell><Tooltip title={convertToDisplayDateReport(row?.created_at)}><div>{convertToDisplayDateReport(row?.created_at)}</div></Tooltip></TableCell>}
                                        {columnFields && columnFields["Profile last modified date"] == false ? "" :<TableCell><Tooltip title={row?.modified_on}><div>{row?.modified_on}</div></Tooltip></TableCell>}
                                        {columnFields && columnFields["Profile last active date"] == false ? "" :<TableCell><Tooltip title={row?.last_active}><div>{row?.last_active}</div></Tooltip></TableCell>}
                                        {/* <TableCell><div className={row?.status == "In Progress" ? "primary-badge" : 'warm-badge'}>{row?.status_name}</div></Tooltip></TableCell> */}
                                    </TableRow>
                                    </>
                                    )
                                    } 
                                ): <div style={{height:"300px"}}>{renderEmptySearchBox()}</div>}
                            </TableBody>
                        </Table>
                    </TableContainer>
                    <Box className="reportTablePaginationSect" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <Box sx={{ display: 'flex' }}>
                            <p style={{color:"#7f8082",fontSize:"14px"}}>Rows per page:</p>
                            <FormControl fullWidth>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={pageSize}
                                    label="Age"
                                    onChange={(event:any) => {setDefaultPageCount(1);setPageSize(event.target.value);reportQueryParmsHandler({"pageSize":event.target.value})}}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={30}>30</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                    <MenuItem value={75}>75</MenuItem>
                                    <MenuItem value={100}>100</MenuItem>
                                </Select>
                            </FormControl>
                        </Box>
                        <Pagination count={pageCount} page={defaultPageCount} variant="outlined" shape="rounded" onChange={(event, pageNumber) => {setDefaultPageCount(pageNumber); reportQueryParmsHandler({"pageNo":pageNumber})}}/>
                    </Box>
                </Box>
                
            </Box>

        </>
    )

}

export default Report