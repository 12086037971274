import { Box, Menu, MenuItem } from '@mui/material'
import { Settings, Preferences, SignOut } from '../../Actions'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import NoProfilePhoto from "../../../assets/images/no_profile_photo.png";
import userIcon from "../../../assets/icons/userProfileIcon.svg"
import userLogoutIcon from "../../../assets/icons/userLogoutIcon.svg"
import { useNavigate } from 'react-router-dom';
import { ReactComponent as UserLocationGrey } from "../../../assets/icons/userLocationGrey.svg"
import { ReactComponent as UserRoleIcon } from "../../../assets/icons/userRoleIcon.svg"
import { authUserDetails, updateUserBuRole } from '../../../features/userDetail/UserDetailReducer';
import { ReactComponent as PilotBack } from "../../../assets/images/pilotBack.svg"
import "../../../components/Dashboard/OverviewDashboard/DashboardEvents/DashboardEvents.scss"
import { getCopilotUserToken, getSystemConfig } from '../../../services/UserService';
import { getAccessToken } from '../../../services/http-common';
import { setSysTemplate } from '../../../features/systemConfig/SystemConfig';
import WestIcon from '@mui/icons-material/West';
import { toast } from 'react-toastify';

interface DefaultMenuProps {
  isMenuOpen: boolean
  handleMenuClose: () => void
  handleLogout:()=>void
  anchorEl: HTMLElement | null
}

export const DefaultMenu = ({ isMenuOpen, handleMenuClose, anchorEl, handleLogout }: DefaultMenuProps) => {
  const userDetails = useAppSelector((state) => state?.UserDetail)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const changeBuRole = (buRolePermission:any) => {
    dispatch(updateUserBuRole({
      userPermissions: buRolePermission.userPermissions,
      userRole: buRolePermission.userRole,
      roleId: buRolePermission.roleId,
      businessUnitCode: buRolePermission.businessUnitCode,
      businessUnitId: buRolePermission.businessUnitId,
      businessUnitName: buRolePermission.businessUnitName,
      employmentLocation : buRolePermission.employmentLocation,
      employmentLocationName: buRolePermission.employmentLocationName
    }))
    navigate("/")
    setTimeout(() => {
      location.reload()      
    },200)    
  }

  const getPayloadData = (treeViewDataObj:any) => {
    const userBuList:any = [];
    let childLocation:any = []
    let userDetailObj:any = {
      userPermissions: [],
      userRole: "",
      roleId: "",
      businessUnitCode: "",
      businessUnitId: "",
      businessUnitName: "",
      employmentLocation : "",
      employmentLocationName: ""
    }
    function collectSelectedKeys(nodes:any) {          
        for (const node of nodes) {
            if ("children" in node == false || (node.children && node.children.length == 0)) {
              userDetailObj["userPermissions"] = node?.permissions;
              userDetailObj["userRole"] = node?.title; 
              userDetailObj["roleId"] = node?.value;
              if(childLocation.length > 0){
                userDetailObj["employmentLocationName"] = childLocation[0];
                userDetailObj["employmentLocation"] = childLocation[1];
              }
              userBuList.push(userDetailObj);
              userDetailObj = {
                userPermissions: [],
                userRole: "",
                roleId: "",
                businessUnitCode: "",
                businessUnitId: "",
                businessUnitName: "",
                employmentLocation : "",
                employmentLocationName: ""
              }
            } else {                  
              if(node?.type == "LOCATION"){                    
                userDetailObj["employmentLocationName"] = node?.title;
                userDetailObj["employmentLocation"] = node?.value;
                node.children.length > 1 ? childLocation = [node?.title,node?.value] : childLocation = []
              }else if(node?.type == "BUSINESS_UNITS"){
                userDetailObj["businessUnitName"] = node?.title;
                userDetailObj["businessUnitId"] = node?.value; 
                userDetailObj["businessUnitCode"] = node?.business_unit_code?.bu_code;
              }
              collectSelectedKeys(node.children);
            }
        }
    }    
    collectSelectedKeys(treeViewDataObj);
    return userBuList;
};

const getAppConfig = async () => {
  const reqObj = {
    businessUnit: true,
    appConfiguration: true,
    dropdownRequest: []
  }

  try{
    const res = await getSystemConfig(reqObj)
    if(res.status === 200) {
      const obj = {
        businessUnitTemplate: res?.data?.result?.businessUnit?.buMlpTemplate,
        chatGptShow:res?.data?.result?.configuration?.length && 
        res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 29),
        systemConfigData: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 33),
        googleIntegrationConfig: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 43),
        welcomeConfigData: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 49),
        uploadFileConfigData: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 3),
        smartFlowIncomingData: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 54),
        confettiEffectTimerData: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 56),
        isWelcomePopupEnabled: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 58),
        welcomePopupDisplayTimer: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 59),
        welcomePopupContent: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 60),
        welcomePopupClose: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 61),
        welcomeScreenAnimationEnabled: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 62),
        JoStatusList: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 65),
        toursConfigList: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 70),
        buElementAccessRoles: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 72)
      }
      dispatch(setSysTemplate(obj))
      setTimeout(() => {
        navigate('/')
        location.reload()
      },200)
      
    }
  }catch(error){
    console.log(error, "config api error")
  }
}
  const changeCopilot = async (copilot:any) => {
    const copilotObj:any = {
      authToken : getAccessToken(),
      userId: copilot.pilot_id,
      currentUserId : userDetails.userId
    }
    try{
        const response:any = await getCopilotUserToken(copilotObj)
        const copilotData = response.data.data
        const userToken = copilotData.authDetails.access_token
        localStorage.setItem('access_token', userToken)
        if(localStorage.getItem("original_user_id")){
          localStorage.setItem('original_user_id', "")
        }else{
          localStorage.setItem('original_user_id', copilotObj.currentUserId)
        }
          
        const dlsRoleList = copilotData?.userDetails?.dlsRoleAccess != null ? getPayloadData(copilotData?.userDetails?.dlsRoleAccess?.otherRoleDls?.dlsData) : []
        dispatch(authUserDetails({
          userId: copilotData.userDetails.keyCloakId,
          id: copilotData.userDetails.id,
          username: copilotData.userDetails.userName,
          firstName: copilotData.userDetails.firstName,
          smartCallEmail: copilotData.userDetails.sfloId,
          smartCallPassword: copilotData.userDetails.sfloPwd,
          userPermissions: copilotData.userDetails.permissions,
          userRole: copilotData.userDetails.role,
          roleId: copilotData.userDetails.roleId,
          businessUnitCode: copilotData.userDetails.businessUnitCode,
          businessUnitId: copilotData.userDetails.businessUnitId,
          businessUnitName: copilotData.userDetails.businessUnit,
          employmentLocation : copilotData.userDetails.employmentLocation,
          employmentLocationName: copilotData.userDetails.employmentLocationName,
          dlsRoleAccess: dlsRoleList,
          orgId: copilotData.userDetails.orgId,
          orgName: copilotData.userDetails.orgName,
          coPilotAccess: copilotData.userDetails.coPilotAccess != null ? copilotData.userDetails.coPilotAccess : []
        }))
        getAppConfig()
      }
      catch(error:any){  
          if(error.data.message == "Co-pilot Session Is Full"){
            toast.error(error.data.message)     
          }
        }
  }
  const roleBackOnwer = () => {    
    changeCopilot({"pilot_id":localStorage.getItem("original_user_id")})    
  }
  return (
  <Menu
    anchorEl={anchorEl}
    id='primary-search-account-menu'
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    keepMounted
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    open={isMenuOpen}
    onClose={handleMenuClose}
    className='user-menu'
  >
    <Box className="profileContentSect">
      <img src={NoProfilePhoto} />
      <Box className="profileContentDetailSect">
          <Box className="nameField">{userDetails?.firstName}</Box>
          <Box className="userRole">{userDetails?.userRole}</Box>
      </Box>
    </Box>    
    <Box className="profileContentSectRole">
        <p><UserRoleIcon /><span>{userDetails?.businessUnitName}</span></p>
        <p className='middleLine'></p>
        <p><UserLocationGrey /><span>{userDetails?.employmentLocationName}</span></p>
    </Box>
    {/* <MenuItem onClick={handleMenuClose} className='user-menu-item'>
      <Settings disableTooltip />
      Settings
    </MenuItem> */}
    <MenuItem onClick={() => {navigate('/UserProfile');handleMenuClose()}} className='user-menu-item'>
      {/* <Preferences disableTooltip /> */}
      <img src={userIcon} />
      My Profile
    </MenuItem>
    <MenuItem onClick={handleLogout} className='user-menu-item'>
      {/* <SignOut disableTooltip /> */}
      <img src={userLogoutIcon} />      
      Logout
    </MenuItem>
      {localStorage.getItem("original_user_id") && 
      <p className="backToOwner" onClick={roleBackOnwer}><PilotBack /> Back To Pilot Login</p>}
    {/* 
    {/* {userDetails?.dlsRoleAccess.length > 1 && <Box className="userAccountSwitchList">
      <h3>Switch to Other Account</h3>
      <ul>
        {userDetails?.dlsRoleAccess && userDetails?.dlsRoleAccess.map((roleItem:any, index:number) => {
          return (
            <>
            {(roleItem.userRole != userDetails?.userRole || roleItem.businessUnitCode != userDetails?.businessUnitCode) && <li key={index} onClick={() => changeBuRole(roleItem)}>
              <h4>{roleItem.userRole}</h4>
              <h5>{roleItem.businessUnitName} <span className='middleLine'></span> {roleItem.employmentLocationName}</h5>
            </li>}
            </>
          )
        })}
      </ul>
    </Box>} */}
  </Menu>
)
}