import { TabContext, TabList, TabPanel } from '@mui/lab'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  debounce,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Popper,
  Select,
  Tab,
  TextField,
  Typography,
} from '@mui/material'
import { useCallback, useEffect, useState } from 'react'
import './styles/CandidateHeaderTabs.scss'
import { ReactComponent as KeySearchIcon } from '../../../src/assets/icons/KeywordSearch.svg'
import { ReactComponent as RotateIcon } from '../../../src/assets/icons/reset.svg'
import { ReactComponent as SearchIcon } from '../../../src/assets/icons/search.svg'
import {
  resetFilters,
  setFilterValues,
  setFixedFilterValues,
  resetFilterPreloadData,
  resetApiFilters
} from '../../features/filters/FilterReducer'
import {resetPersistFilter, setCandidateTabResponse, setFilterPersist, setPersistFilter, setSideBaseOpenStatus} from '../../features/filters/PersistFilter'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import AppMandateDropdown from '../AppComponents/AppMandateDropdown/AppMandateDropdown'
import { GetCandidateDetailsIntelligentSkill, tagListByMandateId } from '../../services/CandidateService'
import _ from 'lodash'
import ClearIcon from '@mui/icons-material/Clear'
import { renderSelectOptions } from '../../utils/design-utils'
import RangeSlider from '../Common/RangeSlider/RangeSlider'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { getMandateLocation } from '../../services/MandateService'
import { useLocation, useSearchParams,useNavigate } from 'react-router-dom';
import ArtemisIntelligentSkill from './ArtemisIntelligentSkill'
import { toast } from 'react-toastify';


type Tprop = {
  immediateFilter: any
  setImmediateFilter: (val: any) => any;
  searchFilter: any;
  setSearchFilter: (val:any) => any;
  tabVal: 0 | 1 | 2 | 3;
  setTabVal: (val:any) => any;
  tabFilters: any;
  setTabFilters: (val:any) => any;
  selectedMandate: any;
  setSelectedMandate: (val:any) => any;
  handleTabInitValset:any;
  initialManTab:any;
  setSelectedLocation:any;
  selectedLocation:any;
  selectedKeywords:any;
  setSelectedKeywords:any;
  setAdvancedFilters:any;
  advancedFilters:any;
  setSearchListData:any;
  searchListData:any;
  setApplyFilterStatus?:any;
  selectedIntelligent?:any;
  setSelectedIntelligent?:any
}

const CandidateHeaderTabs: React.FC<Tprop> = ({ immediateFilter, setImmediateFilter,searchListData, searchFilter, setSearchFilter, tabVal, setTabVal, tabFilters, setTabFilters, selectedMandate, setSelectedMandate,handleTabInitValset,initialManTab,selectedLocation,setSelectedLocation,selectedKeywords,setSelectedKeywords,setAdvancedFilters,advancedFilters,setSearchListData,setApplyFilterStatus,selectedIntelligent,setSelectedIntelligent}) => {
  const dispatch = useAppDispatch()
  const location = useLocation();
  const navigate = useNavigate()
  const persistFilter = useAppSelector((state)=> state.PersistFilterReducer.persistFilter)
  const persistanceDataChangeStatus = useAppSelector((state) => state?.PersistFilterReducer?.filterPersistance)
  const [isMandateLoad, setIsMandateLoad] = useState<boolean>(false)

  const [tagData, setTagData] = useState<any>(persistFilter?.tagOptions && persistFilter?.tagOptions?.length >0?persistFilter?.tagOptions:[])
  const [selectedTags, setSelectedTags] = useState<any[]>([])
  const [matchCriteratemp, setmatchCriteratemp] = useState('')
  const [isSearchParam, setIsSearchParam] = useState<boolean>(false)
  const [basicSearchValuesAvailable, setBasicSearchValuesAvailable] = useState(false)
  const [locationList, setlocationList] = useState<any>({})
  const [tabKeyWordFieldStatus, setTabKeyWordFieldStatus] = useState<boolean>(false)
  const [artemisIntelligentState, setArtemisIntelligentState] = useState(selectedIntelligent?.artemisIntelligentSkills != undefined ? selectedIntelligent?.artemisIntelligentSkills : [])
  const persistanceTabResponse = useAppSelector((state) => state?.PersistFilterReducer?.persistTabResponse)
  useEffect(() => {
    setArtemisIntelligentState(selectedIntelligent?.artemisIntelligentSkills != undefined ? selectedIntelligent?.artemisIntelligentSkills : [])
  },[selectedIntelligent])
  
  const resetObj = {
    "mandateId": {
        "value": "",
        "label": ""
    },
    "mLocationId": null,
    "keyword": "",
    "matchCriteria": "",
    "searchQueryId": "",
    "confidenceScore": [
        0,
        100
    ],
    "booleanSearch": false,
    "tags": [],
    "profileType": "All",
    "location": "",
    "preferredLocation": "",
    "primarySkills": [],
    "secondarySkills": [],
    "education": "",
    "company": "",
    "designation": "",
    "salaryRange": [
        0,
        100
    ],
    "experience": [
        0,
        30
    ],
    "lastActivity": -1,
    "tabKeyword": ""
}



  const filters = useAppSelector((state) => state.filtersReducer.filters)
  const filterPreloadData = useAppSelector((state) => state.filtersReducer.filterPreloadData)
  

  const {
    handleSubmit,
    control,
    setValue,
    reset,
    getValues,
    watch,
    formState: { isDirty },
  } = useForm<any>({ defaultValues:{
    minCtc: selectedIntelligent?.minCtc ?? "",
    maxCtc: selectedIntelligent?.maxCtc ?? "",
    minExp: selectedIntelligent?.minExperience ?? ""
  }})

  useEffect(()=>{
    // const data = (tabFilters[`tab${tabVal}`])
    // setTabFilters({...tabFilters,[`tab${tabVal}`] : data})
    setIsMandateLoad(true)
      setTimeout(() => {
        setIsMandateLoad(false)
      })
      handleSubmit(filterSubmitHandler)()
     
    if(persistFilter?.keywordsPersist){
      if(tabVal===0){
        setValue("keyword",persistFilter?.keywordsPersist?.[`keyTab${tabVal}`])
      }
      else{
        setValue("tabKeyword",persistFilter?.keywordsPersist?.[`keyTab${tabVal}`])
      }
      
    }
    setTimeout(() => {
      dispatch(setPersistFilter({...persistFilter,persistTab:tabVal}))
    },500)    
    // if(searchFilter?.module!="rpa"){
    //   reset(data)
    //   resetAllFilters()
    // }
  },[tabVal])

  useEffect(() => {
    console.log("minExp",watch('minExp'))
  },[watch('minExp')])

  const watchMandateId = watch('mandateId')
  const watchLocationId = watch('mLocationId')
  const watchBasicFields = watch(['mandateId', 'keyword'])
  const watchTabKeyWordSearch = watch('tabKeyword')  


  const handleChange = (newValue: 0|1|2|3) => {
    setTabVal(newValue)
  }

  const getTags = async () => {
    // if(tabVal!=1){
    //   return
    // }
    const response = await tagListByMandateId(persistFilter?.persistMandate?.[`manTab1`]?.value,selectedLocation[`locTab1`])
    if (response) {
      setTagData(response)
      // dispatch(setPersistFilter({...persistFilter,tagOptions:tagData}))
    } else {
      setTagData([])
      // dispatch(setPersistFilter({...persistFilter,tagOptions:null}))
    }
  }
  useEffect(()=>{
    if(tabVal===1){
      dispatch(setPersistFilter({...persistFilter,tagOptions:tagData}))
    } 
    },[tagData])
  const filterSubmitHandler = async(data: any) => {
    const extractfilter = location?.state?.searchFilter;
    const submittedData = data
    // if(location?.state?.searchFilter?.module!="rpa" || (location?.state?.searchFilter?.module==="rpa"&&( (typeof(getValues("searchQueryId"))==='number')||typeof(getValues("mlocationId"))==='number')) ||  typeof(getValues("mandateId"))?.value==='number'){
      if(!isSearchParam)setIsSearchParam(true)
    const filter = {...searchFilter, ...data}
    // setSearchFilter(filter)
    let apiTabData = {}
    const formData = tabFilters[`tab${tabVal}`]
    dispatch(setSideBaseOpenStatus(false))
    const persistanceTabRes = JSON.parse(JSON.stringify(persistanceTabResponse));
    persistanceTabRes[`tab${tabVal}`] = {}
    // dispatch(setCandidateTabResponse(persistanceTabRes))  
    if (tabVal === 0) {
      
      const finalData = {
        ...formData,
        keyword: persistFilter?.keywordsPersist?.[`keyTab${tabVal}`]??"",
        booleanSearch: watch('booleanSearch'),
      }
      apiTabData = {...finalData}
    } else if (tabVal === 1) {
      // const formData = tabFilters[`tab${tabVal}`]
      const finalData = {
        ...formData,
        mandateId: persistFilter?.persistMandate?persistFilter?.persistMandate[`manTab${tabVal}`]?.value :"",
        tags: persistFilter?.persistTags?.length > 0 && tabVal === 1? persistFilter?.persistTags:[],
        mLocationId: persistFilter?.persistLocation?persistFilter?.persistLocation[`locTab${tabVal}`]:"",
        keyword: persistFilter?.keywordsPersist?.[`keyTab${tabVal}`]??""
      }
      apiTabData = {...finalData}
    } else if (tabVal === 2) {
      // const formData = tabFilters[`tab${tabVal}`]
      const finalData = {
        ...formData,
        mandateId:persistFilter?.persistMandate?persistFilter?.persistMandate[`manTab${tabVal}`]?.value :"",
        searchQueryId:persistFilter?.persistSearchQuery && tabVal===2?persistFilter?.persistSearchQuery:"",
        mLocationId:persistFilter?.persistLocation?persistFilter?.persistLocation[`locTab${tabVal}`]:"",
        keyword:persistFilter?.keywordsPersist?.[`keyTab${tabVal}`]??""
      }
      apiTabData =  {...finalData}
    } else if (tabVal === 3) {
      // const formData = tabFilters[`tab${tabVal}`]
      const finalData = {
        ...formData,
        mandateId: persistFilter?.persistMandate?persistFilter?.persistMandate[`manTab${tabVal}`]?.value :"",
        matchCriteria: persistFilter?.persistCriteria && typeof(persistFilter?.persistCriteria)==="string" && tabVal===3?persistFilter?.persistCriteria:""  ,
        confidenceScore: watch('confidenceScore'),
        mLocationId: persistFilter?.persistLocation?persistFilter?.persistLocation[`locTab${tabVal}`]:"",
        // keyword: watch('tabKeyword')
        keyword:persistFilter?.keywordsPersist?.[`keyTab${tabVal}`]??"",
        minExperience: watch('minExp'),
        minCtc: watch('minCtc'),
        maxCtc: watch('maxCtc'),        
        skillWeights: artemisIntelligentState.reduce(function(r:any, e:any) {r[e.skillId] = e.weight;return r;}, {}),
        skillThresholds: artemisIntelligentState.reduce(function(r:any, e:any) {r[e.skillId] = e.skillVariance;return r;}, {})
      }
      const skillWeightValue = artemisIntelligentState.reduce(function (r: any, e: any) {
        if (e.weight !== 0) {
          r[e.skillId] = e.weight/1000 + 1;
        }
        return r;
      }, {})
      const artemisNewDatas = {
        minExperience: watch('minExp'),
        minCtc: watch('minCtc'),
        maxCtc: watch('maxCtc'),
        artemisIntelligentSkills: artemisIntelligentState,
        skillWeights: skillWeightValue,
        skillThresholds: artemisIntelligentState.reduce(function (r: any, e: any) {
          if (e.skillVariance !== 0 && e.skillId in skillWeightValue) {
            r[e.skillId] = e.skillVariance/1000 + 0.900;
          }
          return r;
        }, {})
      }
      dispatch(setPersistFilter({...persistFilter,persistIntelligentTab:artemisNewDatas}))
      apiTabData = {...finalData}
    }
    if(filters?.advFilters?.[`advfilTab${tabVal}`]){
      if(tabVal == 3){
        submittedData["skillWeights"] = artemisIntelligentState.reduce(function(r:any, e:any) {r[e.skillId] = e.weight;return r;}, {});
        submittedData["skillThresholds"] = artemisIntelligentState.reduce(function(r:any, e:any) {r[e.skillId] = e.skillVariance;return r;}, {})
      }
      const finalDatas = {...filters?.advFilters?.[`advfilTab${tabVal}`], ...submittedData }
      setTabFilters({...tabFilters, [`tab${tabVal}`] : finalDatas})
    }
    else{
      
      setTabFilters({...tabFilters, [`tab${tabVal}`] : apiTabData})
    }
    
    const persistanceData = JSON.parse(JSON.stringify(persistanceDataChangeStatus));
    persistanceData[`tab${tabVal}`] = "changed"                          
    dispatch(setFilterPersist(persistanceData))
    
    // }
  }
  
  const handleTagSelect = (e: any, v: any) => {
    setSelectedTags([...v.map((item: any) => ({ tag: item?.label, id: item.id }))])
    setValue(
      'tags',
      v.map((item: any) => item.id),
    )
  }

  const getMandateLocationList = async (manId:any)=> {
    const response = await getMandateLocation(manId)
    if(response?.status === 200){
      setlocationList({...locationList,[`locList${tabVal}`]:response?.data?.result?.mandateLocations})
    }else{
      setlocationList({...locationList,[`locList${tabVal}`]:null})
    }
  }
  const resetAllFilters = async() => {
    setSearchFilter({})
    const persistanceTabRes = JSON.parse(JSON.stringify(persistanceTabResponse));
    persistanceTabRes[`tab${tabVal}`] = {}
    dispatch(setCandidateTabResponse(persistanceTabRes))
    const resetMandate = await { ...selectedMandate, [`manTab${tabVal}`]: null };
    const resetLocation = await { ...selectedLocation, [`locTab${tabVal}`]: null };
    const resetKeywords = await { ...selectedKeywords, [`keyTab${tabVal}`]: null };
    const resetAdvancedFilters = await { ...advancedFilters, [`advancedFilter${tabVal}`]: null };
    const resetLocationOptions = await { ...locationList, [`locList${tabVal}`]: null }
    const resetSelectedIntelligent = await { ...selectedIntelligent, "": null };
    await setSelectedMandate({ ...selectedMandate, [`manTab${tabVal}`]: null });
    await setSelectedLocation({ ...selectedLocation, [`locTab${tabVal}`]: null });
    await setSelectedKeywords({ ...selectedKeywords, [`keyTab${tabVal}`]: null })
    await setAdvancedFilters({ ...advancedFilters, [`advancedFilter${tabVal}`]: null })
    await setlocationList({ ...locationList, [`locList${tabVal}`]: null })
    setSelectedIntelligent({})    
    if(tabVal===0){
      dispatch(setPersistFilter({...persistFilter,persistMandate:resetMandate,persistLocation:resetLocation,
      keywordsPersist:resetKeywords,persistAdvancedFilters:resetAdvancedFilters,locationOptions:resetLocationOptions}))
    }
    if(tabVal===1){
      setTagData([])
      dispatch(setPersistFilter({...persistFilter,persistTags:[],persistMandate:resetMandate,persistLocation:resetLocation,
        keywordsPersist:resetKeywords,persistAdvancedFilters:resetAdvancedFilters,locationOptions:resetLocationOptions}))
    }
    if(tabVal===2){
      dispatch(setPersistFilter({...persistFilter,persistSearchQuery:"",persistMandate:resetMandate,persistLocation:resetLocation,
      keywordsPersist:resetKeywords,persistAdvancedFilters:resetAdvancedFilters,locationOptions:resetLocationOptions}))
    }
    if(tabVal===3){
      setImmediateFilter("")
      setArtemisIntelligentState([])
      setTabFilters((prevState:any) => ({
        ...prevState,
        tab3: {
          ...prevState.tab3,
          mandateId: null,
        },
      }));
      // setTabFilters({...tabFilters, [`tab${tabVal}`] : apiTabData})
      dispatch(setPersistFilter({...persistFilter,persistCriteria:[],persistMandate:resetMandate,persistLocation:resetLocation,persistIntelligentTab:{},
        keywordsPersist:resetKeywords,persistAdvancedFilters:resetAdvancedFilters,locationOptions:resetLocationOptions}))
      }
    // dispatch(resetPersistFilter())
    
    const formData = tabFilters[`tab${tabVal}`]
    let finalData = {...formData}
    switch (tabVal) {
      case 0:
        reset({...getValues(), keyword:"", booleanSearch:false})
        finalData = {...formData,  keyword:"", booleanSearch:false}
        break;
      case 1:
        reset({...getValues(), mandateId: { value: '', label: '' }, tags: [], mLocationId:null})
        setSelectedMandate({...selectedMandate, manTab1:null})
        finalData = {...formData,  mandateId: { value: '', label: '' }, tags: [], mLocationId:null}
        break;
      case 2:
        reset({...getValues(), mandateId: { value: '', label: '' }, searchQueryId:"", mLocationId:null, location: null})
        setSelectedMandate({...selectedMandate, manTab2:null})
        finalData = {...formData,   mandateId: { value: '', label: '' }, searchQueryId:"", mLocationId:null, location: null}
        break;
      case 3:
        reset({...getValues(), mandateId: { value: '', label: '' }, matchCriteria: '', minCtc: null,maxCtc: null,minExp: null,artemisIntelligentSkills: null, mLocationId:null, location: null})
        setSelectedMandate({...selectedMandate, manTab3:null})
        finalData = {...formData,  mandateId: { value: '', label: '' }, matchCriteria: '', minCtc: null,maxCtc: null,minExp: null,artemisIntelligentSkills: null, mLocationId:null, location: null}
        break;
    }
    setValue("tabKeyword","")
    setTabKeyWordFieldStatus(false)
    // setTabFilters({...tabFilters, [`tab${tabVal}`] : finalData})
    // dispatch(resetFilters())
    // setImmediateFilter("")
    // setSelectedMandate({
    //   manTab1:null,
    //   manTab2:null,
    //   manTab3:null
    // })
    // setTabFilters(handleTabInitValset())
    // setTabVal(2)
    
    dispatch(resetFilters())
    dispatch(resetFilterPreloadData({}))
    
    setSearchListData({...searchListData,[`searchList${tabVal}`]:[]})
    // setlocationList([])
    setApplyFilterStatus({
      status: 'reset',
    })
    const persistanceData = JSON.parse(JSON.stringify(persistanceDataChangeStatus));
    persistanceData[`tab${tabVal}`] = ""
    dispatch(setFilterPersist(persistanceData))
    const filtersCopy = JSON.parse(JSON.stringify(filters))
    filtersCopy.advFilters[`advfilTab${tabVal}`] = resetObj
    dispatch(resetApiFilters(filtersCopy))
  }

  const isBasicSearchValuesAvailable = () => {
    const mandateId = _.trim(_.get(getValues(`mandateId`), 'value'))
    const keyword = _.trim(getValues('keyword'))
    return !!mandateId || !!keyword
  }
  const clearFilters = () =>{
    if(watchMandateId?.value === ""){
      dispatch(resetFilters())
    }    
  }

  const getCandidateSkills = async (id:any) => {
    if(parseInt(id?.value) && selectedIntelligent?.artemisIntelligentSkills == undefined){
      try {
      const response = await GetCandidateDetailsIntelligentSkill(id?.value)
      if(response?.status === 200){
        const skillData = response?.data?.data.map((item:any) => {
          return {
            name: item?.skillname,
            skillId : item?.skillid,
            weight: 0,
            skillVariance: 0
          }
        })
        setArtemisIntelligentState(skillData)
      }else{
        console.log("skills", response)
      }
    } catch (error:any) {
      if (error.status == 404) {
        toast.warning('No skill found.') 
      }
    }
  }

  }
  useEffect(() => {
    setBasicSearchValuesAvailable(isBasicSearchValuesAvailable())
  }, [watchBasicFields])

  useEffect(() => {
    // if(tabVal != 3) handleSubmit(filterSubmitHandler)()
    if(persistFilter?.persistMandate && persistFilter?.persistMandate?.[`manTab${tabVal}`]?.value){
      setTabKeyWordFieldStatus(true)
      // getMandateLocationList(watchMandateId?.value)
      if(watch('mandateId')?.value && watch('mandateId')?.value !=""){
        getMandateLocationList(persistFilter?.persistMandate?.[`manTab${tabVal}`]?.value)
      }   
    }
    if(tabVal===3 && (!watchMandateId?.value || watchMandateId?.value==="")){
      setImmediateFilter("")
    }
    // clearFilters()
    if(watch('mandateId') != undefined){
      if(persistFilter?.persistMandate?.manTab3 == null || (persistFilter?.persistMandate?.manTab3?.label != tabFilters?.tab3?.mandateId?.label))getCandidateSkills(watch('mandateId'))
    }
  }, [watch('mandateId')])
  useEffect(()=>{
    if((typeof(watchLocationId)==='number'&& !isNaN(watchLocationId))||selectedLocation[`locTab${tabVal}`]){
      if(tabVal != 3)handleSubmit(filterSubmitHandler)()
    }
  },[watch('mLocationId')])
 
  useEffect(() => {
    if(tabVal===3){
      setImmediateFilter(matchCriteratemp)
    }else{
      setImmediateFilter("")
    }
    setTabKeyWordFieldStatus(false)
  }, [matchCriteratemp, tabVal])

  useEffect(() => {
    setValue('matchCriteria', immediateFilter)
  }, [immediateFilter])
  useEffect(() => {
    if (!selectedMandate[`manTab${tabVal}`]) {
      setIsMandateLoad(true)
      setTimeout(() => {
        setIsMandateLoad(false)
      })
    }
    if(selectedMandate?.manTab2 && Object.keys(selectedMandate?.manTab2)?.length && tabVal===2){
      getMandateLocationList(selectedMandate?.manTab2?.value)
    }
  }, [selectedMandate])
  useEffect(()=>{
  // if(selectedLocation[`locTab${tabVal}`]){
  //   setValue('mLocationId',selectedLocation[`locTab${tabVal}`])
  // }
  if(tabVal===1 && selectedLocation[`locTab1`]){
    getTags() 
  }
  },[selectedLocation])
  useEffect(()=>{
    if(watch('tags')){
      if(JSON.stringify(watch('tags')) != JSON.stringify(persistFilter?.persistTags)){
        dispatch(setPersistFilter({...persistFilter,persistTags:watch('tags')}))
      }  
    }
  },[watch('tags')])
  useEffect(()=>{
    if(watch('matchCriteria') && tabVal===3){
      if(JSON.stringify(watch('matchCriteria')) != JSON.stringify(persistFilter?.persistCriteria)){
        dispatch(setPersistFilter({...persistFilter,persistCriteria:watch('matchCriteria')}))
      }
    }
    else if(tabVal === 3){
      dispatch(setPersistFilter({...persistFilter,persistCriteria:[]}))
    }
  },[watch('matchCriteria')])
  // useEffect(() => {
  //     // setIsMandateLoad(true)
  //     // setTimeout(() => {
  //     //   setIsMandateLoad(false)
  //     // })
  //     // handleSubmit(filterSubmitHandler)()
  // }, [tabVal])
  useEffect(()=>{
    // if(persistFilter?.module==="artemis"){
    //   setTimeout(()=>{
    //     setValue("mandateId",persistFilter?.filterVal?.mandateId)
    //   },1000) 
    // }
    if(tabVal===1 && persistFilter?.persistTags){
      setValue("tags",persistFilter?.persistTags)
    }
    // if(!persistFilter?.persistFilter?.[`manTab${tabVal}`]){
    //   console.log("mandate removed")
    // }
  },[persistFilter])
  const debouncedSearch = useCallback(    
    debounce((nextValue) => {
      const persistanceTabRes = JSON.parse(JSON.stringify(persistanceTabResponse));
      persistanceTabRes[`tab${tabVal}`] = {}
      dispatch(setCandidateTabResponse(persistanceTabRes))    
      setSelectedKeywords({...selectedKeywords,[`keyTab${tabVal}`]:nextValue})
    }, 500),
    []
  );
  const handleDebouncedSearch = _.debounce((value:any) => {
    // Implement your search logic here
    if(value){
      setSelectedKeywords({...selectedKeywords,[`keyTab${tabVal}`]:value})
      // dispatch(setPersistFilter({...persistFilter,keywordsPersist:{...selectedKeywords,[`keyTab${tabVal}`]:value}}))
    }
    else{
      setSelectedKeywords({...selectedKeywords,[`keyTab${tabVal}`]:""})
      // dispatch(setPersistFilter({...persistFilter,keywordsPersist:{...selectedKeywords,[`keyTab${tabVal}`]:""}}))
    }    
  }, 1000); // Adjust the delay as needed
  useEffect(()=>{
    dispatch(setPersistFilter({...persistFilter,keywordsPersist:{...persistFilter?.keywordsPersist,[`keyTab${tabVal}`]:selectedKeywords[`keyTab${tabVal}`]}}))
    let apiTabData = {}
    const formData = tabFilters[`tab${tabVal}`]
    const finalData = {
      ...formData,
      keyword: selectedKeywords?.[`keyTab${tabVal}`]??"",
      booleanSearch: watch('booleanSearch'),
    }
    apiTabData = {...finalData}
    setTabFilters({...tabFilters, [`tab${tabVal}`] : apiTabData})
  },[selectedKeywords])
  const SourcingTagDropdown = () => {
    const tagId: any = []
    const dropdownOption = tagData
      .map((item: any) => {
        if (tagId.includes(item.id)) {
          return
        } else {
          tagId.push(item.id)
          return { label: item.tag, value: item.id, id: item.id }
        }
      })
      ?.filter((ele: any) => ele !== undefined)
    return (
      <Box className='source-tag-wrap'>
        <FormControl>
          <Autocomplete
            multiple
            limitTags={1}
            className='tag-auto-dropdown'
            id='multiple-limit-tags'
            disableCloseOnSelect
            disableListWrap
            options={dropdownOption}
            defaultValue={persistFilter?.persistTags??[]}
            getOptionLabel={(option: any) => option.label}
            value={dropdownOption?.filter((ele: any) =>
              getValues('tags')?.some((element: any) => element === ele.id),
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Sourcing Tags'
                placeholder={`${getValues('tags')?.length === 0 ? 'Tags' : ''}`}
                focused
              />
            )}
            onChange={handleTagSelect}
          />
        </FormControl>
      </Box>
    )
  }
 
  const RenderRpaSearchInputs = () => {
    const dataItems = _.get(filterPreloadData, `searchOptions2`, [])
    const dropdownOptions = _.map(dataItems, (value: any) => {
      if (typeof value === 'string') {
        return {
          label: value,
          value: value,
        }
      } else {
        return {
          label: _.get(value, 'queryName'),
          value: _.get(value, 'id'),
        }
      }
    })
    

    return (
      <Box className='rpa-tag-wrap'>
        <FormControl>
          <InputLabel className='label-tag-rpa'>RPA queries</InputLabel>
          <Controller
            control={control}
            name='searchQueryId'
            render={({ field }: any) => (
              <Select
              {...field}
                labelId='candidate-header-filter-label'
                className='rpaSearchSelect'
                label='RPA queries'
                value={persistFilter?.persistSearchQuery??""}
                // disabled={dropdownOptions.length === 0}
                onChange={(e) => {
                  field.onChange(e.target.value);
                  // dispatch(setFilterValues({...filters,searchQueryId:e.target.value}))
                  dispatch(setPersistFilter({...persistFilter,persistSearchQuery:e.target.value}))
                  //  setSearchqueryId(!searchqueryId)
                }}
                endAdornment={
                  <IconButton
                    sx={{ visibility: field.value ? 'visible' : 'hidden' }}
                    onClick={() => {
                      field.onChange('')
                  dispatch(setPersistFilter({...persistFilter,persistSearchQuery:''}))
                      // setmatchCriteratemp('');
                      // setSearchqueryId(!searchqueryId)
                    }}
                  >
                    <ClearIcon sx={{ fontSize: '1rem', mr: '0.5rem' }} />
                  </IconButton>
                }
              >
                {renderSelectOptions(dropdownOptions)}
              </Select>
            )}
          />
        </FormControl>
      </Box>
    )
  }

  const RenderMatchCriteriaInputs = () => {
    const dataItems = _.get(filterPreloadData, 'matchCriteriaList', {})

    return (
      <Box className='artemisIntelligentSect'>
        {/* <FormControl>
          <Controller
            control={control}
            name='matchCriteria'
            render={({ field: { onChange, value } }: any) => {
              const handleProperData = (val: any) => {
                if(val){
                  const criterias = val
                  .sort((a: any, b: any) => (a.key > b.key ? 1 : -1))
                  .map((x: any) => x.value)
                  .join('+')
                setmatchCriteratemp(criterias)
                onChange(criterias)
                }
              }
              const properValue = value
                ? value
                    ?.split('+')
                    ?.map((ele: string) => dropdownOptions?.find((x: any) => x?.value === ele))
                : []

              return (
                <Autocomplete
                  multiple
                  id='checkboxes-tags-demo'
                  className='tag-auto-dropdown'
                  limitTags={1}
                  options={dropdownOptions}
                  disableCloseOnSelect
                  value={properValue || []}
                  onChange={(e, val) => {
                    handleProperData(val)
                  }}
                  getOptionLabel={(option: any) => option?.label}
                  defaultValue={persistFilter?.persistCriteria??[]}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.label}
                    </li>
                  )}
                  renderInput={(params) => {
                    return <TextField {...params} label='Match Criteria' />
                  }}
                />
              )
            }}
          />
        </FormControl> */}
        <FormControl fullWidth required >
          <Controller
            name="minCtc"
            control={control}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='Min CTC'
                required={false}
                value={value != null ? value : ""}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if ((parseInt(inputValue) > -1 && parseInt(inputValue) <= 100000) || inputValue.length == 0) {
                    onChange(inputValue);
                  }
                }}
                onBlur={onBlur}
                type='number'
              />
            )}
          />
        </FormControl> 
        <FormControl fullWidth required >
          <Controller
            name="maxCtc"
            control={control}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='Max CTC'
                required={false}
                value={value != null ? value : ""}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if ((parseInt(inputValue) > -1 && parseInt(inputValue) <= 100000) || inputValue.length == 0) {
                    onChange(inputValue);
                  }
                }}
                onBlur={onBlur}
                type='number'
              />
            )}
          />
        </FormControl> 
        <FormControl fullWidth required >
          <Controller
            name="minExp"
            control={control}
            render={({field : {onChange, value, onBlur}})=>(
              <TextField
                label='Min Exp'
                required={false}
                value={value != null ? value : ""}
                onChange={(e) => {
                  const inputValue = e.target.value;
                  if ((parseInt(inputValue) > -1 && parseInt(inputValue) <= 100) || inputValue.length == 0) {
                    onChange(inputValue);
                  }
                }}
                onBlur={onBlur}
                type='number'
              />
            )}
          />
        </FormControl> 
      </Box>
    )
  }
  useEffect(()=>{
  //   {
  //   if(searchFilter?.module==="rpa"){
  //     setValue("mLocationId",searchFilter?.locationid)
  //   }

  // }
  if(locationList){
    dispatch(setPersistFilter({...persistFilter,locationOptions:locationList}))
  }
  },[locationList])
 
  // useEffect(()=>{
  // if(searchFilter?.mandateId?.value!="" && searchFilter?.module==="rpa"){
  //   if(searchFilter?.searchQueryId&& typeof(searchFilter?.searchQueryId)==='number'){
  //     setValue("searchQueryId",searchFilter?.searchQueryId)
  //   }
  //   const formObject:any ={
  //     "clientName":searchFilter?.mandateId?.clientName,
  //     "createdByName":searchFilter?.mandateId?.createdByName,
  //     "label":searchFilter?.mandateId?.label,
  //     "mandateId":searchFilter?.mandateId?.mandateId,
  //     "title":searchFilter?.mandateId?.title,
  //     "value":searchFilter?.mandateId?.value
  //   };
  //   const val = { ...selectedMandate }
  //   val[`manTab${tabVal}`] = formObject;
  //   setSelectedMandate(val)
  // }
  // if(Object.keys(searchFilter)?.length){
  // dispatch(setFilterValues({...filters,advFilters:{...filters?.advFilters,[`advfilTab${tabVal}`]:searchFilter}})) 
  // }
  // },[searchFilter])
  return (
    <Box className='candidate-header-wrapper'>
      <Box className='header-tabs'>
        <Box
          className={`tab-container keyword_search ${tabVal === 0 ? 'active-tab' : 'no-active'}`}
          onClick={() => handleChange(0)}
        >
          <Box className='tab'>
            <KeySearchIcon />
            <Typography>Keyword Search</Typography>
          </Box>
        </Box>
        <Box
          className={`tab-container sourcing_tags ${tabVal === 1 ? 'active-tab' : 'no-active'}`}
          onClick={() => handleChange(1)}
        >
          <Box className='tab'>
            <KeySearchIcon />
            <Typography>Sourcing Tags</Typography>
          </Box>
        </Box>
        <Box
          style={{display:"none"}}
          className={`tab-container rpa_search ${tabVal === 2 ? 'active-tab' : 'no-active'}`}
          onClick={() => handleChange(2)}
        >
          <Box className='tab'>
            <KeySearchIcon />
            <Typography>RPA Search Results</Typography>
          </Box>
        </Box>
        <Box
          className={`tab-container artemis_intelligent ${tabVal === 3 ? 'active-tab' : 'no-active'}`}
          onClick={() => handleChange(3)}
        >
          <Box className='tab'>
            <KeySearchIcon />
            <Typography>Artemis Intelligent Recommendations</Typography>
          </Box>
        </Box>
      </Box>
      <Box
        className='filter-container'
        component={'form'}
        onSubmit={handleSubmit(filterSubmitHandler)}
      >
        <Box>
          {tabVal === 0 && (
            <div className="candidateTabKeyword">
            <FormControl fullWidth>
              <Controller
                control={control}
                name='keyword'
                rules={{
                  required: {
                    value: true,
                    message: 'Start time is required',
                  },
                }}
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      placeholder='Search Keyword'
                      value={value}
                      onChange={(e:any) => {
                        onChange(e.target.value)
                        if(e.target.value===''){
                          setSearchListData({...searchListData,[`searchList${tabVal}`]:[]})
                          resetAllFilters()                          
                        }                        
                        const persistanceData = JSON.parse(JSON.stringify(persistanceDataChangeStatus));                        
                        persistanceData[`tab${tabVal}`] = "changed"
                        dispatch(setFilterPersist(persistanceData))
                        // handleDebouncedSearch(e.target.value)
                        debouncedSearch(e.target.value)
                        // setSelectedKeywords({...selectedKeywords,[`keyTab${tabVal}`]:e.target.value})
                        // if (e.target.value === '') resetAllFilters()
                      }}
                      onKeyDown={(ev) => {
                        if (ev.key === 'Enter') {
                            const persistanceData = JSON.parse(JSON.stringify(persistanceDataChangeStatus));
                            persistanceData[`tab${tabVal}`] = "changed"
                            dispatch(setFilterPersist(persistanceData))
                        }
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='end' style={{ paddingRight: '10px' }}>
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        // endAdornment: (
                        //   <InputAdornment position='end'>
                        //     <Controller
                        //       control={control}
                        //       name='booleanSearch'
                        //       render={({ field: { onChange: onBoolChange, value: boolVal } }) => {
                        //         return (
                        //           <Checkbox
                        //             size='small'
                        //             checked={boolVal}
                        //             onChange={onBoolChange}
                        //           />
                        //         )
                        //       }}
                        //     />
                        //     <Typography
                        //       style={{ fontSize: '13px', fontWeight: 400, color: '#002882' }}
                        //     >
                        //       Boolean search
                        //     </Typography>
                        //   </InputAdornment>
                        // ),
                      }}
                    />
                  )
                }}
              />
            </FormControl>
          </div>
          )}
          {tabVal !== 0 && (
            <Box style={{ width: '100%', display: 'flex', gap: '35px' }} className={`${tabVal == 3 ? "artemisIntellTab" : ""} candidateTabSource`}>
              <FormControl style={{ width: '35%' }}>
                {!isMandateLoad && (
                  <Controller
                    name={`mandateId`}
                    control={control}
                    render={({ field: { onChange, value, onBlur } }) => (
                      <AppMandateDropdown
                        label=''
                        required={false}
                        tabVal={tabVal}
                        value={selectedMandate[`manTab${tabVal}`]}
                        selectedLocation={selectedLocation}
                        setSelectedLocation={setSelectedLocation}
                        resetAllFilters={()=>resetAllFilters()}
                        onChange={(e: any) => {
                            // navigate('/candidate-listing')
                            // setSelectedMandate(initialManTab)
                            // setTabFilters(handleTabInitValset())
                            // // setTabVal(2)
                            // dispatch(resetPersistFilter())
                            // dispatch(resetFilters())
                            // dispatch(resetFilterPreloadData({}))
                            // setSearchFilter({})
                          if (!e) {
                            const manObj = {
                              value: '',
                              label: '',
                            }
                            // resetAllFilters()
                            // setSearchFilter({...searchFilter,...{"locationid":""}})
                            setImmediateFilter("")
                            // dispatch(setFilterValues({...filters,mandateId:manObj}))
                            onChange(manObj)      
                          } else {
                            const manObj = {
                              value: e?.value,
                              label: e?.label,
                            }
                            // dispatch(setFilterValues({...filters,mandateId:manObj}))
                            onChange(manObj)
                            // setValue("tabKeyword","")
                            setTabKeyWordFieldStatus(false)
                            const val = { ...selectedMandate }
                            val[`manTab${tabVal}`] = e
                            setSelectedMandate(val)
                            // setSelectedLocation({...selectedLocation})
                            dispatch(setPersistFilter({...persistFilter,persistMandate:val}))
                            // if(selectedMandate[`manTab${tabVal}`] == null) setApplyFilterStatus({status: "search",})
                            const persistanceData = JSON.parse(JSON.stringify(persistanceDataChangeStatus));
                            persistanceData[`tab${tabVal}`] = "changed"                          
                            if(selectedMandate[`manTab${tabVal}`] == null) dispatch(setFilterPersist(persistanceData))
                          }
                        
                         
                          
                          // dispatch(setPersistFilter({...persistFilter,persistMandate:val,
                          //   persistLocation:{...selectedLocation,[`locTab${tabVal}`]:null},
                          //   persistTags:tabVal===1?null:persistFilter?.persistTags,keywordsPersist:{...selectedKeywords,[`keyTab${tabVal}`]:null},
                          //   persistSearchQuery:tabVal===2?null:persistFilter?.persistSearchQuery}))
                        }}
                      />
                    )}
                  />
                )}
              </FormControl>
              <FormControl style={{ width: '30%' }}>
                <InputLabel>Location</InputLabel>
                <Controller
                  name='mLocationId'
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <Select 
                      label='Location' 
                      // value={value ?? ""} 
                      value={selectedLocation[`locTab${tabVal}`]?? ""}
                      className='candidate-loc-dropdown'
                      onChange={async(e)=>{
                        if(getValues("searchQueryId")&&getValues("searchQueryId")!='' && tabVal === 2){
                          const newSearch = new URLSearchParams(location.search);
                         newSearch.set('locationid', e.target.value);
                          setValue("searchQueryId",'')
                          // dispatch(resetFilters())
                          dispatch(setFilterValues({...filters,mLocationId:e.target.value,searchQueryId:''}))
                        }
                        const val = {...selectedLocation};
                        val[`locTab${tabVal}`] = e.target.value;
                        setSelectedLocation({...selectedLocation,[`locTab${tabVal}`]:e.target.value})
                        
                          dispatch(setPersistFilter({...persistFilter,persistLocation:{...selectedLocation,[`locTab${tabVal}`]:e.target.value},persistTags:tabVal===1? null:persistFilter?.persistTags}))
                        // setTabFilters({...tabFilters,})
                        onChange(e.target.value);
                      }}
                    >
                      {persistFilter?.locationOptions?.[`locList${tabVal}`]&&persistFilter?.locationOptions?.[`locList${tabVal}`]?.length > 0 && persistFilter?.locationOptions?.[`locList${tabVal}`]?.map((loc:any, index:number) => (
                        <MenuItem key={index} value={loc?.id} >{loc?.location}</MenuItem>
                      ))}
                      {locationList?.length === 0 && (
                        <MenuItem disabled={true}>No Data</MenuItem>
                      )}
                    </Select>
                  )}
                />
              </FormControl>
              {tabVal === 1 && SourcingTagDropdown()}
              {tabVal === 2 && RenderRpaSearchInputs()}
              {tabVal === 3 && RenderMatchCriteriaInputs()}
            </Box>
          )}
          {tabVal === 3 && (
            <Box className='confidence-wrapper'>
              {artemisIntelligentState .length > 0 && <ArtemisIntelligentSkill artemisIntelligentState={artemisIntelligentState} setArtemisIntelligentState={setArtemisIntelligentState}/>}
              {/* <FormControl className='confidence-formctl'>
                <InputLabel className='label-of-Score'>Confidence Score</InputLabel>
                <Controller
                  control={control}
                  name='confidenceScore'
                  render={({ field: { onChange, value } }) => (
                    <RangeSlider
                      rangeValue={value ?? [0, 100]}
                      setRangeValue={onChange}
                      minValue={0}
                      maxValue={100}
                      rangeStep={1}
                      markStep={Math.floor(20)}
                      dontShowLabel={true}
                      onChangeHandler={(e) => onChange(e.target.value)}
                      labelText={''}
                      className={'candidate-slider'}
                    />
                  )}
                />
              </FormControl> */}
            </Box>
          )}
          {/* { (tabVal !== 0) && ((location?.state?.searchFilter?.module==="rpa"&& getValues("searchQueryId")!="") || (watchMandateId?.value && watchMandateId?.value !== "")) ? <div className="candidateTabKeyword" style={{marginTop:"20px"}}>
            <FormControl fullWidth>
              <Controller
                control={control}
                name='tabKeyword'
                render={({ field: { onChange, value } }) => {
                  return (
                    <TextField
                      placeholder='Search Keyword'
                      value={value||""}
                      onChange={(e:any) => {
                        onChange(e.target.value)
                        // setSelectedKeywords({...selectedKeywords,[`keyTab${tabVal}`]:e.target.value})
                        // dispatch(setPersistFilter({...persistFilter,keywordsPersist:{...selectedKeywords,[`keyTab${tabVal}`]:e.target.value}}))
                        handleDebouncedSearch(e.target.value);
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position='end' style={{ paddingRight: '10px' }}>
                            <SearchIcon />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position='end'>
                            <Controller
                              control={control}
                              name='booleanSearch'
                              render={({ field: { onChange: onBoolChange, value: boolVal } }) => {
                                return (
                                  <Checkbox
                                    size='small'
                                    checked={boolVal}
                                    onChange={onBoolChange}
                                  />
                                )
                              }}
                            />
                            <Typography
                              style={{ fontSize: '13px', fontWeight: 400, color: '#002882' }}
                            >
                              Boolean search
                            </Typography>
                          </InputAdornment>
                        ),
                      }}
                    />
                  )
                }}
              />
            </FormControl>
          </div>
          :<></>
          } */}
        </Box>
        {(basicSearchValuesAvailable) ||(location?.state?.searchFilter?.module==="rpa"&& getValues("searchQueryId")!="") ?(
          <Box className='footer-btn' style={{marginTop:"10px"}}>
            {tabVal != 0 && <Button className='submit-btn' onClick={() => handleSubmit(filterSubmitHandler)()}>
              Search
            </Button>}
            <Button className='reset-btn' onClick={resetAllFilters}>
              <RotateIcon />
              <Typography>Reset All</Typography>
            </Button>
          </Box>
        ):<></>}
      </Box>
    </Box>
  )
}

export default CandidateHeaderTabs