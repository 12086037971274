import { Drawer as MuiDrawer, styled, Box } from '@mui/material'
import { Routes } from './Routes'
import { DRAWER_WIDTH } from '../../utils/constants'
import { navClosedMixin, navOpenedMixin } from '../../styles/mixins'
import { Hamburger } from '../Header/Hamburger'
import mainLogo from '../../assets/xpheno_logo.svg'
import collapseLogoIcon from '../../assets/xpheno_logo_collpase.svg'
import { useNavigate } from 'react-router-dom'

import './Navigation.css'
import { useAppSelector } from '../../store/hooks'

interface NavigationProps {
  open: boolean | undefined
  handleClose: () => void
  toggleNavigation: () => void
}

export const Navigation = ({ open, handleClose, toggleNavigation }: NavigationProps) => {
  const navigate = useNavigate()
  const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
  return (
    <Drawer
      variant='permanent'
      open={open}
      onClose={handleClose}
      className={`rightToggleMenu navToggle${open}`}
      sx={{
        display: { sm: 'block', xs: 'none', md: 'block' },
      }}
    >
      <DrawerHeader className='drawer-header'>
        {open ? <Box onClick={() => navigate('/')} component='img' alt='Xpheno' src={mainLogo} /> : <Box onClick={() => navigate('/')} component='img' alt='Xpheno' src={collapseLogoIcon}/>}
        <Box sx={{ flexGrow: 1 }} />
        <Hamburger toggleNavigation={toggleNavigation} />
      </DrawerHeader>
      {Object.keys(userPermissions != undefined ? userPermissions : {}).length > 0 && <Routes open={open} />}
    </Drawer>
  )
}

const DrawerHeader = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
  padding: '10px',
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  width: DRAWER_WIDTH,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...navOpenedMixin(theme),
    '& .MuiDrawer-paper': navOpenedMixin(theme),
  }),
  ...(!open && {
    ...navClosedMixin(theme),
    '& .MuiDrawer-paper': navClosedMixin(theme),
  }),
}))
