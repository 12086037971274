import React, { useEffect, useState } from 'react';
import { Paper, Box, Divider, Typography, Grid, Stack, Button, Checkbox, FormControl, InputLabel, InputAdornment, OutlinedInput, Autocomplete, TextField, AlertProps, Snackbar, Select, MenuItem  } from '@mui/material'
import { PRIMARY1, PRIMARY2, PRIMARY3, TERTIARY12, TERTIARY3 } from '../../utils/constants';
import { AppButton } from '../AppComponents/AppButton';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download-white.svg'
import { ReactComponent as FunnelIcon } from '../../assets/icons/funnel.svg'
import { ReactComponent as ArrowDownIcon } from '../../assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from '../../assets/icons/arrow-up.svg'
import { ReactComponent as AddNewIcon } from '../../assets/icons/add-new.svg'
import { useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import SearchIcon from '@mui/icons-material/Search';
import { ReactComponent as RotateIcon } from '../../assets/icons/reset.svg'
import { useAppSelector } from '../../store/hooks';

interface IFormInput {
  searchKey: string;
  roleStatus: any;
}

const RoleAccessFilter = ({totalList,setRoleListFilter}:any) => {
  const initValues = {
    searchKey: "",
    roleStatus: null,
  }
  const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
  const { userRole } = useAppSelector((state) => state.UserDetail)
  const methods = useForm<IFormInput>({mode: "onChange", defaultValues: initValues });

    const {
      control,
      handleSubmit,
      getValues,
      trigger,
      formState:{isValid,errors},
      reset,
      clearErrors 
    } = methods
    const [filterChecked, setfilterChecked] = useState<boolean>(false)
    const navigate =useNavigate()
    const handleFilter = () => {
        setfilterChecked((prev) => !prev)
      }

    const mandateSubmitHandler = async (data:any, event:any) => {
        setRoleListFilter({
          "keyword": data?.searchKey,
          "roleStatus": data?.roleStatus,
        })
    }
    const filterDataReset = () => {
      reset(initValues);
      setRoleListFilter({
        "keyword": "",
        "roleStatus": "",
      })
    }
    
    return (
        <div>
            <Paper className='box-style' elevation={1}>
            <Box display='flex' justifyContent='space-between' className='filter-parent-box'>
              <Box className='tasklist' >
                <Typography display='flex' gap={1} className='tasklist-title'>
                    Roles Lists <Typography color={PRIMARY2}>({totalList})</Typography>
                </Typography>
              </Box>
              <Box display='flex' justifyContent='space-between'>
                {/* <ReportSearch value={keyWordSearch} onChange={onFilterSearchChange}/>
                <Divider orientation='vertical' flexItem variant={'middle'} /> */}
                <Box>
             
                  <AppButton
                    variant='contained'
                    bgcolor={filterChecked ? PRIMARY1 : TERTIARY3}
                    margin={5}
                    minwidth={40}
                    onClick={handleFilter}
                    textColor={filterChecked ? TERTIARY12 : PRIMARY3}
                  >
                    {' '}
                    <FunnelIcon className='add-new-icon' />{' '}
                    {filterChecked ? <ArrowUpIcon /> : <ArrowDownIcon />}
                  </AppButton>
                  {/* <AppButton minwidth={40} margin={-5}>
                    <SortIcon />
                  </AppButton> */}
                  {(userPermissions?.user_management_module?.role_module?.create?.selected && userRole != "Admin") && <AppButton
                    variant='contained'
                    bgcolor={PRIMARY2}
                    margin={5}
                    textColor={TERTIARY12}
                    width={'80px'}
                    className='class-new-button'
                    onClick={() => navigate('/createrole')}
                    tooltip='Add New Role'
                >
                    <AddNewIcon style={{ marginRight: '10px' }} /> New
                </AppButton>}

                {(userRole == "Admin") && <AppButton
                    variant='contained'
                    bgcolor={PRIMARY2}
                    margin={5}
                    textColor={TERTIARY12}
                    width={'80px'}
                    className='class-new-button'
                    onClick={() => navigate('/createrole')}
                    tooltip='Add New Role'
                >
                    <AddNewIcon style={{ marginRight: '10px' }} /> New
                </AppButton>}
                </Box>
              </Box>
            </Box>

    
            {filterChecked && (
              <Box>
                <Box sx={{ flexGrow: 1 }} className='filter-box-style'>
                  <Grid item xs={12} md={12} sm={3} lg={12}>
                    <Typography className='filter-title'> Filter</Typography>
                  </Grid>
                  <Box component={'form'} onSubmit={handleSubmit(mandateSubmitHandler)}>
                    <Box className="roleListFilterSect">
                      <FormControl fullWidth className="roleListFilterSearch" style={{width:"250px"}}>
                        <SearchIcon />
                        <Controller
                            name={"searchKey"}
                            control={control}
                            render={({ field: { onChange, value, onBlur } }) => (
                              <TextField
                              label='Search Keyword'
                              value={value}
                              onChange={onChange}
                              onBlur={onBlur}
                            />
                            )}
                            />
                      </FormControl>
                      <FormControl fullWidth style={{width:"250px"}}>
                        <InputLabel id="demo-simple-select-label">Role Status</InputLabel>
                        <Controller
                            name={"roleStatus"}
                            control={control}
                            render={({ field: { onChange, value, onBlur } }) => (
                            <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value}
                            defaultValue=""
                            label="Role Status"
                            onChange={(e:any) => {
                                onChange(e.target.value)
                            }}
                            >
                                <MenuItem key="1" value="1">{"Active"}</MenuItem>
                                <MenuItem key="2" value="2">{"Inactive"}</MenuItem>
                            </Select>
                            )}
                            />
                      </FormControl>
                    </Box>
                    <Grid className="filter-btn-container" item xs={12} sm={6}>
                      <Stack justifyContent="end" gap={1} direction='row'>
                          <Box className='filter-search-btn'>
                            <AppButton
                              label='Search'
                              bordercolor='black'
                              bgcolor='black'
                              size='large'
                              type='submit'
                              className='search-bth'
                              sx={{ color: '#fff' }}
                            />
                          </Box>
                          <Button
                            sx={{
                              color: '#002882',
                              textTransform: 'none',
                            }}
                            variant='text'
                            // onClick={resetAllFilters}
                          >
                            <RotateIcon />
                            <Typography className='reset-label' onClick={() => {filterDataReset()}}>Reset All</Typography>
                          </Button>
                        </Stack>
                    </Grid>
                  </Box>
                </Box>
              </Box>
            )}
          </Paper>
        </div>
    );
};

export default RoleAccessFilter;