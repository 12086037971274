import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, Box, TableHead, TableRow, Paper, Chip, FormControl, MenuItem, Select, Pagination, Tooltip, Popover, OutlinedInput, InputAdornment, Checkbox ,Avatar, SelectChangeEvent, FormGroup, FormControlLabel, Dialog, DialogTitle, IconButton, DialogContent, TextField, DialogActions, Button, Typography, Input } from "@mui/material";
import { ReactComponent as SortUpDown } from '../../../../assets/icons/sort-up-down.svg';
import { ReactComponent as SortUp } from '../../../../assets/icons/sort-up.svg';
import { ReactComponent as SortDown } from '../../../../assets/icons/sort-down.svg';
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg';
import { useLocation, useNavigate,useParams } from 'react-router-dom';
import { useAppSelector } from '../../../../store/hooks';
import _ from 'lodash';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import { ReactComponent as DefaultClientLogo } from '../../../../assets/images/defaultUserIcon.svg'
import { ReactComponent as ToolfilterIcon } from '../../../../assets/icons/ToolfilterIcon.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/lightcloseIcon.svg'
import { useForm } from 'react-hook-form';
import { styled } from '@mui/system';
import '../HiringDemandCard/HiringDemandCardDetail.scss'
import { IMaskInput } from 'react-imask';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { ReactComponent as ClientIcon } from '../../../../assets/icons/widgets/Client.svg'
import { ReactComponent as MandateIcon } from '../../../../assets/icons/widgets/Mandate.svg'
import { ReactComponent as CandidateIcon } from '../../../../assets/icons/widgets/candidate.svg'
import { getFunnelConversionListApi, gethiringdemandlistapi, getOfferMgtListApi } from '../../../../services/DashboardService';
import DashboardFilterIndex from '../../../Common/CustomeDasboardFilter/Filters/DashboardFilterIndex';
import HiringDemandTableSection from '../HiringDemandCard/HiringDemandTableSection';

const OfferManagementWidgetDetails = () => {

    
    const { id } = useParams();
    const location = useLocation();
    const navigate = useNavigate()
    console.log("view Hiring : ",id,location)
    const [HirningDemandParamsDetails,setHirningDemandParamsDetails] = useState<any>(location)
    const [HirningDemandParamsDetailsCopy, setHirningDemandParamsDetailsCopy] = useState<any>("parentFilterObject" in location.state ? location.state.parentFilterObject: location)
    const [DashboardFilterDatas,setDashboardFilterDatas] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "dashboardfilterObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.dashboardfilterObj : {} : {}) : {})
    const [exportdatareqest,setexportdatareqest] = useState<any>({})
    const [candidates, setCandidates] = useState<any>({});
    const [candidateData, setCandidateData] = useState<any | any[]>([]);
    const [initalCount, setInitalCount] = useState(0)
    const [reportSubmitdatalabels,setreportSubmitdatalabels] = useState<any>({})
    const [reportSubmissionAPIData,setReportSubmissionAPIData] = useState<any>({})
    const {userRole,userId} = useAppSelector((state) => state.UserDetail)
    const [userRoleObjectSelected,setUserRoleObjectSelected] = useState()
    const [userDependendObj,setUserDependendObj] = useState({})
    const [tableColumnFields,setTableColumnFields] = useState([])
    const [filterAppliedState,setFilterAppliedState] = useState(false)
    console.log("HirningDemandParamsDetails",HirningDemandParamsDetails)
    const [hiringDemandTabDetail,setHiringDemandTabDetail] = useState<any>(HirningDemandParamsDetails?.state?.hiringTabObj ?? {
        sortColumn: "",
        sortDirection: "",
        paginationCountSize: {
          inputPageSize: 10,
          pageSize: 1,
          inputPageNo: 1,
          totalRecords: 0,
        },
        textFieldValues: ""
      })

      function toCamelCaseAndAppend(str:string, appendWord:string) {
        const camelCaseStr = str
            .toLowerCase()
            .split('_')
            .map((word, index) =>
                index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
            )
            .join('');
        
        return camelCaseStr + appendWord.charAt(0).toUpperCase() + appendWord.slice(1);
    }
    
    const offerMgtListApi = async () => {
      const HirningDemandParamsUserRoleDetails = HirningDemandParamsDetails?.state?.userFilterObj != undefined ? HirningDemandParamsDetails?.state?.userFilterObj : []
      const reqObj:any = {
        "coe": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.coe : HirningDemandParamsDetails?.state?.filterList?.coe,
        "createdAt": "",
        "createdBy": "",
        "jobTitle":  Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.jobTitle : HirningDemandParamsDetails?.state?.filterList?.jobTitle,
        "userId": userId,
        "pageSize": hiringDemandTabDetail.paginationCountSize.inputPageSize,
        "pageNo": hiringDemandTabDetail.paginationCountSize.inputPageNo,
        "clientId": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.client : HirningDemandParamsDetails?.state?.filterList?.client || undefined,
        "joLocation": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.joLocation : HirningDemandParamsDetails?.state?.filterList?.joLocation || [],
        "businessUnit": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.businessUnit : HirningDemandParamsDetails?.state?.filterList?.businessUnit || undefined,
        "mandateType": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.mandateType : HirningDemandParamsDetails?.state?.filterList?.mandateType || [],
        "jobMode": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.jobMode : HirningDemandParamsDetails?.state?.filterList?.jobMode || [],
        "mandateSPOC": Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.mandateSPOC : HirningDemandParamsDetails?.state?.filterList?.mandateSPOC || [],
        "roles":Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.roles : HirningDemandParamsDetails?.state?.filterList?.roles || [],
        "primarySkills":Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.primarySkills : HirningDemandParamsDetails?.state?.filterList?.primarySkills || [],
        "customEndDate":Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.customEndDate : HirningDemandParamsDetails?.state?.filterList?.customEndDate || "",
        "customStartDate":Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData?.customStartDate : HirningDemandParamsDetails?.state?.filterList?.customStartDate || "",
        "stageFilter":reportSubmissionAPIData.stage,
        "statusFilter":reportSubmissionAPIData.status,
        "profileLastActiveDate": ""
      };
      Object.keys(hiringDemandTabDetail.textFieldValues).map((item:any) => {
        if(hiringDemandTabDetail.textFieldValues[item].length > 0)reqObj[toCamelCaseAndAppend(item,"search")] = hiringDemandTabDetail.textFieldValues[item]
      })
      if(hiringDemandTabDetail.sortColumn.length > 0){reqObj["sortColumn"] = hiringDemandTabDetail.sortColumn}else{reqObj["sortColumn"] = "candidate_name"}
      if(hiringDemandTabDetail.sortDirection.length > 0){reqObj["sortDirection"] = hiringDemandTabDetail.sortDirection}else{reqObj["sortDirection"] = ""}
      if(Object.keys(reportSubmissionAPIData).length > 0){if("userId" in reportSubmissionAPIData) reqObj["userId"] = reportSubmissionAPIData?.userId}else if(HirningDemandParamsUserRoleDetails.length > 0){reqObj["userId"] = HirningDemandParamsUserRoleDetails.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue : [userId]: [userId]}
      if(Object.keys(reportSubmissionAPIData).length > 0){if("userId" in reportSubmissionAPIData) reqObj["userId"] = reportSubmissionAPIData?.userId}else if(HirningDemandParamsUserRoleDetails.length > 0){reqObj["userId"] = HirningDemandParamsUserRoleDetails.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue : [userId]: [userId]}

        try {
            const res: any = await getOfferMgtListApi(reqObj);
            setCandidates({"data":res?.data,"requestData":res?.data.data});
            setexportdatareqest(reqObj)
        } catch (error: any) {
            console.log(error);
        }
    };

        useEffect(() => {
          offerMgtListApi();
          setFilterAppliedState(false)
        }, [hiringDemandTabDetail]);
        
        useEffect(() => {
          if(initalCount == 0){
            setInitalCount(1)
          }else{
            setHiringDemandTabDetail({
              sortColumn: "",
              sortDirection: "",
              paginationCountSize: {
                inputPageSize: hiringDemandTabDetail.paginationCountSize.inputPageSize,
                inputPageNo: 1,
                pageSize: 10,
                totalRecords: 0,
              },
              textFieldValues: ""
            })
            setFilterAppliedState(true)
          }
        }, [reportSubmissionAPIData,HirningDemandParamsDetails])

        const renderEmptySearchBox = () => {;
          return (
              <Box className="empty-data-card">
                  <Box>
                      <NoResultFound />
                  </Box>
                  <Box className="empty-search-message">
                      No data found
                  </Box>
              </Box>
          )
      }


      const tabsData = [
        {
          id: "clients",
          label: "CLIENTS",
          value: candidateData?.clientCount,
          icon: <ClientIcon />,
        },
        {
          id: "mandates",
          label: "MANDATES",
          value: candidateData?.mandateCount,
          icon: <MandateIcon />,
        },
        {
          id: "candidates",
          label: "CANDIDATES",
          value: candidateData?.candidateCount,
          icon: <CandidateIcon />,
        },
      ];

      const HiringHeader = `<Box>
          <p class="breadcrumbFilter">Post Select - Candidate Status Distribution</p>
          <Typography display='flex' gap={'5px'} class='dashboardDetailHeaderTitle' style="margin-top:-15px;margin-bottom:10px;cursor:pointer">
              <span style="color:gray">Overview Dashboard > </span><span style="color:#0033a0">Distribution: Post Select Candidate Status'</span>
          </Typography>
        </Box>`

        const candidateRedirectNavigator = (candidateId:any,type:string) => {
          console.log("HirningDemandParamsDetails",HirningDemandParamsDetailsCopy)
          navigate(`/candidate/${candidateId}`, { state: {parentFilterObject: HirningDemandParamsDetailsCopy, dashboardfilterObj:DashboardFilterDatas, filterList: Object.keys(reportSubmissionAPIData).length > 0 ? reportSubmissionAPIData : HirningDemandParamsDetails?.state?.filterList ,filterListLabels: Object.keys(reportSubmitdatalabels).length > 0 ? reportSubmitdatalabels: HirningDemandParamsDetailsCopy?.state?.filterListLabels ,userFilterObj: userRoleObjectSelected, clientDependedObj: userDependendObj,source: "offerManagementDrillDown",hiringTabObj: hiringDemandTabDetail} });
        }

  return (
    <div>
      <div style={{ backgroundColor: '#fff', borderRadius: '10px'}}>
            <Box className="offerManageSect">
              <Box sx={{padding: '10px' }}>
              <Box sx={{paddingX:'5px'}}><KeyboardBackspaceIcon style={{color:'#002882',cursor:'pointer'}} onClick={()=>{navigate("/postSelectCandidate",{ state: {dashboardfilterObj:HirningDemandParamsDetailsCopy?.state?.dashboardfilterObj, filterList: HirningDemandParamsDetailsCopy?.state?.filterList ,filterListLabels: HirningDemandParamsDetailsCopy?.state?.filterListLabels,userFilterObj: HirningDemandParamsDetailsCopy?.state?.userFilterObj, clientDependedObj: HirningDemandParamsDetailsCopy?.state?.clientDependedObj } })}}/></Box>
              <DashboardFilterIndex HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} setReportSubmissionAPIData={setReportSubmissionAPIData} DashboardFilterDatas={DashboardFilterDatas} setDashboardFilterDatas={setDashboardFilterDatas} reportSubmitdatalabels={reportSubmitdatalabels} setreportSubmitdatalabels={setreportSubmitdatalabels} reportSubmissionAPIData={reportSubmissionAPIData} hideFieldsList={["stage","status","industryData","Start Date"]} headerTitle={HiringHeader} downloadName={`Post Select Candidate Status`} setUserRoleObjectSelected={setUserRoleObjectSelected} setUserDependendObj={setUserDependendObj} exportdatareqest={exportdatareqest} filterType={"subScreen"} screenType={"offmanagementDrill"} hiringDemandTabDetail={hiringDemandTabDetail}/>
                </Box>
                
                {Object.keys(candidates).length > 0 ? <HiringDemandTableSection candidates={candidates} setHiringDemandTabDetail={setHiringDemandTabDetail} tabType={""} candidateRedirectHandler={(candidateId:any,type:string) => candidateRedirectNavigator(candidateId,type)} setTableColumnFields={setTableColumnFields} filterAppliedState={filterAppliedState}/> : <div className='tabEmptyCase'>{renderEmptySearchBox()}</div>}
            </Box>
        </div>
    </div>
  )
}

export default OfferManagementWidgetDetails