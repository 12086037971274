import { Accordion, AccordionSummary, Avatar, Box, FormControl, IconButton, MenuItem, Modal, Pagination, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../../assets/icons/backarrowicon1.svg';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ReactComponent as ManageCopilotIcon } from '../../../assets/icons/manageCopilot.svg';
import { ReactComponent as CopilotForApproval } from '../../../assets/icons/task.svg';
import { ReactComponent as CopilotAuditLog } from '../../../assets/icons/auditLog.svg';
import { ReactComponent as ActiveCopilot } from '../../../assets/icons/activeCopilot.svg';
import { ReactComponent as InactiveCopilot } from '../../../assets/icons/inactiveCopilot.svg';
import { ReactComponent as CancelApprover } from '../../../assets/icons/cancelApprover.svg';
import { ReactComponent as UserIcon } from '../../../assets/icons/coPilotProfile.svg';
import { ReactComponent as CoPilotCalender } from '../../../assets/icons/coPilotCalendar.svg';
import { ReactComponent as TickApprover } from '../../../assets/icons/tickApprover.svg';
import AddCopilotImg from '../../../assets/icons/addCopilotImg.svg';
import ProfileDefault from "../../../assets/images/profileDefault.png"
import GroupStrick from '../../../assets/images/default-avatar.png'
import { ReactComponent as ReasonIcon } from '../../../assets/icons/reject-reason.svg'
import "./ManageCopilot.scss"
import { convertToDisplayDateTime } from '../../../utils/date-utils';
import { AppButton } from '../../AppComponents/AppButton';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import AddCopilot from './AddCopilot';
import { changeCopilotStatusAddAPI, getCopilotAuditListAPI, getCopilotListAPI } from '../../../services/UserService';
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import moment from 'moment';
import { AppErrorHandlerModal } from '../../AppComponents/AppErrorHandlerModal';
import { ReactComponent as SortUpDown } from '../../../assets/icons/sort-up-down.svg';
import { ReactComponent as SortUp } from '../../../assets/icons/sort-up.svg';
import { ReactComponent as SortDown } from '../../../assets/icons/sort-down.svg';
import { AppConfirmationHandlerActionModal } from '../../AppComponents/AppErrorHandlerModal/AppConfirmationHandlerActionModal';
import { drawerclose, expanddrawer, listStatus } from '../../../features/mandateDetail/DrawerReducer';
import { leftdrawertoggleclose } from '../../../features/toggle/ToggleReducer';
import { openNotification } from '../../../features/notification/notificationReducer';
import { ReactComponent as NoResultFound } from '../../../assets/icons/no-result-found.svg';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { toast } from 'react-toastify';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '506px',
    // height: '280px',
    borderRadius: '10px',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    padding: "32px 32px 20px"
  }

const CopilotCard = ({type,addClick,data}:any) => {    
    const {userRole, roleId } = useAppSelector((state) => state.UserDetail)
    const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
    return (
        <>
            <Box onClick={() => {if(type == "addCopilot")addClick(type,data,"")}} style={{height: type == "addCopilot" ? "250px": ""}} className={`coPoilotCardsSect ${(type == "approvePending" || type == "deactivePending") ? "disableCard" : type == "addCopilot" ? "addCopilotCard" : ""}`}>
                <Box className={`cardStatus ${type == "forApproveInactive" ? "cardImgDisable" : "cardImgDisable"}`}>
                    <img className={`${type == "inactive" ? "inactiveDisable" : ""}`} src={type == "addCopilot" ? AddCopilotImg : ProfileDefault} />
                    {type == "inactive" && <p className='inactiveRequest'>Inactive</p>}
                    {type == "forApproveInactive" && <p className='inactiveRequest'>Deactive Request</p>}
                    {type == "forApproveNewRequest" && <p className='NewRequest'>New Request</p>}                    
                </Box>
                <Box className={`cardDesc ${type == "inactive" ? "inactiveDisable" : ""}`}>
                    {type == "addCopilot" ? 
                    <>
                        <p className='addCopilotText'>Add <br></br> Copilot</p>
                    </>
                    :
                    <>
                        <Tooltip title={data.co_pilot_name}><h4>{data.co_pilot_name}</h4></Tooltip>
                        <Tooltip title={data.co_pilot_role_name}><p className='coPilotRole'>{data.co_pilot_role_name}</p></Tooltip>
                        <Box className="pilotInfoSect">
                            <Box><UserIcon /><Tooltip title={data.pilot_name}><p className='coPilotText'>{data.pilot_name}</p></Tooltip></Box>
                            <Box><CoPilotCalender /><p className='coPilotText'>{moment(data.access_till).format("DD MMM YYYY")}</p></Box>
                            {/* <p className='coPilotExpire'>{moment(data.access_till).format("DD MMM YYYY")}</p> */}
                        </Box>
                        
                    </>}
                </Box>
                {!(type == "forApproveInactive" || type == "forApproveNewRequest") &&                 
                <>
                {type != "addCopilot" && <Box className="manageCopilotApprover">
                    <h6 style={{margin:"0px"}}>Approver by</h6>
                    <p>{data?.approver_name}</p>
                </Box>}
                </>
                }
                
                <Box className="cardAction" style={{borderTop: ((type == "forApproveInactive" || type == "forApproveNewRequest") || type == "addCopilot") ? "none" : "", paddingTop: (type == "forApproveInactive" || type == "forApproveNewRequest") ? "0px":""}}>
                    {type == "inactive" && <Tooltip title={"Activate Co-pilot"}><ActiveCopilot onClick={() => {if(userPermissions.user_management_module.co_pilot.activate_co_pilot.selected ? true : (userRole == "Admin") ? true : false)addClick(type,data,"active")}}/></Tooltip>}
                    {type == "active" && <Tooltip title={"Deactivate Co-pilot"}><InactiveCopilot onClick={() => {if(userPermissions.user_management_module.co_pilot.deactivate_co_pilot.selected ? true : (userRole == "Admin") ? true : false)addClick(type,data,"inactive")}}/></Tooltip>}
                    {(type == "forApproveInactive" || type == "forApproveNewRequest") && 
                        <>
                            <Tooltip title={`${type == "forApproveInactive" ? "Approve Copilot Deactive request" : type == "forApproveNewRequest" ? "Approve Copilot new request" : ""}`}><TickApprover onClick={() => {addClick(type,data,"tick")}}/></Tooltip>
                            <Tooltip title={`${type == "forApproveInactive" ? "Cancel Copilot Deactive request" : type == "forApproveNewRequest" ? "Cancel Copilot new request" : ""}`}><CancelApprover onClick={() => {addClick(type,data,"cancel")}}/></Tooltip>
                        </>
                    }
                </Box>
                {(type == "approvePending" || type == "deactivePending") && <Box className="cardApprovalPending">
                    Approval Pending
                </Box>}

            </Box>
        </>
    )
}

const AuditLog = () => {
    const location = useLocation();
    const [userDetailData, setUserDetailData] = useState(location?.state)
    const [pageCount, setPageCount] = useState(0);
	const [pageSize, setPageSize] = useState(10)
	const [defaultPageCount, setDefaultPageCount] = useState(1)
    const [sortingTableHead, setSortingTableHead] = useState<any>({});
    const [sortColumn, setSortColumn] = useState<string>('Module');
    const [sortDirection, setSortDirection] = useState<string>('asc');
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const isRecruiter = useAppSelector((state)=>state?.mandatedrawer?.isRecruiter)
    const [paginationCountSize, setPaginationCountSize] = useState({
        pageSize: 10,
        pageCount: 0,
        keyWordSearch: ""
      })
    const sortTableHeadHandler = (column: any) => {
        let newDirection = '';
        if(!(column in sortingTableHead)){
            newDirection = 'asc';
        }else{
            if (sortDirection === 'asc') {
                newDirection = 'desc';
            } else if (sortDirection === 'desc') {
                newDirection = '';
            } else {
                newDirection = 'asc';
            }
        }
        

        setSortDirection(newDirection);
        setSortColumn(column);
        setSortingTableHead({
            [column]: !(column in sortingTableHead) ? 'Asc' : newDirection === 'asc' ? 'Asc' : newDirection === 'desc' ? 'Desc' : '',
        });
    };
    const [auditLogList,setAuditLogList] = useState<any>([])
    const getCopilotListAPi = async () => {
        try {
            const res: any = await getCopilotAuditListAPI({
                "pilotId":userDetailData.userId,
                "keyword": paginationCountSize.keyWordSearch,
                "pageSize":paginationCountSize.pageSize,
                "pageNo":paginationCountSize.pageCount,
                "sortColumn":sortColumn,
                "sortOrder":sortDirection.toUpperCase()
            });
            const copilotData = res.data.data.auditLogList.map((item:any) => {
                return {
                    "firstName": item.user_name,
                    "roleName": item.role_name,
                    "eventType": item.action,
                    "createdAt": moment(item.action_date).format("DD MMM YYYY, HH:mm"),
                    "module": item.module,
                    "event": item.event_id
                }
            })
            setPageCount(res.data.data.totalPages)
            setAuditLogList(copilotData)
        }catch (error: any) {
            console.log(error);
        }
    }
    const updatePagination = (paginationKey:any, changeValue:any) => {
        setPaginationCountSize(prevState => ({...prevState,[paginationKey]: changeValue}))
    }
    useEffect(() => {
        getCopilotListAPi()
    },[paginationCountSize,sortingTableHead])

    const auditLogTransfer = (modulescreen:string, eventId: any) => {
        if(eventId !=  undefined){
            if (modulescreen === "mandate" || modulescreen === "mandate_detail" || modulescreen ==="mandate_approval" || modulescreen === "mandate sharing") {
                dispatch(drawerclose())
                dispatch(leftdrawertoggleclose())
                dispatch(openNotification({ module: modulescreen, id: eventId }))
                dispatch(expanddrawer())
                setTimeout(() => {
                    navigate(`/mandates?id=${eventId}`,{state:"copilotAuditRedirection"});
                }, 300)
                }else if(modulescreen ==="mandate_sharing"){
                dispatch(drawerclose())
                dispatch(leftdrawertoggleclose())
                dispatch(openNotification({ module: modulescreen, id: eventId }))
                dispatch(expanddrawer())
                setTimeout(() => {
                    navigate(`/mandates?id=${eventId}`,{state:"copilotAuditRedirection"});
                }, 300)
                }
                else if (modulescreen === "candidate" || modulescreen === "candidate tracking" || modulescreen === "smart_call") {
                dispatch(openNotification({ module: modulescreen, id: eventId }))
                setTimeout(() => {
                    navigate(`/candidate/${eventId}`,{state:{source:modulescreen === "smart_call" ? "smartCall": modulescreen === "candidate tracking" ? "candidateRedirection" : "",id:eventId,timeStamp: new Date().getTime()}})
                }, 500)
                }else if (modulescreen === "mandate_allocation") {
                dispatch(openNotification({ module: modulescreen, id: eventId }))
                dispatch(listStatus({isApproval:true,isRecruiter}))
                setTimeout(() => {
                    navigate(`/mandates`,{state:"allocationRedirection"})
                }, 500)
                }else if(modulescreen === "task"){           
                    setTimeout(() => {
                        navigate(`/my-tasks`)
                    }, 500)
                }
                else if(modulescreen === "work management"){           
                    setTimeout(() => {
                        navigate(`/work-management`)
                    }, 500)
                }
                else if(modulescreen == "job order" ){           
                setTimeout(() => {
                    // navigate(`/jobOderDetailView?id=${eventId}&locId=${mandateData?.mandate_location_id}`,{state:{timeStamp: new Date().getTime()}})
                    navigate(`/jobOderDetailView?id=${eventId}`,{state:{timeStamp: new Date().getTime()}})
                }, 500)
            }
        }
    }
    const renderEmptySearchBox = () => {;
              return (
                  <Box style={{padding: "136px 160px",minHeight: "500px",textAlign:"center"}}>
                      <Box>
                          <NoResultFound />
                      </Box>
                      <Box className="empty-search-message">
                          No data found
                      </Box>
                  </Box>
              )
          }
    return (
        <>
        <Box className="mandate-detail-wrapper manageCopilotAuditTable">
        <Box className="userListFilterSect">
                      <FormControl fullWidth className="userListFilterSearch">
                        <SearchIcon />
                        <TextField
                        label='Search Keyword'
                        value={paginationCountSize.keyWordSearch}
                        onChange={(e:any) => {setPaginationCountSize(prevState => ({...prevState,keyWordSearch: e.target.value}))}}
                    />
                      </FormControl>
                    </Box>
                <Box className="auditLogTableSect">
                    {auditLogList.length > 0 ? <Table className="audit-log-table">
                    <TableHead>
                        <TableRow>
                            <TableCell style={{width:"20%"}}><p onClick={() => sortTableHeadHandler("module")}>Module {sortingTableHead && sortingTableHead["module"] ?(sortingTableHead["module"] === "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}</p></TableCell>
                            <TableCell style={{width:"20%"}}><p onClick={() => sortTableHeadHandler("action")}>Action {sortingTableHead && sortingTableHead["action"] ?(sortingTableHead["action"] === "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}</p></TableCell>
                            <TableCell style={{width:"25%"}}><p onClick={() => sortTableHeadHandler("action_date")}>Date {sortingTableHead && sortingTableHead["action_date"] ?(sortingTableHead["action_date"] === "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}</p></TableCell>                       
                            <TableCell style={{width:"20%"}} ><p onClick={() => sortTableHeadHandler("action_by_user")}>Action By {sortingTableHead && sortingTableHead["action_by_user"] ?(sortingTableHead["action_by_user"] === "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />}</p></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                    {auditLogList?.map((item:any, index:number) => (
                        <TableRow key={index} >
                        <TableCell style={{width:"20%"}}>
                            <span className="primary-text text-ellips" style={{fontSize:"14px",textTransform: "capitalize",color:"#002882",textDecoration:"underline",cursor:"pointer"}} onClick={() => {auditLogTransfer(item?.module.toLowerCase(), item.event)}}>{item?.module.toLowerCase() || "-"}</span>
                        </TableCell>
                        <TableCell style={{width:"20%"}}>
                            <Tooltip title={item?.eventType} placement="bottom-start" >
                            <span className="primary-text text-ellips" style={{fontSize:"14px",textTransform: "capitalize"}}>{item?.eventType.toLowerCase() || "-"}</span>
                            </Tooltip>
                        </TableCell>
                        <TableCell style={{width:"25%"}}>
                            <div className="primary-text" >{item?.createdAt}</div>
                        </TableCell>                   
                        <TableCell style={{width:"40%"}}>
                            <Box className="d-flex" >
                            <Box>
                                <Avatar
                                alt='no-group'
                                src={GroupStrick}
                                sx={{ width: 30, height: 30 }}
                                />
                            </Box>
                            <Box className='user-detail' >
                                <Tooltip title={item?.firstName} placement="bottom-start">
                                <span className='primary-text ellipsisText1'>{item?.firstName}</span>
                                </Tooltip>
                                <span className='secondary-text'>{item?.roleName || "-"}</span>
                            </Box>
                            </Box>
                        </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>: <>{renderEmptySearchBox()}</>}
                </Box>
            <Box className="reportTablePaginationSect" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
						<Box sx={{ display: 'flex' }}>
							<p style={{color:"#7f8082",fontSize:"14px"}}>Rows per page:</p>
							<FormControl fullWidth>
								<Select
									labelId="demo-simple-select-label"
									id="demo-simple-select"
									value={pageSize}
									label="Age"
									onChange={(event:any) => {setDefaultPageCount(1);setPageSize(event.target.value);updatePagination("pageSize",event.target.value)}}
								>
									<MenuItem value={5}>5</MenuItem>
									<MenuItem value={10}>10</MenuItem>
									<MenuItem value={15}>15</MenuItem>
									<MenuItem value={20}>20</MenuItem>
								</Select>
							</FormControl>
						</Box>
						<Pagination count={pageCount} page={defaultPageCount} variant="outlined" shape="rounded" onChange={(event, pageNumber) => {setDefaultPageCount(pageNumber);updatePagination("pageCount",pageNumber - 1)}}/>
					</Box>
            </Box>
        </>
    )
}

const ManageCopilot = () => {
    const navigate =useNavigate()
    const location = useLocation();
    const manageUrlTab = window.location.href?.split("?")[1]?.split("&")[0]
    const manageUrlPilotId = window.location.href?.split("?")[1]?.split("&")[1]
    const [userDetailData, setUserDetailData] = useState(manageUrlTab != undefined ? {
        "defaultTab" : manageUrlTab.split("=")[1],
        "userId": manageUrlPilotId.split("=")[1]
    }: location?.state)
    const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
    const [copilotTabValue, setCopilotTabValue] = React.useState(userDetailData?.defaultTab != undefined ? userDetailData?.defaultTab?.toString() : manageUrlTab != undefined ? manageUrlTab.split("=")[1] : 1);
    const handleChange = (event: any, newValue: string) => {
        setCopilotTabValue(newValue);
      };      
    
    const [addCopilotPopupStatus, setAddCopilotPopupStatus] = useState(false)
    const { userId, userRole } = useAppSelector((state) => state?.UserDetail)
    const [copilotList, setCopilotList] = useState<any>([])
    const [copilotApproverList, setCopilotApproverList] = useState<any>([])
    const [inactiveCopilotData, setInactiveCopilotData] = useState<any>("")
    const [uploadsuccess, setUploadsuccess] = useState(false)
    const [SuccessMessage, setSuccessMessage] = useState('')
    const [confirmSubmittStatus, setConfirmSubmittStatus] = useState(false)
    const [confirmSubmittObject, setConfirmSubmittObject] = useState<any>({})
    const [initialLoadCount, setInitialLoadCount] = useState(0)
    useEffect(() => {
        navigate(location.pathname, { replace: true, state: {userId: manageUrlPilotId != undefined ? manageUrlPilotId.split("=")[1] : userDetailData.userId,defaultTab:userDetailData?.defaultTab != undefined ? userDetailData?.defaultTab?.toString() : manageUrlTab != undefined ? manageUrlTab.split("=")[1] : 1,timeStamp: new Date().getTime() } })      
    },[])
    const getCopilotListAPi = async () => {
        const copilotList:any = {            
            tabId: copilotTabValue,            
            pilotId: manageUrlPilotId != undefined ? manageUrlPilotId.split("=")[1] : userDetailData.userId
        }
        if(copilotTabValue == 2)copilotList["userId"] = userId
         try {
            const res: any = await getCopilotListAPI(copilotList);
            if(copilotTabValue == "1"){
                setCopilotList(res.data.data)
            }else if(copilotTabValue == "2"){
                setCopilotApproverList(res.data.data)
            }
            console.log("setCopilotList",res.data.data)
            
        }catch (error: any) {
            console.log(error);
        }
    }
    useEffect(() => {
        if(location?.state?.defaultTab != undefined){
            handleChange({},location?.state?.defaultTab?.toString())
            getCopilotListAPi()
        }
    },[location])
    const changeCopilotStatusAPI = async (changeObj:any) => {
        const statusType1 = changeObj.actionType.trim()
        try {
            const res: any = await changeCopilotStatusAddAPI(changeObj);
            setUploadsuccess(true)
            setSuccessMessage(`${statusType1 == "co_pilot_deactivate_request" ? "Your deactivation request has been successfully submitted and is awaiting for approval": statusType1 == "co_pilot_deactivate_approved" ? "The deactivation request has been successfully approved" :  statusType1 == "co_pilot_deactivate_reject" ? "The deactivation request has been successfully rejected" : statusType1 == "co_pilot_approved" ? "The Co-Pilot request has been successfully approved." : statusType1 == "co_pilot_reject" ? "The Co-Pilot request has been successfully rejected." : ""}`);   
            setConfirmSubmittStatus(false)
        }catch (error: any) {
            console.log(error);
            toast.error(error?.response?.data?.message) 
        }
    }
    useEffect(() => {
        getCopilotListAPi()
    },[copilotTabValue])

    const addCopilotHandler = (type:string, data:any) => {
        setAddCopilotPopupStatus(true)       
        if(type == "inactive"){
            setInactiveCopilotData(data)
        }else{
            setInactiveCopilotData("")
        }
    }
    const changeStatusCopilot = (type:string, data:any, btnType:string) => {
        setConfirmSubmittStatus(true)
        const reqObj:any = {
            status: (type == "forApproveNewRequest" && btnType == "tick") ? 149 : (type == "forApproveInactive" && btnType == "tick") ? 154 : (type == "forApproveNewRequest" && btnType == "cancel") ? 150 : (type == "forApproveInactive" && btnType == "cancel") ? 155 : type == "active" ? 153 : 0,
            id: data.id,
            actionType: (type == "forApproveNewRequest" && btnType == "tick") ? "co_pilot_approved" : (type == "forApproveInactive" && btnType == "tick") ? "co_pilot_deactivate_approved" : (type == "forApproveNewRequest" && btnType == "cancel") ? "co_pilot_reject" : (type == "forApproveInactive" && btnType == "cancel") ? "co_pilot_deactivate_reject" : type == "active" ? "co_pilot_deactivate_request" : "",
            type: (type == "forApproveNewRequest" && btnType == "tick") ? "Are you sure you want to approve the Co-Pilot request?" : (type == "forApproveInactive" && btnType == "tick") ? "Are you sure you want to approve the deactivation request for this Co-Pilot?" : (type == "forApproveNewRequest" && btnType == "cancel") ? "Are you sure you want to reject the Co-Pilot request?" : (type == "forApproveInactive" && btnType == "cancel") ? "Are you sure you want to reject the deactivation request for this Co-Pilot?" : type == "active" ? "Are you sure you want to proceed with the deactivation of this Co-Pilot? " : ""
        }
        setConfirmSubmittObject(reqObj)
    }
    const closeCopilotPopup = (status:string) => {
        setAddCopilotPopupStatus(false)
        if(status == "load") getCopilotListAPi()
    }
    const handleClose = () => {
        setUploadsuccess(false);
        setSuccessMessage('');
        getCopilotListAPi()
      }
    return (
        <>
        <Box className='mandate-wrapper manageCopilotSect'>
                <div className='nav-header'>
                    <IconButton
                        style={{paddingLeft:"0px"}}
                        onClick={() =>  window.history.back()}
                        aria-label='back'
                        disableRipple
                        disableFocusRipple
                    >
                        <BackIcon />
                    </IconButton>                    
                </div>
                <Box sx={{ width: '100%', typography: 'body1' }} className="userViewTabBlk">
                    <TabContext value={copilotTabValue}>
                        <Box className="userViewTabSect" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="icon label tabs example">
                                <Tab className={((parseInt(copilotTabValue) - 1) == 1) ? "prev" : ""} icon={<ManageCopilotIcon />} label="Manage Copilot" value="1" />
                                {(userPermissions?.user_management_module?.co_pilot?.approval_co_pilot?.selected ? true : (userRole == "Admin") ? true : false) && <Tab className={((parseInt(copilotTabValue) - 1) == 2) ? "prev" : ""} icon={<CopilotForApproval />} label={<div className='forApprovalTab'>For Approval <span></span></div>} value="2" />}
                                {(userPermissions?.user_management_module?.co_pilot?.history_co_pilot?.selected ? true : (userRole == "Admin") ? true : false) && <Tab className={((parseInt(copilotTabValue) - 1) == 3) ? "prev" : ""} icon={<CopilotAuditLog />} label="Audit Logs" value="3" />}
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Box className="manageCopilotCardSect">
                                {copilotList.map((item:any) => {
                                    console.log("manageCopilotCardSect",moment(moment(item.access_till).format('YYYY-MM-DD')).isAfter(moment()))
                                    return (
                                        <>
                                        <CopilotCard type={`${((item.approval_status == 149 || item.approval_status == 155) && moment(moment(item.access_till).format('YYYY-MM-DD')).isSameOrAfter(moment().format('YYYY-MM-DD')) && item.status == 1) ? "active" : item.approval_status == 151 ? "approvePending": (item.approval_status == 153) ? "deactivePending" : "inactive"}`} data={item} addClick={(type:string,data:any,selectedBtn:string) => {if(type == "inactive"){addCopilotHandler(type,data)}else{changeStatusCopilot(type,data,selectedBtn)}}}/>
                                        </>
                                    )
                                })}
                                {(userPermissions?.user_management_module?.co_pilot.create_co_pilot.selected ? true : (userRole == "Admin") ? true : false) && <CopilotCard type="addCopilot" addClick={(type:string,data:string) => {addCopilotHandler(type,data)}}/>}
                            </Box>
                        </TabPanel>
                        <TabPanel value="2">
                            <Box className="manageCopilotCardSect">
                                {copilotApproverList.map((item:any) => {
                                    console.log("manageCopilotCardSect",moment(moment(item.access_till).format('YYYY-MM-DD')).isAfter(moment()))
                                    return (
                                        <>
                                        <CopilotCard type={`${item.approval_status == 153 ? "forApproveInactive" : "forApproveNewRequest"}`} data={item} addClick={(type:string,data:any,selectedBtn:string) => {changeStatusCopilot(type,data,selectedBtn)}}/>
                                        </>
                                    )
                                })}
                                {/* <CopilotCard type="forApproveInactive" data={{}}/> */}
                                {/* <CopilotCard type="forApproveNewRequest"  data={{}}/> */}
                            </Box>
                        </TabPanel>
                        <TabPanel value="3">
                            <Box className="manageCopilotCardSect">
                                <AuditLog />
                            </Box>
                        </TabPanel>                        
                    </TabContext>
                </Box>                
            </Box>
            <AppErrorHandlerModal
                open={uploadsuccess}
                content={SuccessMessage}
                handleClose={handleClose}
                factor='Success'
                ButtonText='Done'
            ><></></AppErrorHandlerModal>
            {addCopilotPopupStatus && <AddCopilot userDetailId={userDetailData.userId} closeModal={(status:string) => {closeCopilotPopup(status)}} inactiveCopilotData={inactiveCopilotData} selectedUserId={userDetailData.userId}/>}
            <AppConfirmationHandlerActionModal
                iconType={"warning"}
                open={confirmSubmittStatus}
                content={`${confirmSubmittObject.type}`}
                handleClose={() => {setConfirmSubmittStatus(false)}}
                handleConfirm={() => {changeCopilotStatusAPI(confirmSubmittObject)}}
                factor=''
                cancelText='Cancel'
                // diableConfirmBtn={confirmBtnDisable}
                confirmationBtn= "Confirm"
            ><></></AppConfirmationHandlerActionModal>
        </>
    );
};

export default ManageCopilot;