import React from 'react';
import { instance, reportInstance } from './http-common';

class ReportListService {
    getReportList = async (listData:any) => {
        const response = await instance.post('candidate/submission/report', {
                "candidateName": listData["candidateName"],
                "jobTitle": listData["jobTitle"],
                "currentOrganization": listData["currentOrganization"],
                "totalExperience": listData["totalExperience"],
                "relevantExperience": listData["relevantExperience"],
                "currentLocation": listData["currentLocation"],
                "preferredLocation": listData["preferredLocation"],
                "noticePeriod": listData["noticePeriod"],
                "phoneNumber": listData["contactNumber"],
                "email": listData["email"],
                "currentCTC": listData["currentCTC"],
                "expectedCTC": listData["expectedCTC"],
                "cvSentDate": listData["cvSentDate"],
                "source": listData["source"],
                "createdBy": listData["createdBy"],
                "createdAt": listData["createdAt"],
                "stage": listData["candidateStage"]?.length > 0 ? listData["candidateStage"].map((item:any) => item.label).join(",") : "",
                "status": listData["candidateStatus"]?.length > 0 ? listData["candidateStatus"].map((item:any) => item.label).join(",") : "",  
                "stageSearchFilter": listData["stage"],
                "statusSearchFilter": listData["status"],
                "profileEntryDate": listData["profileEntryDate"],
                "profileLastModifiedDate": listData["profileLastModifiedDate"],
                "profileLastActiveDate": listData["profileLastActiveDate"],
                "pageSize": listData["pageSize"],
                "pageNo": listData["pageNo"],
                // "userId": listData["userId"],
                "userId": "userIds" in listData ? listData["userIds"] : listData["userId"],
                "clientName": listData["clientFilter"],
                "clientSearchFilter": listData["client"],
                // "buHead": listData["bUHead"],
                // "deliveryAccountManager": listData["deliveryManager"],
                // "teamLead": listData["teamLead"],
                "recruiterName": listData["recruiter"],
                "Recruiter": "",                
                "mandateId": listData["Mandate"],
                "locationId": listData["mandateLocation"],
                "department": listData["department"],
                "industry": listData["industry"],
                "submissionDate": listData["submissionDate"],
                "submissionEndDate": listData["submissionEndDate"],
                "sortColumn":listData["sortColumn"],
                "sortDirection":listData["sortDirection"],
                'buId': listData["buid"]
        })
        return response;
    }
    getBussinessHierarchy = async () => {
        const response = await instance.get('candidate/getCurrentUserDropDown')
        return response;
    }
    exportSubmitionReport = async (reqBody:any) => {
        const response = await instance.post('candidate/candidateSubmissionExcelExport/',reqBody,{responseType: 'blob'})
        return response;
    }

    exportSubmitionDashborad = async (reqBody:any) => {
        const response = await reportInstance.post('dashboard/hiringDemandDrillDown',reqBody,{responseType: 'blob'})
        return response;
    }

    exportFunnelDashboard = async (reqBody:any) => {
        const response = await reportInstance.post('dashboard/recrutingEffeciencyDrillDownExcelExport',reqBody,{responseType: 'blob'})
        return response;
    }
    exportOffMgtDashboard = async (reqBody:any) => {
        const response = await reportInstance.post('dashboard/offerMgtEffectivenessDrillDownExcelExport',reqBody,{responseType: 'blob'})
        return response;
    }
    exportJobOrderAgeing = async (reqBody:any) => {
        const response = await reportInstance.post('dashboard/JOsAgeingReportDrillDownExcelExport',reqBody,{responseType: 'blob'})
        return response;
    }   
    exportAppTrendsDrill = async (reqBody:any) => {
        const response = await reportInstance.post('dashboard/appUsageTrendDrillDownExcelExport',reqBody,{responseType: 'blob'})
        return response;
    }   
    exportJobsWithCandidateDrill = async (reqBody:any) => {
        const response = await reportInstance.post('dashboard/topJobsWithCandidateDrillDownExcelExport',reqBody,{responseType: 'blob'})
        return response;
    }
    exportHiringDemandLeftExport = async (reqBody:any) => {
        const response = await reportInstance.post('dashboard/hiringDemandClientAndMandateDrillDown',reqBody,{responseType: 'blob'})
        return response;
    }

    exportClientActivityExport = async (reqBody:any) => {
        const response = await reportInstance.post('/candidate-activity/candidateActivityDrillDown',reqBody,{responseType: 'blob'})
        return response;
    }
    exportMandateAgeingExport = async (reqBody:any) => {
        const response = await reportInstance.post('jo-ageing-report/mandateAndJoAgeingReport',reqBody,{responseType: 'blob'})
        return response;
    }
    exportmandateCandidateAgeingExport = async (reqBody:any) => {
        const response = await reportInstance.post('jo-ageing-report/mandateAndJoCandidateReport',reqBody,{responseType: 'blob'})
        return response;
    }
    exportTimeToFillCandidateReportExport = async (reqBody:any) => {
        const response = await reportInstance.post('time-to-fill/timeToFillCandidateDrillDown',reqBody,{responseType: 'blob'})
        return response;
    }
    
};

export const ReportkManagementService = new ReportListService();