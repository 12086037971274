import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Modal, Typography, } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/icons/backarrowicon1.svg';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ReactComponent as UserViewManageCopiolet } from "../../assets/icons/userViewManageCopiolet.svg"
import UserViewAudit from "../../assets/icons/userViewAudit.svg"
import { ReactComponent as UserViewOrganization } from "../../assets/icons/userViewOrganization.svg"
import { ReactComponent as UserViewSecurity } from "../../assets/icons/userViewSecurity.svg"
import { ReactComponent as UserViewRole } from "../../assets/icons/userViewRole.svg"
import { ReactComponent as UserViewBasicDetails } from '../../assets/icons/userViewBasicDetails.svg';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { ReactComponent as UserViewSubmission } from "../../assets/icons/userViewSubmission.svg"
import { ReactComponent as UserViewEdit } from "../../assets/icons/userViewEdit.svg"
import profileDefault from "../../assets/images/profileDefault.png"
import CreateUserProfile from "../../assets/icons/createUserProfile.svg"
import CreateUserEmployee from "../../assets/icons/createUserEmployee.svg"
import CreateUserDocument from "../../assets/icons/createUserDocument.svg"
import CreateUserCall from "../../assets/icons/createUserCall.svg"
import CreateUserSocialLink from "../../assets/icons/createUserSocialLink.svg"
import GroupStrick from '../../assets/images/default-avatar.png'
import { ReactComponent as ReasonIcon } from '../../assets/icons/reject-reason.svg'
import { convertToDisplayDateTime } from '../../utils/date-utils';
import { downloadMandateFile, getMandateAuditLogs } from '../../services/MandateService';
import { ReactComponent as DownloadIcon } from '../../assets/icons/download.svg'
import { baseUrl } from '../../services/http-common';
import docIcon from '../../assets/icons/Doc.svg'
import pdfIcon from '../../assets/icons/pdf.svg'
import txtIcon from '../../assets/icons/txt.svg'
import excelIcon from '../../assets/icons/excelIcon.svg'
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { getUserDetail, postUserStatus } from '../../services/UserService';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import { AppButton } from '../AppComponents/AppButton';
import ListTransfer from '../Common/ListTransfer/ListTransfer';
import ListTreeAntTranfer from '../Common/ListTreetransfer/ListTreeAntTranfer';
import UserDetailRoleView from './UserDetailRoleView';
import UserDetailDlsView from './UserDetailDlsView';
import { useAppSelector } from '../../store/hooks';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '506px',
    // height: '280px',
    borderRadius: '10px',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    padding: "32px 32px 20px"
  }
const ViewUserDetails = () => {
    const navigate =useNavigate()
    const [value, setValue] = React.useState('1');
    const location = useLocation();
    const [userDetailData, setUserDetailData] = useState(location?.state?.userDetail)
    const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
    console.log("userDetailData",userDetailData)
    const [manAuditHistory, setManAuditHistory] = useState<any[]>([])
    const [showPassword, setShowPassword] = useState(false)
    const handleClickShowPassword = () => setShowPassword(!showPassword)
    const handleMouseDownPassword = () => setShowPassword(!showPassword)
    const [showRejectReasonPopup, setShowRejectReasonPopup]= useState(false)
    const [rejectReason, setRejectReason]= useState('')
    const isEdit:any = location?.state?.status
    const [treeViewDataObj, setTreeViewDataObj] = useState([])
    const [treeViewSelectedDataObj, setTreeViewSelectedDataObj] = useState([])
    const [userModifiedTreeView, setUserModifiedTreeView] = useState([])
    const { userId,userRoleHierarchy } = useAppSelector((state) => state.UserDetail)
    const [approverAlertStatus, setApproverAlertStatus] = useState(userDetailData.coPilotApprovalCount)
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };
    const deactivateStatusOption = [
        {
            name: "Exit"
        },
        {
            name: "Others"
        }
    ]
    const activationStatusOption = [
        {
            name: "Rejoined"
        },
        {
            name: "Others"
        }
    ]
    const [employeeLocation, setEmployeeLocation] = useState([
        {
            id:1,
            name: "Bengaluru"
        },
        {
            id:2,
            name: "Udupi"
        },
        {
            id:3,
            name: "Hyderabad"
        },
        {
            id:4,
            name: "Chennai"
        },
        {
            id:5,
            name: "Mumbai"
        }
    ])
    const [exitReasonList, setExitReasonList] = useState<any>([]);
    const [exitReasonValue, setExitReasonValue] = useState("");
    const [showActivatePopup, setShowActivatePopup] = useState(false)
    const {userRole, roleId } = useAppSelector((state) => state.UserDetail)
    const [userActiveStatusComments, setUserActiveStatusComments] = useState("");
    const [userActiveStatus, setUserActiveStatus] = useState(userDetailData?.status != null ? ((userDetailData?.status == 1 || userDetailData?.status == 137) ? true : false): false)
    const userStatusTriggerHandler = () => {
        // setUserActiveStatus(!userActiveStatus)
        if(userPermissions?.user_management_module?.user_module?.edit?.selected && userRole != "Admin" || userRole == "Admin"){
            setShowActivatePopup(true)
            setExitReasonList(userActiveStatus == true ? deactivateStatusOption : activationStatusOption)
        }        
    }
    const getUserDetailInfo = async (userId:string) => {
        const res:any = await getUserDetail(userId)
        if(res.status == 200){
            setUserActiveStatus(res.data.status != null ? ((res.data?.status == 1 || res.data?.status == 137) ? true : false): false)
        }
    }
    const userStatusUpdate = async () => {
        const userUpdateObj = {
            "userId": userDetailData?.keyCloakId,
            "reason": exitReasonValue,
            "comment": userActiveStatusComments,
            "status": userActiveStatus ? 136 : 137
        }
        try{
            const res:any = await postUserStatus(userUpdateObj)
            setShowActivatePopup(false)
            getStatusLog()
            toast.success('Successfully updated status')
            setUserActiveStatus(!userActiveStatus)
            await getStatusLog()
            getUserDetailInfo(userDetailData?.keyCloakId)
          }
          catch(error:any){
            console.log(error)    
            setShowActivatePopup(false)  
            toast.error('Something went wrong') 
            setUserActiveStatus(userActiveStatus)  
        }
        
    }
    const userStatusCancel = () => {
        setUserActiveStatus(userActiveStatus)
        setShowActivatePopup(false)
    }
    const handleReasonChange = (event: any) => {
        setExitReasonValue(event.target.value as string);
    };

    const label = { inputProps: { 'aria-label': 'Switch demo' } };
    const getFileIcon = (fileName:string) => {
        const fileType = fileName ? fileName.split('.').at(-1) : ""
        switch(fileType){
          case "docx":
          case "doc":
            return docIcon
          case "pdf":
            return pdfIcon
          case "txt":
            return txtIcon
          case "xlsx":
            return excelIcon
          default:
            return txtIcon
        }
      }
      const handleAttachmentDownload = async (filePath:any) => {
        const excelLinkSource = `filename=${filePath}`
        try {
          const response = await downloadMandateFile(excelLinkSource)
          if (response?.data) {
            window.location.href = (`${baseUrl}mandate/download?${excelLinkSource}`)
          } else {
            console.error("No Such File")
          }
        } catch (error) {
          console.error("Internal Server Error", error)
        }
      };
    const getStatusLog = async () => {
        const reqObj = {
          filters: [
            {
              field: "eventId",
              operator: "in",
              type: "string",
              value: ["USER_ENTITY"]
            },
            {
              field: "entityId",
              operator: "equals",
              type: "string",
              value: String(userDetailData?.keyCloakId)
            }
          ],
          page: {
            pageNo: -1,
            pageSize: 10
          },
          sorting: {
              direction: "DESC",
              field: "createdAt"
          }
        }
        try{
          const res = await getMandateAuditLogs(reqObj)
          if(res?.status === 200){
            setManAuditHistory(res?.data?.result?.list[0] ?? [])
          }
        }catch(error){
          console.log(error,"error")
        }
      }

      useEffect(() => {
        getStatusLog()
        getUserDetailInfo(userDetailData?.keyCloakId)
      },[])

      const userEditList = async (userId:string) => {
        const res:any = await getUserDetail(userId)
        if(res.status == 200){
            navigate(`/createuser`,{state:{userDetail: res.data,status:"edit",userId:userDetailData?.keyCloakId}})
        }
    }
    const manageCopilot = (tabState:number) => {
        navigate(`/manageCopilot`,{state:{userId:userDetailData?.keyCloakId,defaultTab:tabState}})
    }
    return (
        <>
            <Box className='mandate-wrapper'>
                <div className='nav-header'>
                    <IconButton
                        style={{paddingLeft:"0px"}}
                        onClick={() =>  navigate(`/users`)}
                        aria-label='back'
                        disableRipple
                        disableFocusRipple
                    >
                        <BackIcon />
                    </IconButton>
                    <Box className="userViewTopOptionSect">
                        <div className='header-text'>User Profile</div>
                        <Box className="userViewTopActionSect">
                            {userRoleHierarchy.includes(userDetailData.keyCloakId) &&
                            <>
                            {((userPermissions?.user_management_module?.co_pilot?.view_co_pilot?.selected || userPermissions?.user_management_module?.co_pilot?.create_co_pilot?.selected || userPermissions?.user_management_module?.co_pilot?.deactivate_co_pilot?.selected || userPermissions?.user_management_module?.co_pilot?.approval_co_pilot?.selected || userPermissions?.user_management_module?.co_pilot?.history_co_pilot?.selected || userPermissions?.user_management_module?.co_pilot?.activate_co_pilot?.selected) ? true : (userRole == "Admin") ? true : false) && <Box className="copilotBtnSect">
                                <Button className='copiolet' onClick={() => manageCopilot(1)} startIcon={<UserViewManageCopiolet />}>Manage Copilot</Button>
                                {approverAlertStatus && <Box className="collabApproveNoti">
                                    <div className="arrow-up"></div>
                                    <p><span style={{fontWeight:"600",marginRight:"5px"}}>New request for co-pilot, Approval is pending.<span className="viewDetail" onClick={() => {manageCopilot(2)}}>View Details</span></span> <CloseIcon onClick={() => {setApproverAlertStatus(false)}}/></p>
                                </Box>}
                            </Box>}
                            </>
                            }
                            <Button disabled className='submission' startIcon={<UserViewSubmission />}>View Submissions</Button>
                            {(userPermissions?.user_management_module?.user_module?.edit?.selected && userRole != "Admin") && <Button className='edit' startIcon={<UserViewEdit />} onClick={() => userEditList(userDetailData?.keyCloakId)}>Edit</Button>}
                            {(userRole == "Admin") && <Button className='edit' startIcon={<UserViewEdit />} onClick={() => userEditList(userDetailData?.keyCloakId)}>Edit</Button>}
                        </Box>
                    </Box>
                    
                </div>
                <Box sx={{ width: '100%', typography: 'body1' }} className="userViewTabBlk">
                    <TabContext value={value}>
                        <Box className="userViewTabSect" sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleChange} aria-label="icon label tabs example">
                                <Tab className={((parseInt(value) - 1) == 1) ? "prev" : ""} icon={<UserViewBasicDetails />} label="Basic Details" value="1" />
                                <Tab className={((parseInt(value) - 1) == 2) ? "prev" : ""} icon={<UserViewRole />} label="Role" value="2" />
                                <Tab className={((parseInt(value) - 1) == 3) ? "prev" : ""} icon={<UserViewSecurity />} label="Data level security" value="3" />
                                <Tab className={((parseInt(value) - 1) == 4) ? "prev" : ""} icon={<UserViewOrganization />} disabled label="Organization structure" value="4" />
                            </TabList>
                        </Box>
                        <TabPanel value="1">
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    className='accordian-header'
                                >
                                    <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                                    <img src={CreateUserProfile} alt="otherInfo" />
                                    <div>Personal Information</div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className='accordian-details'>
                                    <Box className="userViewPersonalSect">
                                        <img src={userDetailData?.profileImageUrl.length > 0 ? userDetailData?.profileImageUrl : profileDefault} />
                                        <Box className="userViewPersonalFields">
                                            <Box className="userViewPersonalFieldsFlex">
                                                <Box>
                                                    <p>Employee Name</p>
                                                    <h5>{userDetailData?.firstName}</h5>
                                                </Box>
                                                <Box>
                                                    <p>DOB</p>
                                                    <h5>{convertToDisplayDateTime(userDetailData?.dob)}</h5>
                                                </Box>
                                                <Box>
                                                    <p>Primary Email</p>
                                                    <h5>{userDetailData?.userEmail}</h5>
                                                </Box>
                                            </Box>
                                            <Box className="userViewPersonalFieldsFlex">
                                                <Box>
                                                    <p>Personal Email</p>
                                                    <h5>{userDetailData.secondaryEmail?userDetailData.secondaryEmail:"-"}</h5>
                                                </Box>
                                                <Box>
                                                    <p>Primary Contact</p>
                                                    <h5>{userDetailData?.primaryContactNumberCode ? "+" : ""}{userDetailData?.primaryContactNumberCode} {userDetailData?.userMobile}</h5>
                                                </Box>
                                                <Box>
                                                    <p>Secondary Contact</p>
                                                    <h5>{userDetailData?.secondaryContactNumberCode ? "+" : ""}{userDetailData?.secondaryContactNumberCode} {userDetailData?.secondaryMobile}</h5>
                                                </Box>
                                            </Box>
                                            <Box className="userViewPersonalFieldsFlex">
                                                <Box>
                                                    <p>Residential Address</p>
                                                    <h5>{userDetailData?.addressDetails?userDetailData?.addressDetails:"-"}</h5>
                                                </Box>
                                                <Box>
                                                    <p>Status</p>
                                                    <h5>
                                                        <FormGroup className={!userPermissions?.user_management_module?.user_module?.edit?.selected && userRole != "Admin" ? "disableActiveCheck" : ""}>
                                                            <FormControlLabel control={<Switch defaultChecked={userActiveStatus} checked={userActiveStatus} />} onClick={() => {userStatusTriggerHandler()}} label="Active" />
                                                        </FormGroup>
                                                    </h5>
                                                </Box>
                                                {/* <Box>
                                                    <p>Servicing Notice Period</p>
                                                    <h5>
                                                        <FormGroup>
                                                            <FormControlLabel control={<Switch />} label="No" />
                                                        </FormGroup>
                                                    </h5>
                                                </Box> */}
                                            </Box>  
                                        </Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                className='accordian-header'
                                >
                                    <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                                    <img src={CreateUserEmployee} alt="otherInfo" />
                                    <div>Employement Details</div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className='accordian-details'>
                                    <Box className="userViewEmployeeFields">
                                        <Box className="userViewEmpolyeeFieldsFlex">
                                            <Box>
                                                <p>Employee Code</p>
                                                <h5>{userDetailData?.employeeCode}</h5>
                                            </Box>
                                            <Box>
                                                <p>Role</p>
                                                <h5>{userDetailData?.role}</h5>
                                            </Box>
                                            <Box>
                                                <p>Xpheno Joining Date</p>
                                                <h5>{convertToDisplayDateTime(userDetailData?.xphenoJoiningDate)}</h5>
                                            </Box>
                                            <Box>
                                                <p>Previous Experience</p>
                                                <h5>{userDetailData?.previousExperienceStr ? userDetailData?.previousExperienceStr : "-"}</h5>
                                            </Box>
                                        </Box>
                                        <Box className="userViewEmpolyeeFieldsFlex">
                                            <Box>
                                                <p>Designation</p>
                                                <h5>{userDetailData?.designation?userDetailData?.designation:"-"}</h5>
                                            </Box>
                                            <Box>
                                                <p>Business Unit</p>
                                                <h5>{userDetailData?.businessUnit?userDetailData?.businessUnit:"-"}</h5>
                                            </Box>
                                            <Box>
                                                <p>Domain</p>
                                                <h5 className='domain'>{userDetailData?.domain?userDetailData?.domain:"-"}</h5>
                                            </Box>
                                            <Box>
                                                <p>Reporting To</p>
                                                <h5>{userDetailData?.reportingTo?userDetailData?.reportingTo:"-"}</h5>
                                            </Box>
                                        </Box>
                                        <Box className="userViewEmpolyeeFieldsFlex">
                                            <Box>
                                                <p>Xpheno Experience</p>                                                
                                                <h5>{userDetailData?.xphenoExperience.length > 0 ? (JSON.parse(userDetailData?.xphenoExperience).years>0 || JSON.parse(userDetailData?.xphenoExperience).months > 0) ? ((JSON.parse(userDetailData?.xphenoExperience).years > 0 ? (JSON.parse(userDetailData?.xphenoExperience).years + " Years") : "" ) + "  " + (JSON.parse(userDetailData?.xphenoExperience).months > 0 ? JSON.parse(userDetailData?.xphenoExperience).months + " Month":"")) : "-": "-"}</h5>
                                            </Box>
                                            <Box>
                                                <p>Total Experience</p>
                                                <h5>{userDetailData?.totalExperience.length > 0 ? (JSON.parse(userDetailData?.totalExperience).years>0 || JSON.parse(userDetailData?.totalExperience).months > 0) ? ((JSON.parse(userDetailData?.totalExperience).years > 0 ? (JSON.parse(userDetailData?.totalExperience).years + " Years") : "" ) + "  " + (JSON.parse(userDetailData?.totalExperience).months > 0 ? JSON.parse(userDetailData?.totalExperience).months + " Month":"")) : "-": "-"}</h5>
                                            </Box>
                                            <Box>
                                                <p>Employee Location</p>
                                                <h5>{userDetailData?.employmentLocation?employeeLocation[userDetailData?.employmentLocation - 1].name:"-"}</h5>
                                            </Box>
                                        </Box>  
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    className='accordian-header'
                                >
                                        <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                                        <img src={CreateUserSocialLink} alt="otherInfo" />
                                        <div>Social Profiles</div>
                                        </div>
                                </AccordionSummary>
                                <AccordionDetails className='accordian-details'>
                                    <Box className="userViewSocialFields">
                                    {userDetailData?.profileUrl.length > 0 && JSON.parse(userDetailData?.profileUrl)?.map((urlItem: any, index: number) => {
                                        return (
                                        <>
                                            <Box>
                                                <p>Profile URL {index+1}</p>
                                                <Tooltip title={urlItem.profileUrl}><h5>{urlItem.profileUrl}</h5></Tooltip>
                                            </Box>
                                        </>
                                        )
                                    })}
                                        {/* <Box>
                                            <p>Profile URL 1</p>
                                            <h5>{userDetailData?.profileUrl.length == 0 ? "-" : userDetailData?.profileUrl}</h5>
                                        </Box> */}
                                        {/* <Box>
                                            <p>DOB</p>
                                            <h5>Nov 9, 2020</h5>
                                        </Box> */}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    className='accordian-header'
                                >
                                    <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                                    <img src={CreateUserDocument} alt="otherInfo" />
                                    <div>Documents</div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className='accordian-details'>
                                    <Box className="userViewDocumentFields">
                                        {userDetailData?.files?.map((file: any, index: number) => {
                                        const fileIcon = getFileIcon(file?.fileName)
                                        return (
                                        <>
                                            <div key={index} style={{ overflow: 'hidden' }}>
                                                <div className='header-text'>
                                                {file?.fileTitle ? file?.fileTitle : `Attachment${index + 1}`}
                                                </div>
                                                <div
                                                className='data-text'
                                                style={{ display: 'flex', alignItems: 'start', gap: '4px' }}
                                                >
                                                <img src={fileIcon} alt='icon' />
                                                <span className='mandate-file-name'>{file?.fileName}</span>
                                                <DownloadIcon 
                                                    style={{minWidth:"20px", cursor:"pointer"}}
                                                    onClick={()=> handleAttachmentDownload(file?.filePath)}
                                                />
                                                </div>
                                            </div>
                                        </>
                                        )
                                    })}
                                              
                                        {/* <Box>
                                            <p>DOB</p>
                                            <h5>Nov 9, 2020</h5>
                                        </Box> */}
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    className='accordian-header'
                                >
                                    <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                                    <img src={CreateUserCall} alt="otherInfo" />
                                    <div>Calling</div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className='accordian-details'>
                                    <Box className="userViewCallingFields">
                                        <Box>
                                            <p>Smartflow Username</p>
                                            <h5>{userDetailData?.sfloId}</h5>
                                        </Box>
                                        <Box>
                                            <p>Smartflow Password</p>
                                            <Box className="smartFlowPass">
                                                <input type={showPassword ? 'text' : 'password'} value={userDetailData?.sfloPwd}/>
                                                <Box>
                                                {userDetailData?.sfloPwd?.length > 0 && <IconButton
                                                        aria-label='toggle password visibility'
                                                        onClick={handleClickShowPassword}
                                                        onMouseDown={handleMouseDownPassword}
                                                        className='eye-icon'
                                                        >
                                                        {showPassword ? (
                                                            <Visibility className='eye-icon' />
                                                        ) : (
                                                            <VisibilityOff className='eye-icon' />
                                                        )}
                                                    </IconButton>
                                                }
                                                </Box> 
                                            </Box>
                                        </Box>
                                    </Box>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    className='accordian-header'
                                >
                                    <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                                    <img src={UserViewAudit} alt="otherInfo" />
                                    <div>Audit Logs</div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className='accordian-details'>
                                    {manAuditHistory.length > 0 && <Box className="mandate-detail-wrapper">
                                        <Table className="audit-log-table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{width:"40%"}} >Action By</TableCell>
                                                <TableCell style={{width:"20%"}}>Action</TableCell>
                                                <TableCell style={{width:"15%"}}>Reason</TableCell>
                                                <TableCell style={{width:"25%"}}>Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {manAuditHistory?.map((item:any, index:number) => (
                                            <TableRow key={index} >
                                            <TableCell style={{width:"40%"}}>
                                                <Box className="d-flex" >
                                                <Box>
                                                    <Avatar
                                                    alt='no-group'
                                                    src={GroupStrick}
                                                    sx={{ width: 30, height: 30 }}
                                                    />
                                                </Box>
                                                <Box className='user-detail' >
                                                    <Tooltip title={item?.userEntity?.firstName} placement="bottom-start">
                                                    <span className='primary-text ellipsisText1'>{item?.userEntity?.firstName}</span>
                                                    </Tooltip>
                                                    <span className='secondary-text'>{item?.userEntity?.roleName || "-"}</span>
                                                </Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell style={{width:"20%"}}>
                                                <Tooltip title={item?.eventType} placement="bottom-start" >
                                                <span className="primary-text text-ellips" >{item?.eventType || "-"}</span>
                                                </Tooltip>
                                            </TableCell>
                                            <TableCell style={{width:"15%"}}>
                                                <Box style={{paddingLeft:"15px", cursor: "pointer"}} >
                                                {item?.auditReason ? (
                                                    <ReasonIcon
                                                    onClick={() => {
                                                        setShowRejectReasonPopup(true)
                                                        setRejectReason(item?.auditReason)
                                                    }}
                                                    />
                                                ) : (
                                                    <span>-</span>
                                                )}
                                                </Box>
                                            </TableCell>
                                            <TableCell style={{width:"25%"}}>
                                                <div className="primary-text" >{convertToDisplayDateTime(item?.createdAt)}</div>
                                            </TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                    </Box>}
                                </AccordionDetails>
                            </Accordion>
                        </TabPanel>
                        <TabPanel value="2" className='userRoleTabSect'>
                            <UserDetailRoleView userDetailData={userDetailData}/>
                        </TabPanel>
                        <TabPanel value="3" className='userDlsTabSect'>
                            <UserDetailDlsView userDetailData={userDetailData}/>
                        </TabPanel>
                        <TabPanel value="4"></TabPanel>
                    </TabContext>
                </Box>                
            </Box>
           {showActivatePopup && <Box className="decativeUserSect">
                    <Box className="decativeUserSectBlk">
                        <Box className="decativeUserSectBlkTop">
                            <h3>{userActiveStatus ? "Deactivate" : "Activate"} User</h3>
                            <CloseIcon style={{cursor:"pointer"}} onClick={() => {userStatusCancel()}}/>
                        </Box>
                        <Box className="decativeUserSectBlkBody">
                            <FormControl fullWidth className="reasonlFields">
                                <InputLabel id="demo-simple-select-label">Reason</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={exitReasonValue}
                                    label="Reason"
                                    onChange={handleReasonChange}                                >
                                    {exitReasonList.map((item:any,index:number) => (
                                        <MenuItem key={item?.id} value={item?.name}>{item?.name}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                            <TextField
                                className='reasonCommentsFields'
                                id="outlined-multiline-static"
                                label="Comments"
                                multiline
                                rows={4}
                                onChange={(event:any) => {setUserActiveStatusComments(event.target.value)}}
                            />
                        </Box>
                        <Box className='button-container'>
                            <Button 
                                className='cancel-btn'
                                onClick={() => {userStatusCancel()}}
                            >Cancel</Button>
                            <Button 
                                className={`${userActiveStatus ? "deactive-btn" : "save-btn"}`}
                                onClick={() => {userStatusUpdate()}}
                            >
                                {userActiveStatus ? "Deactivate" : "Activate"}
                            </Button>
                        </Box>
                    </Box>                    
                </Box>
            }
            {
                <Modal
                open={showRejectReasonPopup}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
                >
                <Box sx={style}>
                    <Typography variant='h6' sx={{ fontSize: 'inherit', color: '#000', pb: '16px' }}>
                        Update Mandate Status Reason
                    </Typography>
                    <IconButton
                    aria-label='close'
                    onClick={() => setShowRejectReasonPopup(false)}
                    sx={{
                        position: 'absolute',
                        right: 25,
                        top: 32,
                    }}
                    >
                    <CloseIcon style={{cursor:"pointer"}}/>
                    </IconButton>
                    <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: '',
                        // margin: '',
                        gap: '15px',
                    }}
                    >
                    <Typography>{rejectReason}</Typography>
                    <Box
                        sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                        margin: '20px 0 20px 20px',
                        gap: '15px',
                        }}
                    >
                        <AppButton
                        bgcolor='#E5E8EB'
                        onClick={() => setShowRejectReasonPopup(false)}
                        // margin={15}
                        textColor='#000'
                        fontSize='14px'
                        fontWeight={400}
                        label='Cancel'
                        width='96px'
                        ></AppButton>
                    </Box>
                    </Box>
                </Box>
                </Modal>
            }
        </>
    );
};

export default ViewUserDetails;