import React, { useEffect, useState } from 'react';
import Fireworks from "react-canvas-confetti/dist/presets/fireworks";
import Realistic from "react-canvas-confetti/dist/presets/realistic";
import Pride from "react-canvas-confetti/dist/presets/pride";
import { useAppDispatch, useAppSelector } from '../../../store/hooks';
import { Box, Button, Tooltip } from '@mui/material';
import { resetConfettiNotification, setConfettiNotification } from '../../../features/notification/notificationReducer';
import CloseIcon from '@mui/icons-material/Close';
import CongrateImg from "../../../assets/images/congrateImg.png"
import defaultImg from "../../../assets/images/callNotificationUser.png"


const AppConfettiEffect = () => {
    const confettiNotificationStatus = useAppSelector((state) => state?.notificationState?.confettiNotificationOpen);
    const confettiNotificationInfo = useAppSelector((state) => state?.notificationState?.confettiNotificationInfo);
    const confettiEffectTimer = useAppSelector((state) => state?.SystemConfig?.confettiEffectTimerData)
    const dispatch = useAppDispatch()
    const recruiterData = (userData:any) => {        
        return JSON.parse(userData)[0]?.full_name
    }
    // const [refreshCounter, setRefreshCounter] = useState(1);
    let timeoutId:any
    const callSetTime = () => {
        const myFunction = () => {
            if(confettiNotificationInfo.length > 3){
                const sliceList = [...confettiNotificationInfo]
                sliceList.splice(0, 3)
                dispatch(setConfettiNotification({confettiOpen:true,confettiUserInfo:sliceList,confettiAdditionStatus:true}))
                callSetTime()
            }else{
                dispatch(resetConfettiNotification())
            }            
        };
        timeoutId = setTimeout(myFunction, (confettiEffectTimer && confettiEffectTimer*1000));
    }
    useEffect(() => {
        callSetTime()
        return () => clearTimeout(timeoutId);
    },[confettiNotificationInfo])

    // const callConfettiEffect = () => {
    //     setRefreshCounter(counter => counter + 1)
    // }
    // setInterval(callConfettiEffect, 15000);
    
    return (
        <>
        {(confettiNotificationStatus) && <Box className={`confettiEffectSect ${confettiNotificationInfo.length >= 3 ? "confettiEffectSect3" : ""}`}>
            <CloseIcon className='close' onClick={() => {dispatch(resetConfettiNotification())}}/>
            {/* <div key={refreshCounter}> */}
                <Fireworks autorun={{ speed: 2 }}/>
                <Realistic autorun={{ speed: 0.5 }}/>
            {/* </div> */}
            {confettiNotificationInfo && confettiNotificationInfo.map((itemData:any,index:number) => {
                let TeamData = JSON.parse(itemData?.metaData?.users_details);
                TeamData = TeamData.slice(1, 3);
                return (
                    <>
                    {index <= 2 &&
                        <Box className={`confettiEffectSectCard ${itemData?.metaData?.status_id == 41 ? "confettiEffectSectCardSelected" : itemData?.metaData?.status_id == 51 ? "confettiEffectSectCardJoined":""}`}>
                            <Box className="confettiEffectSectCardStar"></Box>
                            <Box className='cardTopSect'>
                            <p className='wellDone'>Well Done</p>
                            <img src={itemData?.userImage ?? defaultImg} />
                            <Box className="confettiEffectCardContBlk">
                                <p className='name'>{itemData?.metaData?.users_details ? recruiterData(itemData?.metaData?.users_details) : ""}</p>
                                <p className='role'>{itemData?.metaData?.users_details ? JSON.parse(itemData?.metaData?.users_details)[0]?.role_name : ""}</p>
                            </Box>
                            </Box>                        
                        <h4 dangerouslySetInnerHTML={{ __html: itemData?.alertNotificationTemplate?.alert_message_raw_content.replace("{bu_name}",itemData?.metaData?.bu_name).replace("{client_name}",itemData?.metaData?.client_name)} } />
                        {TeamData.length > 0 && <>
                        <p className='congrateText'>Congratulations to the team <img src={CongrateImg} /></p>
                         <Box className="confettiEffectSectCardTeam">
                            {TeamData.map((item:any) => {
                                return(
                                    <>
                                    <Box>
                                        <Tooltip title={item?.full_name}><p className='name'>{item?.full_name}</p></Tooltip>
                                        <p className='role'>{item?.role_name}</p>
                                    </Box>
                                    </>
                                )
                            })}
                        </Box></>}
                        
                    </Box>
                     }
                    </>
                )
            })}
        </Box>}
        </>
    );
};

export default AppConfettiEffect;