import React, { useEffect, useState } from 'react';
import DashboardFilterIndex from '../../Common/CustomeDasboardFilter/Filters/DashboardFilterIndex';
import { Table, TableBody, TableCell, TableContainer, Box, TableHead, TableRow, Paper, Chip, FormControl, MenuItem, Select, Pagination, Tooltip, Popover, OutlinedInput, InputAdornment, Checkbox ,Avatar, SelectChangeEvent, FormGroup, FormControlLabel, Dialog, DialogTitle, IconButton, DialogContent, TextField, DialogActions, Button, Typography, Input } from "@mui/material";
import { ReactComponent as SortUpDown } from '../../../assets/icons/sort-up-down.svg';
import { ReactComponent as SortUp } from '../../../assets/icons/sort-up.svg';
import { ReactComponent as SortDown } from '../../../assets/icons/sort-down.svg';
import { ReactComponent as NoResultFound } from '../../../assets/icons/no-result-found.svg';
import _ from 'lodash';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import "../../Dashboard/OverviewDashboard/HiringDemandCard/HiringDemandCardDetail.scss"
import { IMaskInput } from 'react-imask';
import { ResponsiveBar } from '@nivo/bar'
import { ReactComponent as LoadMoreIcon } from '../../../assets/icons/load-more.svg'
import { PRIMARY1 } from '../../../utils/constants';
import { useAppSelector } from '../../../store/hooks';
import { jobsAgeingReportList } from '../../../services/DashboardService';
import { useLocation, useNavigate } from 'react-router-dom';
import TimeToFill from './TimeToFill/TimeToFill';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }


const JobOrderAgeing = () => {
    const location = useLocation();
    const [HirningDemandParamsDetails,setHirningDemandParamsDetails] = useState<any>(location)
    const [reportSubmissionAPIData,setReportSubmissionAPIData] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state !=  null ? HirningDemandParamsDetails?.state?.filterList != undefined ? HirningDemandParamsDetails?.state?.filterList : {} : {}) : {})
    const [reportSubmitdatalabels,setreportSubmitdatalabels] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "filterListLabels" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.filterListLabels : {} : {}) : {})
    const [DashboardFilterDatas,setDashboardFilterDatas] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "dashboardfilterObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.dashboardfilterObj : {} : {}) : {})
    const [sortColumn, setSortColumn] = useState<string>('candidateName');
    const [sortDirection, setSortDirection] = useState<string>('asc');
    const [columnsort, setcolumnsort] = useState<string>('asc');
    const [sortingTableHead, setSortingTableHead] = useState<any>({});
    const [paginationCount, setPaginationCount] = useState(1)
    const userId = useAppSelector((state: any) => state.UserDetail.userId);
    const [jobAgeingList, setJobAgeingList] = useState([])
    const [jobAgeingTotal, setJobAgeingTotal] = useState(0)
    const navigate = useNavigate();
    const [userRoleObjectSelected,setUserRoleObjectSelected] = useState()
    const [initalCount, setInitalCount] = useState(0)
    const [userDependendObj,setUserDependendObj] = useState({})
    const [timeToFillActivityObj, setTimeToFillActivityObj] = useState(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "timeToFillActivityObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.timeToFillActivityObj : {} : {}) : {})
    console.log("HirningDemandParamsDetails",HirningDemandParamsDetails)
    const [headerTitle,setHeaderTitle] = useState(`<Box className='tasklist mar0'>
        <Typography display='flex' gap={1} className='tasklist-title'>
        <Box style="font-weight: 600; font-size: 16px">Time to Fill Candidate Report</Box>
        </Typography>
    </Box>`)
    return (
        <div>
            <DashboardFilterIndex HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} setReportSubmissionAPIData={setReportSubmissionAPIData} DashboardFilterDatas={DashboardFilterDatas} setDashboardFilterDatas={setDashboardFilterDatas} reportSubmitdatalabels={reportSubmitdatalabels} setreportSubmitdatalabels={setreportSubmitdatalabels} reportSubmissionAPIData={reportSubmissionAPIData} hideFieldsList={["stage","status","industryData","singleDatePicker","Start Date"]} headerTitle={headerTitle} setUserRoleObjectSelected={setUserRoleObjectSelected} setUserDependendObj={setUserDependendObj} filterType={"mainScreen"} datePickerType={"date"}/>
            <TimeToFill reportSubmissionAPIData={reportSubmissionAPIData} timeToFillActivityObj={timeToFillActivityObj} userRoleObjectSelected={userRoleObjectSelected} DashboardFilterDatas={DashboardFilterDatas} reportSubmitdatalabels={reportSubmitdatalabels} userDependendObj={userDependendObj}/>
        </div>
    );
};

export default JobOrderAgeing;