import { Alert, Autocomplete, Box, Button, Checkbox, FormControl, IconButton, InputLabel, ListItemText, MenuItem, Modal, Select, Snackbar, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ReactComponent as CloseIcon } from '../../../../assets/icons/modalcloseicon.svg'
import { AppButton } from '../../../AppComponents/AppButton';
import { AppErrorHandlerModal } from '../../../AppComponents/AppErrorHandlerModal';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import "./CollaboratorPopup.scss"
import CollaboratorLocationArray from './CollaboratorLocationArray';
import InfoCircle from "../../../../assets/icons/info-circle.svg"
import { ReactComponent as ShareMandateIcons } from '../../../../assets/icons/shareMandateIcons.svg';
import { ReactComponent as ShareMandateInfo } from '../../../../assets/icons/shareMandateInfo.svg'
import { ReactComponent as CollNotFound } from '../../../../assets/icons/coll_not_found.svg';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { dropDownData, getCollabApproverDetailList, getCollabApproverList, getCollaboratorList, postCollabApproverList } from '../../../../services/MandateService';
import _ from 'lodash';
import { toast } from "react-toastify";
import { useAppSelector } from '../../../../store/hooks';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '550px',
    maxWidth: "1000px",
    height: 'auto',
    borderRadius: '10px',
    bgcolor: 'background.paper',
    border: 'none',
    padding: "32px 20px 10px 20px",
}
interface IFormInput {
    collaborateName: any[];
    permission: any[],
    approver: any[],
    collaboratorLocations: any[]
}
const CollaboratorPopup = ({ setCollaboratorPopupStatus, collaboratorPopupStatus, setCollaboratorListPopupStatus, mandateId, editCollData,mandateCode,setCollaboratorListData, mandateData, mandateStatus }: any) => {
    const [collaboratorUserList, setCollaboratorUserList] = useState([])
    const [reRenderCount, setReRenderCount] = useState(0)
    const { businessUnitId } = useAppSelector((state) => state?.UserDetail)
    const [collaboratorApproverList, setCollaboratorApproverList] = useState([])
    const [dropdownOption, setDropdownOption] = useState([])
    const [collabSuccessMessage, setCollabSuccessMessage] = useState("")
    const [collabSuccessStatus, setCollabSuccessStatus] = useState(false)
    const [approverCollList, setApproverCollList] = useState<any>({})
    const { userId, userRole } = useAppSelector((state) => state?.UserDetail)
    console.log("editcaseData,",editCollData)
    const restructureLoaction = (location:any) => {
        const options = _.map(location, (data: any) => {
            return {
              location: data.mlocation_id,
              jobOrder: data.job_orders,
              maxValue: "max_jo" in data ? data.max_jo : 0
            }}) 
            return options
    }
    const initValues = {
        collaborateName: Object.keys(editCollData).length > 0 ? [editCollData.collaborator_id] : [],
        permission: Object.keys(editCollData).length > 0 ? JSON.parse(editCollData.sharing_permission).map((permissionItem:any) => permissionItem.id) : [],
        approver: Object.keys(editCollData).length > 0 ? JSON.parse(editCollData.approvers) : [],
        collaboratorLocations:  Object.keys(editCollData).length > 0 ? restructureLoaction(JSON.parse(editCollData.share_locations)) : [{ location: "", jobOrder: "", maxValue: 0 }]
    }
    const methods = useForm<IFormInput>({ mode: "onChange", defaultValues: initValues });
    const { handleSubmit, control, reset, formState: { isValid, errors }, clearErrors, getValues } = methods
    const mandateSubmitHandler = async (data: any, event: any) => {
        const collaboratorPayload = {
            "collaborators": data.collaborateName.map((item: any) => { 
                const collaboratorObj:any = {
                    "mandateId": mandateId,
                    "collaboratorId": typeof item === 'string' ? item : item.value,
                    "permissionsIds": JSON.stringify(data.permission),
                    "approverIds": JSON.stringify(data.approver.map((item: any) => { return  typeof item === 'string' ? item : item.value })),                    
                } 
                if(Object.keys(editCollData).length > 0) collaboratorObj["sharing_id"] = editCollData.sharing_id                
                return collaboratorObj
            }),
            "sharingLocations": data.collaboratorLocations.map((itemLocation: any) => {
                const collaboratorLocationObj:any = {
                    "mandateId": mandateId,
                    "locationId": itemLocation.location,
                    "jobOrders": itemLocation.jobOrder,
                    "maxValue": itemLocation.maxValue
                }
                if(Object.keys(editCollData).length > 0) collaboratorLocationObj["sharing_id"] = editCollData.sharing_id
                return collaboratorLocationObj
            }),
            "mandateBehaviour": "collaborator"
        }
        try {
            const res: any = await postCollabApproverList(collaboratorPayload)
            if(res?.data?.result?.message == "The Collaborator Is Already Assigned To This mandate. Please Update The Existing Request With The Changes."){
                toast.error(res?.data?.result?.message)
            }else{
                setCollabSuccessMessage(`${mandateCode} Mandate has been successfully shared`);
                setCollabSuccessStatus(true);
            }
        }
        catch (error: any) {
            console.log(error)
        }
    }
    const getCollaboratorListApi = async () => {
        try {
            const res: any = await getCollaboratorList({ "keyword": "" })
            const options = _.map(res.data.result.list, (data: any) => {
                return {
                  label: _.get(data, 'full_name'),
                  value: _.get(data, 'keycloak_id')
                }}) 
            setCollaboratorUserList(options.filter((filterItem:any) => filterItem.value != userId))
            setReRenderCount(reRenderCount + 1)
        }
        catch (error: any) {
            console.log(error)
        }
    }
    const getCollabApproverListApi = async () => {
        try {
            const res: any = await getCollabApproverList({
                "buId": businessUnitId,
                "keyword": ""
            })
            const options = _.map(res.data.result.list, (data: any) => {
                return {
                  label: _.get(data, 'full_name'),
                  value: _.get(data, 'keycloak_id')
                }}) 
            setCollaboratorApproverList(options.filter((filterItem:any) => filterItem.value != userId))
            setReRenderCount(reRenderCount + 1)
        }
        catch (error: any) {
            console.log(error)
        }
    }
    const getMandateType = async () => {
        const reqObj = {
            "businessUnit": false,
            "appConfiguration": false,
            "dropdownRequest": [
                {
                    "drpCode": "XPN0006",
                    "drpName": "Permissions"
                }
            ]
        }
        try {
            const res: any = await dropDownData(reqObj)

            if (res?.status === 200) {
                const options = _.map(res?.data?.result?.dropdownData?.Permissions, (data: any) => {
                    return {
                      domValue: _.get(data, 'domValue'),
                      id: _.get(data, 'id')
                    }}) 
                setDropdownOption(options)
            }
        } catch (error) {
            console.log(error, "error")
        }
    }

    const getCollApprovalDetailListApi = async () => {
        try {
            const res: any = await getCollabApproverDetailList({
                "mandateId": mandateId
            })
            setApproverCollList(res.data.result.data)
            // setCollaborateList(res.data.result.data.collaboratorList)
        }
        catch (error: any) {
            console.log(error)
        }
    }

    useEffect(() => {        
        getCollaboratorListApi();
        getCollabApproverListApi();
        getMandateType();
        getCollApprovalDetailListApi()
    }, [])

    const checkArrayofString = (arrayItem:any) => {
        let checkStatus = false;
        arrayItem.map((item:any) => {
            if(typeof item === 'object'){
                checkStatus = true
            }
        })
        return checkStatus
    }
    const handleClose = () => {
        setCollabSuccessStatus(false);
        setCollabSuccessMessage('');
        setCollaboratorPopupStatus(false)
        location.reload();
      }

    const showCollabList = () => {
        setCollaboratorListData(approverCollList)
        setCollaboratorPopupStatus(false); 
        setCollaboratorListPopupStatus(true)
    }
    const showApproverList = () => {
        setCollaboratorListData({})
        setCollaboratorPopupStatus(false); 
        setCollaboratorListPopupStatus(true)
    }
    return (
        <>
            <Modal
                open={collaboratorPopupStatus}
                aria-labelledby='modal-modal-title'
                aria-describedby='modal-modal-description'
            >
                <Box className="collaboratorPopupSect">
                    <Box sx={style} className='popup-wrap'>
                        <div className='header-wrapper' style={{ paddingRight: "22px" }}>
                            <Typography variant='h6' sx={{ fontSize: 'inherit', color: '#000', fontWeight: "600" }}>
                                Shared Mandate
                            </Typography>
                            <IconButton
                                aria-label='close'
                                onClick={() => { clearErrors(); setCollaboratorPopupStatus(false) }}
                            >
                                <CloseIcon style={{ cursor: "pointer" }} />
                            </IconButton>
                        </div>
                        <Box>
                            <FormProvider {...methods} key={reRenderCount}>
                                <Box
                                    component="form"
                                    data-testid="formHandle"
                                    id="mandateForm"
                                    noValidate
                                    autoComplete="off"
                                    onSubmit={handleSubmit(mandateSubmitHandler)}
                                >
                                    <FormControl fullWidth required className='pb-30 collaboratorField'>
                                        {/* <InputLabel id="demo-simple-select-label">Role</InputLabel> */}
                                        <Controller
                                            name={"collaborateName"}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "This field is required",
                                                }
                                            }}
                                            render={({ field: { onChange, value, onBlur } }) => (
                                                <Autocomplete
                                                    multiple
                                                    id="tags-standard"
                                                    limitTags={2}
                                                    options={collaboratorUserList}
                                                    value={!checkArrayofString(value) ? collaboratorUserList.filter((loc:any) => value.includes(loc.value)) : value}
                                                    onChange={(event:any, value) => {
                                                        const filterSelectedValue = value.filter((filterItem:any) => filterItem.label.trim() == event.target.innerText.trim())
                                                        if(filterSelectedValue.length > 1 && value.length > 1)value.pop()
                                                        onChange(value)
                                                    }}
                                                    renderInput={(params) => <TextField required={true} {...params} label={`Collaborators`} />}
                                                />
                                            )}
                                        />
                                        <Box className="collaboratorInfoSect">
                                            <img src={InfoCircle} />
                                            <p>You can share mandate to Team Lead and above</p>
                                        </Box>
                                        {errors?.collaborateName?.message && (
                                            <span className="reminder_text-danger select-danger-text" style={{ marginTop: "10px" }} role="alert">
                                                {errors?.collaborateName?.message}
                                            </span>
                                        )}
                                    </FormControl>
                                    <FormControl fullWidth required className='pb-30 collaboratorField'>
                                        <InputLabel id="demo-simple-select-label">Permissions</InputLabel>
                                        <Controller
                                            name={"permission"}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "This field is required",
                                                }
                                            }}
                                            render={({ field: { onChange, value, onBlur } }) => (
                                                <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                    multiple
                                                    value={value}
                                                    label="Permissions"
                                                    renderValue={(selected: any) => {
                                                        const selectedArray: any = []
                                                        dropdownOption.map((item: any) => {
                                                            if (selected.indexOf(item.id) > -1) selectedArray.push(item?.domValue)
                                                        })
                                                        return selectedArray.join(', ')
                                                    }}
                                                    onChange={(e: any) => {
                                                        onChange(e.target.value)
                                                    }}
                                                >
                                                    {dropdownOption.map((item: any, index: number) => (
                                                        <MenuItem key={item?.id} value={item?.id}>
                                                            <Checkbox checked={getValues("permission").indexOf(item?.id) > -1} />
                                                            <ListItemText primary={item?.domValue} />
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        />
                                        {errors?.permission && (
                                            <span className="reminder_text-danger select-danger-text" style={{ marginTop: "10px" }} role="alert">
                                                {errors?.permission?.message}
                                            </span>
                                        )}
                                    </FormControl>
                                    <FormControl fullWidth required className=' collaboratorField'>
                                        <CollaboratorLocationArray mandateId={mandateId} mandateData={mandateData}/>
                                    </FormControl>
                                    <FormControl fullWidth required className='pb-30 collaboratorField'>
                                        {/* <InputLabel id="demo-simple-select-label">Approver</InputLabel> */}
                                        <Controller
                                            name={"approver"}
                                            control={control}
                                            rules={{
                                                required: {
                                                    value: true,
                                                    message: "This field is required",
                                                }
                                            }}
                                            render={({ field: { onChange, value, onBlur } }) => (
                                                <Autocomplete
                                                    multiple
                                                    id="tags-standard"
                                                    limitTags={2}
                                                    options={collaboratorApproverList}
                                                    value={!checkArrayofString(value) ? collaboratorApproverList.filter((loc:any) => value.includes(loc.value)): value}
                                                    onChange={(event:any, value) => {
                                                        const filterSelectedValue = value.filter((filterItem:any) => filterItem.label.trim() == event.target.innerText.trim())
                                                        if(filterSelectedValue.length > 1 && value.length > 1)value.pop()
                                                        onChange(value)
                                                    }}
                                                    renderInput={(params) => <TextField required={true} {...params} label={`Approver`} />}
                                                />
                                            )}
                                        />
                                        {errors?.approver && (
                                            <span className="reminder_text-danger select-danger-text" style={{ marginTop: "10px" }} role="alert">
                                                {errors?.approver?.message}
                                            </span>
                                        )}
                                    </FormControl>
                                    {Object.keys(approverCollList).length > 0 && <Box className={`collaboratorMandateListSect ${approverCollList?.collaboratorList?.length == 0 ? "disableCollList" : ""}`}>
                                        {approverCollList?.collaboratorList?.length ? 
                                        <Box className="collabMandateList">
                                            <Box className="collabMandateListLeft" onClick={() => {if(approverCollList?.collaboratorList?.length > 0)showCollabList()}}>
                                                <ShareMandateIcons />
                                                <p style={{cursor: "pointer"}}><span>{approverCollList?.collaboratorList?.length} Collaborator</span> on this Mandate</p>
                                            </Box>
                                            <Box className="collabMandateListRight">
                                                <Box className={`collNotFoundSectRight ${approverCollList?.approvalRequestList.length == 0 ? "disableApproverList" : ""}`}>
                                                    <p onClick={() => {if(approverCollList?.approvalRequestList?.length > 0)showApproverList()}} style={{cursor: approverCollList?.approvalRequestList?.length > 0 ? "pointer" : "context-menu"}}>{approverCollList?.approvalRequestList?.length} Requests pending <span>{`view`}<KeyboardDoubleArrowRightIcon/></span></p>
                                                </Box>
                                            </Box>                                            
                                        </Box> 
                                        : <Box className="collNotFoundSect">
                                            <Box className="collNotFoundSectLeft">
                                                <Box className="collNotFound"><CollNotFound /></Box>
                                                <p>No Collaborators Found</p>
                                            </Box>
                                            <Box className={`collNotFoundSectRight ${approverCollList?.approvalRequestList.length == 0 ? "disableApproverList" : ""}`}>
                                                <p onClick={() => {if(approverCollList?.approvalRequestList?.length > 0)showApproverList()}} style={{cursor: approverCollList?.approvalRequestList?.length > 0 ? "pointer" : "context-menu"}}>{approverCollList?.approvalRequestList?.length} Requests pending <span>{`view`}<KeyboardDoubleArrowRightIcon/></span></p>
                                            </Box>
                                        </Box>}
                                        {/* {approverCollList?.collaboratorList?.length > 0 && <KeyboardArrowRightIcon />} */}
                                    </Box>}
                                    <Box className='button-container'>
                                        <Button
                                            className='cancel-btn'
                                            onClick={() => { clearErrors(); setCollaboratorPopupStatus(false) }}
                                        >Cancel</Button>
                                        <Button
                                            className='save-btn'
                                            type='submit'
                                            disabled={mandateStatus == 55}
                                        >
                                            {Object.keys(editCollData).length > 0 ? "Update" : "Share"}
                                        </Button>
                                    </Box>
                                </Box>
                            </FormProvider>
                        </Box>
                    </Box>
                    <AppErrorHandlerModal
                        open={collabSuccessStatus}
                        content={collabSuccessMessage}
                        handleClose={handleClose}
                        factor='Success'
                        ButtonText='Done'
                    ><></></AppErrorHandlerModal>
                    <Box>
                        <Snackbar
                            open={false}
                            autoHideDuration={5000}
                            onClose={() => { }}
                            anchorOrigin={{
                                vertical: 'top',
                                horizontal: 'right',
                            }}
                        >
                            <Alert onClose={() => { }} severity='error' sx={{ width: '100%' }} variant='filled'>
                                {""}
                            </Alert>
                        </Snackbar>
                    </Box>
                </Box>
            </Modal>
        </>
    );
};

export default CollaboratorPopup;