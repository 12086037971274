import React, { useEffect, useState } from 'react';
import { ResponsivePie } from '@nivo/pie';
import { Paper, Typography, useMediaQuery, useTheme, Box, IconButton, Tooltip, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import CustomTooltip from '../../../Common/CustomTooltipComponent/CustomTooltip';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import LaunchIcon from '@mui/icons-material/Launch';
import { ReactComponent as DashboardLaunch } from '../../../../assets/icons/dashboardLaunch.svg'
import { useNavigate } from 'react-router-dom';
import "./JobswithCandidateWidget.scss"
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg';
import { getTopJobsWithCandidate } from '../../../../services/DashboardService';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import defaultImg from '../../../../assets/images/Defaul-image.jpg';
import { setDashboardWidgetPresistance } from '../../../../features/filters/PersistFilter';

// Define the data type
type PieData = {
  id: string;
  label: string;
  value: number;
  color: string;
  imageUrl: string;
  numberOfCandidates: number;
};

const JobswithCandidateWidget:any = ({reportSubmissionAPIData,DashboardFilterDatas,reportSubmitdatalabels,userRoleObjectSelected,userDependendObj,topJobWithCandidateObject}:any) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
  const navigate = useNavigate();
  const {userRole,userId} = useAppSelector((state) => state.UserDetail)
  const columns = isSmallScreen ? 1 : isMediumScreen ? 2 : 3;
  const [activitychange, setActivitychange] = useState(Object.keys(topJobWithCandidateObject).length > 0 ? topJobWithCandidateObject.activitychange : "external");
  const [topCount, setTopCount] = useState(Object.keys(topJobWithCandidateObject).length > 0 ? topJobWithCandidateObject.topCount : 3);
  const [donutData, setDonutData] = useState([])
  const colorCode = ["#4AB46E","#9C6FD6","#0E94F4","#FF6E00","#D33E8D","#D45558","#21B0BB","#BB9D4A","#6B79E2","#7B7B7B"]
  const jobWithCandidateClickHandler = () => {
    navigate(`/jobsWithCandidate`, { state: { dashboardfilterObj:DashboardFilterDatas, filterList: reportSubmissionAPIData ,filterListLabels: reportSubmitdatalabels,userFilterObj: userRoleObjectSelected, clientDependedObj: userDependendObj,reportType: activitychange,secondaryFilter: topCount} });
  }
  const dispatch = useAppDispatch()    
  const dashboardWidgetReduxStore: any = useAppSelector((state) => state.PersistFilterReducer.dashboardWidgetPersistance)
  const handleSwitchChange = (type:string) => {
    console.log("seteffecienychange",type)
    setActivitychange(type);
  };

  const handleChange = (event: any) => {
    setTopCount(event.target.value);
  };

  const getJobWithCandidateDataApi = async () => {
  
  const reportSubmissionAPIDataJson:any = {}
      if(reportSubmissionAPIData !=  null){
          if(Object.keys(reportSubmissionAPIData).length > 0){
              Object.keys(reportSubmissionAPIData).map((item:any) => {
                  if(reportSubmissionAPIData[item].length > 0){
                  reportSubmissionAPIDataJson[item] = reportSubmissionAPIData[item]
                  }
              })
            }  
        }
  const requestPayload = {
    ...reportSubmissionAPIDataJson,
    reportType: activitychange,
    secondaryFilter: topCount
  }
  requestPayload["userID"] = "userId" in reportSubmissionAPIDataJson ? reportSubmissionAPIDataJson.userId : [userId]
  delete requestPayload["userId"]
    try{
      const response = await getTopJobsWithCandidate(requestPayload);        
      console.log("My StageWise Funnel Data : ", response?.data)
      const jobCandidateData = response?.data.map((item:any,index:any) => {
        return { 
          id: item.mandateCode, 
          label: item.jobTitle, 
          value: item.percentage.toFixed(2), 
          color: colorCode[index], 
          unserviceCandidates: item.unservice_candidates,
          inserviceCandidates: item.inservice_candidates,
          numberOfCandidates: item.total_candidates_report,
          imageUrl: defaultImg
        }
      });
    setDonutData(jobCandidateData)
    }
      catch(error:any){
        console.log(error)        
      }
  }

  useEffect(() => {
    getJobWithCandidateDataApi()
    const dashboardWidgetPeristance = JSON.parse(JSON.stringify(dashboardWidgetReduxStore));        
    dashboardWidgetPeristance["topJobWithCandidateActivity"] = {
      "activitychange": activitychange,
      "topCount": topCount
    }
    dispatch(setDashboardWidgetPresistance(dashboardWidgetPeristance))
    
  },[reportSubmissionAPIData,activitychange,topCount])

  const renderEmptySearchBox = () => {;
      return (
          <Box className="empty-data-card">
              <Box>
                  <NoResultFound />
              </Box>
              <Box className="empty-search-message">
                  No data found
              </Box>
          </Box>
      )
  }

  return (
    <Paper className='dashboardWidgetSmallCard' style={{minHeight:"586px",padding:'20px'}}>
        <Box className="dashWidgetHeader" style={{paddingLeft:"0px"}}>
        <Box><Typography variant="h6" gutterBottom>Top 5 Jobs with Candidate Activity, Across Clients</Typography></Box>
        <Box sx={{display:'flex', columnGap:'5px',paddingTop:'5px'}}>
          {donutData.length > 0 && <Tooltip title="Launch"><DashboardLaunch style={{cursor:"pointer"}} className='launchIcon' onClick={jobWithCandidateClickHandler}/></Tooltip>}
        </Box>
      </Box>
      <Box className="jobWithCandiActionSect">
        <FormControl fullWidth>
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={topCount}
            onChange={handleChange}
          >
            <MenuItem value={3}>3</MenuItem>
            <MenuItem value={5}>5</MenuItem>
            <MenuItem value={10}>10</MenuItem>
          </Select>
        </FormControl>
        <Box className="dashboardFunnelSect">
          <Box onClick={() => handleSwitchChange("external")} className={`${activitychange == "external" ? "active":""} dashboardFunnelLeft`}>External Activity</Box>
          <Box onClick={() => handleSwitchChange("internal")} className={`${activitychange == "internal" ? "active":""} dashboardFunnelRight`}>Internal Activity</Box>
        </Box>
      </Box>
      
      <Box className="topJobCandidateSect" sx={{ height: '360px', width: '100%' }}>

        {donutData.length > 0 ? <ResponsivePie
          data={donutData}
          margin={{ top: 40, right: 80, bottom: 20, left: 80 }}
            innerRadius={0.6}
            padAngle={3}
            cornerRadius={3}
            activeOuterRadiusOffset={8}
            borderWidth={1}
            valueFormat={(value:any) => `${(Math.round(value))}%`}
            borderColor={{
                from: 'color',
                modifiers: [
                    [
                        'darker',
                        0.2
                    ]
                ]
            }}
            colors={{ datum: 'data.color' }}
            arcLinkLabelsSkipAngle={10}
            arcLinkLabelsTextColor="#333333"
            
            arcLinkLabelsThickness={2}
            arcLinkLabelsColor={{ from: 'color' }}
            enableArcLabels={true}
            enableArcLinkLabels={false}
            arcLabelsSkipAngle={10}
            arcLabelsTextColor="#ffffff"
            tooltip={({ datum }:any) => (
                <CustomTooltip
                  tooltipcontentName = "Candidates"
                  id={datum.id as string}
                  value={datum.value}
                  color={datum.color}
                  label={datum.label as string}
                  imageUrl={datum.data.imageUrl}
                  donutType={activitychange}
                  numberOfCandidates={datum.data.numberOfCandidates}
                  data={datum.data}
                  tooltipstatus={{
                    enable: false,
                    hideSect: ["title","percentage"]
                  }}
                />
              )}
            defs={[
                {
                  id: 'shadow',
                  type: 'patternDots',
                  background: 'rgba(0, 0, 0, 0.1)',
                  color: 'rgba(0, 0, 0, 0.1)',
                  size: 4,
                  padding: 2,
                  paddingBottom:5,
                  stagger: true
                }
              ]}

              theme={{
                tooltip: {
                  container: {
                    background: 'rgba(0, 0, 0, 0.5)',
                    color: 'inherit',
                    boxShadow: '10px 10px 9px rgba(0, 0, 0, 0.5)',
                    borderRadius: '2px',
                    padding: '5px 9px',
                  },
                },
                labels: {
                    text: {
                      fontWeight: 'bold',
                      color:'white'
                    },
                  },
              }}
        />: renderEmptySearchBox()}
      </Box>
      <Box className='legend-items' sx={{ display: 'flex', position: 'absolute', bottom: '0px', rowGap: '0px', columnGap: '30px',flexWrap:"wrap", width: 'calc(100% - 20px)', paddingY: '10px', paddingLeft: '0px', minHeight: "120px" }}>
        {donutData.length > 0 && donutData.map((item:any, index) => (
          <Box key={index} className='legend-item' sx={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
            <Box className='itm-clr' sx={{ backgroundColor: item.color, width: '7px', height: '7px', borderRadius:'1px' }}></Box>
            <Tooltip title={item.label}><Box className='itm-val' sx={{ fontSize: '10px' }}>{item.label}</Box></Tooltip>
          </Box>
        ))}
      </Box>
    </Paper>
  );
};

export default JobswithCandidateWidget;
