import React, { useEffect, useState } from 'react';
import { ResponsiveBar, BarDatum } from '@nivo/bar';
import { Box, CardContent, Typography } from '@mui/material';
import { useTooltip, BasicTooltip } from '@nivo/tooltip';

interface DataItem extends BarDatum {
  id: string;
  value: number;
  client: string;
  activity: number;
  activityType: string;
}

interface ResponsiveBarChartProps {
  data: DataItem[];
  yAxisKey: string;
  keys: any;
  isSmallScreen: boolean;
  isMediumScreen: boolean;
  customColors: any;
  selectAll: boolean;
  type : string;
  setBarHandlerData?: any
}


const CustomTooltip = ({
  value,
  activity,
  contentType,
  getMousePosition
}:any) => {
  return (
    <Box
    sx={{
      position: 'fixed',
      padding: '8px 0px',
      background: 'black',
      border: '1px solid #000',
      borderRadius: '10px',
      left: getMousePosition.clientX - 100+"px",
      bottom: (window.innerHeight - getMousePosition.clientY + 20) +"px",
      // color: color,
      zIndex: 999999999,
      width: "200px",
      // maxWidth: "250px",
      // height: "200px",
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%)',
        width: 0,
        height: 0,
        borderLeft: '8px solid transparent',
        borderRight: '8px solid transparent',
        borderTop: '8px solid black',
      },
      '&::after': {
        content: '""',
        position: 'absolute',
        top: '100%',
        left: '50%',
        transform: 'translateX(-50%) translateY(-1px)',
        width: 0,
        height: 0,
        borderLeft: '7px solid transparent',
        borderRight: '7px solid transparent',
        borderTop: '7px solid #000',
      },
    }}
    >
          <CardContent sx={{ padding: '10px 10px 0 10px !important',zIndex:"99999999", '&:hover': { backgroundColor: '#333' } }}>
            <Box sx={{ paddingBottom: '10px !important' }}>
              <Typography variant="body2" sx={{ fontSize: '12px', color: '#c1b8b8' }}>{contentType == "client" ? "Client" : contentType == "recruiter" ? "Recruiter" : "Status"}</Typography>
              <Typography variant="body1" sx={{ fontSize: '14px', color: '#fff', textTransform: contentType == "client" ? "initial" : "capitalize" }}>{contentType == "client" ? (activity.activitystatus || value) : contentType == "recruiter" ? (activity.activitystatus || value) : (activity.candidateStatus == "ccm1" || activity.candidateStatus == "ccm2") ? activity.candidateStatus?.toUpperCase() : activity.candidateStatus?.replaceAll("_"," ")}</Typography>
            </Box>
            <Box>
              <Typography variant="body2" sx={{ fontSize: '12px', color: '#c1b8b8' }}>Candidate Activity</Typography>
              <Typography variant="body1" sx={{ fontSize: '14px', color: '#fff' }}>{activity.numberOfactivity}</Typography>
            </Box>
            {contentType != "status" && <Box>
              <Typography variant="body2" sx={{ fontSize: '12px', color: '#c1b8b8' }}>Candidate Status</Typography>
              <Typography variant="body1" sx={{ fontSize: '14px', color: '#fff', textTransform:"capitalize" }}>{(activity.candidateStatus == "ccm1" || activity.candidateStatus == "ccm2") ? activity.candidateStatus?.toUpperCase() : activity.candidateStatus?.replaceAll("_"," ")}</Typography>
            </Box>}
          </CardContent>
    </Box>
  );
};

const RecruiterActivityResponsiveBarChart: React.FC<ResponsiveBarChartProps> = ({ data,yAxisKey,keys,isSmallScreen, isMediumScreen,customColors,selectAll,type,setBarHandlerData }) => {
  const truncateLabel = (label:any, maxLength:any) => {
    return label.length > maxLength ? `${label.slice(0, maxLength)}...` : label;
  };
  const statusId:any = {
    "shortlisted_against_jo": "12",
    "shortlisted_L2_review": "17",
    "shortlisted_client_submission": "18",
    "submitted": "24",
    "client_shortlist": "27",
    "ccm1": "32,33,34",
    "ccm2": "112,113,114",
    "offered": "42",
    "joined": "51",
    "post_joining_drop": "105"
    }
  let highestCandidateCount = 0
  if(type == "client" || type == "recruiter"){
    highestCandidateCount = data.reduce((max:any, client:any) => 
      client.candidate_count > max ? client.candidate_count : max, 0);
  }else{
    highestCandidateCount = Math.max(...data.map(item => Object.values(item).find(v => typeof v === 'number') as number));
  }
  const capitalizeWords = (sentence:any) => {
    return sentence.split(" ")
        .map((word:any) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}

const barChartHandler = (part: any) => {
  setBarHandlerData({
    "clientId": part.data.clientId,
    "status": statusId[part.id].split(",")
  })
};

  const [getMousePosition, setGetMousePosition] = useState<any>({})
  const [toolTipData, setToolTipData] = useState<any>({})
  const theme = {
    labels: {
      text: {
        fontSize: 12, // Increase bar label font size
        fontWeight: "bold",
        fill: "#000", // Optional: Change label color
      },
    },
    axis: {
      ticks: {
        text: {
          fontSize: 12, // Increase axis tick font size
          fill: "#333", // Optional: Change color
          fontWeight: "500", // Optional: Make it bold
        },
      },
    },
    grid: {
      line: {
        stroke: "#F2F2F2", // Change to your desired color
        strokeWidth: 1
      },
    },
  };

  useEffect(() => {
    console.log("getMousePosition",getMousePosition)
  },[getMousePosition])
  return (
    <Box style={{marginBottom:"20px"}}>
      <Box className="respoBarChartScroll">
        <Box onMouseMove={(event) => {
          setGetMousePosition({
            clientX: event.clientX,
            clientY: event.clientY,
          });
      }} className="respoBarChart" style={{width:"565px", height: (data.length > 10 && data.length < 16) ? '500px' : data.length > 16 ? '600px' : "390px" }}>
          <ResponsiveBar
            data={data}
            keys={(!selectAll && keys.length == 10 && (type == "client" || type == "recruiter")) ? ["total count"] : keys}
            indexBy={yAxisKey}
            margin={{ top:0, right: 170, bottom: 0, left: 120 }}
            padding={0.4}
            valueScale={{ type: 'linear' }}
            colors={({ id, data }:any) => (!selectAll && keys.length == 10 && type == "client") ? "#5A6ACF" : (!selectAll && keys.length == 10 && type == "recruiter") ? "#CFB55A" : customColors[id.replaceAll("_","")]}
            indexScale={{ type: 'band', round: true }}
            borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            axisTop={null}
            axisRight={null}
            enableLabel={false}
            // borderRadius={3}
            enableTotals={true}
            layout="horizontal"
            enableGridX={true}
            enableGridY={false}
            theme={theme}
            axisBottom={null}
            axisLeft={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legendPosition: 'middle',
              legendOffset: 0,
              format: (value) => truncateLabel(type == "status" ? (value == "ccm1" || value == "ccm2") ? value.toUpperCase() : capitalizeWords(value.replaceAll("_"," ")) : capitalizeWords(value.split("_")[0]), 12)
            }}
            maxValue={highestCandidateCount + Math.round(highestCandidateCount/ 100 * ((type == "client" || type == "recruiter")? 15 : 10))}
            tooltip= {() => null}
            onClick={barChartHandler}
            labelSkipWidth={12}
            labelSkipHeight={12}
            labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
            role="application"
            ariaLabel="Nivo bar chart demo"
            onMouseEnter={(_datum, event:any) => {
              setToolTipData((prev:any) => ({
                data: _datum
              }));
              event.currentTarget.style.cursor = "pointer";
            }}
            onMouseLeave={() => {
              setGetMousePosition({})
              setToolTipData({})
            }}
            barAriaLabel={(e) => `${e.id}: ${e.formattedValue} in client: ${e.indexValue}`}
          />
        </Box>
      </Box>
      <Box className="barYaxisCard" style={{width:"565px",height:"150x"}}>
            <ResponsiveBar
                  data={data}
                  keys={(!selectAll && keys.length == 10 && (type == "client" || type == "recruiter")) ? ["total count"] : keys}
                  indexBy={yAxisKey}
                  margin={{ top:0, right: 170, bottom: 25, left: 120 }}
                  padding={0.4}
                  valueScale={{ type: 'linear' }}
                  colors={({ id, data }:any) => (!selectAll && keys.length == 10 && type == "client") ? "#5A6ACF" : (!selectAll && keys.length == 10 && type == "recruiter") ? "#CFB55A" : customColors[id.replaceAll("_","")]}
                  indexScale={{ type: 'band', round: true }}
                  borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                  axisTop={null}
                  axisRight={null}
                  enableLabel={false}
                  // borderRadius={3}
                  enableTotals={true}
                  layout="horizontal"
                  enableGridX={false}
                  enableGridY={false}
                  theme={theme}
                  axisBottom={{                    
                    tickSize: 5,
                    tickValues: 6,
                    tickPadding: 5,
                    tickRotation: isSmallScreen ? -45 : 0,
                    legendPosition: 'middle',
                    legendOffset: 32,
                  }}
                  layers={['axes']} // Only show axes
                  axisLeft={null} 
                  maxValue={highestCandidateCount + Math.round(highestCandidateCount/ 100 * ((type == "client" || type == "recruiter")? 15 : 10))}
                  tooltip= {() => null}
                  onClick={barChartHandler}
                  labelSkipWidth={12}
                  labelSkipHeight={12}
                  labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
                  role="application"
                  ariaLabel="Nivo bar chart demo"
                  onMouseEnter={(_datum, event:any) => {
                    setToolTipData((prev:any) => ({
                      data: _datum
                    }));
                    event.currentTarget.style.cursor = "pointer";
                  }}
                  onMouseLeave={() => {
                    setGetMousePosition({})
                    setToolTipData({})
                  }}
                  barAriaLabel={(e) => `${e.id}: ${e.formattedValue} in client: ${e.indexValue}`}
                />
              </Box>
      {(Object.keys(getMousePosition).length > 0 && Object.keys(toolTipData).length > 0) && <CustomTooltip
                    getMousePosition={getMousePosition}
                    contentType = {type}
                    id={toolTipData.data.id}
                    value={toolTipData.data.value}
                    color={toolTipData.data.color}
                    // label={datum?.client ?? ""}
                    activity={{
                        activitystatus: data.find(d => d.client == toolTipData.data.indexValue)?.client.split("_")[0] ?? "",
                        numberOfactivity: toolTipData.data.value,
                        candidateStatus: toolTipData.data.id.toString() ?? "",
                      }}/>}
    </Box>
  );
};

export default RecruiterActivityResponsiveBarChart;