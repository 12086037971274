import { useAppSelector, useAppDispatch } from "../store/hooks";
import { Layout } from "../components/Layout";
import {SocketProvider} from '../contexts/SocketContext'
import SmartCallPop from "../components/Common/smartCallPop/SmartCallPop";
import { useState, useEffect } from "react";
import {authUserDetails, authLogoutUser} from "../features/userDetail/UserDetailReducer"
import {getUserDetails} from "../services/UserService"
import { GetAgentDetails,GetCallerDetails } from "../services/CandidateService"
import Popupwindow from "../components/Popupwindow/Popupwindow";
import {getNotificationCount} from "../services/NotificationService"
import {setNotificationCount} from "../features/notification/notificationReducer";
import {setAgentState} from "../features/CallReducer/CallReducer";
import {listStatus} from '../features/mandateDetail/DrawerReducer'
import MonthlyFunActivePopup from "../components/AppComponents/MonthlyFunActivePopup/MonthlyFunActivePopup";
import { offRotateScreen, showMonthlyCard } from "../features/systemConfig/SystemConfig";
import { useLocation } from "react-router-dom";
import "intro.js/introjs.css";
import introJs from "intro.js";
import { Helmet } from "react-helmet";
import { Box } from "@mui/material";
import { setMandateFilterPersistance } from "../features/filters/PersistFilter";

type ApplayoutProps = {
    children: React.ReactNode;
};

const AppLayout = (props: ApplayoutProps) => {
    const dispatch = useAppDispatch()
    const authUser = useAppSelector((state) => state?.auth?.token)
    const isCallPop = useAppSelector((state) => state?.smartflow.isCallPop)
    const userState = useAppSelector((state) => state?.UserDetail?.userId)
    const isWelcomePopupEnabled = useAppSelector((state) => state?.SystemConfig?.isWelcomePopupEnabled);
    const welcomePopupDisplayTimer = useAppSelector((state) => state?.SystemConfig?.welcomePopupDisplayTimer);
    const isWelcomeRotateOff = useAppSelector((state) => state?.SystemConfig?.welcomeRotateOffCase);
    const hideMonthlyCardStatus = useAppSelector((state) => state?.SystemConfig?.hideMonthlyCardStatus);
    const welcomeScreenAnimationEnabled = useAppSelector((state) => state?.SystemConfig?.welcomeScreenAnimationEnabled);
    const [agentList, setAgentList] = useState<any>([]) // Agent list for smartflo call popup
    const [isAgentLoading, setIsAgentLoading] = useState<boolean>(false);
    const toursConfigList = useAppSelector((state) => state?.SystemConfig?.toursConfigList);
    const { userId, userRole, username } = useAppSelector((state) => state?.UserDetail)
    const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
    const toggleTour = useAppSelector((state) => state?.UserDetail.tourToggle);
    let currentJSONObj:any = document.cookie.split(";").filter((item:any) => item.trim().indexOf("tourScreenObject") > -1)
    currentJSONObj = currentJSONObj.length > 0 ? currentJSONObj[0].replace("tourScreenObject=","").trim(): []
    if(toursConfigList != undefined){
        if(Object.keys(toursConfigList).length > 0){
            if(currentJSONObj != JSON.stringify(toursConfigList)){
                document.cookie = `tourScreenObject=${JSON.stringify(toursConfigList)}`
                document.cookie = `tourScreenViewed=[]`
            }
        }
        
    }
    const getPayloadData = (treeViewDataObj:any) => {
        const userBuList:any = [];
        let childLocation:any = []
        let userDetailObj:any = {
          userPermissions: [],
          userRole: "",
          roleId: "",
          businessUnitCode: "",
          businessUnitId: "",
          businessUnitName: "",
          employmentLocation : "",
          employmentLocationName: ""
        }
        function collectSelectedKeys(nodes:any) {          
            for (const node of nodes) {
                if ("children" in node == false || (node.children && node.children.length == 0)) {
                  userDetailObj["userPermissions"] = node?.permissions;
                  userDetailObj["userRole"] = node?.title; 
                  userDetailObj["roleId"] = node?.value;
                  if(childLocation.length > 0){
                    userDetailObj["employmentLocationName"] = childLocation[0];
                    userDetailObj["employmentLocation"] = childLocation[1];
                  }
                  userBuList.push(userDetailObj);
                  userDetailObj = {
                    userPermissions: [],
                    userRole: "",
                    roleId: "",
                    businessUnitCode: "",
                    businessUnitId: "",
                    businessUnitName: "",
                    employmentLocation : "",
                    employmentLocationName: ""
                  }
                } else {                  
                  if(node?.type == "LOCATION"){                    
                    userDetailObj["employmentLocationName"] = node?.title;
                    userDetailObj["employmentLocation"] = node?.value;
                    node.children.length > 1 ? childLocation = [node?.title,node?.value] : childLocation = []
                  }else if(node?.type == "BUSINESS_UNITS"){
                    userDetailObj["businessUnitName"] = node?.title;
                    userDetailObj["businessUnitId"] = node?.value; 
                    userDetailObj["businessUnitCode"] = node?.business_unit_code?.bu_code;
                  }
                  collectSelectedKeys(node.children);
                }
            }
        }    
        collectSelectedKeys(treeViewDataObj);
        return userBuList;
    };
    const getUserRole = async () => {
        dispatch(authLogoutUser())
        try{
            const response = await getUserDetails()
            const currentUserDetails:any = sessionStorage.getItem("currentUserDetails");
            currentUserDetails == null ? sessionStorage.setItem("currentUserDetails", JSON.stringify(response)) : currentUserDetails.length == 0 ? sessionStorage.setItem("currentUserDetails", JSON.stringify(response)): ""
            const dlsRoleList = response?.dlsRoleAccess != null ? getPayloadData(response?.dlsRoleAccess?.otherRoleDls?.dlsData) : []
            dlsRoleList.push({
                userPermissions: response?.permissions,
                userRole: response?.roleSlug,
                roleId: response?.roleId,
                businessUnitCode: response?.businessUnitCode,
                businessUnitId: response?.businessUnitId,
                businessUnitName: response?.businessUnit,
                employmentLocation : response?.employmentLocation,
                employmentLocationName: response?.employmentLocationName
            })
            let obj;
            if(response?.id && response?.keyCloakId){
                obj = {
                    id: response?.id,
                    userId: response?.keyCloakId,
                    username: response?.userName,
                    userRole: response?.roleSlug,
                    firstName: response?.firstName,
                    smartCallEmail: response?.sfloId,
                    smartCallPassword: response?.sfloPwd,
                    businessUnitCode: response?.businessUnitCode,
                    businessUnitName: response?.businessUnit,
                    userPermissions: response?.permissions,
                    businessUnitId: response?.businessUnitId,
                    employmentLocation: response?.employmentLocation,
                    employmentLocationName: response?.employmentLocationName,
                    dlsRoleAccess: dlsRoleList,
                    roleId: response?.roleId,
                    orgId: response?.orgId,
                    orgName: response?.orgName,
                    coPilotAccess: response?.coPilotAccess != null ? response?.coPilotAccess : []
                }
            }
            dispatch(authUserDetails(obj))
            dispatch(listStatus({"isApproval":null,"isRecruiter":false}))
        }
        catch(error:any){
            console.log(error)        
            }
    }

    const getAgents = async () => {
        setIsAgentLoading(true)
        try{
            const response = await GetCallerDetails()
            if ((response?.status == 200 || response?.status == 201)&& response?.data) {
                if(Object.keys(response?.data)?.length >0){
                    // setAgentList(response?.data)
                    dispatch(setAgentState(response?.data))
                }
                else{
                    setAgentList({})
                }
            }
        }catch(error){
            console.log(error)
            setAgentList([])
        }
        setIsAgentLoading(false)
    }
    const handleNotificationCount = async() =>{
        if(userState){
            const reqObj = {
                "userId":userState
            }
        const response  = await  getNotificationCount(reqObj)
        if(response && response?.result?.data){
            dispatch(setNotificationCount(response?.result?.data))
        }
        else{
            dispatch(setNotificationCount(0))
        }
        }
    }
    useEffect(()=> {
        if(authUser && !userState){
            getUserRole()
        }
    },[authUser])
    useEffect(()=>{
        if(username !=  null){
            setTimeout(() => {
                handleNotificationCount();
                getAgents()
            },500)            
        }        
    },[username])
    const location = useLocation();
    function hasTrueSelected(obj:any) {
        for (const key in obj) {
            if (typeof obj[key] === 'object') {
                if (hasTrueSelected(obj[key])) {
                    return true;
                }
            } else if (key === 'selected' && obj[key] === true) {
                return true;
            }
        }
        return false;
    }
    const getMenuHideList = () => {
        const menuListString:any = ["Mandates","Candidate Search","Work Management","My Task","Scheduler","Reports"]
        const sliceMenuList:any = toursConfigList.dashboard.filter((item:any) => !menuListString.includes(item.title))
        let menuListObj = toursConfigList.dashboard.filter((item:any) => menuListString.includes(item.title))
        const menuList:any = []
        Object.keys(userPermissions).map((item:any) => {
            if(hasTrueSelected(userPermissions[item])) {
                menuList.push(item == "mandate_module" ? "Mandates" : item == "candidate_module"  ? "Candidate Search" : item == "work_management_module"  ? "Work Management" : item == "task_module"  ? "My Task" : item == "scheduler_module"  ? "Scheduler" : item == "reports_module"  ? "Reports" : "")
            }
        })
        menuListObj =menuListObj.filter((menuItem:any) => menuList.includes(menuItem.title))        
        return [...menuListObj,...sliceMenuList]
    }

    const getTourData = async () => {     
            const currentLocation = location.pathname.replace("/","");
            const currentLocationKey = currentLocation;                     
            if(toursConfigList != undefined && Object.keys(toursConfigList).length > 0 && currentLocationKey != "login"){                 
                let menuList:any = []  
                const toursConfigListObj = JSON.parse(JSON.stringify(toursConfigList))            
                    let viewedScreen = document.cookie.split(";").filter((item:any) => item.trim().indexOf("tourScreenViewed") > -1);
                    let currentJSON:any = document.cookie.split(";").filter((item:any) => item.trim().indexOf("tourScreenObject") > -1)
                    currentJSON = currentJSON.length > 0 ? currentJSON[0].replace("tourScreenObject=","").trim(): []
                    if(currentJSON.length == 0){
                        document.cookie = `tourScreenObject=${JSON.stringify(toursConfigList)}`     
                    }
                    if(viewedScreen.length == 0){
                        document.cookie = `tourScreenViewed=[]`
                    }else{
                        viewedScreen = JSON.parse(viewedScreen[0].replace("tourScreenViewed=",""))
                    }                
                    
                    if(userRole != "Admin" && menuList.length == 0){
                        menuList = await getMenuHideList()
                        const screenObj = menuList.filter((item:any) => (document.querySelector(item.element) != null))
                        toursConfigListObj[currentLocation] = screenObj;
                        setTimeout(() => {      
                            const introJsObj:any = {
                                steps:currentLocationKey.length > 0 ? toursConfigListObj[currentLocationKey] : []
                              }
                                if(currentLocationKey.length > 0){
                                    if(Object.keys(toursConfigList).includes(currentLocationKey) && !viewedScreen.includes(currentLocationKey)){                
                                        viewedScreen.push(currentLocationKey)
                                        document.cookie = `tourScreenViewed=${JSON.stringify(viewedScreen)}`
                                        if(introJsObj?.steps.length > 0){
                                            introJs().setOptions(introJsObj).start();
                                        }
                                    }
                                }    
                        },1000)    
                    } else{     
                            const selectedScreenObj = toursConfigListObj[currentLocation] != undefined ? toursConfigListObj[currentLocation] : []
                            const screenObj = selectedScreenObj.filter((item:any) => document.querySelector(item.element) != null)
                            toursConfigListObj[currentLocation] = screenObj;
                            const introJsObj:any = {
                                steps:currentLocationKey.length > 0 ? toursConfigListObj[currentLocationKey] : []
                              }
                                if(currentLocationKey.length > 0){
                                    if(Object.keys(toursConfigList).includes(currentLocationKey) && !viewedScreen.includes(currentLocationKey)){                
                                        viewedScreen.push(currentLocationKey)
                                        document.cookie = `tourScreenViewed=${JSON.stringify(viewedScreen)}`
                                        if(introJsObj?.steps.length > 0){
                                            introJs().setOptions(introJsObj).start();
                                        }   
                                    }
                                }  
                    }   
        }else{            
            document.querySelector(".introjs-overlay")?.remove();
            document.querySelector(".introjs-helperLayer")?.remove();
            document.querySelector(".introjs-tooltipReferenceLayer")?.remove()
        }
    }
    
    useEffect(() => {
        getTourData()
        if(location.pathname.split("/").slice(1)[0] != "mandates" && location.pathname.split("/").slice(1)[0] != "edit-mandate"){
           dispatch(setMandateFilterPersistance({}))
        }
   }, [location,toursConfigList,toggleTour,userRole])


    let screenRotate = document.cookie.split(";").filter((item:any) => item.trim().indexOf("screenRotate") > -1);
    screenRotate = screenRotate.length > 0 ? screenRotate[0].trim().split("=") : []
    if(isWelcomePopupEnabled && screenRotate.length == 0){
        setTimeout(() => {
            dispatch(offRotateScreen())
            const expirationDate = new Date(new Date().getTime() + 7 * 24 * 60 * 60 * 1000);
            document.cookie = 'screenRotate=completed;expires='+expirationDate.toUTCString()+';path=/';
            dispatch(showMonthlyCard())
        },((welcomeScreenAnimationEnabled && welcomePopupDisplayTimer) ? welcomePopupDisplayTimer*1000 : 1000))
    }
    
    // const rotateScreen = () => {
    //     const rotateSec = welcomePopupDisplayTimer+"s"
    //     document.documentElement.style.transitionDuration=rotateSec;document.documentElement.style.transitionTimingFunction="ease-in";document.documentElement.style.transform="rotate(360000deg)"
    // }
    // if(isWelcomePopupEnabled && isWelcomeRotateOff && screenRotate.length == 0){
    //     if(welcomeScreenAnimationEnabled)rotateScreen()        
    // }

    const capitalizeWord = (string:any) => {
        if(string.length > 0){
            let splitString = string.split(" ")
            splitString = splitString.map((item:any) => {
                return item.charAt(0).toUpperCase() + item.slice(1)
            })
            return splitString.join(" ")
        }else{
            return ""
        }
    }

    return (
        <>
        <Helmet>
            <title>{`Xpheno | ${capitalizeWord(location.pathname.replace("/","").replace("-"," "))}`}</title>
        </Helmet>
        <div className={`${(isWelcomePopupEnabled && isWelcomeRotateOff && screenRotate.length == 0 && welcomeScreenAnimationEnabled) ? "rotateHtml" : ""}`}>
            {hideMonthlyCardStatus && <MonthlyFunActivePopup />}
            {authUser ? ( // render with navigation bars
            <SocketProvider>
                {(isCallPop) && <SmartCallPop agentList={agentList} isAgentLoading={isAgentLoading} />}     
                <Popupwindow/>
                <Layout>
                    <Box className={location.pathname.replace("/","")}>{props.children}</Box>
                </Layout>
            </SocketProvider>
            ) : (         // render without navigation bars
                <>
                    {props.children}
                </>
            )}
        </div>
        </>
    )
}

export default AppLayout;