import {
  Paper,
  Box,
  Divider,
  Typography,
  Grid,
  Stack,
  Button,
  TextField,
  ToggleButtonGroup,
  ToggleButton,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Popover,
  OutlinedInput,
  InputAdornment,
  Checkbox,
} from '@mui/material'
import React, { useState, useEffect } from 'react'
import './Filter.scss'
import { PRIMARY1, PRIMARY2, PRIMARY3, TERTIARY3, TERTIARY12 } from '../../../utils/constants'
import { MandatesSearch } from '../Search'
import { ReactComponent as UploadIcon } from '../../../assets/icons/upload.svg'
import { ReactComponent as FunnelIcon } from '../../../assets/icons/funnel.svg'
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrow-up.svg'
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg'
import { ReactComponent as AddNewIcon } from '../../../assets/icons/add-new.svg'
import { AppButton } from '../../AppComponents/AppButton'
import { MandatesModal } from '../UploadModal/MandatesModal'
import { ReactComponent as RotateIcon } from '../../../../src/assets/icons/reset.svg'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DefaultValues, SubmitHandler, useForm, Controller } from 'react-hook-form'
import { AppTextField } from '../../AppComponents/AppTextField'
import { ReactComponent as CalenderIcon } from '../../../../src/assets/icons/input-calendar.svg'
import moment from 'moment'
import _ from 'lodash'
import { ListState } from '../../../pages/Mandates'
import { useNavigate } from 'react-router-dom'
import { CssTextField } from '../../AppComponents/AppTextField/AppTextField'
import { MandateService,getMandateStatusDropdown } from '../../../services/MandateService'
import { getUserDetails } from '../../../services/UserService'
import { AppAutoComplete } from '../../AppComponents/AppAutoComplete'
import { ReactComponent as GridIcon } from '../../../assets/icons/grid.svg'
import { ReactComponent as ListIcon } from '../../../assets/icons/list.svg'
import { ReactComponent as MandateSearchIcon } from '../../../assets/icons/task.svg'
import { ReactComponent as ApprovalSearchIcon } from '../../../assets/icons/mandatesList.svg'
import MenuIcon from '@mui/icons-material/Menu'
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { mandateTableColumnPersist } from '../../../features/mandateDetail/profilePersistReducer'
import { setMandateFilterPersistance } from '../../../features/filters/PersistFilter'

// interface MandatesFilterProps {
//   toggleTab: (event: React.SyntheticEvent, tab: number) => void
//   selectedTab: number
// }

interface ISelectForm {
  reqStartDate: string
  reqEndDate: string
  clientName: string | null
  status:string
  commonFilterText: string
}
export const MandatesFilter = ({
  isRecruiter,
  totalRecords,
  onFilterChange,
  listState,
  onCreateSuccess,
  setsearchvalue,
  viewtype,
  setViewType,
  isApprovalList,
  setIsApprovalList,
  filteredColumns,
  columnFieldsData
}: {
  isRecruiter:boolean
  totalRecords: number
  onFilterChange: (filters: any) => void
  listState: ListState
  onCreateSuccess: () => void
  setsearchvalue: any
  viewtype?: any
  setViewType?: any
  isApprovalList?: boolean
  setIsApprovalList?:any
  filteredColumns?:any
  columnFieldsData?:any
}) => {
  // const [date, setDate] = useState<Dayjs | null>(dayjs(''));
  const [open, setOpen] = useState(false)
  const [filterChecked, setfilterChecked] = useState(false)
  const [selectOption, setSelectOption] = useState([])
  const [statusList, setStatusList] = useState<any[]>([])
  const [viewTypeToggle, setViewTypeToggle] = useState(viewtype == "list" ? true : false)
  const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
  const mandateTableHeaderColumn = useAppSelector((state:any) => state?.profilePersistor.mandateTableColumn)
  const mandateFilterData = useAppSelector((state:any) => state?.PersistFilterReducer?.mandateFilterPersistance)
 const { userRole, roleId, businessUnitId } = useAppSelector((state) => state.UserDetail)
  const dispatch = useAppDispatch()
  // const [startdate, setStartdate] = useState<Date| null>(null)
  // const [enddate, setEnddate] = useState<Date| null>(null)
  const navigate = useNavigate()

  // form default valuue

  const defaultValues: ISelectForm = {
    reqStartDate: '',
    reqEndDate: '',
    clientName: null,
    status:'',
    commonFilterText: '',
  }
  const [values, setValues] = useState(defaultValues)
  const { handleSubmit, control, reset, watch, getValues, setValue } = useForm<ISelectForm>({
    values,
  })
  let sessionUserDetail:any = sessionStorage.getItem("currentUserDetails");
  sessionUserDetail = sessionUserDetail != null ? JSON.parse(sessionUserDetail) : ""
  const watchFields = watch(['reqStartDate', 'reqEndDate'])
  useEffect(() => {
    const newValues = {
      ...values,
      reqStartDate: _.get(mandateFilterData, 'reqStartDate'),
      reqEndDate: _.get(mandateFilterData, 'reqEndDate'),
      clientName: _.get(mandateFilterData, 'clientName'),
      status: _.get(mandateFilterData, 'status'),
      commonFilterText: _.get(mandateFilterData, 'commonFilterText'),
    }
    setValues(newValues)
    onFilterChange(newValues)
  }, [listState])


  const [columnFilterSearch, setColumnFilterSearch] = useState<string>('')
  const [columnFilter, setColumnFilter] = useState<boolean>(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [selectAllColumnsState, setSelectAllColumnsState] = useState<any>(false);  
  const [columnFields, setColumnFields] = useState<any>([
    {label: 'Client Name', id: 2, checkStatus: true},
    {label: 'Created By', id: 3, checkStatus: true},
    {label: 'Created On', id: 4, checkStatus: true},
    {label: 'Job Orders', id: 5, checkStatus: false},
    {label: 'Assignees', id: 6, checkStatus: true},
    {label: 'Due Date', id: 7, checkStatus: false},
    {label: 'Status', id: 8, checkStatus: false}
  ])

  useEffect(() => {
    const columnFieldData = columnFieldsData != undefined ? columnFieldsData : mandateTableHeaderColumn;
    const columnFieldsObject = columnFields.map((item:any) => {
      item.checkStatus = columnFieldData[item.label]
      return item
    })
    setColumnFields(columnFieldsObject)
    dispatch(mandateTableColumnPersist({"columnHeader":columnFieldData,"viewtype":viewtype}))
  },[columnFieldsData])

  const openCoumnFilter = (event:any) => {
    setAnchorEl(event.currentTarget)
    setColumnFilter(true)
    let selectAllFilter = true;
    columnFields.map((columns:any) => {
      !columns.checkStatus ? selectAllFilter = false : "";
    })
    setSelectAllColumnsState(selectAllFilter)
  }

  const updateCheckedColumns = (isChecked:boolean) => {
    columnFields.forEach((columns:any) => {
      if(columns.label.toLowerCase().includes(columnFilterSearch)) setValue(columns.label, isChecked)
    })
  }

    const hitSave = (data:any,type:string) => {
      filteredColumns(data)
    }

  // modal open and close event
  const handleClickOpen = () => {
    setOpen(true)
  }
  const handleClose = () => {
    setOpen(false)
  }

  const handleFilter = () => {
    setfilterChecked((prev) => !prev)
  }

  const resetFilters = () => {
    reset()
    setValues(defaultValues)
    onFilterChange(defaultValues)
    dispatch(setMandateFilterPersistance({}))
  }

  // filter submit
  const onSubmit: SubmitHandler<ISelectForm> = (data) => {
    if (!data.reqEndDate) {
      const StartDate = data.reqStartDate
        ? moment(new Date(data.reqStartDate)).format('YYYY-MM-DD')
        : ''
      const datedata = {
        reqStartDate: StartDate,
        reqEndDate: StartDate,
      }
      const newValues = {
        ...values,
        ...data,
        ...datedata,
      }
      setValues(newValues)
      onFilterChange(newValues)
      dispatch(setMandateFilterPersistance(newValues))
    } else {
      const StartDate = data.reqStartDate
        ? moment(new Date(data.reqStartDate)).format('YYYY-MM-DD')
        : ''
      const EndDate = data.reqEndDate ? moment(new Date(data.reqEndDate)).format('YYYY-MM-DD') : ''
      const datedata = {
        reqStartDate: StartDate,
        reqEndDate: EndDate,
      }
      const newValues = {
        ...values,
        ...data,
        ...datedata,
      }
      const reqEndDate = newValues.reqEndDate
      const reqStartDate = newValues.reqStartDate
      const filterValue = {
        commonFilterText: 'search dates',
        // commonFilterText: reqEndDat
      }
      setsearchvalue(filterValue)
      setValues(newValues)
      onFilterChange(newValues)
      dispatch(setMandateFilterPersistance(newValues))
    }
  }

  const onCommonTextChange = (e: any) => {
    const newValues = {
      ...values,
      commonFilterText: e.target.value,
    }
    setValues(newValues)
    dispatch(setMandateFilterPersistance(newValues))
    onFilterChange(newValues)
    setsearchvalue(newValues)
  }
  const getStatusList = async () => {
    try {
      const reqObj = {
        filters: [
          {
            field: 'workflowGroup',
            operator: 'equals',
            type: 'string',
            value: 'mandate_status',
          },
        ],
        page: {
          pageNo: -1,
          pageSize: 50,
        },
        sorting: {
          direction: 'ASC',
          field: 'statusOrder',
        },
      }
      const res = await getMandateStatusDropdown(reqObj)
      if (res?.status === 200) {
        setStatusList(res?.data?.result?.data.filter((item:any) => (item.id == 53 || item.id == 54 || item.id == 55)) ?? [])
      } else {
        setStatusList([])
      }
    } catch (error) {
      console.log(error)
    }
  }
  useEffect(() => {
    if(filterChecked && selectOption.length == 0){
      MandateService.getClientname({"buIds":[]})
      .then((res: any) => {
        const options = _.map(res?.data?.result?.list?.[0], (data: any) => {
          return {
            label: _.get(data, 'clientName'),
            value: _.get(data, 'id'),
          }
        })
        setSelectOption(options)
      })
      .catch((error: any) => {
        console.log(error.message)
      })
    }    
  }, [filterChecked])

  const FilterForms = () => {
    return (
      <>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <Divider />
          <Box sx={{ flexGrow: 1 }} className='filter-box-style'>
            <Grid item xs={12} md={12} sm={3} lg={12}>
              <Typography className='filter-title' style={{marginTop:"0px"}}> Filter</Typography>
            </Grid>
            <Box component={'form'} onSubmit={handleSubmit(onSubmit)}>
              <Box>
                <Grid className='filterGrid'>
                  <Grid>
                    <Controller
                      name='reqStartDate'
                      defaultValue={defaultValues.reqStartDate}
                      control={control}
                      render={({ field: { onChange, ...restField } }:any) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            label='Start Date'
                            onChange={onChange}
                            inputFormat='YYYY/MM/DD'
                            maxDate={getValues('reqEndDate')}
                            components={{
                              OpenPickerIcon: CalenderIcon,
                            }}
                            renderInput={(params) => (
                              <AppTextField
                                name='reqStartDate'
                                {...params}
                                size='small'
                                className='datePicker-text'
                              />
                            )}
                            {...restField}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid>
                    <Controller
                      name='reqEndDate'
                      defaultValue={defaultValues.reqEndDate}
                      control={control}
                      render={({ field: { onChange, ...restField } }:any) => (
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                          <DatePicker
                            inputFormat='YYYY/MM/DD'
                            label='End Date'
                            onChange={onChange}
                            minDate={getValues('reqStartDate')}
                            components={{
                              OpenPickerIcon: CalenderIcon,
                            }}
                            // className='date-picker-class'
                            // onChange={date => date && setStartdate(date)}
                            renderInput={(params) => (
                              <AppTextField
                                name='reqEndDate'
                                {...params}
                                className='datePicker-text'
                                size='small'
                                sx={{ width: '209px' }}
                                // svgcolor='#FF6E00'
                              />
                            )}
                            {...restField}
                          />
                        </LocalizationProvider>
                      )}
                    />
                  </Grid>
                  <Grid className='clientName-filter-wrap'>
                    <Controller
                      control={control}
                      name={'clientName'}
                      // defaultValue={null}
                      render={({ field: { onChange, value, ...restField } }) => (
                        <AppAutoComplete
                          value={selectOption.filter((ele: any) => ele?.value === value)?.[0]}
                          {...restField}
                          onChange={(e: any) => onChange(e?.value ?? null)}
                          id='name-email-popper'
                          name='clientName'
                          label='Client name'
                          options={selectOption}
                        />
                      )}
                    />
                  </Grid>
                  <Grid className='select-filter-mandate'>
                  <FormControl  fullWidth style={{ height: '40px',  borderRadius: '10px' }}>
                <InputLabel>Status</InputLabel>
                <Controller
                  control={control}
                  name='status'
                  render={({ field: { onChange, value } }) => (
                    <Select
                      value={value || ''}
                      label='Status'
                      // className='status-list'
                      // labelId='mandate-status-label-filter'
                      onChange={onChange}
                      style={{ height: '40px' }}
                    >
                      {statusList?.map((status: any, index: number) => {
                        const tagStyle = status?.styles ? JSON.parse(status?.styles) : {}
                        return (
                          <MenuItem value={status?.id} key={index} disabled={status?.id === 10}>
                            <span
                              className='status-tag'
                              style={
                                tagStyle
                                  ? {
                                      color: tagStyle?.color,
                                      backgroundColor: tagStyle?.background,
                                    }
                                  : {}
                              }
                            >
                              {status?.statusName}
                            </span>
                          </MenuItem>
                        )
                      })}
                      {statusList?.length === 0 && <MenuItem disabled>No data</MenuItem>}
                    </Select>
                  )}
                />
              </FormControl>
                  </Grid>
                  
                </Grid>
              </Box>
              <Box className="filter-btn-container" >
                <Grid>
                  <Stack direction='row' style={{gap:"6px"}}>                    
                    <Button
                      sx={{
                        color: '#002882',
                        textTransform: 'none',
                      }}
                      variant='text'
                      onClick={() => resetFilters()}
                      className='btn-class'
                    >
                      <RotateIcon />
                      <Typography className='reset-label'>Reset All</Typography>
                    </Button>
                    <Box className='filter-search-btn'>
                      <AppButton
                        label='Apply'
                        bordercolor='black'
                        bgcolor='black'
                        size='large'
                        type='submit'
                        className='search-bth btn-class'
                        sx={{ color: '#fff' }}
                      />
                    </Box>
                  </Stack>
                </Grid>
              </Box>
            </Box>
          </Box>
        </LocalizationProvider>
      </>
    )
  }

  const mandateListView = () => {
    setIsApprovalList(false)
  }

  const approvalListView = () => {
    setIsApprovalList(true)

  }
  useEffect(()=>{
    getStatusList()
  },[])
  return (
    <>
      <Paper className='box-style' elevation={1}>
        <Box
          display='flex'
          justifyContent='space-between'
          sx={{ alignItems: 'center' }}
          alignItems={'center'}
          className='filter-parent-box filter-parent-box-mob'
        >
          <Box className='mandate-list'>
            <div
              className={!isApprovalList ? 'mandate-list-title' : 'list-title'}
              onClick={mandateListView}
            ><div className='topline'></div>
              <ApprovalSearchIcon /> <span>Mandate Lists</span>
            </div>

            {/* <div
              className={isApprovalList ? 'approval-list-title' : 'list-title'}
              onClick={approvalListView}
            >
            <div className='topline'></div>
             <MandateSearchIcon /> <span>{'Approval Lists'}</span>
            </div> */}
          </Box>

          <Box
            display='flex'
            justifyContent='space-around'
            alignItems='center'
            className='filter-action-btns'
          >
            <MandatesSearch value={values.commonFilterText} onChange={onCommonTextChange} />
            <Divider
              orientation='vertical'
              flexItem
              variant={'middle'}
              className='filter-action-divider'
            />
            <Box>
              {(userPermissions?.mandate_module?.create?.upload?.selected && userRole != "Admin") && <AppButton
                variant='contained'
                bgcolor={PRIMARY3}
                margin={5}
                minwidth={40}
                onClick={handleClickOpen}
                tooltip='Upload Mandate'
              >
                <UploadIcon />{' '}
              </AppButton>}
              {(userRole == "Admin") && <AppButton
                variant='contained'
                bgcolor={PRIMARY3}
                margin={5}
                minwidth={40}
                onClick={handleClickOpen}
                tooltip='Upload Mandate'
              >
                <UploadIcon />{' '}
              </AppButton>}

              <AppButton
                variant='contained'
                margin={5}
                minwidth={40}
                bgcolor={filterChecked ? PRIMARY1 : TERTIARY3}
                textColor={filterChecked ? TERTIARY12 : PRIMARY3}
                onClick={handleFilter}
                tooltip='Filter Mandate'
              >
                <FunnelIcon style={{ marginRight: '5px' }} className='add-new-icon' />
                {filterChecked ? <ArrowUpIcon /> : <ArrowDownIcon />}
              </AppButton>
              {(userPermissions?.mandate_module?.create?.manual?.selected && userRole != "Admin" && sessionUserDetail.businessUnitId == businessUnitId) && <AppButton
                variant='contained'
                bgcolor={PRIMARY2}
                margin={5}
                textColor={TERTIARY12}
                width={'80px'}
                className='class-new-button'
                onClick={() => navigate('/add-mandate')}
                tooltip='Add New Mandate'
              >
                <AddNewIcon style={{ marginRight: '10px' }} /> New
              </AppButton>}
              {(userRole == "Admin") && <AppButton
                variant='contained'
                bgcolor={PRIMARY2}
                margin={5}
                textColor={TERTIARY12}
                width={'80px'}
                className='class-new-button'
                onClick={() => navigate('/add-mandate')}
                tooltip='Add New Mandate'
              >
                <AddNewIcon style={{ marginRight: '10px' }} /> New
              </AppButton>}
              {viewTypeToggle && <AppButton
                    variant='contained'
                    bgcolor={PRIMARY2}
                    margin={5}
                    textColor={TERTIARY12}
                    minwidth={40}
                    className='class-new-button'
                    onClick={(event) => openCoumnFilter(event)}
                  >
                    <MenuIcon />
                  </AppButton>
              }
              <AppButton style={{cursor: "default"}}>
                <ToggleButtonGroup
                  color='primary'
                  value={viewtype}
                  exclusive
                  onChange={(e, newAlignment: string) => {
                    setViewType(newAlignment)
                    setViewTypeToggle(newAlignment == "list" ? true : false)
                    dispatch(mandateTableColumnPersist({...mandateTableColumnPersist,viewtype:newAlignment}))
                  }}
                  aria-label='Platform'
                  className='toggle-btn mandateFilterToggleBtn'
                >
                  <ToggleButton value='grid'>
                  <Tooltip title="Mandate Grid View"><GridIcon /></Tooltip>
                  </ToggleButton>
                  <ToggleButton value='list'>
                  <Tooltip title="Mandate List View"><ListIcon /></Tooltip>
                  </ToggleButton>
                </ToggleButtonGroup>
              </AppButton>
            </Box>
          </Box>
        </Box>
        {filterChecked && (
          <Box>
            <FilterForms />
          </Box>
        )}
      </Paper>
      {open && <MandatesModal
        open={open}
        handleClose={handleClose}
        title='Upload Mandate'
        onCreateSuccess={onCreateSuccess}
      />}
      <Popover
            open={columnFilter}
            anchorEl={anchorEl}
            onClose={() => setColumnFilter(false)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            className='reportColumnFilters'
            PaperProps={{
              style: { width: `300px` },
            }}
          >
            <FormControl variant="standard">
              <OutlinedInput
                placeholder="Search Column Name"
                value={columnFilterSearch}
                onChange={(e) => setColumnFilterSearch(e.target.value)}
                startAdornment={
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                }
              />
            </FormControl>
            <Box component={'form'}>
              <Box className='selectAllCheckbox' display='flex'>
                <Checkbox
                  checked={selectAllColumnsState}
                  onChange={(e) => {
                    updateCheckedColumns(e.target.checked)
                    setSelectAllColumnsState(!selectAllColumnsState)
                    handleSubmit((data) => hitSave(data,"all"))();
                  }}
                  className='search-result-item-checkbox'
                />
                <p>Select All Columns</p>
              </Box>
              <Box className="reportColumnCheckboxes">
                {columnFields.map((columns:any, index:any) => {
                  return (
                    <Controller
                      key={index}
                      name={columns.label}
                      defaultValue={columns.checkStatus}
                      control={control}
                      render={({ field: { value, onChange, ...restField } }:any) => (
                        <Box display={columns.label.toLowerCase().includes(columnFilterSearch) ? "flex" : 'none'}>
                          <Checkbox
                            onChange={(e) => {
                              onChange(e);
                              const columnFieldsCopy = JSON.parse(JSON.stringify(columnFields))
                              columnFieldsCopy.map((item: any) => {
                                if(item?.label ==  columns.label) {
                                  item.checkStatus = e.target.checked
                                }
                              })
                              setColumnFields(columnFieldsCopy)
                              handleSubmit((data) => hitSave(data,""))();
                            }}
                            checked={value}
                            className='search-result-item-checkbox'
                          />
                          <p>{columns.label}</p>
                        </Box>
                      )}
                    />
                  )
                })}
              </Box>
            </Box>
          </Popover>
    </>
  )
}
