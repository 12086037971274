import * as React from 'react'
import { useState,useEffect } from 'react'
import {
  Box,
  styled,
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  Checkbox,
  Button,
  AvatarGroup,
  Popover
} from '@mui/material'
import { ReactComponent as ProfileIcon } from '../../../assets/icons/profile.svg'
import { ReactComponent as MandatesCalendar } from '../../../assets/icons/mandates-list-calendar.svg'
import { ReactComponent as MandatesCreateAt } from '../../../assets/images/createdByIcon.svg'
import { ReactComponent as DotIcon } from '../../../assets/icons/fulEllipse.svg'
import { ReactComponent as WarningIcon } from '../../../assets/icons/waring-icon.svg'
import { ReactComponent as DateIcon } from '../../../assets/icons/date-exclim.svg'
import { PRIMARY1 } from '../../../utils/constants'
import { ReactComponent as LoadMoreIcon } from '../../../assets/icons/load-more.svg'
import { ReactComponent as NoResultFound } from '../../../assets/icons/no-result-found.svg'
import './GridView.scss'
import { MandateDetail } from '../MandateDetail'
import { Mandatedetail } from '../../../pages/Mandates'
import { draweropen, mandateEdit,expanddrawer } from '../../../features/mandateDetail/DrawerReducer'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { convertToDisplayDate, convertToDisplayDateTime } from '../../../utils/date-utils'
import ClientLogo from '../../../assets/ClientLogo.png'
import defaultClientLogo from '../../../assets/images/defaultUserIcon.svg'
import Tooltip from '@mui/material/Tooltip'
import { Loading } from '../../../pages/Loading'
import draftIcon from '../../../assets/icons/draftIcon.svg'
import { useNavigate } from 'react-router-dom'
import IconButton from '@mui/material/IconButton'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { AssignModal } from '../AssignModel/AssignModel'
import { divide } from 'lodash'
import GroupStrick from '../../../assets/icons/Groupstrick.svg'
import { ReactComponent as AssignIcon } from '../../../assets/icons/assign.svg'
import { ReactComponent as ArchiveIcon } from '../../../assets/icons/archive.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-icon.svg'
import { ReactComponent as DeactivateIcon } from '../../../assets/icons/deactivate.svg'
import { ReactComponent as CloneIcon } from '../../../assets/icons/clone.svg'
import { ReactComponent as PostJobsIcon } from '../../../assets/icons/postjobs.svg'
import { getLoggedInUserName } from '../../../utils/user-utils';
import { ReactComponent as MandateLocationCountIcon } from '../../../assets/icons/positionlabel.svg'
import {resetNotification} from "../../../features/notification/notificationReducer"
import { MandateService, getJobPostData } from '../../../services/MandateService'
import xphenoRocket from '../../../assets/icons/xphenoRocket.svg'
import { toast } from 'react-toastify'
import { ReactComponent as ShareMandateIcons } from '../../../assets/icons/shareMandateIcons.svg';


export const DGrid = styled('div')({
  display: 'grid',
  columnGap: '15px',
  rowGap: '15px',
  // gridTemplateColumns: 'repeat(1, 1fr)',
  gridTemplateColumns: 'repeat(auto-fill, minmax(210px, 1fr))',
  justifyItems: 'center',
  // '@media (min-width: 600px)': {
  //   gridTemplateColumns: 'repeat(1, 1fr)',
  // },

  // '@media (min-width: 780px)': {
  //   gridTemplateColumns: 'repeat(2, 1fr)',
  // },
  // '@media (min-width: 992px)': {
  //   gridTemplateColumns: 'repeat(3, 1fr)',
  // },

  // '@media (min-width: 1280px)': {
  //   gridTemplateColumns: 'repeat(3, 1fr)',
  // },
  // '@media (min-width: 1360px)': {
  //   gridTemplateColumns: 'repeat(4, 1fr)',
  // }
})

export const DGridCol = styled('div')({})

interface Props {
  data: Mandatedetail[]
  drawerdata: Partial<Mandatedetail>
  handlePage?: () => void
  handledetial: (id: number, notification:any) => void
  totalCount: number
  noParse: boolean
  searchValue: any
  parsemsg:string
  isLoadingNextPage: boolean;
  isApprovalList?:boolean;
  setIsApprovalList?:any;
  horizontalMemberList: any[];
  verticalMemberList: any[];
  loadFirstPage: any;
  openNotification:any
}

export const GridView: React.FC<Props> = ({
  data,
  totalCount,
  handlePage,
  handledetial,
  drawerdata,
  noParse,
  searchValue,
  parsemsg,
  isLoadingNextPage,
  isApprovalList,
  horizontalMemberList,
  verticalMemberList,
  loadFirstPage,
  openNotification,
  setIsApprovalList
}) => {
  const dispatch = useAppDispatch()
  const [drawerid, setdrawerid] = useState<number>(0)
  // const [draweropen, setDrawerOpen] = useState(false)
  const [mandateIds, setMandateIds] = useState<number[]>([])
  const isRecruiterRole = useAppSelector((state) => state.mandatedrawer.isRecruiter)
  const navigate = useNavigate()
  const handledrawerdata = (id: number, draft:any,type?:string) => {
    const obj = { isDraft: draft,expand:type==="navigation"?true:false }
    setdrawerid(id)
    handledetial(id,null)
    dispatch(draweropen(obj))
    setTimeout(()=>{
      navigate(`/mandates?id=${id}`,{ state: { "columnFields": {},"viewType": "grid" }})
    },200)
    
  }
  const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
  const { userId, userRole } = useAppSelector((state) => state?.UserDetail)
  dispatch(mandateEdit({ isEdit: false }))
  const [openModel, setOpenModel] = useState(false)
  const getCollaboratorPermission = (mandateData:any, permissionType:string) => {
      const selectedCollPermission =  mandateData?.collaborators.filter((item:any) => item.collobatorId == userId)
      return (selectedCollPermission.length > 0 && permissionType == "Edit" && userRole != "Admin") ? JSON.parse(selectedCollPermission[0]?.permissions).includes(23) : true;
  }
  const options = [
    // { id: 1, label: 'Assign', icon: <AssignIcon /> },
    { id: 2, label: 'Edit', icon: <EditIcon />,disable: (userPermissions?.mandate_module?.edit?.edit?.selected && userRole != "Admin") ? true : userRole == "Admin" ? true : false },
    // { id: 3, label: 'Delete', icon: <TrashIcon /> },
    // { id: 4, label: 'Deactivate', icon: <DeactivateIcon /> },
    // { id: 5, label: 'Archive', icon: <ArchiveIcon /> },
    { id: 6, label: 'Clone', icon: <CloneIcon />,disable: (userPermissions?.mandate_module?.create?.clone?.selected && userRole != "Admin") ? true : userRole == "Admin" ? true : false },
    { id: 7, label: 'Post Jobs', icon: <PostJobsIcon />,disable: (userPermissions?.mandate_module?.list?.postjobs?.selected && userRole != "Admin") ? true : userRole == "Admin" ? true : false },
    { id: 8, label: 'Create Task', icon: <PostJobsIcon />,disable: true},
  ]
  const [assigneeList, setAssigneeList] = useState<{selectedIds:string[], isHorizontalAssignee:string|null}>({selectedIds:[], isHorizontalAssignee: null})
  const listStatusData = useAppSelector((state) => state.mandatedrawer.isApproval)
  const isApprovalStatus = listStatusData === null ? false : listStatusData

  useEffect(()=>{
  if(openNotification && openNotification?.mandateOpen===true){
    setTimeout(()=>{
      handledrawerdata(openNotification?.mandateId,false,'navigation')
    },500)
  }
  },[openNotification])
  const assignAccess = (data:any)=>{
    if(data?.approvalStatus ==2) return false
      if(data?.approvedBy){
        return data?.approvedBy == userId
      }
      if(data?.approvers){
        return JSON.parse(data?.approvers).includes(userId)
      }
      return data?.createdBy == userId
  }
  
  const getUserTooltipName = (name:any) => {
    let tooltipName = ""
    try{
      tooltipName = JSON.parse(name)
    }catch(error){
      // console.log(error)
      tooltipName = name
    }
    return tooltipName
  }
  

  const ITEM_HEIGHT = 52
  const handleCheckboxClick = (e: any, id: any) => {
    e.stopPropagation()
    e.target.checked
      ? setMandateIds([...mandateIds, id])
      : setMandateIds(mandateIds.filter((data) => data != id))
  }

  const disableScroll = () => {
    document.body.style.overflow = 'hidden';
    document.documentElement.style.overflow = 'hidden';
  };

  const enableScroll = () => {
    document.body.style.overflow = 'auto';
    document.documentElement.style.overflow = 'auto';
  };

  function LongMenu({id, isUserEditable,innerData, careerId, mandateCode}:any) {
    const [anchorEl, setAnchorEl] = React.useState(null)
    const open = Boolean(anchorEl)
    const handleClick = (event: any) => {
      disableScroll()
      event.stopPropagation()
      setAnchorEl(event.currentTarget)
    }
    const handleClose = (event: any) => {
      event.stopPropagation()
      enableScroll()
      setAnchorEl(null)
    }
    const handleClosePopup = (event: any, option: any) => {
      event.stopPropagation()
      enableScroll()
      setAnchorEl(null)
      if(option.id === 1){
        const list:any = data?.find((item:any) => item?.id === id)
        const obj = {
          selectedIds : list?.assignTo?.map((user:any)=> user?.assigneeid),
          isHorizontalAssignee : list?.isHorizontalAssignee === 1 ? "horizontal" : "vertical"
        }
        setAssigneeList(obj)
        setMandateIds([id])
        setOpenModel(option.id === 1 ? true : false)
      }
      if(option.id === 2){
        navigate(`/edit-mandate/${id}`)
      }
      if(option?.id === 6){
        navigate(`/clone-mandate/${id}`)
      }
      if(option?.id === 7){
        if(careerId != undefined){
          getJobPostData(careerId)
          .then((res) => {
            const mandateDetails = res[0];
            mandateDetails["mandateCode"] = mandateCode
            navigate(`/post-jobs/${id}`,{state:{mandateView: mandateDetails,type:"update"}})
          })
          .catch((error) => {
            console.log('error', error.message)
          })  
        }else{
          MandateService.getMandateDetail(id)
          .then((res) => {
            if(res?.data?.error?.errorCode == "ERR119"){
              toast.error(res?.data?.error?.message)  
            }else{
              navigate(`/post-jobs/${id}`,{state:{mandateView: res?.data?.result?.data,type:"create"}})
            }
          })
          .catch((error) => {
            console.log('error', error.message)
          })  
        }              
      }
      if(option?.id === 8){
        navigate(`/my-tasks`,{state:{mandateId:id}})
      }
    }
    return (
      <div>
        <IconButton
          aria-label='more'
          id='long-button'
          aria-controls={open ? 'long-menu' : undefined}
          aria-expanded={open ? 'true' : undefined}
          aria-haspopup='true'
          onClick={handleClick}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id='long-menu'
          MenuListProps={{
            'aria-labelledby': 'long-button',
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={(e) => handleClose(e)}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '12ch',
              overflow: 'hidden',
              borderRadius: '10px',
              boxShadow: "none",
              border: "1px solid #eef0f2"
            },
          }}
        >
          {options.map((optionItem,index) => {
            if(optionItem.disable && getCollaboratorPermission(innerData, optionItem.label)){
              return (
                <MenuItem
                  className='menuuuu-item'
                  key={index}
                  selected={optionItem==null}
                  disabled={optionItem.id == 2 && innerData.status == 55}
                  onClick={(e) => handleClosePopup(e, optionItem)}
                ><Box sx={{display:'flex',gap:"10px", flexDirection:'row',justifyContent:'center', alignItems:'center'}}>{optionItem.icon} <span>{(careerId != undefined && optionItem.id == 7) ? "Update Jobs" : optionItem.label}</span></Box>
                </MenuItem>
              )
            }
            }
          )}
        </Menu>
      </div>
    )
  }

  const GridItems = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [isPopOpen, setIsPopOpen] = useState<boolean>(false);
    const [assigneeListPopup, setAssigneeListPopup] = useState<any>()

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    const handleAvatarClick = (event: React.MouseEvent<HTMLButtonElement>, assignList:any) => {
      setAnchorEl(event.currentTarget);
      setAssigneeListPopup(assignList);    
      setIsPopOpen(true)
    };
  
    const handleClose = () => {
      setAnchorEl(null);
      setIsPopOpen(false)
    };
    return (
      <>
        {data.map((data: any) => {
          const assignList:any = data?.assignTo ? data?.assignTo : []
          const isUserEditable = getLoggedInUserName() === data?.createdByName;
          const tagStyle = data?.styles ? JSON.parse(data?.styles) : {}

          return (
          <DGridCol
            className='mandate-grid'
            style={{width: "100%"}}
            key={data.id}
            onClick={(e) => {
              const id = data.id
              const draft = data?.status === 'draft'
              if(userPermissions?.mandate_module?.list?.view?.selected && userRole != "Admin"){
                handledrawerdata(id, draft)
              }else if(userRole == "Admin") {
                handledrawerdata(id, draft)
              }
            }}
          >
            <Card
              className={`mandates-card ${data?.approvalStatus == 2 && 'rejected-card'} ${
                data?.status === 'draft' && 'draft-card'
              }`}
            >
              {/* <Box onClick={() => {
                    handledrawerOpen(data)
                  }}></Box> */}
              <CardHeader
                className='mandates-grid-header'
                avatar={
                  <div className='image-container'>
                    <Avatar
                      sx={{width: 30, height: 30 }}
                      className={(!isApprovalStatus && isUserEditable) ? 'mandate-image' : ""}
                      src={defaultClientLogo}
                    ></Avatar>
                  </div>
                }
                title={
                  <Tooltip title={data.title}>
                    <Typography gutterBottom noWrap className='mandates-grid-header-title'>
                      {data.title}
                    </Typography>
                  </Tooltip>
                }
                subheader={
                  <div className='mandates-sub-header'>
                    <Tooltip title={data.mandateId}>
                      <Typography noWrap className='mandates-grid-header-subtitle'>
                        {data.mandateId}
                      </Typography>
                    </Tooltip>
                    {data.xpCareerId != undefined ? <a onClick={(event:any) => {event.stopPropagation()}} href={`https://www.xpheno.com/JobBoard/Jobs/Careerpage/JobDescription/${data.xpCareerId}`} target="_blank" rel="noreferrer"><img src={xphenoRocket} /></a> : ""}
                  </div>
                }
                action={
                  <div className='mandate-action-header'>
                    {data.status===10 && (
                      <div style={{width:"40px", height:"40px", display:'flex', alignItems:"center", justifyContent:'center'}} >
                        <Tooltip title={'Draft'}>
                          <img src={draftIcon} alt='draft' />
                        </Tooltip>
                      </div>
                    )}
                    {(![3,5,10].includes(data.status) && options.filter((item:any) => item.disable == true).length > 0 && userRole != "Admin") && <LongMenu id ={data.id} careerId={data.xpCareerId} mandateCode={data.mandateId} isUserEditable={isUserEditable} innerData={data}/>}
                    {(![3,5,10].includes(data.status) && userRole == "Admin") && <LongMenu id ={data.id} careerId={data.xpCareerId} mandateCode={data.mandateId} isUserEditable={isUserEditable} innerData={data}/>}
                  </div>
                }
              />
              <Divider variant='middle' />
              <CardContent className='mandates-card-content'>
                <List className='mandats-list'>
                  <ListItem disablePadding>
                    <ListItemIcon className='mandate-card-list-icon'>
                    <Tooltip title="Client Name" placement="top-start">
                      <ProfileIcon />
                      </Tooltip>
                    </ListItemIcon>
                    <Tooltip title={data.clientName}>
                      <ListItemText primary={data.clientName} />
                    </Tooltip>
                  </ListItem>
                  <ListItem disablePadding className='mandateCardCreateAt'>
                    <ListItemIcon className='mandate-card-list-icon'>
                    <Tooltip title="Created By" placement="top-start">
                      <MandatesCreateAt />
                      </Tooltip>
                    </ListItemIcon>
                    <Box>
                      <ListItemText primary={data.createdByName} />
                      <ListItemText primary={convertToDisplayDateTime(data.createdAt)} />                      
                    </Box>
                    
                  </ListItem>
                  <ListItem disablePadding>
                    <ListItemIcon className='mandate-card-list-icon'>
                    <Tooltip title="Job Orders" placement="top-start">
                      <MandateLocationCountIcon />
                      </Tooltip>
                    </ListItemIcon>
                    <ListItemText primary={`${data?.mandateLocationCount ?? 0} Job Orders`} />
                  </ListItem>
                  <ListItem
                    disablePadding
                    style={{ flexDirection: 'column', alignItems: 'flex-start' }}
                  >
                    <ListItemIcon className='mandate-card-list-icon avatar-list-items'>
                      {assignList?.length >0 && (
                        <AvatarGroup className='group-avatar' max={4} onClick={(e:any) => {e.stopPropagation();handleAvatarClick(e, assignList);}}>
                          {assignList?.map((user:any, index:number)=> (
                            <Tooltip 
                              key={index} 
                              title={getUserTooltipName(user?.assigneeName)} 
                              arrow 
                              componentsProps={{
                                tooltip: {
                                  sx: {
                                    bgcolor: '#000',
                                    '& .MuiTooltip-arrow': {
                                      color: '#000',
                                    },
                                  },
                                },
                              }}
                            >
                              <Avatar className={assignList?.length >1 ? 'assignee-avatar' : ""} key={index} alt={user} src={""} sx={{ width: 30, height: 30 }}/>
                            </Tooltip>
                          ))}
                      </AvatarGroup>)}
                      {(assignList?.length === 0) && <Avatar alt='no-grou' src={GroupStrick} sx={{ width: 30, height: 30 }} />}
                    </ListItemIcon>
                    <Box className="gridCardAssignInfo">
                      <ListItemText primary={assignList?.length === 0 ? "No Assignees" : `${assignList?.length} Assignee${assignList?.length>0 && "s"}`} style={{ color: '#4E4E4E' }} />
                      {data?.collaborators.filter((item:any) => item.collobatorId == userId).length > 0 && <Tooltip title={"Collaborators"}><p><ShareMandateIcons /> <span>{data?.noOfCollaborators}</span></p></Tooltip>}
                    </Box>
                    
                  </ListItem>
                  <Divider variant='fullWidth' />
                  <ListItem
                    disablePadding
                    style={{
                      flexDirection: 'row',
                      marginTop: '10px',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    {data?.approvalStatus == 2 && (
                      <Box style={{ display:'flex', flexDirection: 'row', alignItems: 'center', gap: '5px' }}>
                        <WarningIcon className='reject-icon' />
                        <span style={{ color: 'red', fontSize: '14px' }}>Rejected</span>
                        <DotIcon/>
                      </Box>
                    )}
                    {data?.approvalStatus != 2 && (
                      <>
                      <Tooltip title={"Due Date"} placement="top-start">
                      <DateIcon />
                      </Tooltip>
                      </>
                    )
                    }
                    <ListItemText className='date-font' primary={data?.requestEndDate != null ? convertToDisplayDate(data?.requestEndDate) : "Due date not found"} />
                    {!(data.status==10 || !data.status) && !isApprovalStatus && <div 
                      className="status-tag"
                      style={{
                        color: tagStyle?.color,
                        backgroundColor: tagStyle?.background
                      }} 
                    >
                      <Tooltip title={data?.statusName}>
                        {data?.statusName}
                      </Tooltip>
                    </div>}
                  </ListItem>
                </List>
              </CardContent>
              {/* <Divider variant='middle' /> */}
            </Card>
          </DGridCol>
        )})}
        {isPopOpen && <Popover
        id={id}
        open={isPopOpen}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        className='candidate-allocation-avt-popup'
      >
        <Box className="cand-popup-container" >
          {assigneeListPopup?.slice(3, (assigneeListPopup?.length))?.map((candidate:any, index:number) => (
            <Box key={index} className="cand-wrap" >
              <Box>
                <Avatar sx={{ width: 30, height: 30 }}/>
              </Box>
              <Box>
                <div className='cand-name'>{candidate?.assigneeName}</div>
                <div className='cand-role'></div>
              </Box>
            </Box>
          ))}
        </Box>
      </Popover>}
      </>
    )
  }

  const LoadMore = ({ isLoadingNextPage }: { isLoadingNextPage: boolean }) => {
    if (isLoadingNextPage) {
      return (
        <Box className='load-more-section-loading-container'>
          <Loading />
        </Box>
      )
    }
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        className='load-more-container'
        onClick={handlePage}
      >
        <Typography component='span' className='load-more' sx={{ color: `${PRIMARY1}` }}>
          Load More <LoadMoreIcon className='load-more-icon' />
        </Typography>
      </Box>
    )
  }

  return (
    <Box sx={{ pt: 2 }} style={{ height: '70%' }}>
      <DGrid>
        <GridItems />
      </DGrid>
      {data.length === 0 && (
        <div className='no-data-container' style={{ display: 'flex', height: '100%' }}>
          <Grid container>
            <Grid sm={12} className='no-result-found' alignItems='center' justifyContent='center'>
              <Typography>
                <NoResultFound />
              </Typography>
              <Typography>
                {searchValue ? `No results found for “${searchValue}”` : 'No data found'}
              </Typography>
            </Grid>
          </Grid>
        </div>
      )}
      {data.length < totalCount && <LoadMore isLoadingNextPage={isLoadingNextPage} />}
      <div>
        <MandateDetail id={drawerid} isApprovalList={isApprovalList} drawerdata={drawerdata} noParse={noParse} parsemsg={parsemsg} horizontalMemberList={horizontalMemberList} verticalMemberList={verticalMemberList} handledetial={handledetial} loadFirstPage={loadFirstPage} />
      </div>
      {openModel && <AssignModal open={openModel} onClose={() => setOpenModel(false)} mandateIds={mandateIds} horizontalMemberList={horizontalMemberList} verticalMemberList={verticalMemberList} assigneeList={assigneeList}  />}
    </Box>
  )
}