import { useState, useEffect, useMemo } from 'react'
import './QueryBuilder.scss'
import { Box, Typography, IconButton, Tooltip } from '@mui/material'
import { ReactComponent as AddNewIcon } from '../../../assets/icons/add-new.svg'
import { ReactComponent as BackIcon } from '../../../assets/icons/backarrowicon.svg'
import { ReactComponent as MenuIcon1 } from '../../../assets/icons/searchQueryBuilderIconNew.svg'
import { ReactComponent as LinkPath } from '../../../assets/icons/linkpath.svg'
import { ReactComponent as ProfileIcon } from '../../../assets/icons/profileIcon.svg'
import { ReactComponent as MenuIcon2 } from '../../../assets/icons/candidate/candidate-sidebar-menu2.svg'
import { ReactComponent as MenuIcon3 } from '../../../assets/icons/candidate/candidate-sidebar-menu3.svg'
import { ReactComponent as MenuIcon4 } from '../../../assets/icons/candidate/candidate-sidebar-menu4.svg'
import { PRIMARY2, TERTIARY12 } from '../../../utils/constants'
import { AppButton } from '../../AppComponents/AppButton'
import ListQueryBuilder from './ListQueryBuilder'
import AddQueryBuilder from './AddQueryBuilder'
import _ from 'lodash'
import {  MandateService, artemisProfiles, listSearchQuery } from '../../../services/MandateService'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ViewQueryBuilder } from './ViewQueryBuilder'
import { ReactComponent as MenuIcon5 } from '../../../assets/icons/human-resources.svg'
import SourcedProfiles from './SidebarTabs/SourcedProfiles'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import { setPersistFilter } from '../../../features/filters/PersistFilter'
import CloseIcon from '../../../assets/icons/close.svg';
import { toast } from 'react-toastify'

// For candidate tab filter persist
const candidateFilterVal:any = {
  mandateId: { value: '', label: '' },
  keyword: '',
  matchCriteria: '',
  searchQueryId: '',
  confidenceScore: [0, 100],
  booleanSearch: false,
  tags: [],

  profileType: 'All',
  location: '',
  preferredLocation: '',
  primarySkills: [],
  secondarySkills: [],
  education: '',
  company: '',
  designation: '',
  salaryRange: [0, 100],
  experience: [0, 30],
  lastActivity: -1,
}


export const QueryBuilder = ({ data, posIndex, setPosIndex,setManDetToggle }: any) => {
  const [isAddQuery, setIsAddQuery] = useState<boolean>(false)
  const [queryLists, setQueryList] = useState<any>([])
  const [editData, setEditData] = useState<any>({})
  const [isEdit, setIsEdit] = useState<boolean>(false)
  const [isClone, setIsClone] = useState<boolean>(false)
  const search = useLocation()?.search
  const mId:any = new URLSearchParams(search).get("id");
  const tabSearch:any = new URLSearchParams(search).get("tab");
  const [active, setActive] = useState(-1)
  const[ profileCount, setProfileCount]= useState(0)
  const[ profileCountStatus, setProfileCountStatus]= useState(false)
  const [loading, setLoading]= useState(true)
  const mandateId = data?.id ? data?.id : mId
  const [showQueryBuilderView, setShowQueryBuilderView]= useState(false)
  const [currentQueryBuilder,setCurrentQueryBuilder] = useState<any>({})
  const navigate= useNavigate()
  const isApprovalList = useSelector((state:any)=>state?.mandatedrawer?.isApproval)
  const persistFilter = useSelector((state:any)=> state.PersistFilterReducer.persistFilter)
  const [queryNameLists, setQueryListNames] = useState<any[]>([])
  const dispatch = useAppDispatch()
  const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
  const { userRole, roleId } = useAppSelector((state) => state.UserDetail)

  const allProfiles = async() => { 
    setLoading(true)
    try{
      const res = await artemisProfiles(data)
      setProfileCount(res?.data?.data?.length ?? 0)
      setProfileCountStatus(true)
    }catch{
      setProfileCount(0)
      setProfileCountStatus(false)
    }
    setLoading(false)
  };
  

  useEffect(()=>{
    if(data?.id){
      allProfiles()
      getSearchQueries()
    }
  },[data])

  const getSearchQueries = async () => {
    const response = await listSearchQuery(mandateId)
    if (response?.status === 200) {
      if (response?.data?.result?.list.length > 0) {
        setQueryList(response?.data?.result?.list || [])
      } else {
        setQueryList([])
      }
    }
  }

  const handleListQueryNames = (id?:any) => {
    let dataList:any = []
    if(id){
      dataList = queryLists.filter((data:any) => data?.id !== id).map((list:any) => list?.queryName?.trim())
    }else{
      dataList = queryLists?.map((list:any) => list?.queryName?.trim())
    }
    setQueryListNames(dataList)
  }

  const handleEdit = (id: any) => {
    const editData = queryLists?.filter((val: any) => val?.id === id)
    handleListQueryNames(id)
    
    setEditData(editData[0])
    setIsEdit(true)
    setIsClone(false)
    setShowQueryBuilderView(false)
    setIsAddQuery(true)
  }

  const handleClone =(id: any) => {
    const editData = queryLists?.filter((val: any) => val?.id === id)
    handleListQueryNames()
    
    setEditData(editData[0])
    setIsEdit(true)
    setShowQueryBuilderView(false)
    setIsClone(true)
    setIsAddQuery(true)
  }

  const handleBuildQueryView =(id: any) =>{
    const ViewData = queryLists?.filter((val: any) => val?.id === id)
    setShowQueryBuilderView(true)
    setCurrentQueryBuilder(ViewData[0])
  }

  useEffect(()=> {
    if(queryNameLists?.length === 0){
      handleListQueryNames()
    }
  },[queryLists])

  const handleViewResult = async () =>{
    const setSearch = `${search}&tab=insight`
    navigate(`/mandates${setSearch}`)

    await MandateService.getSearchMandates(mandateId).then(res => {
      let filterRes;
      if(res?.status === 200){
        if(res?.data?.result?.list?.length){
          const datares = res?.data?.result?.list;
          filterRes =  datares.filter((fil:any)=>fil?.id===mandateId)
        }
        if(filterRes?.length > 0 ){
          const data = filterRes[0]
          const mandateObj = {
            label: _.get(data, 'title') + ' - ' + String(_.get(data, 'id')) + _.get(data?.mandateId),
            value: _.get(data, 'id'),
            title: _.get(data, 'title'),
            clientName: _.get(data, 'clientName'),
            createdByName: _.get(data, 'createdByName'),
            mandateId: _.get(data, 'mandateId'),
          }
          const filterData = {
            ...candidateFilterVal, 
            mandateId : {value: _.get(data, 'id'), label: _.get(data, 'title')+' - '+_.get(data, 'mandateId'),}
          }
  
          const obj = {
            selectedTab: 3,
            filterVal : filterData,
            selectMandate : mandateObj,
            module:"artemis"
          }
          dispatch(setPersistFilter({...persistFilter,persistMandate:{...persistFilter?.persistMandate,[`manTab3`]:mandateObj},
          persistTab:3}))
          setTimeout(()=>{
            navigate('/candidate-listing')
          },100)
        }
      }
    })
  }

  useEffect(()=>{
    if(tabSearch){
      if(tabSearch === "insight" && active !== 1){
        const tabVal = search.split("&")
        navigate(`/mandates${tabVal[0]}`)
        setActive(1)
      }
    }
  },[tabSearch])

  const renderSidebarMenus = () => {
    const sidebarMenus = [
      {
        icon: MenuIcon1,
        title: "Build search query",
        // disable: (!userPermissions?.mandate_module?.view?.build_search_query?.selected && userRole != "Admin") ? true : (userRole == "Admin") ? false : false
        disable: true
      },
      {
        icon: MenuIcon2,
        title: "Insights",
        disable: (!userPermissions?.mandate_module?.view?.insights?.selected && userRole != "Admin") ? true : (userRole == "Admin") ? false : false
      },
      {
        icon: MenuIcon3,
        title: "Tab 3",
        disable: true
      },
      {
        icon: MenuIcon4,
        title: "Tab 4",
        disable: true
      },
      {
        icon: MenuIcon5,
        title: "Sourced Profiles",
        disable: (!userPermissions?.mandate_module?.view?.sourced_profiles?.selected && userRole != "Admin") ? true : (userRole == "Admin") ? false : false
      },
    ]
    const sidebarMenuHtml = _.map(sidebarMenus, (value: any, index: number) => {
      const Icon: any = _.get(value, `icon`)
      return (
          <Box key={index} className={`${value.disable ? "disableSideMenu" : "" }`}>
            <Tooltip title={value?.title} placement="right-start">
            <Box onClick={()=>{setActive( active == index ? -1 : index);setManDetToggle(active == index ? true : false)}}>{Icon && <Icon className={index == active ?'tabButton-color' :'tabButton-inactive'}/>}</Box>
            </Tooltip>
          </Box>
      )
    })
    return <>{sidebarMenuHtml}</>
  }

  const hideRPAbtn = useMemo(() => {
    return queryLists.some((list: any) => list?.status?.statusName === "inprogress")
  }, [queryLists])

const Insights= (props:any) => {
  const {count,loading}= props
  return (
    <div>
      <Typography className='insigts-text'>
        Artemis AI assistant has found below matches from local database. Click to view
      </Typography>
      <ProfileIcon style={{margin:'10px 0px 0px 10px' }}/>
      <Typography className='insigts-text'>Profiles in Artemis</Typography>
      <div className='total-proflile-wrap'>
      <span className='total-profile'>
        {(!profileCountStatus) || loading ? "Loading..." :count}
      </span>
      <div>
      {(!loading && profileCountStatus) && <LinkPath  className='linkPathIcon' onClick={()=>handleViewResult()}/>}
      </div>
      </div>
    </div>
  )
}


  return (
    <Box className='query-builder-wrapper'>
      <Box style={{ width: '90%' }}>
        <Box className='header-container'>
          {active === 0 && (
            <Box className='builder-header list-divider'>
              {(showQueryBuilderView || isAddQuery) && (
                <div className='goback-button'>
                  <IconButton
                    onClick={() => {setIsAddQuery(false)
                      setShowQueryBuilderView(false)
                    }}
                    aria-label='back'
                    disableRipple
                    disableFocusRipple
                  >
                    <BackIcon />
                  </IconButton>
                </div>
              )}
              <Typography>{showQueryBuilderView ? `${currentQueryBuilder?.queryName}`: `${
                !isAddQuery ? 'Build search query' : `${isEdit && !isClone ? 'Edit' : 'New'} build search query`
              }`}</Typography>
              <AppButton
                disabled = {data.status == 10 || isApprovalList}
                variant='contained'
                bgcolor={PRIMARY2}
                margin={5}
                textColor={TERTIARY12}
                width={'80px'}
                className={`${(isAddQuery ||showQueryBuilderView) ? 'btn-hidden' : ''}`}
                onClick={() => {
                  setIsAddQuery(!isAddQuery)
                  setIsEdit(false)
                }}
              >
                <AddNewIcon style={{ marginRight: '10px' }} /> New
              </AppButton>
            </Box>
          )}
          
          {active === 1 && (
            <Box>
              <Typography className='sidePanel-tab-heading'>Insights</Typography>
            </Box>
          )}
          {active === 2 && (
            <Box>
              <Typography className='sidePanel-tab-heading'>Tab 3 </Typography>
            </Box>
          )}
          {active === 3 && (
            <Box>
              <Typography className='sidePanel-tab-heading'>Tab 4</Typography>
            </Box>
          )}
          {active === 4 && (
            <Box className="header-title-row">
              <Typography className='sidePanel-tab-heading'>Sourced Profiles</Typography>
              <img src={CloseIcon} className='close-icon-cls'/>
            </Box>
          )}
        </Box>
        {
          showQueryBuilderView && <ViewQueryBuilder handleEdit={handleEdit} handleClone={handleClone}setIsAddQuery={setIsAddQuery}setIsClone={setIsClone} setIsEdit={setIsEdit}setShowQueryBuilderView={setShowQueryBuilderView} hideRPAbtn={hideRPAbtn} data={currentQueryBuilder} getSearchQueries={getSearchQueries}/>
          }
        {!showQueryBuilderView && active == 0 &&
          (!isAddQuery ? (
            <ListQueryBuilder
              id={data?.id}
              queryLists={queryLists}
              handleEdit={handleEdit}
              handleBuildQueryView={handleBuildQueryView}
              getSearchQueries={getSearchQueries}
              hideRPAbtn={hideRPAbtn}
            />
          ) : (
            <AddQueryBuilder
              mandateData={data}
              setIsAddQuery={setIsAddQuery}
              editData={editData}
              isClone={isClone}
              isEdit={isEdit}
              getSearchQueries={getSearchQueries}
              queryNameLists={queryNameLists}
            />
          ))}
        {active == 1 && <Insights loading={loading} count={profileCount}/>}
        {active == 4 && <SourcedProfiles mandateData={data} posIndex={posIndex} setPosIndex={setPosIndex} />}
      </Box>
      <Box className='side-nav-bar'>{renderSidebarMenus()}</Box>
    </Box>
  )
}
