import React, { useEffect, useState } from 'react';
import { BarDatum } from '@nivo/bar';
import { Theme, useTheme } from '@mui/material/styles';
import { Select, MenuItem, FormControl, InputLabel, Paper, Box, Typography, useMediaQuery, FormLabel, Checkbox, FormGroup, FormControlLabel, Autocomplete, TextField, OutlinedInput, Tooltip, Popper } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Controller, useForm } from 'react-hook-form';
import NumberInputFieldIncDec from '../../../Common/CustomNumberInputField/NumberInputFieldIncDec';
import ResponsiveBarChart from '../../../Common/CustomBarChat/ResponsiveBarChart';
import { ReportkManagementService } from '../../../../services/ReportListService';
import LaunchIcon from '@mui/icons-material/Launch';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import { getCandidateActivityList, getCandidateActivityMandateList } from '../../../../services/CandidateService';
import "./ClientCandidateactivity.scss"
import { ReactComponent as DashboardLaunch } from '../../../../assets/icons/dashboardLaunch.svg'
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg';
import _ from 'lodash';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { setDashboardWidgetPresistance } from '../../../../features/filters/PersistFilter';
import ClientActivityResponsiveBarChart from './ClientActivityResponsiveBarChart';

interface DataItem extends BarDatum {
    id: string;
    value: number;
    client: string;
    activity: number;
    activityType: string;
}

interface CheckboxItem {
    label: string;
    name: string;
}

interface ClientCandidateActivityProps {
    title: string;
    data: DataItem[];
    filterOptions: string[];
    checkboxItems: CheckboxItem[];
    barcolor:string;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        // width: 250,
      },
    },
  };

// const uniqueActivityTypes = Array.from(new Set(data.map(item => item.activityType)));
// const filterOptions = ['All', ...uniqueActivityTypes];
// const checkboxItems = uniqueActivityTypes.map(type => ({ label: type, name: type.replace(/\s+/g, '').toLowerCase() }));
const checkboxItems = ["Shortlisted Against Jo","Shortlisted L2 Review","Shortlisted Client Submission","Submitted" ,"Client Shortlist","ccm1","ccm2","offered","joined","Post Joining Drop"]
const checkboxItemsColors:any = {"Shortlisted Against Jo":"#5EB0AB","Shortlisted L2 Review":"#9578BA","Shortlisted Client Submission":"#E79C45","Submitted":"#F46464","Client Shortlist":"#6474EE","ccm1":"#84AA72","ccm2":"#8D8D8D","offered":"#469BD9","joined":"#DA6CD3","Post Joining Drop":"#31D8C2"}
const truncateString = (str: string, maxLength: number) => {
    if (str.length > maxLength) {
        return `${str.substring(0, maxLength)}...`;
    }
    return str;
};

const truncateData = (data: DataItem[], maxLength: number): DataItem[] => {
    return data.map(item => ({
        ...item,
        client: truncateString(item.client, maxLength),
    }));
};

const ClientCandidateactivity: React.FC<any> = ({reportSubmissionAPIData,DashboardFilterDatas,reportSubmitdatalabels,userRoleObjectSelected,userDependendObj,clientCandidateActivityObj}:any) => {

    const methods = useForm<DataItem>({mode: "onChange"});
    const dispatch = useAppDispatch()    
    const dashboardWidgetReduxStore: any = useAppSelector((state) => state.PersistFilterReducer.dashboardWidgetPersistance)
    const {
      control,
      handleSubmit,
      getValues,
      trigger,
      formState:{isValid,errors},
      reset,
      clearErrors 
    } = methods
    const {userRole,userId} = useAppSelector((state) => state.UserDetail)
    const [initialLoad, setInitialLoad] = useState(0)
    const navigate = useNavigate();
    const [candidateActivityData, setCandidateActivityData] = useState<any[]>([]);
    const [selectedCheckedKeys,setSelectedCheckedKeys] = useState([])
    const [selectedCheckboxes, setSelectedCheckboxes] = useState<any[]>(["Shortlisted Against Jo","Shortlisted L2 Review","Shortlisted Client Submission","Submitted", "Client Shortlist","ccm1","ccm2","offered","joined","Post Joining Drop"]);
    const [selectedCheckboxesCopy, setSelectedCheckboxesCopy] = useState<any[]>(["Shortlisted Against Jo","Shortlisted L2 Review","Shortlisted Client Submission","Submitted", "Client Shortlist","ccm1","ccm2","offered","joined","Post Joining Drop"]);
    const [selectAll, setSelectAll] = useState<boolean>(true);
    const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
    const checkedIcon = <CheckBoxIcon fontSize='small' />
    const PopperMy = React.useCallback((props: any)=> {
        return <Popper {...props} id={"task-autocomp-popper"} placement='bottom' />
      },[])
    const [candidateActivityMandate, setCandidateActivityMandate] = useState<any>([])
    const [candidateActivityMandateSelected, setCandidateActivityMandateSelected] = useState<any>([])
    const [candidateActivityTypeSelected, setCandidateActivityTypeSelected] = useState<any>(Object.keys(clientCandidateActivityObj).length > 0 ? clientCandidateActivityObj.resultOrder : "Top")
    const [candidateActivitySizeSelected, setCandidateActivitySizeSelected] = useState<any>(Object.keys(clientCandidateActivityObj).length > 0 ? clientCandidateActivityObj.size : 10)
    const [taskMandateStoreList, setTaskMandateStoreList] = useState([])
    const [barHandlerData, setBarHandlerData] = useState({})
    const [customColors,setCustomColors] = useState<any>({
        "shortlistedagainstjo":"#5EB0AB",
        "shortlistedL2review":"#9578BA",
        "shortlistedclientsubmission":"#E79C45",
        "submitted":"#F46464",
        "clientshortlist":"#6474EE",
        "ccm1":"#84AA72",
        "ccm2":"#8D8D8D",
        "offered":"#469BD9",
        "joined":"#DA6CD3",
        "postjoiningdrop":"#31D8C2"
    })
    const statusId:any = {
        "shortlisted_against_jo": "12",
        "shortlisted_L2_review": "17",
        "shortlisted_client_submission": "18",
        "submitted": "24",
        "client_shortlist": "27",
        "ccm1": "32,33,34",
        "ccm2": "112,113,114",
        "offered": "42",
        "joined": "51",
        "post_joining_drop": "105"
        }
    useEffect(() => {
        if(Object.keys(barHandlerData).length > 0)clientCandidateActivityHandler(barHandlerData)
    },[barHandlerData])
    const transformString = (str:any) => {
        return str
            .split('_') // Split the string by underscores
            .map((word:any) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize each word
            .join(' '); // Join the words with a space
        };
    const getCandidateActivityListAPI = async () => {
        console.log("candidateActivityMandateSelected",candidateActivityMandateSelected)
        const reportSubmissionAPIDataJson:any = {}
            if(reportSubmissionAPIData !=  null){
                if(Object.keys(reportSubmissionAPIData).length > 0){
                    Object.keys(reportSubmissionAPIData).map((item:any) => {
                        if(reportSubmissionAPIData[item].length > 0){
                        reportSubmissionAPIDataJson[item] = reportSubmissionAPIData[item]
                        }
                    })
                }  
                }
        const selectedStatusArray:any = selectedCheckboxes.length == 0 ? selectedCheckboxesCopy.map((item:any) => {return statusId[item == "Shortlisted L2 Review" ? "shortlisted_L2_review" : item.replaceAll(" ", "_").toLowerCase()]}).join(",").split(",") :  selectedCheckboxes.map((item:any) => {return statusId[item == "Shortlisted L2 Review" ? "shortlisted_L2_review" : item.replaceAll(" ", "_").toLowerCase()]}).join(",").split(",")
        const requestPayload = {
            ...reportSubmissionAPIDataJson,
            "pageSize": candidateActivitySizeSelected,
            "pageNo": 1,
            "resultType": "client",
            "statusIds": selectedStatusArray.map(Number),
            "resultOrder": candidateActivityTypeSelected,
            "mandateId": candidateActivityMandateSelected
        }  
            requestPayload["userId"] = "userId" in reportSubmissionAPIDataJson ? reportSubmissionAPIDataJson.userId : [userId]
            if("client" in reportSubmissionAPIDataJson) {
                requestPayload["clientId"] = reportSubmissionAPIDataJson.client 
                delete requestPayload.client
            }      
        try{
            const response = await getCandidateActivityList(requestPayload);
            console.log("candidate Activity data: ", selectedCheckboxes)
            let selectedKeysData:any = [];
            if(selectedCheckboxes.length == 0){
                selectedKeysData = selectedCheckboxesCopy.map((item:any) => {
                    return item == "Shortlisted L2 Review" ? "shortlisted_L2_review" : item.replaceAll(" ", "_").toLowerCase()
                })
            }else{
                selectedKeysData = selectedCheckboxes.map((item:any) => {
                    return item == "Shortlisted L2 Review" ? "shortlisted_L2_review" : item.replaceAll(" ", "_").toLowerCase()
                })
            } 
            if(response?.data.length > 0 ){
                const candActiviData = response?.data.map((item:any) => {
                    const clientData:any = {
                        "client": item.client_name + `_${item.client_id}`,
                        "clientId": item.client_id,
                        "clinetCode": item.client_code,
                        "candidate_count": item.candidate_count                    
                      }
                      if(selectedCheckboxes.length == 0){
                        clientData["total count"] = item.candidate_count
                      }else{
                        Object.keys(item).map((litItem:any) => {
                            if(selectedKeysData.includes(litItem)){
                                clientData[litItem] = item[litItem]
                                // clientData[`${litItem}Color`] = checkboxItemsColors[litItem]
                            }
                        })     
                      }   
                    return clientData
                })
                // const uniqueData = candActiviData.filter((item:any, index:any, self:any) =>
                //     index === self.findIndex((t:any) => t.client === item.client)
                // );
                console.log("candActiviData",candActiviData)
                setSelectedCheckedKeys(selectedKeysData)
                setCandidateActivityData(candActiviData.reverse())
            }else{
                setCandidateActivityData([])
            }
            
        }
            catch(error:any){
            console.log(error)        
            }
        }

        const getCandidateActivityMandateListAPI = async (searchTerm:string) => {    
            const resObj = {
                "userId" : reportSubmissionAPIData != undefined ? "userId" in reportSubmissionAPIData ? reportSubmissionAPIData.userId : [userId]: [userId],
                "pageSize": 100,
                "pageNo":1,
                "mandateSearch":searchTerm,
                "selectedMandate" : searchTerm.length > 0 ? [] : clientCandidateActivityObj?.mandateSelected > 0 ? clientCandidateActivityObj?.mandateSelected : []
            }
            try{
                const response = await getCandidateActivityMandateList(resObj);
                const mandateList = response.data.map((item:any) => {
                    return{
                        label: item.mandate_name + ' _ ' + item.mandate_code,
                        value: item.mandate_id
                    }
                })          
                const uniqueData = mandateList.filter((item:any, index:any, self:any) =>
                    index === self.findIndex((t:any) => t.label === item.label)
                );      
                if(taskMandateStoreList.length > 0){
                    let addedList = [...uniqueData,...taskMandateStoreList]
                    addedList = addedList.filter((item:any, index:any, self:any) =>
                        index === self.findIndex((t:any) => t.label === item.label)
                    );
                    setCandidateActivityMandate(addedList)
                  }else{
                    setCandidateActivityMandate(uniqueData)
                  }
                if(initialLoad == 0 && Object.keys(clientCandidateActivityObj).length > 0){
                    setCandidateActivityMandateSelected(clientCandidateActivityObj.mandateSelected)
                    setTaskMandateStoreList(uniqueData.filter((item:any) => clientCandidateActivityObj.mandateSelected.includes(item.value)))
                    const selectCheckOption:any = []
                    Object.keys(statusId).map((item:any) => {
                        if(item != "ccm1" && item != "ccm2" && clientCandidateActivityObj.statusIds.includes(statusId[item])){
                            selectCheckOption.push((item == "joined" || item == "offered") ? item : transformString(item))
                        }
                        else if(item == "ccm1" || item == "ccm2"){
                            const chartStatusObj = statusId[item].split(",")[0]
                            if(clientCandidateActivityObj.statusIds.includes(chartStatusObj))selectCheckOption.push(item)
                        }
                    })
                    setSelectedCheckboxes(selectCheckOption)
                    setInitialLoad(1)
                }else{
                    setInitialLoad(1)
                }
            }
            catch(error:any){
                console.log(error)        
            }
          }
        const loadInitialAPI = async () => {
            if(initialLoad == 0)await getCandidateActivityMandateListAPI("")
            if(initialLoad == 0 && Object.keys(clientCandidateActivityObj).length > 0){
                console.log("not trigger")
                setModalForm({
                    mandate:clientCandidateActivityObj.mandateSelected                    
                  })
            }else{
                getCandidateActivityListAPI()
            }
        }
        useEffect(() => {
            loadInitialAPI()
            if(selectedCheckboxes.length == 10){setSelectAll(true)}else{setSelectAll(false)}
        },[reportSubmissionAPIData,candidateActivityTypeSelected,candidateActivitySizeSelected,candidateActivityMandateSelected,selectedCheckboxes])


  

  const toggleAllCandidateStatus = (toogleStatus:any) => {
    setSelectAll(toogleStatus)
    if (toogleStatus) {
        setSelectedCheckboxes(checkboxItems.map(item => item));
      } else {
        setSelectedCheckboxes([]);
      }
  }

  const onInputChange = _.debounce((event: any, value: string)=>{
      if (_.trim(value) !== '') {
        getCandidateActivityMandateListAPI(value)
      }
    }, 500)

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {    
    const { name, checked } = event.target;
    let currentSelectCheck = JSON.parse(JSON.stringify(selectedCheckboxes));
    checked ? currentSelectCheck.push(name) : currentSelectCheck = currentSelectCheck.filter((item:any) => item !== name)
    const orderedStatus:any = []
    const selectedCheckObj = selectedCheckboxesCopy.map((item) => {
        if(currentSelectCheck.includes(item))orderedStatus.push(item)
    })
    setSelectedCheckboxes(orderedStatus);
  };

    const renderEmptySearchBox = () => {;
          return (
              <Box style={{padding: "136px 160px",minHeight: "500px"}}>
                  <Box>
                      <NoResultFound />
                  </Box>
                  <Box className="empty-search-message">
                      No data found
                  </Box>
              </Box>
          )
      }
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isMediumScreen = useMediaQuery(theme.breakpoints.between('sm', 'md'));
    useEffect(() => {
        const candidateObj = {
            statusIds: selectedCheckboxes.map((item:any) => {return statusId[item == "Shortlisted L2 Review" ? "shortlisted_L2_review" : item.replaceAll(" ", "_").toLowerCase()]}).join(",").split(","),
            resultOrder:candidateActivityTypeSelected,
            drillDownClientId: candidateActivityData.map((item:any) => {return item.clientId}),
            size: candidateActivitySizeSelected,
            mandateSelected: candidateActivityMandateSelected
        }
        const dashboardWidgetPeristance = JSON.parse(JSON.stringify(dashboardWidgetReduxStore));        
        dashboardWidgetPeristance["candidateActivityWidget"] = candidateObj
        dispatch(setDashboardWidgetPresistance(dashboardWidgetPeristance))
    },[candidateActivityMandateSelected,candidateActivitySizeSelected,candidateActivityTypeSelected,selectedCheckboxes,candidateActivityData])
    const clientCandidateActivityHandler = (navData:any) => {        
        const clientCandiActivObj:any = {
            statusIds: selectedCheckboxes.map((item:any) => {return statusId[item == "Shortlisted L2 Review" ? "shortlisted_L2_review" : item.replaceAll(" ", "_").toLowerCase()]}).join(",").split(","),
            resultOrder:candidateActivityTypeSelected,
            drillDownClientId: candidateActivityData.map((item:any) => {return item.clientId}),
            size: candidateActivitySizeSelected,
            mandateSelected: candidateActivityMandateSelected,
            type: "multiple"
        }
        if(Object.keys(navData).length > 0){
            clientCandiActivObj["type"] = "single"
            clientCandiActivObj["singleStatus"] = navData.status
            clientCandiActivObj["singleClient"] = [navData.clientId]
        }
        const userRoleObjectSelectedData = userRoleObjectSelected;
        if(userRoleObjectSelected.length > 0 && userRoleObjectSelected[userRoleObjectSelected.length - 1].user.selectedValue.length == 0) userRoleObjectSelectedData.pop()
          navigate(`/clientCandidateActivityDetail`, { state: { dashboardfilterObj:DashboardFilterDatas, filterList: reportSubmissionAPIData ,filterListLabels: reportSubmitdatalabels,userFilterObj: userRoleObjectSelectedData, clientDependedObj: userDependendObj,clientCandidateActivityObj: clientCandiActivObj} });
        }
    const [modal, setModalForm] = useState<any>({
        mandate: []
    })
    useEffect(() => {
        methods.reset(modal)
      }, [modal])
    
    return (
        <Paper className='dashboardWidgetSmallCard'>
              <Box className="dashWidgetHeader">
                <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 'bold' }} gutterBottom>
                    {`Top 10 clients with high Candidate Activity`}
                </Typography>
                <Box className="dashWidgetActionSect" sx={{display:'flex', columnGap:'5px',paddingTop:'5px'}}>
                    <Tooltip title="Launch"><DashboardLaunch className='launchIcon' style={{cursor:"pointer",visibility: candidateActivityData.length > 0 ? "visible" : "hidden"}} onClick={() => clientCandidateActivityHandler({})}/></Tooltip>
                    {/* <MoreVertIcon style={{ fontSize: '22px', fontWeight: 'bold', paddingBottom: '2px' }} /> */}
                </Box>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <Box className="candidateActivityActionSect" >
                    {/* select options  */}

                    <FormControl className='mandateSelectField' fullWidth sx={{fontSize:"12px",width:'220px'}} size="small">
                        <Controller
                            name='mandate'
                            control={methods.control}
                            render={({ field: { value, onChange } }:any) => {
                                const [inputValue, setInputValue] = React.useState('');
                                const handleSelectMandate = (e: any, val: any) => {
                                if(val.length == 0) getCandidateActivityMandateListAPI("")
                                console.log("mandate val",val)
                                onChange(val.map((x: any) => x.value))
                                setTaskMandateStoreList(val)
                                setInputValue("")
                                setCandidateActivityMandateSelected(val.map((x: any) => x.value))
                                // setValues({ ...values, mandate: val?.map((ele:any)=>ele?.id as string) })
                                }
                                const mandateValue = candidateActivityMandate?.filter((ele: any) =>
                                value?.includes(ele.value),
                                )
                                console.log("mandateValue",mandateValue)
                                return (
                                <FormControl sx={{ width: '100%' }}>
                                    <Autocomplete
                                    multiple
                                    limitTags={1}
                                    id='multiple-limit-tags'
                                    disableCloseOnSelect
                                    disableListWrap
                                    className={`inner-textfield`}
                                    PopperComponent={PopperMy}
                                    options={candidateActivityMandate}
                                    onInputChange={(e, val, reason) => {
                                        if (reason === "input") {
                                        setInputValue(val)
                                        onInputChange(e, val); 
                                        }
                                    }}
                                    getOptionLabel={(option: any) => option?.label}
                                    value={mandateValue}
                                    inputValue={inputValue}
                                    renderOption={(props, option:any, { selected }) => (
                                        <li {...props}>
                                        <Checkbox
                                            icon={icon}
                                            checkedIcon={checkedIcon}
                                            style={{ marginLeft: "-10px" }}
                                            checked={selected}
                                        />
                                        <Box
                                            sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}
                                        >
                                            <Tooltip title={option.label?.split('_')[0]}><Typography
                                            sx={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                fontSize:"12px"
                                            }}
                                            >
                                            {option.label?.split('_')[0]}
                                            </Typography>
                                            </Tooltip>
                                            <Typography className='dropdownSubtext'>
                                            {option.label?.split('_')[1]}
                                            </Typography>
                                        </Box>
                                        </li>
                                    )}
                                    renderInput={(params) => (
                                        <TextField {...params}  label='Mandates' />
                                    )}
                                    onChange={handleSelectMandate}
                                    />
                                </FormControl>
                                )
                            }}
                            />
                      </FormControl>

                      <FormControl fullWidth sx={{fontSize:"12px",width:'100px'}} size="small">
                        {/* <InputLabel id="demo-simple-select-label" sx={{fontSize:"12px",textAlign: "center",transformOrigin: 'center'}}>Top</InputLabel> */}
                        <Controller
                            name={"Top"}
                            control={control}
                            render={({ field: { onChange, value, onBlur } }) => (
                            <Select
                            sx={{fontSize:"12px"}}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={candidateActivityTypeSelected}
                            // label="Top"
                            onChange={(e:any) => {
                               setCandidateActivityTypeSelected(e.target.value)
                            }}
                            >
                            {["Top","Bottom"].map((item:any,index:number) => (
                                <MenuItem key={item} value={item} sx={{fontSize:"12px"}}>{item}</MenuItem>
                                ))}
                            </Select>
                            )}
                            />
                      </FormControl>
                      <FormControl fullWidth sx={{fontSize:"12px",width:'100px'}} size="small">
                        {/* <InputLabel id="demo-simple-select-label" sx={{fontSize:"12px",textAlign: "center",transformOrigin: 'center'}}>Top</InputLabel> */}
                        <Controller
                            name={"Count"}
                            control={control}
                            render={({ field: { onChange, value, onBlur } }) => (
                            <Select
                            sx={{fontSize:"12px"}}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={candidateActivitySizeSelected}
                            defaultValue=""
                            // label="Count"
                            onChange={(e:any) => {
                                setCandidateActivitySizeSelected(e.target.value)
                            }}
                            >
                            {[5,10,15,20].map((item:any,index:number) => (
                                <MenuItem key={item} value={item} sx={{fontSize:"12px"}}>{item}</MenuItem>
                                ))}
                            </Select>
                            )}
                            />
                      </FormControl>

                </Box>
                <Box
                sx={{
                    height: 'fit-content',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight:'60px',
                }}>
                <Box
                sx={{
                    height: 'fit-content',
                    width: '150px',
                    backgroundColor: '#F3F3F7',
                    borderRadius: '8px',
                    position: 'absolute',
                    flexDirection: 'column',
                    top:'130px'
                }}
                >
                <FormControl
                    component="fieldset"
                    sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    }}
                >
                    <FormGroup sx={{ marginTop: '3px' }}>
                    <FormLabel component="legend" sx={{ fontSize: '12px' }}>
                        <Checkbox
                        checked={selectAll}
                        onChange={(e:any) => {toggleAllCandidateStatus(e.target.checked)}}
                        inputProps={{ 'aria-label': 'Select All' }}
                        sx={{ '& .MuiSvgIcon-root': { fontSize: 16 } }}
                        />
                        Select All
                    </FormLabel>
                    <Box
                        sx={{
                        backgroundColor: '#fff',
                        border: '1px solid #E4E4E4',
                        paddingTop: '5px',
                        borderBottomLeftRadius: '8px',
                        borderBottomRightRadius: '8px',
                        width: '100%', // Make sure it takes full width
                        boxSizing: 'border-box', // Ensure padding is included in width calculation
                        }}
                    >

                    {checkboxItems.map((checkbox) => {
                        const checkBoxColor = customColors[checkbox == "Shortlisted L2 Review" ? "shortlistedL2review" : checkbox.replaceAll(" ","").toLowerCase()]
                        return (
                            <>
                            <FormControlLabel
                                className={`candidateStatusCheck ${checkbox == "Shortlisted Client Submission" ? "shortClientSub" : ""}`}
                                key={checkbox}
                                control={
                                <Checkbox
                                    checked={selectedCheckboxes.includes(checkbox)}
                                    onChange={handleCheckboxChange}
                                    name={checkbox}
                                    sx={{
                                        paddingLeft: '20px',
                                        color: 'gray', // Default color when unchecked
                                        '&.Mui-checked': {
                                          color: checkBoxColor, // Color when checked
                                        },
                                        '& .MuiSvgIcon-root': { fontSize: 16 },
                                      }}
                                />
                                }
                                label={<Typography sx={{ fontSize: '12px',textTransform: (checkbox == "ccm1" || checkbox == "ccm2") ? "uppercase" : "capitalize" }}>{checkbox}</Typography>}
                                sx={{ width: '100%' }} // Ensure it takes full width
                            />
                            </>
                        )
                    }
                            
                            )}
                    </Box>
                    </FormGroup>
                </FormControl>
                </Box>
                </Box>

                {candidateActivityData.length > 0 ? <ClientActivityResponsiveBarChart
                    data={candidateActivityData}
                    yAxisKey={"client"}
                    keys={selectedCheckedKeys}
                    isSmallScreen={isSmallScreen}
                    isMediumScreen={isMediumScreen}
                    customColors={customColors}
                    selectAll={selectAll}
                    type={"client"}
                    setBarHandlerData={setBarHandlerData}
                />: <>{renderEmptySearchBox()}</>}
            </Box>
        </Paper>
    );
};

export default ClientCandidateactivity;