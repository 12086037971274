import { Accordion, AccordionDetails, AccordionSummary, Avatar, Box, Button, FormControl, FormControlLabel, FormGroup, IconButton, InputLabel, MenuItem, Select, Switch, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Modal, Typography, } from '@mui/material';

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ReactComponent as BackIcon } from '../../assets/icons/backarrowicon1.svg';
import UserViewAudit from "../../assets/icons/userViewAudit.svg"
import { ReactComponent as UserViewOrganization } from "../../assets/icons/userViewOrganization.svg"
import { baseUrl } from '../../services/http-common';
import CloseIcon from '@mui/icons-material/Close';
import { toast } from "react-toastify";
import { AppButton } from '../AppComponents/AppButton';
import GroupStrick from '../../assets/images/default-avatar.png'
import { convertToDisplayDateTime } from '../../utils/date-utils';
import { getMandateAuditLogs } from '../../services/MandateService';
import { ReactComponent as UserViewEdit } from "../../assets/icons/userViewEdit.svg"
import Access from "../../assets/images/Access.svg"
import RoleDetails from "../../assets/images/Role_Details.svg"
import Warning from "../../assets/images/Warning.svg"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ViewTreeList from './ViewTreeList';
import { getRoleDetail, postRoleActiveDeactivate, postSelectedAccessList } from '../../services/UserService';
import { useAppSelector } from '../../store/hooks';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '506px',
    // height: '280px',
    borderRadius: '10px',
    bgcolor: 'background.paper',
    border: 'none',
    boxShadow: 24,
    padding: "32px 32px 20px"
  }
const ViewUserDetails = () => {
    const navigate =useNavigate()
    const [value, setValue] = React.useState('1');
    const location = useLocation();
    const [userDetailData, setUserDetailData] = useState(location?.state?.roleDetail)
    const [treeSelectedList, setTreeSelectedList] = useState([])
    const [manAuditHistory, setManAuditHistory] = useState<any[]>([])
    const [roleStatusWarning, setRoleStatusWarning] = useState(false)
    const [showActivatePopup, setShowActivatePopup] = useState(false)
    const [userActiveStatus, setUserActiveStatus] = useState(userDetailData?.status != null ? ((userDetailData?.status == 1) ? true : false): false)
    const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
    const { userRole } = useAppSelector((state) => state.UserDetail)
    const getUserDetailInfo = async (userId:string) => {
        const res:any = await getRoleDetail({
            "roleId": userId
        })
        if(res.status == 200){
            setUserActiveStatus(res.data.result.data.role.status != null ? ((res.data.result.data.role.status == 1) ? true : false): false)
        }
    }
    const getSelectedtreeList = async (userId:string) => {
        const res:any = await postSelectedAccessList({
            "roleId": userId
        })
        if(res.status == 200){
            setTreeSelectedList(res.data.result.data)
        }
    }
    const userStatusUpdate = async () => {
        const userUpdateObj = {
            "roleId": userDetailData?.id,
            "status": userActiveStatus ? 2 : 1
        }
        try{
            const res:any = await postRoleActiveDeactivate(userUpdateObj)
            setShowActivatePopup(false)
            toast.success('Successfully updated status')
            setUserActiveStatus(!userActiveStatus)
            await getStatusLog()
            // getUserDetailInfo(userDetailData?.id)
          }
          catch(error:any){
            console.log(error)    
            setShowActivatePopup(false)  
            // toast.error('Something went wrong') 
            if(error?.response?.data?.msgCode == "MSGR001")setRoleStatusWarning(true)
            setUserActiveStatus(userActiveStatus)  
        }
        
    }
    const userStatusCancel = () => {
        setUserActiveStatus(userActiveStatus)
        setShowActivatePopup(false)
    }

    const userStatusTriggerHandler = () => {
        // setUserActiveStatus(!userActiveStatus)
        if((userPermissions?.user_management_module?.role_module?.edit?.selected && userRole != "Admin") || userRole == "Admin")setShowActivatePopup(true)
    }
    
    const getStatusLog = async () => {
        const reqObj = {
          filters: [
            {
              field: "eventId",
              operator: "in",
              type: "string",
              value: ["ROLE_ENTITY"]
            },
            {
              field: "entityId",
              operator: "equals",
              type: "string",
              value: String(userDetailData?.id)
            }
          ],
          page: {
            pageNo: -1,
            pageSize: 10
          },
          sorting: {
              direction: "DESC",
              field: "createdAt"
          }
        }
        try{
          const res = await getMandateAuditLogs(reqObj)
          if(res?.status === 200){
            setManAuditHistory(res?.data?.result?.list[0] ?? [])
          }
        }catch(error){
          console.log(error,"error")
        }
      }

      useEffect(() => {
        getStatusLog()
        getSelectedtreeList(userDetailData?.id)
        getUserDetailInfo(userDetailData?.id)
      },[])

      const userEditList = async (userId:string) => {
        const res:any = await getRoleDetail({
            "roleId": userId
        })
        if(res.status == 200){
            navigate(`/createrole`,{state:{roleDetail: res.data.result.data.role,status:"edit"}})
        }
    }
    return (
        <>
            <Box className='mandate-wrapper roleDetailSect'>
                <div className='nav-header'>
                    <IconButton
                        style={{paddingLeft:"0px"}}
                        onClick={() =>  navigate(`/roleAccess`)}
                        aria-label='back'
                        disableRipple
                        disableFocusRipple
                    >
                        <BackIcon />
                    </IconButton>
                    <Box className="userViewTopOptionSect">
                        <div className='header-text'>{userDetailData?.roleName} {"Detail"}</div>
                        {userPermissions?.user_management_module?.role_module?.edit?.selected && userRole != "Admin" && <Box className="userViewTopActionSect">
                            {userDetailData.roleSlug != "Admin" && <Tooltip title={"Edit Role"}><Button className='edit' startIcon={<UserViewEdit />} onClick={() => userEditList(userDetailData?.id)}>Edit</Button></Tooltip>}
                        </Box>}

                        {userRole == "Admin" && <Box className="userViewTopActionSect">
                            {userDetailData.roleSlug != "Admin" && <Tooltip title={"Edit Role"}><Button className='edit' startIcon={<UserViewEdit />} onClick={() => userEditList(userDetailData?.id)}>Edit</Button></Tooltip>}
                        </Box>}
                    </Box>
                    
                </div>
                <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    className='accordian-header'
                                >
                                    <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                                    <img src={RoleDetails} alt="otherInfo" />
                                    <div>Role Details</div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className='accordian-details'>
                                    <Box className="userViewEmployeeFields">
                                        <Box className="userViewEmpolyeeFieldsFlex">
                                                <Box>
                                                    <p>Role ID</p>
                                                    <h5>{userDetailData?.id}</h5>
                                                </Box>
                                                <Box>
                                                    <p>Role Name</p>
                                                    <h5>{userDetailData?.roleName}</h5>
                                                </Box>
                                                <Box>
                                                    <p>Description</p>
                                                    <h5>{userDetailData?.description}</h5>
                                                </Box>
                                                <Box>
                                                    <p>Status</p>
                                                    <h5>
                                                        <FormGroup className={(!userPermissions?.user_management_module?.role_module?.edit?.selected && userRole != "Admin") || userDetailData.roleSlug == "Admin" ? "disableActiveCheck" : ""}>
                                                            <FormControlLabel control={<Switch defaultChecked={userActiveStatus} checked={userActiveStatus} />} onClick={() => {userStatusTriggerHandler()}} label={userActiveStatus ? "Active" : "Inactive"} />
                                                        </FormGroup>
                                                    </h5>
                                                </Box>
                                            </Box>
                                        </Box>
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                className='accordian-header'
                                >
                                    <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                                    <img src={Access} alt="otherInfo" />
                                    <div>Access</div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className='accordian-details'>
                                    {treeSelectedList.length > 0 ? <ViewTreeList treeSelectedList={treeSelectedList}/>:
                                    <>
                                    <p style={{margin:"0px",fontSize:"14px"}}>No Access </p>
                                    </>
                                    }
                                </AccordionDetails>
                            </Accordion>
                            <Accordion defaultExpanded={true}>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    className='accordian-header'
                                >
                                    <div style={{display: "flex", gap:"10px", alignItems:"center"}}>
                                    <img src={UserViewAudit} alt="otherInfo" />
                                    <div>Audit Logs</div>
                                    </div>
                                </AccordionSummary>
                                <AccordionDetails className='accordian-details'>
                                    {manAuditHistory.length > 0 ? <Box className="mandate-detail-wrapper">
                                        <Table className="audit-log-table">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell style={{width:"40%"}} >Action By</TableCell>
                                                <TableCell style={{width:"20%"}}>Action</TableCell>
                                                <TableCell style={{width:"25%"}}>Date</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                        {manAuditHistory?.map((item:any, index:number) => (
                                            <TableRow key={index} >
                                            <TableCell style={{width:"40%"}}>
                                                <Box className="d-flex" >
                                                <Box>
                                                    <Avatar
                                                    alt='no-group'
                                                    src={GroupStrick}
                                                    sx={{ width: 30, height: 30 }}
                                                    />
                                                </Box>
                                                <Box className='user-detail' >
                                                    <Tooltip title={item?.userEntity?.firstName} placement="bottom-start">
                                                    <span className='primary-text ellipsisText1'>{item?.userEntity?.firstName}</span>
                                                    </Tooltip>
                                                    <span className='secondary-text'>{item?.userEntity?.roleName || "-"}</span>
                                                </Box>
                                                </Box>
                                            </TableCell>
                                            <TableCell style={{width:"20%"}}>
                                                <Tooltip title={item?.eventType} placement="bottom-start" >
                                                <span className="primary-text text-ellips" >{item?.eventType || "-"}</span>
                                                </Tooltip>
                                            </TableCell>                                            
                                            <TableCell style={{width:"25%"}}>
                                                <div className="primary-text" >{convertToDisplayDateTime(item?.createdAt)}</div>
                                            </TableCell>
                                            </TableRow>
                                        ))}
                                        </TableBody>
                                    </Table>
                                    </Box>:<p style={{margin:"0px",fontSize:"14px"}}>No Audit Log</p>}
                                </AccordionDetails>
                            </Accordion>
                </Box> 
           {showActivatePopup && <Box className="decativeUserSect">
                    <Box className="decativeUserSectBlk">
                        <Box className="decativeUserSectBlkTop">
                            <h3>{userActiveStatus ? "Deactivate" : "Activate"} Role</h3>
                            <CloseIcon style={{cursor:"pointer"}} onClick={() => {userStatusCancel()}}/>
                        </Box>
                        <Box className="decativeUserSectBlkBody">
                            <p className='activeInactiveUserText'>Are you sure you want to <span>{userActiveStatus ? "Deactivate" : "Activate"}</span> the <span className='roleName'>{userDetailData?.roleName}</span> role?</p>  
                        </Box>
                        <Box className='button-container'>
                            <Button 
                                className='cancel-btn'
                                onClick={() => {userStatusCancel()}}
                            >Cancel</Button>
                            <Button 
                                className={`${userActiveStatus ? "deactive-btn" : "save-btn"}`}
                                onClick={() => {userStatusUpdate()}}
                            >
                                {userActiveStatus ? "Deactivate" : "Activate"}
                            </Button>
                        </Box>
                    </Box>                    
                </Box>
            }
            {roleStatusWarning && <Box className="roleStatusWarning">
                <Box className="roleStatusWarningBody">
                    <img src={Warning} />
                    <h4>{`Can't Deactivate`}</h4>
                    <Box className="roleStatusWarningBodyCont">
                        <p>This role is mapped to multiple users.</p>
                        <p>{`Can't able to deactivate.`}</p>
                    </Box>
                    <Box className="roleStatusWarningAction">
                        <button onClick={() => {setRoleStatusWarning(false)}}>Done</button>
                    </Box>                    
                </Box>
            </Box>
            }
        </>
    );
};

export default ViewUserDetails;