import React from 'react';
import { ResponsiveBar, BarDatum } from '@nivo/bar';
import CustomTooltip from '../CustomTooltipComponent/CustomTooltip';
import { Box } from '@mui/material';
import { useTooltip, BasicTooltip } from '@nivo/tooltip';

interface DataItem extends BarDatum {
  id: string;
  value: number;
  client: string;
  activity: number;
  activityType: string;
}

interface ResponsiveBarChartProps {
  data: DataItem[];
  yAxisKey: string;
  keys: any;
  isSmallScreen: boolean;
  isMediumScreen: boolean;
  customColors: any;
  selectAll: boolean;
  type : string;
  setBarHandlerData?: any
}

const truncateString = (str: string, maxLength: number) => {
  if (str.length > maxLength) {
    return `${str.substring(0, maxLength)}...`;
  }
  return str;
};


// CustomBar component
const CustomBar: React.FC<any> = ({
  bar: { x, y, width, height, color, data },
}) => {
  const { showTooltipFromEvent, hideTooltip } = useTooltip();


  const handleMouseMove = (event: React.MouseEvent) => {
    
    const datum = data as any;
    console.log("eval :",datum)
    showTooltipFromEvent(
      <CustomTooltip
        id={datum.labelname}
        value={datum.value}
        color={color}
        label={datum.labelname}
        tooltipstatus={{
          enable: true,
          showstatuscolor: true,
          tooltipvaluewithpercentage: datum.value,
          evaulatepercentage: datum.evaulatepercentage,
        }}
        />,
      event
    );
  };

  const handleMouseLeave = () => {
    hideTooltip();
  };

  const borderRadius = 10; // Adjust the radius as needed

  return (
    <g transform={`translate(${x}, ${y})`} onMouseMove={handleMouseMove} onMouseLeave={handleMouseLeave}>
      <rect
        x={0}
        y={borderRadius}
        width={width}
        height={height - borderRadius}
        fill={color}
        rx={0}
        ry={0}
      />
      <rect
        x={0}
        y={0}
        width={width}
        height={borderRadius+10}
        fill={color}
        rx={borderRadius}
        ry={borderRadius}
      />
    </g>
  );
};

const ResponsiveBarChart: React.FC<ResponsiveBarChartProps> = ({ data,yAxisKey,keys,isSmallScreen, isMediumScreen,customColors,selectAll,type,setBarHandlerData }) => {
  const truncateLabel = (label:any, maxLength:any) => {
    return label.length > maxLength ? `${label.slice(0, maxLength)}...` : label;
  };
  const statusId:any = {
    "shortlisted_against_jo": "12",
    "shortlisted_L2_review": "17",
    "shortlisted_client_submission": "18",
    "submitted": "24",
    "client_shortlist": "27",
    "ccm1": "32,33,34",
    "ccm2": "112,113,114",
    "offered": "42",
    "joined": "51",
    "post_joining_drop": "105"
    }
  let highestCandidateCount = 0
  if(type == "client" || type == "recruiter"){
    highestCandidateCount = data.reduce((max:any, client:any) => 
      client.candidate_count > max ? client.candidate_count : max, 0);
  }else{
    highestCandidateCount = Math.max(...data.map(item => Object.values(item).find(v => typeof v === 'number') as number));
  }
  const capitalizeWords = (sentence:any) => {
    return sentence.split(" ")
        .map((word:any) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" ");
}

const barChartHandler = (part: any) => {
  setBarHandlerData({
    "clientId": part.data.clientId,
    "status": statusId[part.id].split(",")
  })
};
  
  return (
    <Box className="respoBarChart" style={{width:"565px", height: (data.length > 10 && data.length < 16) ? '500px' : data.length > 16 ? '600px' : "460px" }}>
      <ResponsiveBar
        data={data}
        keys={(!selectAll && keys.length == 10 && (type == "client" || type == "recruiter")) ? ["total count"] : keys}
        indexBy={yAxisKey}
        margin={{ top:20, right: 180, bottom: 40, left: 90 }}
        padding={0.4}
        valueScale={{ type: 'linear' }}
        colors={({ id, data }:any) => (!selectAll && keys.length == 10 && type == "client") ? "#5A6ACF" : (!selectAll && keys.length == 10 && type == "recruiter") ? "#CFB55A" : customColors[id.replaceAll("_","")]}
        indexScale={{ type: 'band', round: true }}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        enableLabel={false}
        theme={{
          labels: {
            text: {
              fontSize: 12, // Increase bar label font size
              fontWeight: "bold",
              fill: "#000", // Optional: Change label color
            },
          },
          axis: {
            ticks: {
              text: {
                fontSize: 12, // Increase axis tick font size
                fill: "#333", // Optional: Change color
                fontWeight: "500", // Optional: Make it bold
              },
            },
          },
          grid: {
            line: {
              stroke: "#F2F2F2", // Change to your desired color
              strokeWidth: 1
            },
          },
        }}
        // borderRadius={3}
        enableTotals={true}
        layout="horizontal"
        enableGridX={true}
        enableGridY={false}
        axisBottom={{                    
          tickSize: 5,
          tickValues: 6,
          tickPadding: 5,
          tickRotation: isSmallScreen ? -45 : 0,
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legendPosition: 'middle',
          legendOffset: 0,
          format: (value) => truncateLabel(type == "status" ? (value == "ccm1" || value == "ccm2") ? value.toUpperCase() : capitalizeWords(value.replaceAll("_"," ")) : capitalizeWords(value.split("_")[0]), 12)
        }}
        maxValue={highestCandidateCount + Math.round(highestCandidateCount/ 100 * ((type == "client" || type == "recruiter")? 15 : 10))}
        tooltip={({ id, value, color, indexValue }) => {
            const datum = data.find(d => d.client == indexValue);
            return (
              <CustomTooltip
                contentType = {type}
                id={id as string}
                value={value as number}
                color={color as string}
                // label={datum?.client ?? ""}
                activity={{
                    activitystatus: datum?.client.split("_")[0] ?? "",
                    numberOfactivity: value,
                    candidateStatus: id.toString() ?? "",
                  }}
              />
            );
          }}
        onMouseEnter={(_datum, event:any) => {
          event.currentTarget.style.cursor = "pointer";
        }}
        onClick={barChartHandler}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={(e) => `${e.id}: ${e.formattedValue} in client: ${e.indexValue}`}
      />
    </Box>
  );
};

export default ResponsiveBarChart;