// import http from './http-common'
import { forminstance, getAccessToken, instance } from './http-common'
import ITaskListData from '../types/Task'
import jwtDecode from 'jwt-decode';
import _ from 'lodash';
import { getLoggedInUserId } from '../utils/user-utils';
import { delay, objectToQueryString } from '../utils/common-utils';
export interface CreateTaskInfo {
    description: string;
    name: string;
    assignedTo: string[];
    isHorizontal:boolean,
    mandate:any;
    duedate:string;
    topost:boolean;
    userId:string|null;
}
export interface UpdateTaskInfo {
    taskId: number;
    description: string;
    name: string;
    isHorizontal:boolean,
    assignedTo: string[]
    mandate:any;
    duedate:string;
    topost:boolean;
    userId:string|null;
}
class TaskManagementServiceClass {
    async getTaskList({page, size, filters}: {page:number, size:number, filters: any}) {
        const queryString = objectToQueryString({
            page: page+1,
            size,
            userId: getLoggedInUserId(),
            searchTextId: _.get(filters, 'name.value', ''),
            role: _.get(filters, 'role.value', ''),
            keyword: _.get(filters, 'commonFilterText', ''),
        })
        // await delay(2000);
        return instance.get<Array<ITaskListData>>(`task/taskList?${queryString}`);
        // const res = instance.get<Array<ITaskListData>>(`task/taskList?${queryString}`);
        

    }
    createTask(taskInfo: CreateTaskInfo) {
        return instance.post('task/', {
            name: taskInfo.name,
            description: taskInfo.description,
            userId: getLoggedInUserId(),
            assignees: _.map(taskInfo.assignedTo, (assignUserId:string) => {
                return {
                    'taskAssignedTo': assignUserId,
                }
            }),
            mandates:taskInfo?.mandate.map((ele:any)=> ({mandateId: ele})),
            dueDate:taskInfo?.duedate,
            isHorizontal:taskInfo?.isHorizontal ? 1 :0,
            syncGoogleCalendar:taskInfo?.topost ? 1:0
        });
    }
    updateTask(taskInfo: UpdateTaskInfo) {
        return instance.put('task/', {
            id: taskInfo.taskId,
            name: taskInfo.name,
            description: taskInfo.description,
            assignees: _.map(taskInfo.assignedTo, (assignUserId:string) => {
                return {
                    'taskAssignedTo': assignUserId,
                }
            }),
            mandates:taskInfo?.mandate.map((ele:any)=> ({mandateId: ele})),
            isHorizontal:taskInfo?.isHorizontal ? 1 :0,
            dueDate:taskInfo?.duedate,
            syncGoogleCalendar:taskInfo?.topost ? 1 :0
        });
    }
    deletetask(id: number) {
        return instance.delete(`task/${id}`);
    }
    getAssignedToUsers() {
        const tokenData: any = jwtDecode(getAccessToken())
        return instance.post('user/assignTo', {
            "userId" : tokenData.sub,
        });
    }
    getReportAssignedToUsers(userId:any) {
        const tokenData: any = jwtDecode(getAccessToken())
        return instance.post('user/assignTo', {
            "userId" : userId,
        });
    }
    getReportAssignedToUsersHierarchy(userId:any) {
        const tokenData: any = jwtDecode(getAccessToken())
        return instance.post('user/assignTo/submission', {
            "userId" : userId,
        });
    }
    getHorizontalAssignedToUsers() {
        const tokenData: any = jwtDecode(getAccessToken())
        return instance.post('user/horizondalAssignTo', {
            "userId" : tokenData.sub,
        });
    }

    GetTaskComments = async (id: any) => {
        const response = await instance.get(`task/comments/${id}`);
        return response;
    }

    CreateTaskComment = async (comment: any) => {
        const response = await forminstance.post(`task/comment`,comment);
        return response;
    }

    GetTaskHistory = async (id: any) => {
        const response = await instance.get(`task/getTaskHistory?taskId=${id}`);
        return response;
    }

    UpdatetAssignee = async (status: any) => {
        const response = await instance.put(`task/assigneeStatus`,status);
        return response;
    }

    getRoles = async () => {
        const response = await instance.get(`role/`);
        return response;
    }
}

export const TaskManagementService = new TaskManagementServiceClass();