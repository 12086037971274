import React, { useState } from 'react'
import '../MandateTable/MandateTable.scss'
// import MandateCard from './MandateCard/MandateCard'
import Allocation from '../MandateTable/Allocation/Allocation'
import { Button, Typography, Box } from '@mui/material'
import { ReactComponent as PlusIcon } from '../../../assets/icons/plus.svg'
import AddIcon from '@mui/icons-material/Add';
import { ReactComponent as NoResultIcon } from '../../../assets/icons/no-result-found.svg'
import AddMandatePopUp from '../AddMandate/AddMandate'
import { getAllocationDetails, postBulkDeallocation, postBulkReallocation } from '../../../services/WorkManagementService'
import Tooltip from '@mui/material/Tooltip';
import BulkAllocationRecuriterCard from './BulkAllocationRecuriterCard'
import "./BulkAllocationViewContainer.scss";
import BulkAllocationDetailCard from './BulkAllocationDetailCard'
import BulkAllocationConfirmationPopup from './BulkAllocationConfirmationPopup'
import BulkRecruiterContainer from './BulkRecruiterContainer'

const BulkAllocationRecuriterTable = ({ bulkAllocationList = [],selectedMandateId,setCallAllocationList,selectedDate,setBulkStageId,viewType}:any) => {
    const [isAssignPopup, setIsAssignPopup] = useState<boolean>(false)
    const [selectedCandidate, setSelectedCandidate] = useState<any>({})
    const [editAllocateData, setEditAllocateData] = useState<any>(null)
    const [isEdit, setIsEdit] = useState<boolean>(false)
    const [isUnassign, setIsUnassign] = useState<boolean>(false)
    const [selectedMandateTag, setSelectedMandateTag] = useState<any>({})
    const status ='good'
    const acheived = 28
    const [deallocationUserId, setDeallocationUserId] = useState<any>({})
    const [deallocationPopupStatus, setDeallocationPopupStatus] = useState(false)
    const [reSubmissionPopupOpen, setReSubmissionPopupOpen] = useState(false)
    const [active,SetActive] = useState("Submissions")
    const startOfWeek = `${selectedDate.clone().startOf('isoWeek').format('ddd DD')}`;
    const secondDayOfWeek = `${selectedDate.clone().startOf('isoWeek').add(1, 'days').format('ddd DD')}`;
    const thirdDayOfWeek = `${selectedDate.clone().startOf('isoWeek').add(2, 'days').format('ddd DD')}`;
    const fourthDayOfWeek = `${selectedDate.clone().startOf('isoWeek').add(3, 'days').format('ddd DD')}`;
    const fifthDayOfWeek = `${selectedDate.clone().startOf('isoWeek').add(4, 'days').format('ddd DD')}`;
    const sixthDayOfWeek = `${selectedDate.clone().startOf('isoWeek').add(5, 'days').format('ddd DD')}`;
    const endOfWeek = `${selectedDate.clone().startOf('isoWeek').add(6, 'days').format('ddd DD')}`;

    const parseData = (data:string) => {
        try {
          return JSON.parse(data) || []
        } catch (error) {
          return []
        }
      }

      const deallocation = async (type:any) => {  
        const allocationFilterObj = {
            "mandateId": selectedMandateId?.value,
            "allocatedUsers": [
                {
                  "allocatedUser": deallocationUserId?.recruiterId,
                  "locationIds": deallocationUserId?.allocatedLocationIds.split(",").map(function(t:any){return parseInt(t)})
                }
              ],
            "mode": "SINGLE",
            "isAllow": type == null ? false : true
          } 
        try{
            const response = deallocationUserId?.status == 65 ?  await postBulkReallocation(allocationFilterObj) : await postBulkDeallocation(allocationFilterObj)
            if(response?.status === 200){
                setDeallocationPopupStatus(false)
                setCallAllocationList(new Date().getTime())
                if(type != null) setReSubmissionPopupOpen(false)
            }
        }catch(error:any){
            if(error.response.data.error.errorCode == 'ERR123' || error.response.data.error.errorCode == 'ERR003'){
                setDeallocationPopupStatus(false)
                setReSubmissionPopupOpen(true)
              }
        }
      }

      const handleEditAllocation = async (data:any) => {
        const reqObj = {
            allocationId: data?.allocationId,
            mandateId: selectedMandateId?.value,
            allocatedUser: data?.recruiterId
        }
        try{
            const res = await getAllocationDetails(reqObj)
            if(res?.status === 200){
                const tabData = {
                    title: selectedMandateId?.title,
                    mandateId: selectedMandateId?.mandateId,
                    clientName: selectedMandateId?.clientName,
                    createdByName: selectedMandateId?.createdByName
                }
                setSelectedMandateTag(tabData)
                setEditAllocateData(res?.data?.result?.data || null)
                setIsUnassign(false)
                setIsEdit(true)
                setIsAssignPopup(true)
            }
        }catch(error){
            console.log(error)
        }
    }


    return (
        <>
            {viewType != "W" ? 
            <Box className='mandate-table-container' >
                <table className='mandate-table bulkAlloactionRecuriterTable'>
                    <thead className='mandate-table-head'>
                        <tr className='mandate-header-row1'>
                            <th className='mandate-header sticky-left'>ASSIGNEES ({bulkAllocationList.length})
                            </th>
                            <th className='allocation-header'>Allocation Details</th>
                            <th className='Kpi-header' colSpan={5}>KPI</th>
                        </tr>
                        <tr className='mandate-header-row2'>
                            <th className='sticky-left' align='left'>
                               </th>
                            <th className='kpi-header'></th>
                            <th className='weekViewSubHeaderSect'>
                                <div className='kpi-header'>Submissions</div>
                                <div className='kpi-header'>CCM 1</div>
                                <div className='kpi-header'>CCM 2</div>
                                <div className='kpi-header'>Selects</div>
                                <div className='kpi-header'>Joins</div>
                            </th>
                            
                        </tr>
                    </thead>
                    <tbody>
                        {bulkAllocationList.length ? bulkAllocationList.map((eachMandate:any, index:number)=> {
                            const stageData = parseData(eachMandate?.mandateStages)
                            return (  
                                <tr className={`mandate-table-row`} key={index}>
                                    <td className='sticky-left'>
                                    <BulkAllocationRecuriterCard
                                        option={{
                                            recuiterName: eachMandate?.recruiterName|| "-",
                                            recuiterRole: eachMandate?.roleName || "-",
                                            disableEdit: eachMandate?.userActiveStatus ?? false
                                        }}
                                        candidateData = {parseData(eachMandate?.mandateDetails?.candidate_details)}
                                        handleEditAllocation={() => {handleEditAllocation(eachMandate);setDeallocationUserId(eachMandate)}}
                                        handleUnassignAllocation={() => {setDeallocationUserId(eachMandate);setDeallocationPopupStatus(true)}}
                                        recuriterStatus= {eachMandate?.status}
                                    />
                                    </td>
                                    <td><BulkAllocationDetailCard eachData={eachMandate}/></td>
                                    <td >
                                        <Box className="workManagTableKPISect">
                                        <Tooltip title="CV Submission">
                                            <div className='box'>
                                                <div className={`side-line`}></div>

                                                <Typography className= "job-count">
                                                    {stageData?.find((x: any) => x?.stage_name === 'CV Submission')?.status_count || 0}
                                                </Typography>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="CCM1">
                                            <div className='box'>
                                                <div className={`side-line`}></div>

                                                <Typography className= "job-count">
                                                    {stageData?.find((x: any) => x?.stage_name === 'CCM1')?.status_count || 0}
                                                </Typography>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="CCM2">
                                            <div className='box'>
                                                <div className={`side-line`}></div>

                                                <Typography className= "job-count">
                                                    {stageData?.find((x: any) => x?.stage_name === 'CCM2')?.status_count || 0}
                                                </Typography>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Selection Stage">
                                            <div className='box'>
                                                <div className={`side-line`}></div>
                                                <Typography className= "job-count">
                                                    {stageData?.find((x: any) => x?.stage_name === 'Selection Stage')?.status_count || 0}
                                                </Typography>
                                            </div>
                                        </Tooltip>
                                        <Tooltip title="Joining Stage">
                                            <div className='box'>
                                                <div className={`side-line`}></div>

                                                <Typography className= "job-count">
                                                    {stageData?.find((x: any) => x?.stage_name === 'Joining & Post Joining')?.status_count || 0}
                                                </Typography>
                                            </div>
                                        </Tooltip>
                                        </Box>
                                    
                                    </td>
                                </tr>
                            )
                        }) : 
                        <tr className='mandate-table-row'>
                            <td colSpan={7}><div className='noMandateFound'><NoResultIcon />No Allocation</div></td>
                        </tr>
                        }

                    </tbody>
                </table>
                </Box>
                :
                <Box className="week-view-container weekBulkContainerSect" >
            <Box className="week-view-table-container">
                <Box className="workManagStickyHead">
                    <Box className="week-view-filter" >
                    
                        <Box className="Kpi-tab">
                            <div onClick={()=>{
                                SetActive("Submissions")
                                setBulkStageId(3)
                                }} className={`week-view-box-container  ${active == "Submissions" && 'active'}`}>
                                <div className='topline'></div>
                                <Typography className='week-view-header-text'>
                                    Submissions
                                </Typography>
                            </div>

                            <div onClick={()=>{
                                SetActive("CCM 1")
                                setBulkStageId(4)
                                }} className={`week-view-box-container  ${active == "CCM 1"&&'active'}`}>
                                <div className='topline'></div>
                                <Typography className='week-view-header-text'>
                                    CCM 1
                                </Typography >
                            </div>

                            <div onClick={()=>{
                                SetActive("CCM 2")
                                setBulkStageId(11)
                                }} className={`week-view-box-container  ${active == "CCM 2"&&'active'}`}>
                                <div className='topline'></div>
                                <Typography className='week-view-header-text'>
                                    CCM 2
                                </Typography>
                            </div>

                            <div onClick={()=>{
                                SetActive("Selects")
                                setBulkStageId(5)
                                }} className={`week-view-box-container  ${active == "Selects"&&'active'}`}>
                                <div className='topline'></div>
                                <Typography className='week-view-header-text'>
                                    Selects
                                </Typography>

                            </div>


                            <div onClick={()=>{
                                SetActive("Joints")
                                setBulkStageId(6)
                                }} className={`week-view-box-container  ${active == "Joints"&&'active'}`}>
                                <div className='topline'></div>
                                <Typography className='week-view-header-text'>
                                    Joins
                                </Typography>
                            </div>

                        </Box>

                    </Box>
                    <Box className="week-view-header">


                            <div className='week-view-box-container'>
                                <Typography className='week-view-header-text'>
                                    {startOfWeek}
                                </Typography>
                            </div>

                            <div className='week-view-box-container'>
                                <Typography className='week-view-header-text'>
                                   {secondDayOfWeek}
                                </Typography >
                            </div>

                            <div className='week-view-box-container'>
                                <Typography className='week-view-header-text'>
                                   {thirdDayOfWeek}
                                </Typography>
                            </div>

                            <div className='week-view-box-container'>
                                <Typography className='week-view-header-text'>
                                   {fourthDayOfWeek}
                                </Typography>

                            </div>
                            
                            <div className='week-view-box-container'>
                                <Typography className='week-view-header-text'>
                                   {fifthDayOfWeek}
                                </Typography>

                            </div>
                            
                            <div className='week-view-box-container'>
                                <Typography className='week-view-header-text'>
                                   {sixthDayOfWeek}
                                </Typography>

                            </div>


                            <div className='week-view-box-container'>
                                <Typography className='week-view-header-text'>
                                    {endOfWeek}
                                </Typography>
                            </div>



                        </Box>
                </Box>

                    <Box className={`week-view-table ${bulkAllocationList.length == 0 ? "weeklyTableBlk" : ""}`}>                    
                        {bulkAllocationList && bulkAllocationList.length > 0 ? 
                        <>
                        {bulkAllocationList.map((eachRecruiter: any, index: number) => {
                            return (
                                <Box className="weeklyBulkViewData" key={index}>
                                <BulkAllocationRecuriterCard
                                        option={{
                                            recuiterName: eachRecruiter?.recruiterName|| "-",
                                            recuiterRole: eachRecruiter?.roleName || "-"
                                        }}
                                        candidateData = {parseData(eachRecruiter?.mandateDetails?.candidate_details)}
                                        handleEditAllocation={() => {handleEditAllocation(eachRecruiter);setDeallocationUserId(eachRecruiter)}}
                                        handleUnassignAllocation={() => {setDeallocationUserId(eachRecruiter);setDeallocationPopupStatus(true)}}
                                        recuriterStatus= {eachRecruiter?.status}
                                    />
                                    <BulkRecruiterContainer viewType={viewType} recruiterData={eachRecruiter} key={index}  activeTabStatus= {active}/>
                                </Box>
                            )
                        })}
                        </>
                        : <div className='noMandateFound'><NoResultIcon />No Allocation</div>
                        }
                    </Box>
                </Box>
            </Box>
            }
            {isAssignPopup && <AddMandatePopUp
                isPopupOpen={isAssignPopup}
                handlePopupClose={() => { setIsAssignPopup(false)}}
                candidateDetailsData={selectedCandidate}
                recruiterID={deallocationUserId?.recruiterId}
                editAllocateData={editAllocateData}
                isEdit={isEdit}
                isUnassign={isUnassign}
                selectedMandateTag={selectedMandateTag}
                path={"allocation"}
            />}
            {deallocationPopupStatus && 
            <BulkAllocationConfirmationPopup 
            title={'Warning'} 
            subtitle={`Are you sure you want to deallocation`}
            isOpen={deallocationPopupStatus} 
            handleClose={() => {setDeallocationPopupStatus(false);setDeallocationUserId("")}} 
            handleConfirm={() => {deallocation(null)}} 
            OkButtonText={deallocationUserId?.status == 65 ? 'reallocation' : 'deallocation'}
            />
            }
            {reSubmissionPopupOpen && <BulkAllocationConfirmationPopup 
                title={'Confirmation'} 
                subtitle={`Submissions was already started by ${deallocationUserId?.recruiterName} do want to deallocate ?`}
                isOpen={reSubmissionPopupOpen} 
                handleClose={() => {setReSubmissionPopupOpen(false)}} 
                handleConfirm={() => {deallocation("confirmation")}}
                cancelButtonText={"No"}
                OkButtonText={"Yes"}
                />}
        </>
    )
}

export default BulkAllocationRecuriterTable


