import { useEffect, useState } from 'react'
import { List, Collapse } from '@mui/material'
import { RouteItem } from './RouteItem'
import routes from '../../../config/routes'
import { Route } from '../../../types/Route'
import { useAppDispatch, useAppSelector } from '../../../store/hooks'
import {expanddrawerMenu } from '../../../features/mandateDetail/DrawerReducer'
import { leftdrawertoggleclose } from '../../../features/toggle/ToggleReducer'

interface RoutesProps {
  open: boolean | undefined
}

export const Routes = ({ open }: RoutesProps) => {
  const [routesState, setRoutesStage] = useState<Route[]>(routes)
  const { userRole, roleId } = useAppSelector((state) => state.UserDetail)
  const configChatgpt = useAppSelector((state)=>state?.SystemConfig?.chatGptShow);
  const drawerLeftOpenState = useAppSelector((state)=>state?.drawertoggle?.leftopen);
  const [toggleSubMenuSection, setToggleSubMenuSection] = useState(false)  
  const subMenuList = ["reports","analyticsSpace"]
  const urlPath:any = window.location.href.split('/').at(-1)
  const [subMenuSelected, setSubMenuSelected] = useState(subMenuList.includes(urlPath) ? new Date().getTime() : "")
  const dispatch = useAppDispatch()  
  const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
  const handleMenuClick = (route: Route) => {
    const items = routesState.map((item) => {
      if (item.key === route.key) {
        item.expanded = !item.expanded
      }
      return item
    })
    setRoutesStage(items)
  }

  useEffect(() => {
    setSubMenuSelected(subMenuSelected)
  },[subMenuSelected])

  const toggleSubMenuHandler = (toggleObj:any,type:string) => {
    const routesStateObj = routesState.map((routeObj:any) => {      
        if(routeObj?.key == "router-submenu-data-source" || routeObj?.key == "router-dashboard"){
          routeObj.subRoutes.map((childRoute:any) => {
            if(childRoute?.key == toggleObj?.key){
              childRoute.expanded = !childRoute.expanded;
            }
          })          
        }
      return routeObj
    })
    setRoutesStage(routesStateObj)
    // setToggleSubMenuSection((!drawerLeftOpenState && subMenuSelected) ? true : toggleStatus)
    dispatch(expanddrawerMenu())
    dispatch(leftdrawertoggleclose())
  }

  function hasTrueSelected(obj:any) {
    for (const key in obj) {
        if (typeof obj[key] === 'object') {
            if (hasTrueSelected(obj[key])) {
                return true;
            }
        } else if (key === 'selected' && obj[key] === true) {
            return true;
        }
    }
    return false;
}

  const checkSubMenuEnable = (currentMenu:any) => {
    console.log("checkSubMenuEnable",currentMenu)
    return currentMenu == "router-submenu-dashboard" ? true : hasTrueSelected(currentMenu == "router-user-management" ? userPermissions["user_management_module"] : currentMenu == "router-submenu-reports" ? userPermissions["reports_module"] : "")
  }

  const checkMenuEnable = (currentMenu:string, submenu:any) => {
    let enableMenuStatus = false;
    const selectedModule:any = currentMenu == "mandates" ? "mandate_module" : currentMenu == "candidate_search" ? "candidate_module" : currentMenu == "work_management" ? "work_management_module": currentMenu == "my_tasks" ? "task_module" : currentMenu == "scheduler" ? "scheduler_module" : currentMenu == "candidate_pipeline_report" ? "submission_report_module" : currentMenu == "analytics_space" ? "analytics_space_module" : currentMenu == "artemis_users" ? "user_module" : currentMenu == "roles_access" ? "role_module" : ""
    const currentJsonObj = submenu.length > 0 ? userPermissions[submenu == "router-data-source" ? "reports_module" : "user_management_module"][selectedModule] :userPermissions[selectedModule]
    if(currentJsonObj != undefined){     
        if(hasTrueSelected(currentJsonObj)){
          enableMenuStatus = true
        }
    }else{
      enableMenuStatus = true
    }
    
    return enableMenuStatus
  }
  return (
    <>
      <List component='nav' className='list xpheno-navigationList'>
        {routesState.map((route: Route) => (
          <div key={route.key}>
            {(route.subRoutes && route.menuType != 1) ? (
              <>
                {!route.hideMenu && <RouteItem
                  key={`${route.key}`}
                  route={route}
                  hasChildren
                  open={open}
                />}
                <Collapse in={route.expanded} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    {route.subRoutes.map((sRoute: Route) => {
                      const menuLabel:any = sRoute?.menuHideLable
                      if(("subRoutes" in sRoute)){                        
                        const subClassName = sRoute.description !=undefined ? sRoute.description.replaceAll(" ", "") : "";
                        return (
                          <>
                          <div className={`subMenuRoot ${subClassName} ${sRoute.childList.includes(urlPath) ? "subMenuHighlight" : ""}`}>{(checkSubMenuEnable(sRoute?.key) && userRole != "Admin") ? <RouteItem key={sRoute.key} route={sRoute} nested={false} open={open} subMenuToggle={true} subMenuExpand={sRoute.expanded} setSubMenuSelected={setSubMenuSelected} toggleSubMenuHandler={toggleSubMenuHandler}/>:(userRole == "Admin") ? <RouteItem key={sRoute.key} route={sRoute} nested={false} open={open} subMenuToggle={true} subMenuExpand={sRoute.expanded} setSubMenuSelected={setSubMenuSelected} toggleSubMenuHandler={toggleSubMenuHandler}/> : null}</div>
                          {sRoute?.expanded && <div className='subMenuLevelMenu'>
                            {sRoute?.subRoutes?.map((subRoute: any,index:any) => {                              
                              const menuLabel:any = subRoute?.menuHideLable;    
                              const enableMenuStatus = userPermissions[(menuLabel == "artemis_users" || menuLabel == "roles_access") ? "user_management_module" : (menuLabel == "timeToFillCandidate" || menuLabel == "postSelectCandidate") ? "dashboard" : "reports_module"][menuLabel == "jo_ageing_report" ? "jo_aging": menuLabel == "over-all-dashboard" ? "dashboard" : menuLabel == "artemis_users" ? "user_module" : menuLabel == "roles_access" ? "role_module" : menuLabel == "candidate_pipeline_report" ? "submission_report_module" : menuLabel == "timeToFillCandidate" ? "time_to_fill_candidate_report" : menuLabel == "postSelectCandidate" ? "post_select_candidate" : ""];
                              return <div key={index} className={(!hasTrueSelected(enableMenuStatus) && enableMenuStatus != undefined) ? "disableMenu" :""}><RouteItem key={subRoute.key} route={subRoute} nested open={open} setSubMenuSelected={setSubMenuSelected} subMenuEnable={true}/></div>                    
                            })}
                          </div>}                   
                          </>
                        )
                      }
                      else if (sRoute.key === 'router-chatgpt') {
                        if (configChatgpt === "false") {
                          return <RouteItem key={sRoute.key} route={sRoute} nested open={open} />;
                        } else {
                          return null;
                        }
                      } 
                      else if (checkMenuEnable(menuLabel,"") && userRole != "Admin") {
                        return <RouteItem key={sRoute.key} route={sRoute} nested open={open} setSubMenuSelected={setSubMenuSelected} subMenuEnable={false}/>;
                      }else if(userRole == "Admin"){
                        return <RouteItem key={sRoute.key} route={sRoute} nested open={open} setSubMenuSelected={setSubMenuSelected} subMenuEnable={false}/>;
                      } 
                      else {
                        return null;
                      }
                    })}
                  </List>
                </Collapse>
              </>
            ) : (
              <>
                {("subRoutes" in route) ?
                <>
                <Collapse in={route.expanded} timeout='auto' unmountOnExit>
                  <List component='div' disablePadding>
                    {route.subRoutes?.map((sRoute: Route) => {
                      const menuLabel:any = sRoute?.menuHideLable
                      if(("subRoutes" in sRoute)){
                        const subClassName = sRoute.description !=undefined ? sRoute.description.replaceAll(" ", "") : "";
                        return (
                          <>
                          <div className={`subMenuRoot ${subClassName} ${sRoute.childList.includes(urlPath) ? "subMenuHighlight" : ""}`}>{(checkSubMenuEnable(sRoute?.key) && userRole == "Admin") ? <RouteItem key={sRoute.key} route={sRoute} nested={false} open={open} subMenuToggle={true} setSubMenuSelected={setSubMenuSelected} toggleSubMenuHandler={toggleSubMenuHandler}/>:(userRole == "Admin") ? <RouteItem key={sRoute.key} route={sRoute} nested={false} open={open} subMenuToggle={true} setSubMenuSelected={setSubMenuSelected} toggleSubMenuHandler={toggleSubMenuHandler}/> : null}</div>
                          {sRoute?.expanded && <div className='subMenuLevelMenu'>
                            {sRoute?.subRoutes?.map((subRoute: any) => {
                                return <RouteItem key={subRoute.key} route={subRoute} nested open={open} setSubMenuSelected={setSubMenuSelected} subMenuEnable={true}/>                           
                            })}
                          </div>}                   
                          </>
                        )
                      }
                      else {
                        return null;
                      }
                    })}
                  </List>
                </Collapse>
              </>             
                    : 
                    <RouteItem key={route.key} route={route} nested={false} open={open} setSubMenuSelected={setSubMenuSelected} />
                    } 
              </>              
            )}
          </div>
        ))}
      </List>
    </>
  )
}
