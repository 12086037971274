import React, { useEffect, useState } from 'react'
import './MandateProfile.scss'
import { ReactComponent as ForgotArrowIcon } from '../../../assets/icons/arrow-left.svg'
import {
  Box,
  ToggleButtonGroup,
  ToggleButton,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  Avatar,
  Button,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
  Popover,
  Autocomplete,
  IconButton,
  Tooltip
} from '@mui/material'
import { PRIMARY3, PRIMARY1, TERTIARY3, TERTIARY12, PRIMARY2 } from '../../../utils/constants'
import { AppButton } from '../../AppComponents/AppButton'
import { ReactComponent as DownloadIcon } from '../../../assets/icons/download.svg'
import { ReactComponent as FunnelIcon } from '../../../assets/icons/funnel.svg'
import { ReactComponent as ArrowUpIcon } from '../../../assets/icons/arrow-up.svg'
import { ReactComponent as ArrowDownIcon } from '../../../assets/icons/arrow-down.svg'
import { ReactComponent as DocumentIcon } from '../../../assets/icons/documentprof.svg'
import { ReactComponent as DocumentIcon1 } from '../../../assets/icons/mandateprofile/profioleicoprof.svg'
import { ReactComponent as DocumentIcon2 } from '../../../assets/icons/mandateprofile/interviewstageico.svg'
import { ReactComponent as DocumentIcon3 } from '../../../assets/icons/mandateprofile/joiningstageico.svg'
import { ReactComponent as CVSCreening } from '../../../assets/icons/mandateprofile/CVSCreening.svg'
import { ReactComponent as InterviewStage } from '../../../assets/icons/mandateprofile/interviewStage.svg'
import { ReactComponent as SelectionStage } from '../../../assets/icons/mandateprofile/selectionStage.svg'
import { ReactComponent as JoiningStage } from '../../../assets/icons/mandateprofile/JoiningStage.svg'
import { ReactComponent as FinalSelect } from '../../../assets/icons/mandateprofile/finalSelect.svg'
import { ReactComponent as InvoicingLifeCycle } from '../../../assets/icons/mandateprofile/invoicingLifeCycle.svg'
import { ReactComponent as SortIcon } from '../../../assets/icons/sort-arrow.svg'
import { ReactComponent as GridIcon } from '../../../assets/icons/grid.svg'
import { ReactComponent as ListIcon } from '../../../assets/icons/list.svg'
import { ReactComponent as EditIcon } from '../../../assets/icons/edit.svg'
import { ReactComponent as ShowMoreIcon } from '../../../assets/icons/showmore.svg'
import { useLocation, useNavigate } from 'react-router-dom'
import { ReactComponent as ClientLogo } from '../../../assets/images/defaultUserIcon.svg'
import { getMandateLocation, getMandateStages, getMandateStageStatus,getMandateLocationAssignees, getMandateStagesbyOrder, getCandidateHireStage, getMandateCollaborator } from '../../../services/MandateService'
import { ReactComponent as RotateIcon } from '../../../assets/icons/reset.svg'
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import { getCandidateList, initiateRpa, getExportToExcel, getCandidateAssignedMandates, GetCandidateDetails } from '../../../services/CandidateService'
import { Loading } from '../../../pages/Loading'
import { ReactComponent as LoadMoreIcon } from '../../../assets/icons/load-more.svg'
import _ from 'lodash'
import { stringAvatar } from '../../../utils/design-utils'
import { convertToDisplayDate } from '../../../utils/date-utils'
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined'
import CalendarTodayOutlinedIcon from '@mui/icons-material/CalendarTodayOutlined'
import BusinessCenterOutlinedIcon from '@mui/icons-material/BusinessCenterOutlined'
import LocationCityIcon from '@mui/icons-material/LocationCity'
import { ReactComponent as FacesheetIcon } from '../../../assets/icons/facesheet.svg'
import { ReactComponent as LocationIcon } from '../../../assets/icons/location.svg'
import { useAppSelector,useAppDispatch } from '../../../store/hooks'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import GroupStrick from '../../../assets/images/default-avatar.png'
import MandateLocationListModel from './MandateLocationListModel'
import { toast } from "react-toastify";
import { ReactComponent as InfoCircleIcon } from '../../../assets/icons/info-circle.svg'
import { HiringModalPopup } from '../../CandidateDetails/CandidatePersonalDetails/CandidateTracking'
import { ReactComponent as ClippadIcon } from '../../../assets/icons/clippad.svg'
import { ReactComponent as ViewNotesIcon } from '../../../assets/icons/ViewNotes.svg'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash-icon.svg'
import { ReactComponent as SharecvIcon } from '../../../assets/icons/share-cv.svg'
import { ReactComponent as PrescreenIcon } from '../../../assets/icons/prescreen-icon.svg'
import { ReactComponent as NoResultFound } from '../../../assets/icons/no-result-found.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import AssignMandateLocationPopup from './AssignMandateLocationPopup/AssignMandateLocationPopup'
import MandateProfileKanban from './KanbanView/MandateProfileKanban';
import {setProfilePersist,resetProfilePersist} from '../../../features/mandateDetail/profilePersistReducer';
import InfoIcon from '@mui/icons-material/Info';
const initialFilter = {
  keyword:"",
  trackStatusId:null,
  trackCreatedBy:[]
}

const icon = <CheckBoxOutlineBlankIcon fontSize='small' />
const checkedIcon = <CheckBoxIcon fontSize='small' />

const MandateProfile = () => {
  const location = useLocation()
  const dispatch = useAppDispatch();
  const paramData = location?.state
  const { mandateData, posIndex } = paramData
  const navigate = useNavigate()
  const persistProfile = useAppSelector((state)=>state?.profilePersistor)
  const [activeTab, setActiveTab] = useState<number>(persistProfile?.tabValue?persistProfile?.tabValue:0)
  const [mandatelocationLists, setMandateLocationLists] = useState<any[]>(persistProfile?.locationOptionsData&&persistProfile?.locationOptionsData?.length>0?persistProfile?.locationOptionsData :mandateData?.mandateLocation ?? [])
  const [mandatelocationIndex, setMandateLocationIndex] = useState<number>(persistProfile?.location&&persistProfile?.location!=""?persistProfile?.location:posIndex ?? 0)
  const [isFilterOpen, setIsFilterOpen] = useState<boolean>(false)
  const [sortByVal, setSortByVal] = useState<string>('name')
  const [listState, setListState] = useState<any>({
    page: 1,
    size: 10,
  })
  const [isLoading, setLoading] = useState(true)
  const [searchListCount, setSearchResultCount] = useState(0)
  const [searchListData, setSearchListData] = useState<any[]>([])
  const [isLoadingNextPage, setLoadingNextPage] = useState(false)
  const showLoadingNextPage = () => setLoadingNextPage(true)
  const hideLoadingNextPage = () => setLoadingNextPage(false)
  const [refreshCounter, setRefreshCounter] = useState(0)
  const reloadApi = () => setRefreshCounter((refreshCounter) => refreshCounter + 1)
  const showLoading = () => setLoading(true)
  const hideLoading = () => setLoading(false)
  const [stageTabData, setStageTabData] = useState<any[]>([])
  const [filteredStageList, setFilteredStageList] = useState<any[]>()
  const [filterData, setFilterData] = useState<any>(initialFilter)
  const [filterDataCopy, setFilterDataCopy] = useState<any>(initialFilter)
  const [statusList, setStatusList] = useState<any[]>([])
  const [collabList, setCollabList] = useState<any[]>([])
  const userRole = useAppSelector((state)=> state?.UserDetail?.userRole)
  const [assigneeList, setAssigneeList] = useState<any[]>([])
  const [selectedAssignees, setSelectedAssignees] = useState<any[]>([])
  const [selectedCollaborator, setSelectedCollaborator] = useState<any[]>([])
  const [isListPopOpen, setIsListPopOpen] = useState<boolean>(false)
  const [selectedCandidate, setSelectedCandidate] = useState<any>({})
  const [bulkCandidateStatusId,setBulkCandidateStatusId] = useState<any>([])
  const [selectedItemIds, setSelectedItemIds] = useState<any[]>([]);
  const { userId } = useAppSelector((state) => state?.UserDetail)  
  const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
  const { roleId } = useAppSelector((state) => state.UserDetail)

  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [updateHireProp, setUpdateHireProp] = useState({})
  const [viewType, setViewType] = useState<string>("grid")

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)
  const [popupTag, setPopupTag] = useState<string>("")
  const [isAssignPopup, setIsAssignPopup] = useState<boolean>(false)
  const [defaultRollback,setDefaultRollback] = useState<boolean>(false)
  const [hireStatusList, setHireStatusList] = useState<any[]>([])
  const [filterUpdatedStatus,setFilterUpdatedStatus] = useState({
    status: false,
    timeStamp: ""
  })
  const sideMenuOpen = useAppSelector((state) => state.drawertoggle.leftopen)
 
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, tag:string) => {
    setAnchorEl(event.currentTarget)
    setPopupTag(tag)
  }
  
  const handleClose = () => {
    setAnchorEl(null)
    setPopupTag("")
  }

  const open = Boolean(anchorEl)
  const id = open ? 'simple-popover' : undefined

  const tabData = [
    {
      icon: (
        <div
          style={{
            backgroundColor: '#727376',
            borderRadius: '10px',
            width: '48px',
            height: '48px',
            display: "flex",
            alignItems:"center",
            justifyContent:"center"
          }}
        >
          <DocumentIcon1 />
        </div>
      )
    },    
    {
      icon: (
        <div
          style={{
            backgroundColor: '#727376',
            borderRadius: '10px',
            width: '48px',
            height: '48px',
            display: "flex",
            alignItems:"center",
            justifyContent:"center"
          }}
        >
          <CVSCreening />
        </div>
      )
    },
    {
      icon: (
        <div
          style={{
            backgroundColor: '#727376',
            borderRadius: '10px',
            width: '48px',
            height: '48px',
            display: "flex",
            alignItems:"center",
            justifyContent:"center"
          }}
        >
          <DocumentIcon />
        </div>
      ),
    },
    {
      icon: (
        <div
        style={{
          backgroundColor: '#727376',
          borderRadius: '10px',
          width: '48px',
          height: '48px',
          display: "flex",
          alignItems:"center",
          justifyContent:"center"
        }}
        >
          <InterviewStage/>
        </div>
      )
    },
    {
      icon: (
        <div
          style={{
            backgroundColor: '#727376',
            borderRadius: '10px',
            width: '48px',
            height: '48px',
            display: "flex",
            alignItems:"center",
            justifyContent:"center"
          }}
        >
          <FinalSelect/>
        </div>
      )
    },
    {
      icon: (
        <div
          style={{
            backgroundColor: '#727376',
            borderRadius: '10px',
            width: '48px',
            height: '48px',
            display: "flex",
            alignItems:"center",
            justifyContent:"center"
          }}
        >
          <SelectionStage />
        </div>
      )
    },
    {
      icon: (
        <div
          style={{
            backgroundColor: '#727376',
            borderRadius: '10px',
            width: '48px',
            height: '48px',
            display: "flex",
            alignItems:"center",
            justifyContent:"center"
          }}
        >
          <JoiningStage />
        </div>
      )
    },
    {
      icon: (
        <div
          style={{
            backgroundColor: '#727376',
            borderRadius: '10px',
            width: '48px',
            height: '48px',
            display: "flex",
            alignItems:"center",
            justifyContent:"center"
          }}
        >
          <InvoicingLifeCycle />
        </div>
      )
    }
  ]

  const tabIcon = (tab:number) => {
    switch(tab){
      default:
        return DocumentIcon1
    }
  }

  const fetchCandidateList = async () => {
    const queryParams = {
      ...listState,
      profileType: 'All',
      tabType: "mandate_stage"
    }
    setFilterDataCopy(filterData)
    const filterPayload = {...filterData, trackCreatedBy: selectedAssignees.join(),collaborators:selectedCollaborator.join()}
    const requestBody = {
      screenFlag: 'mandateprofile',
      filterMandateId: mandateData?.id,
      mlocationId: mandatelocationLists[mandatelocationIndex]?.id,
      stageId: stageTabData[activeTab]?.stageid,
      sortColumn: sortByVal,
      sortDirection: 'asc',
      ...filterPayload
    }
    const { page } = listState
    try {
      const res = await getCandidateList(requestBody, queryParams,"")
      const listItems = _.get(res, 'data.candidates', [])
      const listCount = _.get(res, 'data.totalCount', 0)
      setSearchResultCount(listCount)
      if(filterUpdatedStatus.status){
        setSelectedItemIds(listItems.map((data:any) => {
          return {
            id: data?.uniqueId,
            name: data?.name,
            position: data?.currentDesignation,
            currentCompany: data?.currentCompany,
            userId: data?.id
          }
        }))
      }
      if (page > 1) {
        setSearchListData((existingItems) => {
          return [...existingItems, ...listItems]
        })
      } else {
        setSearchListData(listItems)
      }
      hideLoadingNextPage()
      if (refreshCounter == 0) reloadApi()
    } catch (error) {
      console.log(error)
      if (page === 1) {
        setSearchListData([])
      }
      hideLoading()
      hideLoadingNextPage()
    }
  }

  const getExportExcel = async () => {
    const queryParams = {
      ...listState,
      size: searchListCount,
      profileType: 'All',
    }

    const filterPayload = {...filterData, trackCreatedBy: selectedAssignees.join()}
    const requestBody = {
      screenFlag: 'mandateprofile',
      filterMandateId: mandateData?.id,
      mlocationId: mandatelocationLists[mandatelocationIndex]?.id,
      stageId: stageTabData[activeTab]?.stageid,
      sortColumn: sortByVal,
      sortDirection: 'asc',
      ...filterPayload
    }
    const { page } = listState
    try{
     const res = await getExportToExcel(requestBody, queryParams)
      if(res?.status === 200){
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `CandidateDetails${Date.now()}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }
      
    }catch(error){
      console.log(error)
    }
  }

  const defaultApiCall = () => {
    setListState({
      page: 1,
      size: 10,
    })
  }

  const getStageData = async () => {
    const manId = mandateData?.id
    const posId = mandatelocationLists[mandatelocationIndex]?.id
    try {
      const res = await getMandateStages(manId, posId)
      if (res?.status === 200) {
        setStageTabData(res?.data?.result?.data)
        getStageStatus(res?.data?.result?.data)
        getCollabData(res?.data?.result?.data)
        getMandateLocationAssignee(res?.data?.result?.data)
      }
    } catch (error) {
      console.log(error)
    }
  }
  const getCollabData = async (stageData:any) => {
    const manId = mandateData?.id
    const posId = mandatelocationLists[mandatelocationIndex]?.id
    const stageTabDataValue = stageData != null ? stageData : stageTabData;
    if(stageTabDataValue.length>0)
    {
      try{
        const res = await getMandateCollaborator(manId, posId, stageTabDataValue[activeTab]?.stageid)
        if(res?.status === 200){
          setCollabList(res?.data?.result?.data)
        }
      }catch(error){
        console.log(error)
      }
    }
  }

  const getStageStatus = async (stageData:any) => {
    const manId = mandateData?.id
    const posId = mandatelocationLists[mandatelocationIndex]?.id
    // const stageId = stageData != null ? stageData[activeTab]?.stageid : stageTabData[activeTab]?.stageid
    const stageTabDataValue = stageData != null ? stageData : stageTabData;
    if(stageTabDataValue.length>0)
    {
      try{
        const res = await getMandateStageStatus(manId, posId, stageTabDataValue[activeTab]?.stageid)
        if(res?.status === 200) {
          setStatusList(res?.data?.result?.data)
        }
      }catch(error){
        console.log(error)
      }
    }
  }

  const getMandateLocationAssignee = async (stageData:any) => {
    const manId = mandateData?.id
    const posId = mandatelocationLists[mandatelocationIndex]?.id
    const stageTabDataValue = stageData != null ? stageData : stageTabData;
    try{
      const res = await getMandateLocationAssignees(manId, posId, stageTabDataValue[activeTab]?.stageid)
      if(res?.status === 200){
        setAssigneeList(res?.data?.result?.data)
      }
    }catch(error){
      console.log(error)
    }
  }

  const handlePage = () => {
    setListState((listState: any) => {
      return {
        ...listState,
        page: listState.page + 1,
      }
    })
  }

  const toggleSelectId = (data:any) => {

    const exsData = selectedItemIds.filter((list:any) => list?.id === data?.uniqueId)

    if(exsData.length > 0){
      const select = selectedItemIds.filter((list:any) => list?.id !== data?.uniqueId)
      setSelectedItemIds(select)
    }else{
      const canData:any = {
        id: data?.uniqueId,
        name: data?.name,
        position: data?.currentDesignation,
        currentCompany: data?.currentCompany,
        userId: data?.id
      }
  
      setSelectedItemIds([...selectedItemIds, canData])
    }
  }

  const onInitateRpaTrigger = (singleProfile:any=null) => {
    
    const selectedQueryItems:any = singleProfile ? [singleProfile] : [...selectedItemIds]
    // initiateRpa(selectedQueryItems).then(() => {
    //     toast.success('Deep search initiated successfully!')
    // }).catch((e) => {
    //     toast.error('Deep search initiation failed!')
    // })
    // setSelectedItemIds([])
  }

  const getMandateList = async (candId:any, mandateId:any) => {
    try{
      const res = await getCandidateAssignedMandates(candId)
      if(res?.status===200){
        let listStatus = res?.data?.result?.data ?? []        
        listStatus = listStatus.filter((data:any)=> { return mandateId == data?.id})
        setUpdateHireProp(listStatus[0])
      }
    }catch(error){
      console.log(error)
    }
  }
     
  const handleUpdateHirePopup = async(canData:any, statusType:string) => {
    // const obj = {
    //   id:mandateData?.id,
    //   mlocationid:mandatelocationLists[mandatelocationIndex]?.id,
    //   mpctrackstatus:canData?.statusId,
    //   stageid:canData?.stageId,
    //   stageorder:canData?.stageOrder,
    //   statusid: canData?.statusId,
    //   statusorder: canData?.statusOrder,
    //   uniqueid:mandateData?.mandateCode,
    //   isend: canData?.isEnd,
    //   subStageTrackings: canData?.subStages
    // }
    let bulkStatusCandidate:any = [];
    if(statusType == "bulkStatus") {
      bulkStatusCandidate = selectedItemIds.map((data:any) => {
        return data?.userId
      })
    }else{
      bulkStatusCandidate = []
    }
    await getMandateList(canData?.id,mandateData?.id)
    setBulkCandidateStatusId(bulkStatusCandidate)
    setSelectedCandidate(canData)
    const reqObj = {
      mandateId:  mandateData?.id,
      mlocationId: mandatelocationLists[mandatelocationIndex]?.id,
      candidateId:canData?.id
    }

    try{
      const res = await getCandidateHireStage(reqObj)
      if(res?.status === 200){        
        if(res?.data?.result?.list.includes(userId)){
          const dataList = res?.data?.result?.data
          const TagList = dataList.filter((data:any) => data?.createdbyfullname)
          setHireStatusList(dataList)
        }else{
          toast.warn('You are not authorized to access this page')  
        }        
      }
    }catch(error){
      console.log(error)
    }
    
    // const manId = mandateData?.id
    // const posId = mandatelocationLists[mandatelocationIndex]?.id
    // const stageid = canData?.stageId
    // const stageorder = canData?.stageOrder
    // const isend = canData?.isEnd

    // try{
    //   const res = await getMandateStagesbyOrder(manId, posId,canData?.id, stageid, stageorder, isend )
    //   if(res?.status === 200){
    //     const listData = res?.data?.result?.data ?? []
    //     setFilteredStageList(listData)
    //   }
    // }catch(error){
    //   console.log(error)
    // }  
  }

  useEffect(() =>{
    if(hireStatusList && hireStatusList.length > 0 )setIsOpen(true)
  },[hireStatusList])

  useEffect(() => {
    if(!persistProfile?.tabValue){
      if(persistProfile?.tabValue == 0) onLoadFunction()
      setActiveTab(0)
    }
    
    // setSearchListData([])
    // getStageData("location")    
    // if (refreshCounter !== 0) defaultApiCall()
    // fetchCandidateList()
  }, [mandatelocationIndex])

  useEffect(() => {
    showLoadingNextPage()
    if (refreshCounter !== 0) reloadApi()
  }, [listState])

  const onLoadFunction = (async () => {
    await getStageData()
    await fetchCandidateList()
  })

  useEffect(() => {    
    onLoadFunction()  
  }, [refreshCounter])

  useEffect(() => {
    if (refreshCounter == 0 && stageTabData.length > 0) fetchCandidateList()
  },[stageTabData])

  useEffect(() => {
    setSelectedAssignees([])
    setSearchListData([])
    setSelectedItemIds([])
    setIsFilterOpen(false)
    if (refreshCounter !== 0) defaultApiCall()
    getStageStatus(null)
    if (refreshCounter !== 0) getMandateLocationAssignee(null)
  }, [activeTab, sortByVal])
  const handleProfileNavigation = (data:any) => {
    dispatch(setProfilePersist({...persistProfile,mandateOptions:mandateData,locationOptions:mandatelocationLists,
      location:mandatelocationIndex,candidateId:data?.id,tabValue:activeTab}))
      setTimeout(()=>{
        navigate(`/candidate/${data?.id}`)
      },300)
  }
  const LoadMore = ({ isLoadingNextPage }: { isLoadingNextPage: boolean }) => {
    if (isLoadingNextPage) {
      return (
        <Box className='load-more-section-loading-container'>
          <Loading />
        </Box>
      )
    }
    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        className='load-more-container'
        onClick={handlePage}
      >
        <Typography component='span' className='common-load-more' sx={{ color: `${PRIMARY1}` }}>
          Load More <LoadMoreIcon className='load-more-icon' />
        </Typography>
      </Box>
    )
  }

  const renderEmptySearchBox = () => {
    return (
        <Box className="empty-data-card">
            <Box>
                <NoResultFound />
            </Box>
            <Box className="empty-search-message">
                No data found
            </Box>
        </Box>
    )
}

useEffect(() => {
  defaultApiCall()
},[filterUpdatedStatus])

const editCandidate = (id:any) => {
  GetCandidateDetails(id)
  .then((res:any) => {
    const candidateDetail = res.data;
    navigate(`/createCandidate`,{state:{candidateDetail: candidateDetail,status:"edit"}})
  })
  .catch((error:any) => {
    console.log('error', error.message)
  })      
}

  return (
    <div className='mandate-profile'>
      <div className='header-container'>
        <div className='left-pane'>
          <ForgotArrowIcon style={{cursor:"pointer"}} onClick={() => navigate(-1)} />
          <Typography className='primary-text'>All Profiles</Typography>
        </div>
        <div className='right-pane'>
        {viewType =="grid"?<AppButton
            variant='contained'
            margin={5}
            minwidth={40}
            bgcolor={isFilterOpen ? PRIMARY1 : TERTIARY3}
            textColor={isFilterOpen ? TERTIARY12 : PRIMARY3}
            onClick={() => {
              if(!isFilterOpen && refreshCounter == 1){
                getMandateLocationAssignee(null)
                getStageStatus(null)
              }
              setIsFilterOpen(!isFilterOpen)
            }}
          >
            <FunnelIcon style={{ marginRight: '5px' }} className='add-new-icon' />
            {isFilterOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
          </AppButton>:null}
          {viewType =="grid"?
          <AppButton onClick={(e:any)=>handleClick(e, "sort")}>
            <SortIcon />
          </AppButton>:null}
          <Popover
            id={id}
            open={popupTag === "sort"}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '10px',
                padding: '20px',
              }}
            >
              <Typography style={{ fontWeight: 600, fontSize: '14px' }}>Sort by</Typography>
              <Typography style={{ fontSize: '14px', color:`${sortByVal === "name" ? "#002882":""}` }} onClick={() => setSortByVal('name')}>
                By name
              </Typography>
              <Typography style={{ fontSize: '14px', color:`${sortByVal === "last_active" ? "#002882":""}` }} onClick={() => setSortByVal('last_active')}>
                By active
              </Typography>
              <Typography style={{ fontSize: '14px', color:`${sortByVal === "modified_on" ? "#002882":""}` }} onClick={() => setSortByVal('modified_on')}>
                By creation date
              </Typography>
            </Box>
          </Popover>
          <AppButton >
            <ToggleButtonGroup
              color='primary'
              value={viewType}
              exclusive
              onChange={(e, newAlignment: string) => {
                if(newAlignment){
                  setViewType(newAlignment)
                }
              }}
              aria-label='Platform'
              className='toggle-btn'
            >
              <ToggleButton value='grid'>
                <GridIcon />
              </ToggleButton>
              <ToggleButton value='list'>
                <ListIcon />
              </ToggleButton>
            </ToggleButtonGroup>
          </AppButton>
          <AppButton variant='contained' bgcolor={PRIMARY3} margin={5} minwidth={40} onClick={getExportExcel} disabled={searchListData?.length == 0}>
            <DownloadIcon className='down-svg' />{' '}
          </AppButton>
        </div>
      </div>
      <div className='mandate-container'>
        <div className='left-pane d-flex'>
          <div className='d-flex man-wrapper'>
            <ClientLogo />
            <div className='mand-text'>
              <span>{persistProfile?.mandate?.jobTitle?persistProfile?.mandate?.jobTitle:mandateData?.jobTitle ?? '-'}</span>
              <span className='mand-id'>{persistProfile?.mandate?.mandateCode??mandateData?.mandateCode}</span>
            </div>
          </div>
          <div className='mandate-location-dropdown'>
            <Select
              value={mandatelocationIndex}
              onChange={(e: any) => {
                setMandateLocationIndex(e.target.value)
              }}
              className='mandate-location-list'
              labelId='mandate-location-select-label'
            >
              {mandatelocationLists?.length &&
                mandatelocationLists?.map((mll: any, index: number) => (
                  <MenuItem key={index} value={index} className='mandate-location-lists-opt'>
                    <div className='primary-text'>{`${mll?.primaryLocation} (${mll?.jobOrders})`}</div>
                  </MenuItem>
                ))}
              {mandatelocationLists?.length === 0 && (
                <MenuItem value={0} disabled={true}>
                  No mandatelocations
                </MenuItem>
              )}
            </Select>
          </div>
        </div>
        <div className='right-pane d-flex' style={{flexWrap:"wrap", gap:"0"}}>
        {viewType =="grid"?
          <AppButton
            variant='contained'
            bgcolor={'#fff'}
            // onClick={handleClose}
            margin={5}
            textColor={PRIMARY3}
            fontSize='14px'
            fontWeight={400}
            label={'Chrome deep search'}
            style={{ border: '1px solid #002882', color: '#002882', opacity:"0.5" }}
            disabled
          />:null}
          {viewType =="grid"?
          <AppButton
            variant='contained'
            bgcolor={'#000'}
            margin={5}
            textColor={PRIMARY3}
            fontSize='14px'
            fontWeight={400}
            label={'Initiate Deep Search'}
            disabled={true}
            style={{ 
              color: '#fff', 
              // opacity: selectedItemIds?.length === 0 ? "0.5" : "1", 
              cursor: selectedItemIds?.length === 0 ? "not-allowed" : "pointer"
            }}
            onClick={()=> {if(selectedItemIds?.length !== 0)onInitateRpaTrigger()}}
          />:null}
        </div>
      </div>
      <div className='body-container'>
      {viewType =="grid"?  <Box className='stage-tab'>
          {stageTabData?.map((ele: any, index: number) => {
            const IconTab = tabIcon((index%7))
            return (
              <Box
                key={index}
                className={`stage-card ${activeTab === index ? 'active-card' : ''}`}
                onClick={() => {
                  setActiveTab(index); 
                  setFilterUpdatedStatus({status: false,timeStamp: new Date().getTime().toString()})
                  setFilterData({...filterData, trackStatusId:null,keyword:""})
                  setSelectedCollaborator([])
                }}
                style={{width: `calc(100% / ${stageTabData.length})`}}
              >
                {activeTab === index && <div className='active-bar'></div>}
                <Box className={`ele-card ${activeTab !== index && 'divider-right'}`}>
                  <div className='icon-cont'>{tabData?.[index]?.icon}</div>
                  {/* <IconButton className='icon-wrapper' >
                    <IconTab/>
                  </IconButton> */}
                  <div className='label-wrapper'>
                    <Typography className='ele-header'>{ele.stagename}</Typography>
                    <Typography className='ele-count'>{ele?.stagecount < 10 ? "0"+ele?.stagecount : ele?.stagecount }</Typography>
                  </div>
                </Box>
              </Box>
            )
          })}
        </Box>:null}
       
        
        {viewType =="list"? 
        <>
        <MandateProfileKanban
        mandateData ={mandateData}
        mandatelocationLists ={mandatelocationLists}
        setUpdateHireProp ={setUpdateHireProp}
        setSelectedCandidate={setSelectedCandidate}
        setIsOpen={setIsOpen}
        setFilteredStageList={setFilteredStageList}
        filteredStageList ={filteredStageList}
        isOpen={isOpen}
        setDefaultRollback ={setDefaultRollback}
        mandatelocationIndex ={mandatelocationIndex}
        /></>:null}
        <Box className={`mandateProfileListScrollSect ${(filterUpdatedStatus?.status && searchListData.length > 0) ? "mandateScrollProfile":""}`}>
        {isFilterOpen && (
          <div className='filter-container'>
            <Box className='search-wrapper'>
              <Typography
                className='primary-text'
                style={{
                  fontSize: '16px',
                  fontWeight: 600,
                }}
              >
                Filter ({searchListCount})
              </Typography>
              <Box className='d-flex' style={{ paddingTop: '10px' }}>
                <TextField
                style={{width:"100%"}}
                  // label="Search Mandate"
                  className='mandate-search'
                  placeholder='Search Keyword...'
                  value={filterData?.keyword}
                  onChange={(e:any) => setFilterData((prev:any)=>{
                    return {
                      ...prev,
                      keyword: e.target.value
                    }
                  })}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                  }}
                />
                {(userPermissions.mandate_module.mandate_profile_page.assignee_filter.selected && userRole != "Admin") && 
                <div style={{width:"100%", height:"100%"}}>
                  <Autocomplete
                    multiple
                    id='checkboxes-tags'
                    options={assigneeList ?? []}
                    limitTags={2}
                    sx={{ borderRadius: '10px' }}
                    disableCloseOnSelect
                    getOptionLabel={(option:any) => option?.assignedby}
                    // className={`inner-textfield`}
                    value={assigneeList.filter((item:any)=> selectedAssignees?.includes(item?.id))}
                    onChange={(e, value:any) => {
                      setSelectedAssignees(value?.map((ele:any)=>ele?.id))}}
                    renderOption={(props, option:any, { selected }) => (
                      <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                        <Avatar
                          alt='no-group'
                          src={GroupStrick}
                          sx={{ width: 30, height: 30, marginRight: '5px' }}
                        />
                        {option?.assignedby}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Assignee'
                      />
                    )}
                  />
                </div>}
                {userRole == "Admin" &&
                  <div style={{width:"100%", height:"100%"}}>
                  <Autocomplete
                    multiple
                    id='checkboxes-tags'
                    options={assigneeList ?? []}
                    limitTags={2}
                    sx={{ borderRadius: '10px' }}
                    disableCloseOnSelect
                    getOptionLabel={(option:any) => option?.assignedby}
                    // className={`inner-textfield`}
                    value={assigneeList.filter((item:any)=> selectedAssignees?.includes(item?.id))}
                    onChange={(e, value:any) => {
                      setSelectedAssignees(value?.map((ele:any)=>ele?.id))}}
                    renderOption={(props, option:any, { selected }) => (
                      <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                        <Avatar
                          alt='no-group'
                          src={GroupStrick}
                          sx={{ width: 30, height: 30, marginRight: '5px' }}
                        />
                        {option?.assignedby}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Assignee'
                      />
                    )}
                  />
                </div>
                }
                <Box style={{display:"flex",flexDirection:"column",gap:"5px",width:"100%"}}>
                  <FormControl>
                    <InputLabel>Status</InputLabel>
                    <Select 
                      label='Status' 
                      // placeholder='Status' 
                      className='select-dropdown'
                      value={filterData?.trackStatusId}
                      onChange={(e:any)=> {
                        setFilterData({...filterData,trackStatusId: e?.target?.value})
                      }}
                    >
                      {statusList.length === 0 && <MenuItem>No status data</MenuItem>}
                      {statusList.map((status:any, index:number)=>(
                        <MenuItem key={index} value={status?.id}>{status?.statusname}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <Box className="bulkStateFilter">
                      <InfoIcon /><p>(Select status and search filter for bulk status)</p>
                  </Box>
                </Box>   
                  <div style={{width:"100%", height:"100%"}}>
                  <Autocomplete
                    multiple
                    id='checkboxes-tags'
                    options={collabList ?? []}
                    limitTags={2}
                    sx={{ borderRadius: '10px' }}
                    disableCloseOnSelect
                    getOptionLabel={(option:any) => option?.assignedby}
                    // className={`inner-textfield`}
                    value={collabList.filter((item:any)=> selectedCollaborator?.includes(item?.id))}
                    onChange={(e, value:any) => {
                      setSelectedCollaborator(value?.map((ele:any)=>ele?.id))}}
                    renderOption={(props, option:any, { selected }) => (
                      <li {...props}>
                        <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} />
                        <Avatar
                          alt='no-group'
                          src={GroupStrick}
                          sx={{ width: 30, height: 30, marginRight: '5px' }}
                        />
                        {option?.assignedby}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label='Collaborators'
                      />
                    )}
                  />
                </div>        
              </Box>
            </Box>
            <Box
              className='filter-button-group'
              sx={{
                marginTop: { xs: '0.5rem', lg: '0rem' },
                justifyContent: { xs: 'center', lg: 'right' },
              }}
            >
              <Button type='submit' className='filter-submit-button' onClick={()=> {(filterDataCopy?.trackStatusId != filterData?.trackStatusId || filterData?.trackStatusId) ? setFilterUpdatedStatus({status: true,timeStamp: new Date().getTime().toString()}):defaultApiCall()}}>
                Search
              </Button>
              <Button
                className='filter-reset-button'
                variant='text'
                onClick={()=>{
                  setFilterData(initialFilter)
                  setSelectedItemIds([])
                  setFilterUpdatedStatus({status: false,timeStamp: new Date().getTime().toString()})
                  setSelectedAssignees([])
                  setSelectedCollaborator([])
                  defaultApiCall()
                }}
              >
                <RotateIcon />
                <Typography className='filter-reset-label'>Reset All</Typography>
              </Button>
            </Box>
          </div>
        )}
        {viewType =="grid" &&
        <>
        {(viewType =="grid" && searchListData?.length > 0)?<>
        <div className='profile-container'>
          {searchListData?.map((data: any, index: number) => (
            <div key={index} className='profile-card'>
              <div className='header'>
                <div className='d-flex'>
                  <div>
                    <Checkbox 
                      disabled={data?.status !== 'basic' && !filterUpdatedStatus?.status} 
                      checked={selectedItemIds.some((obj:any) => (obj?.id === data?.uniqueId))}
                      onClick={()=> toggleSelectId(data)}
                    />
                  </div>
                  <div>
                    <div className='d-flex' style={{ paddingBottom: '20px' }}>
                      <Avatar {...stringAvatar(data?.name)} className='avatar-div' onClick={()=>handleProfileNavigation(data)} style={{cursor:"pointer"}}/>
                      <div className='address-icons'>
                        <span className='primary-text' onClick={()=>handleProfileNavigation(data)} style={{cursor:"pointer"}}>{data?.name}</span>
                        <div className='d-flex'>
                          {data?.currentLocation && (
                            <div className='d-flex secondary-text'>
                              <PlaceOutlinedIcon />
                              <span className='secondary-text'>{data?.currentLocation ?? ''}</span>
                            </div>
                          )}
                          {data?.currentDesignation && (
                            <div className='d-flex secondary-text mandateProfilecCompany'>
                              <BusinessCenterOutlinedIcon />
                              <Tooltip title={data?.currentDesignation ?? ''}><span className='secondary-text'>
                                {data?.currentDesignation ?? ''}
                              </span>
                              </Tooltip>
                            </div>
                          )}
                          <div className='d-flex secondary-text'>
                            <CalendarTodayOutlinedIcon />
                            <span className='secondary-text'>{data?.experience ?? 0} years</span>
                          </div>
                          {data?.currentCompany && (
                            <div className='d-flex secondary-text'>
                              <LocationCityIcon />
                              <span className='secondary-text'>{data?.currentCompany ?? ''}</span>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='primary-text'>{data?.about}</div>
                  </div>
                </div>
                <div className='status-icon-wrapper'>
                  <span className='color-1'>{data?.status === 'basic' ? 'Basic' : 'Complete'}</span>
                  <Tooltip title={data?.statusName ? data?.statusName : "-"} placement='bottom' arrow>
                    <span style={{cursor:"pointer"}} className='color-2' onClick={()=> handleUpdateHirePopup(data,"singleStatus")}>{data?.statusName ? data?.statusName : "-"}</span>
                  </Tooltip>
                  <Box style={{height:"fit-content",cursor:"pointer"}} onClick={(e:any)=>handleClick(e, `showMore-${data?.id}`)}>
                    <ShowMoreIcon />
                  </Box>
                  <Popover
                    id={id}
                    open={popupTag === `showMore-${data?.id}`}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                    className='show-more-popup'
                  >
                    <Box
                      className="popup-wrapper"
                    >
                      <Box 
                        className="icon-wrapper" 
                        onClick={()=> {
                          setSelectedCandidate(data)
                          setIsAssignPopup(true)
                          handleClose()
                        }}
                      >
                        <ClippadIcon/>
                        <Typography className='pop-text'>Assign JO</Typography>
                      </Box>
                      <Box className="icon-wrapper">
                        <EditIcon/>
                        <Typography className='pop-text' onClick={() => {editCandidate(data.id)}}>Edit</Typography>
                      </Box>
                      <Box className="icon-wrapper disable-popup">
                        <SearchIcon/>
                        <Typography className='pop-text'>Manual Deep Search</Typography>
                      </Box>
                      {/* <Box className={`icon-wrapper ${data?.status !== 'basic' && "disable-popup"}`} */}
                      <Box className={`icon-wrapper disable-popup`}
                        // onClick={()=>{
                        //   if(!(data?.status !== 'basic')){
                        //     const canData:any = {
                        //       id: data?.uniqueId,
                        //       name: data?.name,
                        //       position: data?.currentDesignation,
                        //       currentCompany: data?.currentCompany
                        //     }
                        //     onInitateRpaTrigger(canData)
                        //     handleClose()
                        //   }
                        // }} 
                        >
                        <ViewNotesIcon className='notes-icon'/>
                        <Typography className='pop-text'>Initiate Deep Search</Typography>
                      </Box>
                      <Box className="icon-wrapper disable-popup">
                        <TrashIcon/>
                        <Typography className='pop-text'>Delete</Typography>
                      </Box>
                      <Box className="icon-wrapper disable-popup">
                        <SharecvIcon/>
                        <Typography className='pop-text'>Share CV</Typography>
                      </Box>
                      <Box className="icon-wrapper disable-popup">
                        <PrescreenIcon/>
                        <Typography className='pop-text'>Initiate Prescreening</Typography>
                      </Box>
                    </Box>
                  </Popover>
                </div>
              </div>
              <div className='footer'>
                <div className='d-flex'>
                  <div>
                    <span className='secondary-text'>Modified on:</span>
                    <span className='primary-text'> {convertToDisplayDate(data?.modifiedOn)}</span>
                  </div>
                  <div>
                    <span className='secondary-text'>Active:</span>
                    <span className='primary-text'> {convertToDisplayDate(data?.lastActive)}</span>
                  </div>
                  <div>
                    <span className='secondary-text'>Artemis Creation Date:</span>
                    <span className='primary-text'> {convertToDisplayDate(data?.lastActive)}</span>
                  </div>
                </div>
                <div className='d-flex' style={{ alignItems: 'center' }}>
                  <div className='d-flex' style={{ opacity: '0.5' }}>
                    <FacesheetIcon />
                    <span className='primary-text' style={{ color: '#002882' }}>
                      Generate Facesheet
                    </span>
                  </div>
                  <div 
                    className='d-flex' 
                    style={{ alignItems: 'center', gap:"4px", cursor:"pointer" }} 
                    onClick={()=>{
                      setSelectedCandidate(data)
                      setIsListPopOpen(true)
                    }}
                  >
                    <LocationIcon className='loc-icon' />
                    <span className='primary-text'>{data?.mandatelocationCount ?? 0} Mandate Locations</span>
                    <KeyboardArrowDownIcon style={{width:"16px", height:"16px"}} />
                  </div>
                </div>
              </div>
            </div>
          ))}
          {searchListCount > searchListData.length && (searchListData.length !== 0) && (
            <LoadMore isLoadingNextPage={isLoadingNextPage} />
          )}
        </div>
        </>: renderEmptySearchBox()}  
        </>
        } 
        {(filterUpdatedStatus?.status && searchListData.length > 0) && <div className={`bulkStatusUpdateSect ${sideMenuOpen ? "menuExpand":""}`}>
            <div className='bulkStatusUpdateCheckSect' onClick={()=> {
                  if(selectedItemIds.length == searchListData.length){
                    setSelectedItemIds([])
                  }else{
                    setSelectedItemIds(searchListData.map((data:any) => {
                      return {
                        id: data?.uniqueId,
                        name: data?.name,
                        position: data?.currentDesignation,
                        currentCompany: data?.currentCompany,
                        userId: data?.id
                      }
                    }))                    
                  }
                }}>
              <Checkbox
                checked={selectedItemIds.length == searchListData.length}                
              />
              <p>Select All <span>({searchListData.length} Profile Selected)</span></p>
            </div>
            <Box className="footer-wrapper"
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'flex-end',
                marginTop: '0px',
                gap: '15px',
              }}
            >
              <AppButton
                bgcolor='#E5E8EB'
                onClick={() => {
                  setSelectedItemIds([])
                  setFilterUpdatedStatus({status: false,timeStamp: new Date().getTime().toString()})
                }}
                textColor='#000'
                fontSize='14px'
                fontWeight={400}
                label='Cancel'
                width='96px'
              ></AppButton>
              <AppButton
                  disabled={selectedItemIds.length == 0}
                  bgcolor='#002882'                  
                  textColor='#ffffff'
                  fontSize='14px'
                  fontWeight={400}
                  label={"Bulk Status Update"}
                  onClick={() => {handleUpdateHirePopup(searchListData.filter((item:any) => item?.uniqueId == selectedItemIds[0]?.id)[0],"bulkStatus")}}
                ></AppButton>
            </Box>

          </div>
          }
        </Box>
          
      </div>
      {isListPopOpen && 
        <MandateLocationListModel
          selectedCandidate={selectedCandidate}
          onClose={()=> setIsListPopOpen(false)}
        />
      }
      {isOpen && 
        <HiringModalPopup 
          candidateDetailsData={selectedCandidate} 
          showMandate={false} 
          onclose={(e:boolean)=>{setIsOpen(false)}}
          selectedMandate={updateHireProp}
          isRemarkOpen={false}
          remarkData={""}
          getMandateList={() => {getStageData();defaultApiCall()}}
          hireStatusList={hireStatusList}
          bulkStatusCandidates={bulkCandidateStatusId}
        />
      }
      {isAssignPopup && 
        <AssignMandateLocationPopup
          isPopupOpen={isAssignPopup}
          handlePopupClose={()=> {setIsAssignPopup(false); defaultApiCall()}}
          candidateDetailsData={selectedCandidate}
        />
      }
    </div>
  )
}

export default MandateProfile