import { Avatar, Box, FormControl, FormControlLabel, Grid, InputAdornment, InputLabel, MenuItem, Modal, Select, Switch, TextField, Tooltip, Typography } from "@mui/material";
import './style.scss'
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import { ReactComponent as DropdownIcon } from '../../../../assets/icons/dropdown.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/modalcloseicon.svg'
import { ReactComponent as RemarkIcons } from '../../../../assets/icons/reject-reason.svg'
import { useState, useEffect, useRef } from "react";
import { MandateOptionTag } from "../../../AppComponents/AppMandateDropdown/AppMandateDropdown";
import { AppButton } from "../../../AppComponents/AppButton";
import {getCandidateAssignedMandates} from '../../../../services/CandidateService'
import { getMandateStagesbyOrder,getMandateStages, getStatusbyStage,getStatusAll, updateHireStages, getCandidateHireStage, getCandidateTrackingRemarks, downloadMandateFile } from '../../../../services/MandateService'
import {convertToDisplayDateTimeWithOutLocal } from "../../../../utils/date-utils";
import { stringAvatar } from '../../../../utils/design-utils'
import { RollBackModal } from "./RollBackModal";
import { AppErrorHandlerModal } from "../../../AppComponents/AppErrorHandlerModal";
import RadioButtonCheck from "../../../../assets/icons/RadioButtonCheck.svg"
import RadioButton from "../../../../assets/icons/RadioButton.svg"
import RadioButtonGray from "../../../../assets/icons/RadioButtonGray.svg"
import _ from "lodash"
import { useAppSelector } from "../../../../store/hooks";
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg';
import InfoVector from "../../../../assets/images/infoVector.svg";
import RemarkAttachment from "../../../../assets/images/remarkAttachment.svg";
import RemarkAttachIcon from "../../../../assets/icons/remarkAttachIcon.svg";
import RemarkDownload from "../../../../assets/icons/remarkDownload.svg"
import RemarkTrash from "../../../../assets/icons/remarkTrash.svg"
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { ReactComponent as RemarkCalendar } from "../../../../assets/icons/remarkCalendar.svg"
import moment from "moment";
import Download from "../../../../assets/icons/download.svg"
import { baseUrl } from "../../../../services/http-common";
import { toast } from "react-toastify";
import dayjs from "dayjs";

type Tprop = {
  candidateDetailsData: any;
  candidateNotificationState?:any
}

type TpropHiringModal = {
  candidateDetailsData: any;
  showMandate : boolean;
  onclose:(x:boolean)=>any;
  selectedMandate:any;
  getMandateList:any;
  isRemarkOpen: boolean;
  remarkData: string,
  hireStatusList?:any,
  bulkStatusCandidates?:any
}


const remarksRequired = "Remarks is required"

export const HiringModalPopup:React.FC<TpropHiringModal> = ({ 
  candidateDetailsData ,
  showMandate, 
  onclose,
  selectedMandate,
  getMandateList,
  isRemarkOpen,
  remarkData,
  hireStatusList,
  bulkStatusCandidates
}) => {
  const selectMandateSubStagingTrack = ('subStageTrackings' in selectedMandate) ? selectedMandate?.subStageTrackings != null ? selectedMandate?.subStageTrackings : null : selectedMandate?.sub_stage_trackings !=  null ? JSON.parse(selectedMandate?.sub_stage_trackings)[JSON.parse(selectedMandate?.sub_stage_trackings).length - 1]: null;
  const [selectStage, setSelectStage] = useState<any>(selectedMandate?.stageid)
  const [mandateSubtracking, setMandateSubtracking] = useState<any>(selectMandateSubStagingTrack ?? undefined)
  const [statusList, setStatusList] = useState<any[]>([])
  const [selectedStatus, setSelectedStatus] = useState<any>(selectedMandate?.mpctrackstatus ?? null);
  const [selectedCCM, setSelectedCCM] = useState<boolean>((mandateSubtracking?.subStage == "FINAL_CCM2" || mandateSubtracking?.subStage == "FINAL_CCM1") ? true : false)
  const [hideFieldsSection, setHideFieldsSection] = useState<any>({})
  const [remarkText, setRemarkText] = useState<string>()
  const [errors, setErrors] = useState({
    remark: "", status : "", joinDate : "", CTCValue: "", clearStatus: "", relievingDate: "", dojDate: ""
  })
  const businessUnitCode = useAppSelector((state)=>state?.UserDetail.businessUnitCode)
  const [successPopup, setSuccessPopup] = useState<boolean>(false)
  const [SuccessMessage, setSuccessMessage] = useState<string>('')
  const [currentStatus, setCurrentStatus] = useState<any>([])
  const isStageChanged = (selectedMandate?.mpctrackstatus !== selectedStatus || ((mandateSubtracking?.subStage == "FINAL_CCM2" || mandateSubtracking?.subStage == "FINAL_CCM1")? true : false) != selectedCCM)
  const [candidateTrackingFileUpload, setCandidateTrackingFileUpload] = useState<any>({})
  const [isInterview, setIsInterview] = useState<string>((selectedMandate?.stageid == 4 && selectMandateSubStagingTrack?.subStageId == 25) ? "normalCCM" : (selectedMandate?.stageid == 4 && selectMandateSubStagingTrack?.subStageId == 26) ? "finalCCM":"")
  const ref = useRef<any>(null);
  const reset = () => {
    ref.current.value = "";
    setCandidateTrackingFileUpload({})
  };
  const [selectedHiring, setSelectedHiring] = useState(hireStatusList.filter((data:any) => data?.createdbyfullname))
  const [selectedCurrentSubStage,setSelectedCurrentSubStage] = useState(selectedHiring ? JSON.parse(selectedHiring.at(-1).processing_stages) != null ? JSON.parse(selectedHiring.at(-1).processing_stages) : [] : [])
  const [selectedDate, setSelectedDate] = useState<any>(null);
  const [selectedRelievingDate, setSelectedRelievingDate] = useState<any>(null);
  const [selectedDojDate, setSelectedDojDate] = useState<any>(null);
  const [ctcBillable, setCtcBillable] = useState<any>("")
  const [selectedClearStatus, setSelectedClearStatus] = useState("");
  const [getRemarkAPIObj, setGetRemarkAPIObj] = useState<any>({})  
  const fileExtension = ["application/msword","application/vnd.openxmlformats-officedocument.wordprocessingml.document","application/pdf","text/plain","application/vnd.openxmlformats-officedocument.spreadsheetml.sheet","application/vnd.ms-excel","image/jpeg","image/jpeg","image/png","text/csv"]
  const getStatusList = async () => {
    try{
      const res = await getStatusAll(selectedMandate?.id,selectedMandate?.mlocationid,candidateDetailsData?.id, selectStage)
      if(res?.status === 200){
        setStatusList(res?.data?.result?.statuses)
        const selectedStage = res?.data?.result?.statuses.filter((item:any) => {return item?.id == selectedMandate?.mpctrackstatus})
        setHideFieldsSection(JSON.parse(selectedStage[0]?.props))
      }      
    }catch(error){
      console.log(error)
    }
  }

  useEffect(() => {
    const showData = selectedCurrentSubStage.at(-1);
    if(hideFieldsSection.joinDate && !isStageChanged){
      setSelectedDate(showData?.joindate)
      setCtcBillable(showData?.ctcbillable?.toString())
    }
    if(hideFieldsSection.show_relieving_date && !isStageChanged){
      setSelectedRelievingDate(showData?.relievingdate)
      setSelectedDojDate(showData?.doj_confirmed)
    }
    if(hideFieldsSection?.bgv && !isStageChanged){
      setSelectedClearStatus(showData?.bgvresult)
    }    
  },[hideFieldsSection])

  const getDateMaxValue = () => {
    const maxDate = new Date();
      const currentBuCode = hideFieldsSection?.validation.filter((item:any) => item?.buCode == businessUnitCode)
      if(currentBuCode[0]?.dmw == "m"){
        maxDate.setMonth(maxDate.getMonth() + currentBuCode[0]?.value)
      }else if(currentBuCode[0]?.dmw == "w"){
        maxDate.setDate(maxDate.getDate() + (currentBuCode[0]?.value * 7));
      }else if(currentBuCode[0]?.dmw == "d"){
        maxDate.setDate(maxDate.getDate() + currentBuCode[0]?.value);
      }
    return maxDate
  }

  const handleAttachmentDownload = async (filePath:any) => {
    const excelLinkSource = `filename=${filePath}`
    try {
      const response = await downloadMandateFile(excelLinkSource)
      if (response?.data) {
        window.location.href = (`${baseUrl}mandate/download?${excelLinkSource}`)
      } else {
        console.error("No Such File")
      }
    } catch (error) {
      console.error("Internal Server Error", error)
    }
  };

  const submitHireStage = async () => {
    const selectedStatusObj:any = statusList.filter((item:any) => {return item?.id == selectedStatus})
    const reqObj:any = {
      mandateId: selectedMandate?.id,
      mlocationId: selectedMandate?.mlocationid,      
      fromStatus:selectedMandate?.mpctrackstatus,
      toStatus:selectedStatus,
      remark:remarkText,
      stageId:selectedStatusObj[0]?.stage_id,
      subStageId: selectedStatusObj[0]?.sub_stage_id
    }
    if(bulkStatusCandidates?.length > 0){
      reqObj["candidateIds"] = bulkStatusCandidates,
      reqObj["updateMode"] = "BULK_UPDATE"
    }else{
      reqObj["candidateId"] = candidateDetailsData?.id
    }   
    
    if(isInterview == "normalCCM"){
      reqObj["subStage"] = selectedCCM ? 'FINAL_CCM1' : 'CCM1';
      reqObj["isEnd"] =  selectedCCM ? 1 : 0;
    }
    if(isInterview == "finalCCM"){
      reqObj["subStage"] = selectedCCM ? 'FINAL_CCM2' : 'CCM2';
      reqObj["isEnd"] =  selectedCCM ? 1 : 0;
    }
    if(Object.keys(candidateTrackingFileUpload).length > 0){
      reqObj["files"] = {
        [candidateTrackingFileUpload[0].name]: "Test"
      }
    }
    if(hideFieldsSection.joinDate){
      reqObj["joinDate"] = moment(new Date(selectedDate)).format('YYYY-MM-DD'),
      reqObj["ctcBillable"] = parseInt(ctcBillable)      
    }
    if(hideFieldsSection.show_relieving_date){
      reqObj["relievingDate"] = moment(new Date(selectedRelievingDate)).format('YYYY-MM-DD'),
      reqObj["dojConfirmed"] = moment(new Date(selectedDojDate)).format('YYYY-MM-DD')
    }
    if(hideFieldsSection?.bgv){
      reqObj["bgvResult"] = selectedClearStatus.toUpperCase()
    }
    const formData = new FormData()
    formData.append("updateHiringStage", JSON.stringify(reqObj));
    if(Object.keys(candidateTrackingFileUpload).length > 0)formData.append("files", candidateTrackingFileUpload[0]);
    try{
      const res = await updateHireStages(formData)
      if(res?.status === 200){
        getMandateList()
        setSuccessMessage(`Hiring stage for ${selectedMandate?.uniqueid} has been successfully updated.`)
        setSuccessPopup(true)
      }else{
        console.log("server error")
      }
    }catch(error){
      console.log(error)
    }
  }

  const onUpdate = () => {
    if(!remarkText){
      setErrors(prev => { return {...prev,remark: remarksRequired}})
    }
    if(hideFieldsSection?.joinDate){
      if(selectedDate == null){
        setErrors(prev => { return {...prev,joinDate: "Joining Date required"}})
      }
      if(!ctcBillable){
        setErrors(prev => { return {...prev,CTCValue: "CTC billable required"}})
      }
    }
    if(hideFieldsSection.show_relieving_date){
      if(selectedRelievingDate == null){
        setErrors(prev => { return {...prev,relievingDate: "Relieving Date required"}})
      }
      if(!selectedDojDate){
        setErrors(prev => { return {...prev,dojDate: "DOJ Date required"}})
      }
    }    
    if(hideFieldsSection?.bgv){
      if(!selectedClearStatus){
        setErrors(prev => { return {...prev,clearStatus: "This status required"}})
      }
    }
    if(selectedStatus && remarkText) {
      setErrors(prev => { return {...prev,status: ''}})
      if(hideFieldsSection?.joinDate){
        if(selectedDate == null || !ctcBillable){
          return
        }        
      }
      if(hideFieldsSection.show_relieving_date){
        if(selectedRelievingDate == null || !selectedDojDate){
          return
        }        
      }
      if(hideFieldsSection?.bgv){
        if(!selectedClearStatus){
          return
        }        
      }      
      submitHireStage()
    }
  }

  useEffect(()=>{
    if(selectStage && !isRemarkOpen){
      getStatusList()
    }
  },[selectStage])

  const getRemarkAPI = async () => {
    try{
      const res = await getCandidateTrackingRemarks({id:remarkData})
      if(res?.status === 200){
        setGetRemarkAPIObj(res?.data?.result?.data)
      }      
    }catch(error){
      console.log(error)
    }
  }

  useEffect(() => {
    if(remarkData)getRemarkAPI()
  },[remarkData])


  useEffect(()=>{
    if(currentStatus.length == 0){
      setCurrentStatus((prevStatus:any) => {
        prevStatus = statusList.filter(stat => {
          return stat.id == selectedStatus
        })
        return prevStatus
      })
    }
  },[statusList])

  

  const handleDateChange = (date:any) => {
    const today = dayjs();
    const cutoffDate = today.subtract(30, "days");
    if (date && date.isBefore(cutoffDate)) {
      toast.error("Date of joining can be the current date or backdated up to 29 days")
    } else {
      setSelectedDate(date);
    }
    if(date == null){
      setErrors(prev => { return {...prev,joinDate: "Joining Date required"}})
    }else{
      setErrors(prev => { return {...prev,joinDate: ""}})
    }
    
  };

  const clearStatusHandler = (status:string) => {
    setSelectedClearStatus(status);
    setErrors(prev => { return {...prev,clearStatus: ""}})
  }

  return(
    <>
    <Modal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className="hiring-model"
      onClose={()=>onclose(false)}
    >
      <Box className="model-container">
        <Box className="header-wrapper">
          <Typography>{isRemarkOpen ? "Remarks" : "Update Hiring Stage"}</Typography>
          <CloseIcon style={{cursor:"pointer"}} onClick={()=>onclose(false)} />
        </Box>
        <Box className="hiring-model-scollBar">

          {!isRemarkOpen &&
          <Box className="body-wrapper">
            {showMandate &&
            <div className="field-container">
              <div className="label-text" >Mandate</div>
              <div className="d-flex">
                <MandateOptionTag
                  option={{
                    title: selectedMandate?.jobtitle,
                    mandateId: selectedMandate?.uniqueid,
                    clientName: selectedMandate?.client_name,
                    createdByName: selectedMandate?.createdby
                  }} 
                  className="man-option-tag"
                />
                <div className="card">
                  <div className="label-text">Location</div>
                  {`${selectedMandate?.primary_location} (${selectedMandate?.job_orders})`}
                </div>
              </div>
            </div>
            }
            {!isRemarkOpen && 
            <div className="d-flex space-between canidateTrackInfoSect">
              <div className="field-container flex-fill">
                <div className="label-text" >Current Candidate Stage</div>
                <span className="loc-text" style={{fontSize:"14px",fontWeight:"500"}}>{selectedHiring.at(-1)?.stagename}</span>
              </div>
              <div className="field-container flex-fill">
                <div className="label-text" >Current Candidate Processing Stage</div>
                {selectedCurrentSubStage.length > 0 ? <span className="loc-text currentCandProcessStag" style={{color: JSON.parse(selectedCurrentSubStage?.at(-1)?.stagestyles)?.color,backgroundColor:JSON.parse(selectedCurrentSubStage?.at(-1)?.stagestyles)?.background}}>{ selectedCurrentSubStage.at(-1)?.stagename}</span> : <span className="loc-text currentCandProcessStag">-</span> }  
              </div>
              <div className="field-container flex-fill">
                <div className="label-text" >Current Candidate Status</div>
                {selectedCurrentSubStage.length > 0 ? <div className="mandate-location-tag" style={{backgroundColor:JSON.parse(selectedCurrentSubStage?.at(-1)?.statusstyles)?.background}}>
                  <span className="loc-text" style={{color: JSON.parse(selectedCurrentSubStage?.at(-1)?.statusstyles).color}}>{selectedCurrentSubStage?.at(-1).statusname}</span>
                </div> : <div className="mandate-location-tag">-</div>}
              </div>
              
            </div>}
          </Box>
          }
          <Box className="body-wrapper">
            {!isRemarkOpen && 
            <div className="field-container">
              <FormControl fullWidth>
                  <InputLabel required={true} >Update Status</InputLabel>
                  <Select
                    value={selectedStatus}
                    label="Update Status"
                    required ={true}
                    className='ststus-list'
                    labelId='status-select-label'
                    onChange={(e:any)=> {
                      setSelectedStatus(e?.target?.value)                      
                      const selectedStage = statusList.filter((item:any) => {return item?.id == e?.target?.value})
                      setHideFieldsSection(JSON.parse(selectedStage[0]?.props))
                      setIsInterview((selectedStage[0]?.stage_id == 4 && selectedStage[0]?.sub_stage_id == 25) ? "normalCCM" : (selectedStage[0]?.stage_id == 4 && selectedStage[0]?.sub_stage_id == 26) ? "finalCCM":"")
                      if((selectedStage[0]?.id == mandateSubtracking?.statusId) && (mandateSubtracking?.subStage == "FINAL_CCM2" || mandateSubtracking?.subStage == "FINAL_CCM1") || (selectedStage[0]?.stage_id == 4 && mandateSubtracking?.subStage == "FINAL_CCM2" && selectedStage[0]?.sub_stage_id == 26)){
                        setSelectedCCM(true)
                      }else{
                        setSelectedCCM(false)
                      }                      
                      if(!e?.target?.value){
                        setErrors({...errors,status: 'status required '})
                      }
                      else {
                        setErrors({...errors,status: ''})
                      }
                    }}
                  >
                    {statusList?.map((status:any,index:number)=>{
                      const tagStyle = status?.styles ? JSON.parse(status?.styles) : {}
                      return (
                      <MenuItem value={status?.id} key={index} >
                        <span className="status-tag" style={
                          tagStyle
                            ? {
                                color: tagStyle?.color,
                                backgroundColor: tagStyle?.background,
                              }
                            : {}
                        }>{status?.status_name}</span>
                      </MenuItem>
                    )})}
                    {statusList?.length === 0 && <MenuItem disabled>No data</MenuItem>}
                  </Select>
                </FormControl>
                {errors?.status && (
                <span className="reminder_text-danger select-danger-text error-padding" role="alert">
                    {errors?.status}
                </span>
              )}
            </div>}
            {(!isRemarkOpen && (isInterview == "normalCCM")) && 
            <div className={`interview-rounds ${(!isStageChanged && selectedCCM)? "readOnlySection" : ""}`}>
              <FormControlLabel
                value=""
                control={<Switch checked={selectedCCM} onChange={() => setSelectedCCM(!selectedCCM)} />}
                label={isInterview == "normalCCM" ? "Final CCM1" : "Final CCM2"}
                labelPlacement="end"
              />
              <p><img src={InfoVector} />To reach final interview CSP, enable {isInterview == "normalCCM" ? "Final CCM1" : "Final CCM2"}</p>
            </div>
            }
            {(!isRemarkOpen && hideFieldsSection.joinDate) && 
            <Box className={`offerAcceptSect ${!isStageChanged ? "readOnlySection" : ""}`}>
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Joining Date"
                      value={selectedDate}
                      onChange={handleDateChange}
                      maxDate={new Date()}
                      renderInput={(params) => <TextField {...params} />}
                      components={{
                        OpenPickerIcon: RemarkCalendar,
                      }}
                    />
                  </LocalizationProvider>
                  {errors?.joinDate && (
                      <span className="reminder_text-danger select-danger-text error-padding" role="alert">
                          {errors?.joinDate}
                      </span>
                    )}
                  </Box>
                  <Box>
                    <TextField
                      value={ctcBillable}
                      label='CTC billable (INR)'
                      type="number"
                      inputProps={{ maxLength: 9 }}
                      onChange={(event:any) => {
                        if(event.target.value.length > 0 ){
                          setErrors({...errors,CTCValue: ''})
                          if(event.target.value.length > 9){
                            setCtcBillable(ctcBillable)
                          }else{
                            setCtcBillable(event.target.value)
                          }
                        }else{
                          setErrors(prev => { return {...prev,CTCValue: "CTC billable required"}})
                          setCtcBillable(event.target.value)
                        }                        
                      }}
                    />
                    {errors?.CTCValue && (
                      <span className="reminder_text-danger select-danger-text error-padding" role="alert">
                          {errors?.CTCValue}
                      </span>
                    )}
                  </Box>
            </Box>
            }
            {(!isRemarkOpen && hideFieldsSection.show_relieving_date) && 
            <Box className={`offerAcceptSect ${!isStageChanged ? "readOnlySection" : ""}`}>
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Relieving date assigned"
                      value={selectedRelievingDate}
                      onChange={(e:any) => {
                          setSelectedRelievingDate(e);
                          if(e == null){
                            setErrors(prev => { return {...prev,relievingDate: "Relieving Date required"}})
                          }else{
                            setErrors(prev => { return {...prev,relievingDate: ""}})
                          }
                      }}
                      minDate={new Date()}
                      renderInput={(params) => <TextField {...params} />}
                      components={{
                        OpenPickerIcon: RemarkCalendar,
                      }}
                    />
                  </LocalizationProvider>
                  {errors?.relievingDate && (
                      <span className="reminder_text-danger select-danger-text error-padding" role="alert">
                          {errors?.relievingDate}
                      </span>
                    )}
                </Box>
                <Box>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        label="DOJ confirmed"
                        value={selectedDojDate}
                        onChange={(e:any) => {
                          setSelectedDojDate(e);
                          if(e == null){
                            setErrors(prev => { return {...prev,dojDate: "DOJ Date required"}})
                          }else{
                            setErrors(prev => { return {...prev,dojDate: ""}})
                          }
                      }}
                        minDate={new Date()}
                        maxDate={getDateMaxValue()}
                        renderInput={(params) => <TextField {...params} />}
                        components={{
                          OpenPickerIcon: RemarkCalendar,
                        }}
                      />
                    </LocalizationProvider>
                  {errors?.dojDate && (
                    <span className="reminder_text-danger select-danger-text error-padding" role="alert">
                        {errors?.dojDate}
                    </span>
                  )}
                </Box>
            </Box>
            }
            {(!isRemarkOpen && hideFieldsSection?.bgv) && 
            <>
            <div className={`checkClear ${!isStageChanged ? "readOnlySection" : ""}`}>
              <label>Select</label>
              <Box className="checkClearList">
                  <p className={`checkClearListRed ${selectedClearStatus == "RED" ? "active" : ""}`} onClick={() => {clearStatusHandler("RED")}}><span></span>Red</p>
                  <p className={`checkClearListAmber ${selectedClearStatus == "AMBER" ? "active" : ""}`} onClick={() => {clearStatusHandler("AMBER")}}><span></span>Amber</p>
                  <p className={`checkClearListGreen ${selectedClearStatus == "GREEN" ? "active" : ""}`} onClick={() => {clearStatusHandler("GREEN")}}><span></span>Green</p>
              </Box>
            </div>
            {errors?.clearStatus && (
              <span className="reminder_text-danger select-danger-text error-padding" role="alert">
                  {errors?.clearStatus}
              </span>
            )}
            </>
            }
            {!isRemarkOpen ? 
            <div className="field-container">
          <FormControl className={Object.keys(candidateTrackingFileUpload).length > 0 ? "candidateTrackFileUploaded":""} fullWidth > 
              <Box className="remarkSect">     
                  <TextField
                label="Remarks"
                placeholder="Remarks"
                required={true}
                multiline
                rows={6}
                value={remarkText}
                onChange={(e:any)=> {
                  if(!e.target.value){
                    setErrors({...errors,remark: remarksRequired})
                  }else{
                    setErrors({...errors,remark: ""})
                  }
                  setRemarkText(e?.target?.value)
                }}
              />              
              <Box className="remarkFileUpload">
                <input
                    type='file'
                    id='mandateFile'
                    onChange={(event:any) => {
                      if(fileExtension.includes(event?.target?.files?.[0]?.type)){
                        if(event?.target?.files?.[0]?.size <= 5000000){
                          setCandidateTrackingFileUpload(event.target.files)
                        }else{
                          toast.error('Max file size is 5MB')                          
                        }                        
                      }else{
                        toast.error('Upload file type not accepted')
                      }                      
                    }}
                    accept=".doc, .docx,.txt,.pdf, image/jpeg,.uml"
                    name='mandateFile'
                    ref={ref}
                />
                <label htmlFor='mandateFile'>
                  <img src={RemarkAttachment} />
                </label>
              </Box>
              {Object.keys(candidateTrackingFileUpload).length > 0 && <Box className="remarkFileAttach">
                <Tooltip title={candidateTrackingFileUpload[0]?.name}><p>{candidateTrackingFileUpload[0]?.name}</p></Tooltip>
                <Box>
                  {/* <img src={RemarkDownload} /> */}
                  <img onClick={(e:any) => reset()} src={RemarkTrash} />
                </Box>
              </Box>}
              
              <p><img src={InfoVector} />Attachment type: Pdf/excel/docx/JPEG/ uml, Max file size: 5MB</p>
              </Box>
          
            </FormControl>
          
              {errors?.remark && (
                <span className="reminder_text-danger select-danger-text error-padding" role="alert">
                    {errors?.remark}
                </span>
              )}
            </div> : 
            <div className="field-container">
              <div className="label-text" >Remarks</div>
              <div className="remark-text">{getRemarkAPIObj?.remarks ?? ""}</div>
              {getRemarkAPIObj?.filename && 
              <>
              <p className="remarkAttShow" onClick={() => {handleAttachmentDownload(getRemarkAPIObj?.filepath)}}><img src={RemarkAttachIcon} /><Tooltip title={getRemarkAPIObj?.filename}><span>{getRemarkAPIObj?.filename}</span></Tooltip><img src={Download} /></p>
              </>}
            </div>            
            }
          </Box>
        </Box>
        <Box className="footer-wrapper"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'flex-end',
            marginTop: '20px',
            gap: '15px',
          }}
        >
          <AppButton
            bgcolor='#E5E8EB'
            onClick={() => onclose(false)}
            textColor='#000'
            fontSize='14px'
            fontWeight={400}
            label='Cancel'
            width='96px'
          ></AppButton>
          {!isRemarkOpen &&
          <AppButton
              bgcolor='#002882'
              type='submit'
              textColor='#ffffff'
              fontSize='14px'
              fontWeight={400}
              label={"Update"}
              width='96px'
              style={{color:"#fff", opacity: ( !isStageChanged || selectedStatus===null ) ? "0.5" : "1"}}
              onClick={onUpdate}
              disabled={!isStageChanged || selectedStatus===null}
            ></AppButton>}
        </Box>
      </Box>
    </Modal>
    <AppErrorHandlerModal
          open={successPopup}
          content={SuccessMessage}
          handleClose={()=>{
            setSuccessMessage("")
            setSuccessPopup(false)
            onclose(false)
          }}
          factor='Success'
          ButtonText='Done'
        ><></></AppErrorHandlerModal>
    </>
  )
}

const CandidateTracking:React.FC<Tprop> = ({candidateDetailsData,candidateNotificationState}) => {
  const renderEmptySearchBox = () => {
    const emptyMessage = `No data found`;
    return (
        <Box className="candidate-empty-search-box candidateDetailWraper">
            <Box>
                <NoResultFound />
            </Box>
            <Box className="empty-search-message">
                {emptyMessage}
            </Box>
        </Box>
    )
}
  const [searchText, setSearchText] = useState<string>("")
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [mandateList, setMandateList] = useState<any[]>([])
  const [selectedMandate, setSelectedMandate] = useState<any>({})
  const [stagesList, setStagesList] = useState<any[]>([])
  const [selectedStage, setSelectedStage] = useState<any>({})
  const [hireStatusList, setHireStatusList] = useState<any[]>([])
  const [hireInterviewLastStatus, setHireInterviewLastStatus] = useState<boolean>()
  const [hireUserList, setHireUserList] = useState<any[]>([])
  const [isRemarkOpen, setIsremarkOpen] = useState(false)
  const [remarkData, setRemarkData] = useState<string>("")
  const [selectedHireTag, setSelectedHireTag] = useState<string>("")
  const [mandateFilterList, setMandateFilterList] = useState<any[]>([])
  const { userId, userRole } = useAppSelector((state) => state?.UserDetail)  
  const isAssignMandateState = useAppSelector((state)=>state?.mandatedrawer?.assignMandate)
  const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)

  const getMandateList = async () => {
    try{
      const res = await getCandidateAssignedMandates(candidateDetailsData?.id)
      if(res?.status===200){
        let listStatus = res?.data?.result?.data ?? []
        listStatus = listStatus.map((data:any)=> {
          const obj = {...data, mandateFilterLabel: `${data?.jobtitle}-${data?.uniqueid}`}
          return obj
        })
        setMandateList(listStatus)
        if(_.isEmpty(selectedMandate)){
          setSelectedMandate(listStatus[0])
        }else {
          const selectData = listStatus?.find((data:any) => data?.id === selectedMandate?.id)
          selectData != undefined ? setSelectedMandate(selectData) : setSelectedMandate(listStatus[0])
        }
        setMandateFilterList(listStatus)
      }else{
        setMandateList([])
        setSelectedMandate({})
      }

    }catch(error){
      console.log(error)
    }
  }

  const getStagesList = async () => {
    const manId = selectedMandate?.id
    const posId = selectedMandate?.mlocationid
    const stageid = selectedMandate?.stageid
    const stageorder = selectedMandate?.stageorder 
    const isend = selectedMandate?.isend 

    try{
      const res = await getMandateStagesbyOrder(manId, posId,candidateDetailsData?.id, stageid, stageorder, isend )
      if(res?.status === 200){
        const listData = res?.data?.result?.data ?? []
        const selectStage = listData?.filter((list:any) => list?.stageid === selectedMandate?.stageid)
        setSelectedStage(selectStage[0] ?? {})
        setStagesList(listData)
      }
    }catch(error){
      console.log(error)
    }
  }

  const getHireStages = async () => {

    const reqObj = {
      mandateId:  selectedMandate?.id,
      mlocationId: selectedMandate?.mlocationid,
      candidateId:candidateDetailsData?.id
    }

    try{
      const res = await getCandidateHireStage(reqObj)
      if(res?.status === 200){
        const dataList = res?.data?.result?.data
        const TagList = dataList.filter((data:any) => data?.createdbyfullname)
        setSelectedHireTag(TagList.at(-1)?.processing_stages == null ? TagList.at(-1)?.statusname : JSON.parse(TagList.at(-1)?.processing_stages).at(-1)?.statusname)
        setHireStatusList(dataList)
        // dataList.map((items:any,index:number) => {
        //   if(items.stageid == 4) setHireInterviewLastStatus(true)
        // })
        setHireUserList(res?.data?.result?.list)
        setRemarkData(res?.data?.result?.data[res?.data?.result?.data.length - 1]?.remarks)
      }
    }catch(error){
      console.log(error)
    }
  }

  useEffect(()=>{
    getMandateList()
  },[isAssignMandateState,candidateNotificationState])

  useEffect(()=> {
    if(selectedMandate?.id){
      getStagesList()
      getHireStages()
    }
  },[selectedMandate])

  useEffect(()=>{
    if(searchText !== ""){
      const manList = mandateList.filter((data:any) => data?.mandateFilterLabel.includes(searchText))
      setMandateFilterList(manList)
    }else{
      setMandateFilterList(mandateList)
    }
  },[searchText])

  return (
    <Box className="candidate-tracking-container">
      {mandateList.length > 0 ? 
      <Box>
        <Box className="container">
          <div>
            <TextField
              // label="Search Mandate"
              className="mandate-search"
              placeholder="Search Mandate"
              value={searchText}
              onChange={(e:any) => setSearchText(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                )
              }}
            />
          </div>
          <div className="mandate-list">
            <div className="tag-wrapper">
              {mandateFilterList.map((list:any, index:number)=>(
                <MandateOptionTag 
                  key={index} 
                  className={`hire-man-tag ${selectedMandate?.id === list?.id && "active-tag"}`}
                  option={{
                    title: list?.jobtitle,
                    mandateId: list?.uniqueid || "-",
                    clientName: list?.client_name,
                    createdByName: list?.createdby
                  }} 
                  onClick={()=> setSelectedMandate(list)}
                />
              ))}
            </div>
          </div>
          <div style={{paddingTop:"10px"}} >
            <div className="mandate-location-tag">
              <span className="loc-text" >{`${selectedMandate?.primary_location} (${selectedMandate?.job_orders})`}</span>
              {/* <span className="loc-id" >{`XRDMAN${selectedMandate?.id}LOC${selectedMandate?.mlocationid}`}</span> */}
            </div>
          </div>
        </Box>
        <Box className="container">
          <div className="hiring-header d-flex">
            <div style={{fontWeight:600}} >Hiring Stages</div>
            <div className="d-flex" >
            {(userPermissions?.candidate_module?.view?.manage_candidate_tracking?.selected && userRole != "Admin") && <><div style={{color:"#727376"}} >Update Hiring Stage</div>
               <div style={{cursor:"pointer"}} className={`stage-dropdown ${!hireUserList.includes(userId) ? "disableStage" : ""}`} onClick={()=> setIsOpen(true)} >
                <span className="status-tag" >{selectedHireTag ?? "-"}</span>
                <DropdownIcon/>
              </div></>}
              {(userRole == "Admin") && <><div style={{color:"#727376"}} >Update Hiring Stage</div>
               <div style={{cursor:"pointer"}} className={`stage-dropdown ${!hireUserList.includes(userId) ? "disableStage" : ""}`} onClick={()=> setIsOpen(true)} >
                <span className="status-tag" >{selectedHireTag ?? "-"}</span>
                <DropdownIcon/>
              </div></>}
            </div>
          </div>
          <div className="hiring-stages">
            {hireStatusList?.length && hireStatusList?.map((data:any, index:number) => {
              let firstListStyle:any = ""
              const subStageTrackingsData =  JSON.parse(data?.processing_stages)
              let ccm2LastStatusRound = 0
              subStageTrackingsData?.map((items:any) =>{
                if(items?.subStage == "CCM2") ccm2LastStatusRound = items?.roundNumber;
              })
              if((subStageTrackingsData && (subStageTrackingsData.length == 1 && hireStatusList?.[index+1]?.processing_stages != null))){
                firstListStyle = JSON.parse(subStageTrackingsData[0]?.stagestyles)
              }
              return (
                <div className='status-wrap' key={index}>
                  <div className={`svgcontainer ${ hireStatusList?.[index+1]?.statusid ? 'active' : ''}`}>
                    {<img src={hireStatusList?.[index+1]?.statusid ?  RadioButtonCheck : (hireStatusList?.[index-1]?.statusid && hireStatusList?.[index]?.statusid) ? RadioButton : RadioButtonGray} />}
                  </div>
                  <Box>
                    <Grid key={index} className={`profile-card ${(subStageTrackingsData != null && hireStatusList?.[index+1]?.processing_stages == null) ? "currentStage" : ""} ${!data?.statusid && 'disable-card'}`}>
                      <div className='stage-box'>
                        <Typography className='primary-text'>{data?.stagename ?? '-'}</Typography>
                        <Typography className='secondary-text'>
                          {data?.createdat ? convertToDisplayDateTimeWithOutLocal(data?.createdat) : '-NA-'}
                        </Typography>
                      </div>
                      <div className='status-box' style={{gap:"10px"}}>         
                      {(subStageTrackingsData && (subStageTrackingsData.length == 1 && hireStatusList?.[index+1]?.processing_stages != null)) && 
                        <>
                         {subStageTrackingsData[0].bgvresult != null && <p className="candidateTrackListClearStatus"><span className={subStageTrackingsData[0].bgvresult == "RED" ? "redCode" : subStageTrackingsData[0].bgvresult == "GREEN" ? "greenCode" : "amberCode"}></span>{(subStageTrackingsData[0].bgvresult).toLowerCase()}</p>}
                         </>
                      }
                      {(subStageTrackingsData && (subStageTrackingsData.length == 1 && hireStatusList?.[index+1]?.processing_stages != null)) && <Tooltip title={subStageTrackingsData[0].stagename}>
                        <Typography
                          className={`primary-text status-tag ${
                            data?.statusid
                              ? data?.statusid === 1
                                ? 'active-tag'
                                : 'inprogrees-tag'
                              : 'disable-tag'
                          } `}
                          style={{
                              color: firstListStyle?.color,
                              backgroundColor: firstListStyle?.background,
                                }
                          }
                        >
                          {subStageTrackingsData[0].stagename ?? ''}
                        </Typography>
                        </Tooltip>
                        }
                      {(subStageTrackingsData && (subStageTrackingsData.length == 1 && hireStatusList?.[index+1]?.processing_stages != null)) && <Tooltip title={data?.statusname}>
                        <Typography
                          className={`primary-text status-tag ${
                            data?.statusid
                              ? data?.statusid === 1
                                ? 'active-tag'
                                : 'inprogrees-tag'
                              : 'disable-tag'
                          } `}
                          style={{
                            color: JSON.parse(data?.statusstyles)?.color,
                            backgroundColor: JSON.parse(data?.statusstyles)?.background                            
                          }
                    }
                        >
                          {data?.statusname ?? ''}
                        </Typography>
                        </Tooltip>
                      }
                      </div>
                      {(subStageTrackingsData && (subStageTrackingsData.length == 1 && hireStatusList?.[index+1]?.processing_stages != null)) && 
                      <>
                      <div className='remark-box'>
                        <RemarkIcons
                        style={{cursor: subStageTrackingsData ? "pointer" : ""}}
                          onClick={() => {
                            if (data?.statusid) {
                              setRemarkData(subStageTrackingsData[0]?.id)
                              setIsremarkOpen(true)
                              setIsOpen(true)
                            }
                          }}
                        />
                      </div>
                      {(data && data?.statusid) ? (
                        <div className='user-box'>
                          <Avatar
                            { ...stringAvatar(data?.createdbyfullname)}
                            className='search-result-avatar'
                          />
                          <div className="recruiter-wrapper" >
                            <Typography className='primary-text text-over-flow'>{data?.createdbyfullname ?? '-'}</Typography>
                            <Typography className='secondary-text'>
                              {data?.createdbyrole ?? '-'}
                            </Typography>
                          </div>
                        </div>
                      ) : (
                        <div className='user-box'>
                          <Typography className='primary-text'>- Yet to Process -</Typography>
                        </div>
                      )}
                      </>
                      }
                    </Grid>
                    <div className="candidateTrackingChildList">
                      {(subStageTrackingsData && (subStageTrackingsData.length > 1 || hireStatusList?.[index+1]?.processing_stages == null)) && subStageTrackingsData?.map((dataList:any, indexChild:number) => {
                      const StageStyle = JSON.parse(dataList?.stagestyles);
                      const joinStageEnable = dataList.joindate
                      const relievingDate = dataList.relievingdate
                      return (
                          <Box key={indexChild} style={{display:"flex",gap:"10px",margin:"10px 0px"}} className={`${(indexChild < subStageTrackingsData.length - 1 || hireStatusList?.[index+1]?.processing_stages != null) ? "candidateTrackingChildActive" : ""} ${dataList?.isend == 1 ? "rejectedCase" : ""}`}>
                            <div className="candidateTrackingChildSvg">
                              <Box className="interviewStageRist">L{dataList?.round_number}</Box>
                            </div>
                            <Box>
                              <Grid key={indexChild} style={{position:"relative"}}>
                                <Box className={`profile-card`} style={{paddingBottom: (joinStageEnable || relievingDate) ? "40px":"10px"}}>
                                <div className='stage-box'>
                                  <Typography className='primary-text' style={{paddingBottom:"7px"}}><span className="stageName" style={{color: StageStyle?.color, backgroundColor: StageStyle?.background}}>{dataList?.stagename ?? '-'}</span>{(dataList?.substage == "FINAL_CCM1" || dataList?.substage == "CCM2" || dataList?.substage == "FINAL_CCM2" ) && data?.stage_id == 4 ? <span className="candidateTrackingChildListCCM">{dataList?.substage == "FINAL_CCM1" ? "FINAL CCM1" : dataList?.substage == "CCM2" ? "CCM2" : dataList?.substage == "FINAL_CCM2" ? "FINAL CCM2" : ""}</span> : ""}</Typography>
                                  <Typography className='secondary-text'>
                                    {dataList?.createdat ? convertToDisplayDateTimeWithOutLocal(dataList?.createdat) : '-'}
                                  </Typography>
                                </div>
                                <div className='status-box'>                                
                                {dataList.bgvresult != null && <p className="candidateTrackListClearStatus"><span className={dataList.bgvresult == "RED" ? "redCode" : dataList.bgvresult == "GREEN" ? "greenCode" : "amberCode"}></span>{(dataList.bgvresult).toLowerCase()}</p>}
                                {/* <p className="candidateTrackListClearStatus"><span className={"redCode"}></span>{"red"}</p> */}
                                <Tooltip title={dataList?.statusname}>
                                  <Typography
                                    className={`primary-text status-tag ${
                                      dataList?.statusid
                                        ? dataList?.statusid === 1
                                          ? 'active-tag'
                                          : 'inprogrees-tag'
                                        : 'disable-tag'
                                    } `}
                                    style={{
                                      color: JSON.parse(dataList?.statusstyles)?.color,
                                      backgroundColor: JSON.parse(dataList?.statusstyles)?.background,
                                    }}
                                  >
                                    {dataList?.statusname ?? '-'}
                                  </Typography>
                                  </Tooltip>
                                </div>
                                <div className='remark-box'>
                                  <RemarkIcons
                                    style={{cursor:"pointer"}}
                                    onClick={() => {
                                      if (dataList?.statusid) {
                                        setRemarkData(dataList?.id)
                                        setIsremarkOpen(true)
                                        setIsOpen(true)
                                      }
                                    }}
                                  />
                                </div>
                                {dataList?.statusid ? (
                                  <div className='user-box'>
                                    <Avatar
                                      {...stringAvatar(dataList?.createdbyfullname)}
                                      className='search-result-avatar'
                                    />
                                    <div className="recruiter-wrapper" >
                                      <Typography className='primary-text text-over-flow'>{dataList?.createdbyfullname ?? '-'}</Typography>
                                      <Typography className='secondary-text'>
                                        {dataList?.createdbyrole ?? '-'}
                                      </Typography>
                                    </div>
                                  </div>
                                ) : (
                                  <div className='user-box'>
                                    <Typography className='primary-text'>- Yet to Process -</Typography>
                                  </div>
                                )}
                                </Box>
                                {joinStageEnable && <Box className="joinStageInfo">
                                  <p>Joining Date <span>{moment(dataList.joindate).format('DD MMM YYYY')}</span></p>
                                  <p>CTC billable (INR) <span>{dataList.ctcbillable}</span></p>
                              </Box>}
                              {relievingDate && <Box className="joinStageInfo">
                                  <p>Relieving Date <span>{moment(dataList.relievingdate).format('DD MMM YYYY')}</span></p>
                                  <p>DOJ Confirmation <span>{moment(dataList.doj_confirmed).format('DD MMM YYYY')}</span></p>
                              </Box>}                              
                              </Grid>                              
                            </Box>
                            </Box>
                          )})}
                    </div>
                  </Box>
                </div>
              )
            })}
          </div>
        </Box>
        {isOpen && 
          <HiringModalPopup 
            candidateDetailsData={candidateDetailsData} 
            showMandate={true} 
            onclose={(e:boolean)=>{setIsOpen(false); setIsremarkOpen(false);setRemarkData("")}}
            selectedMandate={selectedMandate}
            getMandateList={getMandateList}
            isRemarkOpen={isRemarkOpen}
            remarkData={remarkData}
            hireStatusList={hireStatusList}
          />}
      </Box> : renderEmptySearchBox() }
    </Box>
  )

}

export default CandidateTracking;