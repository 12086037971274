import { forminstance, instance } from "./http-common";
import { getLoggedInUserId } from '../utils/user-utils';
import { objectToQueryString } from "../utils/common-utils";
import _ from "lodash";

export const getUserDetails = async () =>{
    const userId = getLoggedInUserId()
    try{
        const response = await forminstance.get(`user/${userId}`);
        if( response.status == 200){
            return response.data
        }
        return null
    }catch(error){
        console.log('Error while getting user details', error)
        return null
    }
    
}

export const getSystemConfig = async (obj:any) => {
    // sample payload
    // const obj = {
    //     "businessUnit":true,
    //     "appConfiguration":true,
    //     "dropdownRequest": [
    //         {
    //             "drpCode": "XPN0001",
    //             "drpName": "MANDATE_TYPE"
    //         }
    //     ]
    // }
    const response = await instance.post(`master/user/system/config`, obj);
    return response;
}

/* smartflo userConfig */
export const getUserConfig = async (obj:any) => {
 
    const response = await instance.post(`/user/update/smartflo/credentials`, obj);
    return response;
}

export const getCountryCode = async () => { 
    const response = await instance.get(`/master/countries`);
    return response;
}

export const getBussinessUnit = async (reqObj:any) => { 
    const response = await instance.post(`/master/businessunit`,reqObj);
    return response;
}

export const getRoleList = async () => { 
    const response = await instance.get(`/role/list`);
    return response;
}

export const getAllRoleList = async (roleObj:any) => { 
    const response = await instance.post(`role/list/search/roles`,roleObj);
    return response;
}

export const getRoleDetail = async (roleObj:any) => { 
    const response = await instance.post(`role/get`,roleObj);
    return response;
}

export const postCreateRole = async (roleObj:any) => { 
    const response = await instance.post(`/role/create`,roleObj);
    return response;
}

export const postUpdateRole = async (roleObj:any) => { 
    const response = await instance.put(`/role/update`,roleObj);
    return response;
}

export const getAllUserList = async (userObj:any) => { 
    const response = await instance.post(`/user/getAllUsers`,userObj);
    return response;
}

export const getUserDetail = async (userId:string) => { 
    const response = await instance.get(`/user/${userId}`);
    return response;
}

export const getUserReportingTo = async (buId:string) => { 
    const response = await instance.get(`/user/getUsers?buId=${buId}`);
    return response;
}

export const getUserCreateReportingTo = async (roleId:string, bussId:string) => { 
    const response = await instance.get(`/user/getUsers?buId=${bussId}&roleId=${roleId}`);
    return response;
}

export const postCreateUser = async (userObj:any) => { 
    const response = await forminstance.post(`/user/create`,userObj);
    return response;
}

export const postUpdateUser = async (userObj:any, userId:string) => { 
    const response = await forminstance.post(`/user/update/${userId}`,userObj);
    return response;
}

export const postUserStatus = async (userObj:any) => { 
    const response = await instance.post(`/user/update/status`,userObj);
    return response;
}

export const postAvailableAccessList = async (userObj:any) => { 
    const response = await instance.post(`/role/available/access/details`,userObj);
    return response;
}

export const postSelectedAccessList = async (userObj:any) => { 
    const response = await instance.post(`/role/selected/access/details`,userObj);
    return response;
}

export const postRoleActiveDeactivate = async (userObj:any) => { 
    const response = await instance.put(`/role/update/role/status`,userObj);
    return response;
}

export const getUserRoleList = async () => { 
    const response = await instance.get(`/role/list`);
    return response;
}

export const getUserRoleSelectedList = async (userObj:any) => { 
    const response = await instance.post(`/user/assigned/roles`,userObj);
    return response;
}

export const postUserRoleSelectedList = async (userObj:any) => { 
    const response = await instance.post(`/user/role/mapping`,userObj);
    return response;
}

export const getUserDlsList = async (userObj:any) => { 
    const response = await instance.post(`/user/available/dls/details`,userObj);
    return response;
}

export const getUserDlsSelectedList = async (userObj:any) => { 
    const response = await instance.post(`/user/selected/dls/details`,userObj);
    return response;
}

export const postUserDlsData = async (userObj:any) => { 
    const response = await instance.post(`/user/dls/mapping`,userObj);
    return response;
}

export const getCopilotListAPI = async (userObj:any) => { 
    const apiReq:any = {
        tabType: _.get(userObj, 'tabId'),
        pilotId: _.get(userObj, 'pilotId')
    }
    if(apiReq.tabType == 2)apiReq["userId"] = _.get(userObj, 'userId')
    const queryString = objectToQueryString(apiReq);
    const response = await instance.get(`/user/co-pilot/get?${queryString}`);
    return response;
}

export const getCopilotAuditListAPI = async (auditObj:any) => { 
    const response = await instance.post(`/user/co-pilot/getCoPilotAudit`,auditObj);
    return response;
}

export const getCopilotAddAPI = async (copilotObj:any) => { 
    const response = await instance.post(`/user/co-pilot/`,copilotObj);
    return response;
}

export const getCopilotReportingListAPI = async (userId:any) => { 
    const response = await instance.get(`/master/getReportingToManagers/?userId=${userId}`);
    return response;
}

export const getCopilotUserListAPI = async () => { 
    const response = await instance.get(`/master/getAllUsers`);
    return response;
}

export const changeCopilotStatusAddAPI = async (copilotObj:any) => { 
    const response = await instance.post(`/user/co-pilot/status?status=${copilotObj.status}&Id=${copilotObj.id}&actionType=${copilotObj.actionType}`);
    return response;
}

export const getCopilotUserToken = async (copilotUser:any) => { 
    const response = await instance.post(`/user/co-pilot/switchCoPilot`,copilotUser);
    return response;
}

export const userRoleHierarchyAPI = async (userList:any) => { 
    const response = await instance.post(`/user/roleUserFilter`,userList);
    return response;
}

export const getUserRoleIdHierarchyAPI = async (userId:any) => { 
    const response = await instance.post(`/user/co-pilot/usersBasedOnRoleHierarchy?userId=${userId}`);
    return response;
}

export const getCopilotUserPilot = async (userId:any) => { 
    const response = await instance.get(`/user/co-pilot/checkActiveCopilotsForUsers?userId=${userId}`);
    return response;
}
