import React, { useEffect, useState } from 'react';
import ListTreeAntTranfer from '../Common/ListTreetransfer/ListTreeAntTranfer';
import { Box, Button } from '@mui/material';
import { getUserDlsList, getUserDlsSelectedList, postUserDlsData } from '../../services/UserService';
import { useAppSelector } from '../../store/hooks';
import { toast } from "react-toastify";

const UserDetailDlsView = ({userDetailData}:any) => {
    const { userId } = useAppSelector((state) => state?.UserDetail)
    const [treeViewDataObj, setTreeViewDataObj] =useState([])
    const [treeViewSelectedDataObj, setTreeViewSelectedDataObj] =useState([])
    const [treeViewSelectedDataObjCopy, setTreeViewSelectedDataObjCopy] = useState([])
    const [userModifiedTreeView, setUserModifiedTreeView] =useState([])
    const userPermissions = useAppSelector((state:any) => state?.UserDetail.userPermissions)
    const {userRole, roleId } = useAppSelector((state) => state.UserDetail)

    const getUserDLsAccessListApi = async () => {
        const res:any = await getUserDlsList({
            "userId": userDetailData.keyCloakId
        })
        if(res.status == 200){
            setTreeViewDataObj(res?.data?.result?.data)
        }
    }

    const getUserDLsSelectedListApi = async () => {
        const res:any = await getUserDlsSelectedList({
            "userId": userDetailData.keyCloakId
        })
        if(res.status == 200){
            setTreeViewSelectedDataObj(res?.data?.result?.data)
            setTreeViewSelectedDataObjCopy(res?.data?.result?.data)
        }
    }

    const postUserDlsApi = async () => {
        const res:any = await postUserDlsData({
            "userId": userDetailData.keyCloakId,
            "dls": userModifiedTreeView
        })
        if(res.status == 200){
            toast.success(treeViewSelectedDataObj.length > 0 ? "DLS Successfully updated": "DLS Successfully Added")
        }
    }

    const initialLoadFunction = async () => {
        await getUserDLsSelectedListApi()
        getUserDLsAccessListApi()    
    }

    useEffect(() => {
        initialLoadFunction() 
    },[])
    return (
        <>
            {(treeViewDataObj.length > 0) && <ListTreeAntTranfer treeViewDataObj={treeViewDataObj} treeViewSelectedDataObj={treeViewSelectedDataObj} setUserModifiedTreeView={setUserModifiedTreeView} isEdit={treeViewSelectedDataObj.length > 0 ? true : false}/>}
            <Box className='button-container'>
                <Button 
                    className='cancel-btn'
                    onClick={() => {}}
                >Cancel</Button>
                <Button 
                    disabled={JSON.stringify(treeViewSelectedDataObjCopy) == JSON.stringify(userModifiedTreeView)}
                    className={`updateBtn ${!userPermissions?.user_management_module?.user_module?.edit?.selected && userRole != "Admin" ? "disableEdit" :""}`}
                    onClick={() => {if((userPermissions?.user_management_module?.user_module?.edit?.selected && userRole != "Admin") || userRole == "Admin" )postUserDlsApi()}}
                >
                    Update
                </Button>
            </Box>
        </>
    );
};

export default UserDetailDlsView;