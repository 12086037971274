import { Autocomplete, Box, Button, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AppButton } from '../../AppComponents/AppButton';
import CloseIcon from '@mui/icons-material/Close';
import { ReactComponent as CalenderIcon } from '../../../assets/icons/input-calendar.svg'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { getCopilotAddAPI, getCopilotReportingListAPI, getCopilotUserListAPI } from '../../../services/UserService';
import { useAppSelector } from '../../../store/hooks';
import { dropDownData } from '../../../services/MandateService';
import moment from 'moment';
import { AppErrorHandlerModal } from '../../AppComponents/AppErrorHandlerModal';
import { toast } from 'react-toastify';
interface IFormInput {
    email: string;
    approver: string;
    reason: string;
    accessTill: string|null;
    remarks: string
}
const AddCopilot = ({setAddCopilotState,closeModal,userDetailId,inactiveCopilotData,selectedUserId}: any) => {
    const initValues = {
        email: "",
        approver: "",
        reason: "",
        accessTill: null,
        remarks: ""
    }
    const { userId, userRole } = useAppSelector((state) => state?.UserDetail)
    const methods = useForm<IFormInput>({ mode: "onChange", defaultValues: initValues });
    const [copilotReporting, setCopilotReporting] = useState([])
    const [copilotUsers, setCopilotUsers] = useState([])
    const [copilotReason, setCopilotReason] = useState([])
    const [uploadsuccess, setUploadsuccess] = useState(false)
    const [SuccessMessage, setSuccessMessage] = useState('')
    const {
        control,
        handleSubmit,
        setValue,
        trigger,
        formState: { isValid, errors },
        reset,
        clearErrors
    } = methods
    const getCopilotReportingAPi = async () => {
        try {
            const res: any = await getCopilotReportingListAPI(userDetailId);
            setCopilotReporting(res.data)
        }catch (error: any) {
            console.log(error);
        }
    }
    const getCopilotUsersAPi = async () => {
        try {
            const res: any = await getCopilotUserListAPI();
            const usersList = res.data.map((item:any) => {
                return {label: item.userEmail,value:item.keycloakId}
            })
            setCopilotUsers(usersList.filter((item:any) => item.value != userId && item.value != userDetailId))
        }catch (error: any) {
            console.log(error);
        }
    }

    useEffect(() => {
        if(Object.keys(inactiveCopilotData).length >  0) {
            const selectedValue:any = copilotUsers.filter((item:any) => item.value == inactiveCopilotData.co_pilot_id)
            setValue("email", selectedValue[0])
        }
    },[copilotUsers])

    const getCopilotDopdownAPi = async () => {
        const reqObj = {
            businessUnit: false,
            appConfiguration: false,
            dropdownRequest: [
                {drpCode: "XPN00014", drpName: "Reason"}
            ]
        };
        try {
            const res: any = await dropDownData(reqObj);
            const reasonList = res.data.result.dropdownData.Reason.map((item:any) => {
                return {label: item.domValue,value:item.domValue}
            })
            setCopilotReason(reasonList)
        }catch (error: any) {
            console.log(error);
        }
    }

    

    useEffect(() => {
        getCopilotReportingAPi();
        getCopilotUsersAPi();
        getCopilotDopdownAPi();
    },[])
    

    const mandateSubmitHandler = async (data: any, event: any) => {
        const requestBody:any = {
            email: data?.email?.label,
            reason: data.reason,
            remarks: data.remarks,
            accessTill: data.accessTill.$d,
            coPilotId: data?.email?.value,
            approverId: data.approver,
            status: "co_pilot_add_request",
            actionType: inactiveCopilotData.length == 0 ? "add_copilot" : "reactivate_copilot",
            pilotId:selectedUserId
        }
        if(typeof inactiveCopilotData === 'object' )requestBody["id"] = inactiveCopilotData.id
        try {
            const res: any = await getCopilotAddAPI(requestBody);
            console.log("getCopilotAddAPI",res)
            setUploadsuccess(true)
            setSuccessMessage('Your co-pilot request has been successfully submitted and is awaiting for approval');
        }catch (error: any) {
            console.log(error);
            toast.error(error?.response?.data?.message) 
        }
    }
    const handleClose = () => {
        setUploadsuccess(false);
        setSuccessMessage('');
        closeModal("load")
      }
    return (
        <>
        <Box className="addCopilotSect">
            <Box className="addCopilotSectBlk">
                <Box className="addCopilotSectBlkTop">
                    <h3>Add Copilot</h3>
                    <CloseIcon style={{ cursor: "pointer" }} onClick={() => {closeModal("") }} />
                </Box>
                <Box className="addCopilotSectBlkBody">
                    <Box component={'form'} noValidate autoComplete="off" onSubmit={handleSubmit(mandateSubmitHandler)}>
                        <Box className="addCopilotSectBlkForm">
                            <FormControl fullWidth required>
                                {/* <InputLabel id="demo-simple-select-label">Email</InputLabel> */}
                                <Controller
                                    name={"email"}
                                    control={control}
                                    rules={{
                                        required: {
                                          value: true,
                                          message: "This field is required",
                                        }
                                      }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Autocomplete
                                            options={copilotUsers}
                                            sx={{
                                            marginBottom: { lg: '0rem' },
                                            }}
                                            disablePortal
                                            value={value}
                                            onChange={(event, value) => {
                                            onChange(value)          
                                            }}
                                            renderOption={(props, option:any, index:any) => {
                                            return (
                                                <li {...props} key={index} >
                                                <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: "10px" }}>
                                                    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                                                    <Typography
                                                        sx={{
                                                        whiteSpace: 'nowrap',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        }}
                                                    >
                                                        {option.label}
                                                    </Typography>
                                                    </Box>
                                                </Box>
                                                </li>
                                            )
                                            }}
                                            renderInput={(params: any) => (
                                            <TextField
                                                {...params}
                                                label='Email'
                                                required
                                            />
                                            )}
                                        />
                                    )}
                                />
                                {errors?.email && (
                                    <span className="reminder_text-danger select-danger-text" role="alert">
                                        {errors?.email?.message}
                                    </span>
                                )}
                            </FormControl>
                            <FormControl style={{position:"relative"}}>
                                <LocalizationProvider dateAdapter={AdapterDayjs} >
                                    <Controller
                                        name="accessTill"
                                        control={control}
                                        rules={{
                                            required: {
                                                value: true,
                                                message: "This field is required",
                                            },
                                        }}
                                        render={({ field: { onChange, ...restField } }) => (
                                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'stretch', width: "100%" }}>
                                                <DatePicker
                                                    label='Access Till'
                                                    className='datePicker'
                                                    inputFormat="DD/MM/YYYY"
                                                    onChange={(e: any) => { onChange(e) }}
                                                    components={{
                                                        OpenPickerIcon: CalenderIcon,
                                                    }}
                                                    minDate={new Date()}
                                                    // minDate={moment().subtract(1,'years').toDate()}
                                                    // maxDate={moment().toDate()}
                                                    renderInput={(params: any) => (
                                                        <TextField
                                                            name='accessTill'
                                                            style={{ width: "100%" }}
                                                            required={true}
                                                            {...params}
                                                        />
                                                    )}
                                                    {...restField}
                                                />
                                                {errors?.accessTill && (
                                                    <span className="reminder_text-danger select-danger-text" role="alert">
                                                        {errors?.accessTill?.message}
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                    />
                                </LocalizationProvider>
                            </FormControl>
                            <FormControl fullWidth required>
                                <InputLabel id="demo-simple-select-label">Approver</InputLabel>
                                <Controller
                                    name={"approver"}
                                    control={control}
                                    rules={{
                                        required: {
                                          value: true,
                                          message: "This field is required",
                                        }
                                      }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={value}
                                            defaultValue=""
                                            label="Approver"
                                            required={true}
                                            onChange={(e: any) => {
                                                onChange(e.target.value)
                                            }}
                                        >
                                            {copilotReporting.map((item: any, index: number) => (
                                                <MenuItem key={index} value={item?.user_id}>{item?.email}</MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {errors?.approver && (
                                    <span className="reminder_text-danger select-danger-text" role="alert">
                                        {errors?.approver?.message}
                                    </span>
                                )}
                            </FormControl>
                            <FormControl fullWidth required>
                                <InputLabel id="demo-simple-select-label">Reason</InputLabel>
                                <Controller
                                    name={"reason"}
                                    control={control}
                                    rules={{
                                        required: {
                                          value: true,
                                          message: "This field is required",
                                        }
                                      }}
                                    render={({ field: { onChange, value, onBlur } }) => (
                                        <Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={value}
                                            defaultValue=""
                                            label="Reason"
                                            required={true}
                                            onChange={(e: any) => {
                                                onChange(e.target.value)
                                            }}
                                        >
                                            {copilotReason.map((item: any, index: number) => (
                                                <MenuItem key={item?.id} value={item?.value}>{item?.label}</MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                                {errors?.reason && (
                                    <span className="reminder_text-danger select-danger-text" role="alert">
                                        {errors?.reason?.message}
                                    </span>
                                )}
                            </FormControl>
                            <FormControl fullWidth>
                                <Controller
                                name="remarks"
                                control={control}
                                render={({field : {onChange, value, onBlur}})=>(
                                    <TextField
                                    className="textarea"
                                    required = {false}
                                    label='Remarks'
                                    value={value}
                                    onChange={onChange}
                                    onBlur={onBlur}
                                    multiline={true}
                                    minRows={3}                
                                    />
                                )}
                                />
                            </FormControl>
                        </Box>
                        <Box className='button-container'>
                            <Button
                                className='cancel-btn'
                                onClick={() => {closeModal("")}}
                            >Cancel</Button>
                            <Button
                                className={`save-btn`}
                                type='submit'
                            >
                                {"Add"}
                            </Button>
                        </Box>
                    </Box>
                </Box>

            </Box>
        </Box>
        <AppErrorHandlerModal
        open={uploadsuccess}
        content={SuccessMessage}
        handleClose={handleClose}
        factor='Success'
        ButtonText='Done'
      ><></></AppErrorHandlerModal>
      </>
    );
};

export default AddCopilot;