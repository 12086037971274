import { Container, Grid, Box, Typography, Stack, Divider, FormHelperText, CircularProgress } from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { useForm, FormProvider, Controller } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { object, string, TypeOf } from 'zod'
import { zodResolver } from '@hookform/resolvers/zod'
import { ReactComponent as ForgotArrowIcon } from '../../../assets/icons/arrow-left.svg'
import { Slider } from '../SliderComponent/Slider'
import MainLogo from '../../../assets/images/xpheno.svg'
import ArtemisLogo from '../../../assets/images/Artemis.svg'
import { MuiOtpInput } from 'mui-one-time-password-input'
import './OtpScreen.css'
import { getVerifyEmailUserId, isVerifyEmailSessionEnabled, removeVerifyEmailUserId, setResetPasswordOtp, setResetPasswordUserId } from '../../../utils/user-utils'
import { useEffect, useState } from 'react'
import { ForgotPasswordService } from '../../../services/ForgotPasswordService'
import * as _ from "lodash";

//  OTP Schema with Zod
export const otpgeneratorSchema = object({
  otp: string().min(1, 'OTP is required'),
})

type OTP = TypeOf<typeof otpgeneratorSchema>

export const OtpScreen = () => {
  const defaultValues: OTP = {
    otp: '',
  }

  const navigate = useNavigate()  
  const [isLoading, setLoading] = useState(false);
  const showLoading = () => setLoading(true);
  const hideLoading = () => setLoading(false);
  const search = useLocation()?.search
  const location = useLocation();
  const userId: any = new URLSearchParams(search).get('userid')
  const [loginRouteState,setLoginRouteState] = useState<any>(location)
  //  The object returned from useForm Hook
  const methods = useForm<OTP>({
    resolver: zodResolver(otpgeneratorSchema),
    defaultValues,
  })

  const { control, handleSubmit } = useForm({
    defaultValues,
  })
  
  const { setError, formState } = methods;

  const { errors } =  formState;

  const onSubmit = (values: any) => {
    showLoading();
    ForgotPasswordService.verifyOtp({userId: userId ? userId : getVerifyEmailUserId(), otp: values.otp}).then((res) => {
      // const userId = _.get(res, 'data.userId');
      // if(!userId) throw new Error("userId missing")
      const message = _.get(res, 'data.message');
      if(message != "success") throw new Error(message)
      setResetPasswordUserId(userId? userId : getVerifyEmailUserId());
      setResetPasswordOtp(values.otp);
      removeVerifyEmailUserId();
      navigate('/reset-password');
      hideLoading();
    }).catch((e) => {
      setError('otp', { type: 'custom', message: 'Please enter correct OTP to reset password' });
      hideLoading();
    })
  }

  const goBack = () => {
    navigate('/forgot-password',{state:{loginState:"",type:loginRouteState.state.type}})
  }

  useEffect(() => {
      if(!isVerifyEmailSessionEnabled() && userId == null){
        goBack();
      }
  }, [])


  return (
    <Container maxWidth={false}>
      <Grid
        container
        justifyContent='center'
        alignItems='center'
        className='login-screens-container-grid'
      >
        <Grid item className='otp-form-container-grid'>
          <FormProvider {...methods}>
            <Grid container className='login-screens-container-sub-grid'>
              <Grid
                item
                container
                sx={{
                  marginInline: 'auto',
                }}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{
                    paddingLeft: { sm: '3rem' },
                    paddingRight: { sm: '3rem' },
                    display: { sm: 'block', xs: 'none', md: 'block' },
                  }}
                >
                  <Slider />
                </Grid>
                <Divider
                  orientation='vertical'
                  flexItem
                  sx={{ mr: '-1px', display: { xs: 'none', md: 'block' } }}
                />
                {/* Formcomponent wrapper */}
                <Grid
                  item
                  xs={12}
                  sm={6}
                  sx={{ paddingLeft: { sm: '3rem' }, paddingRight: { sm: '3rem' } }}
                >
                  <Box sx={{ paddingLeft: { lg: '3rem' }, mb: '1rem' }}>
                  <Box className="loginRightTopLogoSect">
                      <Box component='img' alt='Xpheno' src={MainLogo} />
                      <Box component='img' alt='Xpheno' src={ArtemisLogo} />                    
                    </Box>
                    <Stack direction='row' spacing={1}>
                      <ForgotArrowIcon onClick={goBack} className='back-button' />
                      <Typography className='welcome-text login-screen-welcome-text'>Reset Password</Typography>
                    </Stack>
                    <Typography className='login-screen-welcome-note' sx={{ marginTop: '5px' }}>
                      A One Time Password (OTP) will be sent on your registered Mobile Number /
                      e-mail ID{' '}
                    </Typography>
                  </Box>
                  <Box
                    display='flex'
                    flexDirection='column'
                    component='form'
                    noValidate
                    autoComplete='off'
                    sx={{
                      paddingLeft: { md: '0', lg: '3rem' },
                      paddingRight: { md: '0', lg: '3rem' },
                      marginTop: '30px',
                    }}
                    onSubmit={handleSubmit(onSubmit)}
                  >
                    <Controller
                      control={control}
                      rules={{ validate: (value) => value.length === 4 }}
                      render={({ field, fieldState }) => (
                        <Box>
                          <MuiOtpInput sx={{ gap: 1 }} {...field} length={4} />
                          {fieldState.invalid ? (
                            <FormHelperText error>OTP is Invalid</FormHelperText>
                          ) : null}
                          {errors['otp'] ? (
                            <FormHelperText error>{errors['otp'].message}</FormHelperText>
                          ) : null}
                        </Box>
                      )}
                      name='otp'
                    />
                    <LoadingButton
                      loading={isLoading}
                      type='submit'
                      variant='contained'
                      className='login-button'
                      loadingIndicator={<CircularProgress color="primary" size={30} />}
                    >
                      Verify
                    </LoadingButton>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </FormProvider>
        </Grid>
      </Grid>
    </Container>
  )
}
