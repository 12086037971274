import React, {useEffect, useRef, useState } from 'react'
import { Paper, Box, Divider, Typography, Grid, Stack, Button, Checkbox, FormControl, InputAdornment, OutlinedInput, Autocomplete, TextField, AlertProps, Snackbar, InputLabel, Select, MenuItem, ListItemText, SelectChangeEvent, styled  } from '@mui/material'
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import { AppButton } from '../../../AppComponents/AppButton'
import { PRIMARY1, PRIMARY2, PRIMARY3, TERTIARY3, TERTIARY12 } from '../../../../utils/constants'
import { ReportSearch } from '../Search'
import { ReactComponent as DownloadIcon } from '../../../../assets/icons/download-white.svg'
import { ReactComponent as FunnelIcon } from '../../../../assets/icons/funnel.svg'
import { ReactComponent as ArrowDownIcon } from '../../../../assets/icons/arrow-down.svg'
import { ReactComponent as ArrowUpIcon } from '../../../../assets/icons/arrow-up.svg'
import { ReactComponent as RotateIcon } from '../../../../assets/icons/reset.svg'
import { KeyboardArrowUp, KeyboardArrowDown } from '@mui/icons-material';
import _ from 'lodash'
import { useAppDispatch,useAppSelector } from "../../../../store/hooks";
import moment from 'moment'
import IndustryDropdown from '../../../Mandates/AddMandate/IndustryDropdown'
import MuiAlert from '@mui/material/Alert';
import '../../../../components/Mandates/AddMandate/DepartmentDropDown.scss'
import './DashboardFilter.scss';
import { DefaultValues, SubmitHandler, useForm, Controller } from 'react-hook-form'
import { useLocation , useNavigate} from "react-router-dom";
import { getJobTitleapi, getJoLocationsapicall, getStatusFilter, MandateSPOCapi } from '../../../../services/DashboardService'
import { getLoggedInUserId } from '../../../../utils/user-utils'
import { objectToQueryString } from '../../../../utils/common-utils'
import DateRangePickerSelect from '../../CustomeDaterangepricker/DateRangePickerSelect'
import { ReactComponent as UserRoleImgPlus } from '../../../../assets/icons/plus.svg'
import { ReactComponent as UserRoleCancel } from '../../../../assets/icons/userRoleClose.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { getprimaryskill, MandateService } from '../../../../services/MandateService'
import { ReportkManagementService } from '../../../../services/ReportListService'
import { Breadcrumb } from '../../../Breadcrumb'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { ReactComponent as CalenderIcon } from '../../../../assets/icons/input-calendar.svg'


const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


export const DatePrickerStyle = { 
  // '& .MuiInputBase-root': { height: '40px' }, // Adjust height as needed
  // '& .MuiInputLabel-root': { top: '-5px', }   // Adjust label position as needed

  '& .MuiInputBase-root': {
    height: '38px', // Adjust height as needed
    top: '0.2px',
    '& fieldset': {
      borderColor: '#CFD1D2', // Default border color
    },
    '&:hover fieldset': {
      borderColor: '#CFD1D2', // Hover border color
    },
    '&.Mui-focused fieldset': {
      borderColor: '#CFD1D2', // Focus border color
      borderWidth: '1px', // Optional: Adjust the border width
    },
    '&.Mui-focused': {
      borderColor: '#CFD1D2', // Focus border color
    },
    '&:hover': {
      borderColor: '#CFD1D2', // Change this to your preferred hover border color
    },
  },
  '& .MuiOutlinedInput-root': {
    '&.Mui-focused fieldset': {
      borderColor: '#CFD1D2', // Focus border color
      borderWidth: '1px', // Optional: Adjust the border width
    },
    '&:hover fieldset': {
      borderColor: '#CFD1D2', // Hover border color
    },
  },
  '& .MuiInputLabel-root': {
    top: '-5px', // Adjust label position as needed
    '&.Mui-focused': {
      color: '#CFD1D2', // Focus label color
    },
    '&:hover': {
      color: '#CFD1D2', // Change this to your preferred hover label color
    },
  },
}


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP
    },
  },
};

const StyledButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#f4f9ff',
  color: '#0033a0',
  border: '1px solid #0033a0',
  borderRadius: '10px',
  textTransform: 'none',
  padding: '7px',
  '&:hover': {
    backgroundColor: '#e0f3ff',
  },
}));

const DashboardFilter = ({HirningDemandParamsDetails,setHirningDemandParamsDetails,Coelist,reportClientAPIList,jobModeList,mandateTypeList,businessUnitList,rolesdataList,primaryskillList,setReportSubmissionAPIData,setDashboardFilterDatas,setreportSubmitdatalabels,setMLocationList, hideFields, hideDashHeader,handleApiCall,reportSubmissionAPIData,reportStageList,headerTitle,downloadName,datePickerType,dateYearEnable,userRoleListObject,setUserRoleListObject,userRoleChange,setUserDependendObj,exportdatareqest,filterType,hideForm,screenType,hiringDemandTabDetail}:any) => {
  // const coeRemoveList = ["Assigned","Participated"] --
  const [filterChecked, setfilterChecked] = useState<boolean>(false)
  const [isOpendrilldownfilter, setIsOpendrilldownfilter] = useState(false);
  // const [columnFilter, setColumnFilter] = useState<boolean>(false) --
  // const [keyWordSearch, setKeyWordSearch] = useState("") --
  let userRoleState = useAppSelector((state:any) => state?.UserDetail?.userRole);
  const userStateId = useAppSelector((state) => state.UserDetail.userId)
  const bussinessUnitId = useAppSelector((state) => state.UserDetail.businessUnitId)
  const [dashboradfilterList,setdashboradfilterList] = useState<any>(HirningDemandParamsDetails?.state?.filterListLabels)
  userRoleState = userRoleState ? userRoleState.replace("/","").replace(/ /g,"").trim() : "";
  const dispatch = useAppDispatch()
  // const [filterTabCloseStatus, setfilterTabCloseStatus] = useState<string>() --
  const handleFilter = () => {
    setfilterChecked((prev) => !prev)
    handleApiCall(!filterChecked)
  }
  const currentSelectedYear:any = (parseInt(moment().format('M')) > 3) ? moment().format('YYYY') : parseInt(moment().format('YYYY'))
  const location = useLocation();
  const navigate = useNavigate();
  const buElementAccessRoles = useAppSelector((state) => state?.SystemConfig?.buElementAccessRoles);
  const [mandateSpocList, setMandateSpocList] = useState<any>([]);
    const [JobtitleList, setJobtitleList] = useState<any>([])
    const [JobLocationList, setJobLocationList] = useState<any>([])
    const [statusListData, setStatusListData] = useState<any>([])
    const [reportClientAPIListData,setReportClientAPIListData] = useState(reportClientAPIList)
    const [primaryskillListData,setprimaryskillListData] = useState<any>(primaryskillList);
    const HiringListInitial = HirningDemandParamsDetails?.state
    const [clientfieldrequestdata, setClientFieldRequestData] = useState({
      filterkey: [] as any[],
      clientid: [] as any[],
      search: "" as string,
      jobtitlereq: [] as any[],
      dateRangeType: "",
      buId: [],
      userCurrentIndex: {} as any
    });    

    useEffect(() => {
      setClientFieldRequestData((prevState: any) => ({
        ...prevState,
        userCurrentIndex: {}
      }));
    },[hiringDemandTabDetail])
    const autoBussId = useRef<any>(null);
    const autoClient = useRef<any>(null);
    const autoClient1 = useRef<any>(null);
    const autoJoTitle = useRef<any>(null);
    const autoJoTitle1 = useRef<any>(null);
    const autoJoLocation = useRef<any>(null);
    const autoMandate = useRef<any>(null);    
    const autoMandateSpoc = useRef<any>(null);    
    const autoMandateSpoc1 = useRef<any>(null);
    function prepend(value:any, array:any) {
      const newArray = array.slice();
      newArray.unshift(value);
      return newArray;
    }

    const getClientList = async () => {
      await MandateService.getClientname({"buIds": getValues("businessunit").length > 0 ? getValues("businessunit").map((item:any) => {return item.value.toString()}) :[]})
      .then((res: any) => {
        let options = _.map(res.data.result.list[0], (data: any) => {
          return {
            label: _.get(data, 'clientName'),
            value: _.get(data, 'id'),
          }
        }
      ) 
        options = prepend({label: "All",value: "All"}, options)
        const uniqueData:any = options.filter((item:any, index:any, self:any) =>
          index === self.findIndex((t:any) => t.label === item.label)
      );
      setReportClientAPIListData([...uniqueData])
      })
      .catch((error: any) => {
        console.log(error.message)
      })  
    }

    const getprimaryskillDetailsApi = async (joTitle:any) => {

      const reqObj = {
          "keyword": null,
          "pageNumber": 0,
          "pageSize" : Number.MAX_SAFE_INTEGER,
          "mandateIds": joTitle.length > 0 ? joTitle : [],
      } 
  
      try {
        await getprimaryskill(reqObj)
          .then((res: any) => {
              const primaryskilloptions = _.map(res?.data, (data: any) => {
                return {
                  label: data,
                  value: data,
                }
              })
              const uniqueData = primaryskilloptions.filter((item:any, index:any, self:any) =>
                  index === self.findIndex((t:any) => t.label === item.label)
              );
              // primaryskilloptions = prepend({label: "All",value: "All"}, primaryskilloptions)
              setprimaryskillListData(uniqueData);
          })
        
  
      } catch (error) {
        console.log(error);
      }
    };

    
    

    useEffect(() => {
      setUserDependendObj(clientfieldrequestdata)
    },[clientfieldrequestdata])

    const [clientsearchTerm, setclientSearchTerm] = useState('');

    const mandatespocapicall = async () =>{
      const reqObj = {
        filter: clientfieldrequestdata.search,
        pageNumber: 0,
        pageSize: Number.MAX_SAFE_INTEGER,
        clientId: clientfieldrequestdata.clientid.length > 0 ? clientfieldrequestdata.clientid : HirningDemandParamsDetails.state != null ? ("clientDependedObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails.state.clientDependedObj != undefined ? HirningDemandParamsDetails.state.clientDependedObj.clientid : [] : []) : [],
        buId: getValues("businessunit").length > 0 ? getValues("businessunit").map((item:any) => {return item.value.toString()}) : HirningDemandParamsDetails.state != null ? ("clientDependedObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails.state.clientDependedObj != undefined ? HirningDemandParamsDetails.state.clientDependedObj.buId : [] : []) : ((bussinessUnitId != null && bussinessUnitId !=  0) ? [bussinessUnitId.toString()] : [])
      };
    
      try {
        const res = await MandateSPOCapi(reqObj);    
        const Mandatespocoptions = _.map(res?.data, (data: any,index:number) => {
          return {
            label: data,
            value: data
          }
        })
        // Mandatespocoptions = prepend({label: "All",value: "All"}, Mandatespocoptions)
        setMandateSpocList(Mandatespocoptions)
      } catch (error) {
        console.log(error, "config api error");
      }
    }

    const jobtitlecallapi = async () =>{
      const queryString = objectToQueryString({
        page: 0 ,
        // size:Number.MAX_SAFE_INTEGER,
        size:9999,
        userId: getLoggedInUserId(),
        IsApproved: 1,
        screen:"mandates",
        // clientId:[]
      })
      const reqObj = {
          jobTitleFilterList: clientfieldrequestdata.clientid.length > 0 ? clientfieldrequestdata.clientid : HirningDemandParamsDetails.state != null ? ("clientDependedObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails.state.clientDependedObj != undefined ? HirningDemandParamsDetails.state.clientDependedObj.clientid : [] : []) : [],
          buId: getValues("businessunit").length > 0 ? getValues("businessunit").map((item:any) => {return item.value.toString()}) : HirningDemandParamsDetails.state != null ? ("clientDependedObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails.state.clientDependedObj != undefined ? HirningDemandParamsDetails.state.clientDependedObj.buId : [] : []) : ((bussinessUnitId != null && bussinessUnitId !=  0) ? [bussinessUnitId.toString()] : [])
      }
      try {
        const res = await getJobTitleapi(queryString,reqObj);

        const JobtitleOptions = _.map(res?.data?.result.list, (data: any,index:number) => {
          return {
            label: data.title,
            value: data?.id,
          }
        })
        const uniqueData = JobtitleOptions.filter((item:any, index:any, self:any) =>
            index === self.findIndex((t:any) => t.label === item.label)
        );
        setJobtitleList(uniqueData)
      } catch (error) {
        console.log(error, "config api error");
      }

  }


  const joLocationscallapi = async (joLocationValue:string,type:string) =>{
  const reqObj = {
    "mandateIds": (joLocationValue.length == 0 && type == "auto") ? (clientfieldrequestdata.jobtitlereq.length > 0 ? clientfieldrequestdata.jobtitlereq : HirningDemandParamsDetails.state != null ? ("clientDependedObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails.state.clientDependedObj !=  undefined ? HirningDemandParamsDetails.state.clientDependedObj.jobtitlereq : [] : []) : []) : joLocationValue,
    "clientId": clientfieldrequestdata.clientid.length > 0 ? clientfieldrequestdata.clientid : HirningDemandParamsDetails.state != null ? ("clientDependedObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails.state.clientDependedObj != undefined ? HirningDemandParamsDetails.state.clientDependedObj.clientid : [] : []) : [],
    "buId": getValues("businessunit").length > 0 ? getValues("businessunit").map((item:any) => {return item.value.toString()}) : HirningDemandParamsDetails.state != null ? ("clientDependedObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails.state.clientDependedObj != undefined ? HirningDemandParamsDetails.state.clientDependedObj.buId : [] : []) : ((bussinessUnitId != null && bussinessUnitId !=  0) ? [bussinessUnitId.toString()] : [])
}

try {
  const res = await getJoLocationsapicall(reqObj);

  const JobtitleOptions = _.map(res?.data, (data: any,index:number) => {
    return {
      label: data?.primary_locations,
      value: data?.primary_locations,
    }
  })
  setJobLocationList(JobtitleOptions)
} catch (error) {
  console.log(error, "config api error");
}

}

const getReportStatusApi = async (statusId:string) =>{
  try {
    const res = await getStatusFilter(statusId);
    const statusListOptions = _.map(res?.data.filter((item:any) => item.enable == 1), (data: any,index:number) => {
      return {
        label: data?.status_name,
        value: data?.id,
      }
    })
    setStatusListData(statusListOptions)
  } catch (error) {
    console.log(error, "config api error");
  }
}

  const apiCallClientBased = async () => {
    if(!hideFields.includes("Mandate SPOC"))await mandatespocapicall()
    if(!hideFields.includes("Job Title"))await jobtitlecallapi()
    if(!hideFields.includes("Job Location"))await joLocationscallapi("","auto")
    if(getValues("client").length > 0){
      setTimeout(() => {
        if(autoClient1.current != null)autoClient1.current.focus();
      },500)
    }      
  } 

    useEffect(()=>{
      apiCallClientBased()
    },[clientfieldrequestdata.clientid])

    useEffect(() => {
      setReportClientAPIListData(reportClientAPIList)
      setprimaryskillListData(primaryskillList)
    },[])

    const jobTitleTrigger = async (jobTitle:string) => {
      await joLocationscallapi(jobTitle,"self")
      await getprimaryskillDetailsApi(jobTitle)
      if(getValues("jobtitle").length > 0){
        setTimeout(() => {
          autoJoTitle1.current.focus();
        },500)
      }      
    }

    const buIdTriggerAPI = async (buIdValue:any) => {
      await getClientList()      
      const bussinessUnitIdValue = buIdValue.length > 0 ? buIdValue : []
      userRoleChange([userStateId],"",bussinessUnitIdValue)
      setValue("")      
      setTimeout(() => {
        autoBussId.current.focus();
      },1000)
    }

  const initValues:any = 
  {
    submissionEndDate: HiringListInitial?.dashboardfilterObj?.["submissionEndDate"] ?? null,
    submissionDate:  datePickerType == "date" ? HiringListInitial?.dashboardfilterObj?.["submissionDate"] != undefined && Object.keys(HiringListInitial?.dashboardfilterObj?.["submissionDate"]).length > 0 ? HiringListInitial?.dashboardfilterObj?.["submissionDate"]?.$d ? moment(HiringListInitial?.dashboardfilterObj?.["submissionDate"].$d).format('MM/DD/YYYY') : new Date(HiringListInitial?.dashboardfilterObj?.["submissionDate"]): !isNaN(Date.parse(HiringListInitial?.dashboardfilterObj?.["submissionDate"])) ? new Date(HiringListInitial?.dashboardfilterObj?.["submissionDate"]) : null : HiringListInitial?.dashboardfilterObj?.["submissionDate"] ?? null,
    // submissionDate: HiringListInitial?.dashboardfilterObj?.["submissionDate"] ?? null,
    coe: HiringListInitial?.dashboardfilterObj?.["coe"]?.map((item: any) => item) || [],
    businessunit: HiringListInitial?.dashboardfilterObj?.["businessunit"]?.map((item: any) => item) || [],
    mandatespoc: HiringListInitial?.dashboardfilterObj?.["mandatespoc"]?.map((item: any) => item) || [],
    client: HiringListInitial?.dashboardfilterObj?.["client"]?.map((item: any) => item) || [],
    jobtitle: HiringListInitial?.dashboardfilterObj?.["jobtitle"]?.map((item: any) => item) || [],
    jolocation: HiringListInitial?.dashboardfilterObj?.["jolocation"]?.map((item: any) => item) || [],
    primaryskill: HiringListInitial?.dashboardfilterObj?.["primaryskill"]?.map((item: any) => item) || [],
    mandatetype: HiringListInitial?.dashboardfilterObj?.["mandatetype"]?.map((item: any) => item) || [],
    industryData: HiringListInitial?.dashboardfilterObj?.["industry"]?.map((item: any) => item) || [],
    role: HiringListInitial?.dashboardfilterObj?.["role"]?.map((item: any) => item) || [],
    jobmode: HiringListInitial?.dashboardfilterObj?.["jobmode"]?.map((item: any) => item) || [],
    BUHead: HiringListInitial?.filterListLabels?.["BUHeadUserID"],
    RecruiterUserID: HiringListInitial?.filterListLabels?.["RecruiterUserID"],
    dateYear: HiringListInitial?.dashboardfilterObj?.["dateYear"] != undefined ?  HiringListInitial?.dashboardfilterObj?.["dateYear"] : currentSelectedYear
  }

  const { handleSubmit, control, reset, watch, getValues,setValue, formState: { errors } }:any = useForm({defaultValues: initValues})

  const [preloadDataObj , setPreloadDataObj] = useState<any>()

  const FilterForms = () => {    
    const { userId, userRole, roleId }:any = useAppSelector((state) => state?.UserDetail)
    const [dateRangeType,setDateRangeType] = useState(HiringListInitial?.dashboardfilterObj?.dateRange != undefined ? HiringListInitial?.dashboardfilterObj?.dateRange : clientfieldrequestdata.dateRangeType)
    const autoC = useRef<any>(null);        
    const industryConvertObj = (industryObj:any) => {
      const arrayObj = industryObj.map((item:any) => {
        return item.industryName
      })
      return arrayObj
    }

    const filterSubmitHandler = (Data:any) => {
      const getDateSelected = (() => {
        if(Data?.submissionDate?.$d){
          return moment(Data.submissionDate.$d, 'DD/MM/YYYY').format('YYYY-MM-DD')
        }else if(!isNaN(Date.parse(Data?.submissionDate))){
          return moment(Data?.submissionDate).format('YYYY-MM-DD')
        }else{
          return Data?.submissionDate.split("/")[2] + "-" + Data?.submissionDate.split("/")[0] + "-" + Data?.submissionDate.split("/")[1]
        }
      })
      const dashboardFilterObj:any = {}
      if(Data?.client.length > 0){dashboardFilterObj["client"] = Data?.client?.map((item:any)=>item.value)}
      if(Data?.jobtitle.length > 0) {dashboardFilterObj["jobTitle"] = Data?.jobtitle?.map((item:any)=>item.label)}
      if(Data?.primaryskill.length > 0) {dashboardFilterObj["primarySkills"] = Data?.primaryskill?.map((item:any)=>item.value)}else{dashboardFilterObj["primarySkills"] = []}
      if(Data?.mandatetype.length > 0) {dashboardFilterObj["mandateType"] = Data?.mandatetype?.map((item:any)=>item.label)}else{dashboardFilterObj["mandateType"] = []}
      if(Data?.jobmode.length > 0) {dashboardFilterObj["jobMode"] = Data?.jobmode?.map((item:any)=>item.label)}else{dashboardFilterObj["jobMode"] = []}
      if(Data?.mandatespoc.length > 0) {dashboardFilterObj["mandateSPOC"] = Data?.mandatespoc?.map((item:any)=>item.label)}else{dashboardFilterObj["mandateSPOC"] = []}
      dashboardFilterObj["joLocation"] = Data?.jolocation.length > 0 ? Data?.jolocation?.map((item:any)=>item.value) : []
      if(Data?.coe.length > 0) {dashboardFilterObj["coe"] = Data?.coe?.map((item:any)=>item.value)}
      if (Data?.businessunit.length > 0) { dashboardFilterObj["businessUnit"] = Data?.businessunit?.map((item:any)=>item.value); }
      if (Data?.submissionDate) { dashboardFilterObj["customStartDate"] = datePickerType == "date" ? getDateSelected() : Data?.submissionDate[0]}
      if (Data?.submissionDate) { dashboardFilterObj["customEndDate"] = datePickerType == "date" ? getDateSelected() : Data?.submissionDate[1] }
      dashboardFilterObj["userId"] = userRoleListObject.length > 0 ? userRoleListObject.at(-1).user.selectedValue.length > 0 ? userRoleListObject.at(-1).user.selectedValue : userRoleListObject.length > 1 ?  userRoleListObject[(userRoleListObject.length - 1) - 1].user.selectedValue :  [userId] : [userId]
      // userRoleListObject.at(-1).user.selectedValue.length > 0 ? userRoleListObject.at(-1).user.selectedValue : 
      dashboardFilterObj["dateRange"] = dateRangeType != undefined ? dateRangeType : ""
      // if (DateRangePickervalue?.submissionDate != '') {dashboardFilterObj["customeStartDate"]=DateRangePickervalue?.submissionDate}
      // if (DateRangePickervalue?.submissionEndDate != '') {dashboardFilterObj["customEndDate"]=DateRangePickervalue?.submissionEndDate}

      if (Data?.industryData != null) { dashboardFilterObj["industry"] = industryConvertObj(Data.industryData) }
      if (Data?.role.length > 0) { dashboardFilterObj["roles"] = Data?.role?.map((item:any)=>item.label); }else{dashboardFilterObj["roles"] = []}
      if (Data?.stage != null) { dashboardFilterObj["stage"] = Data?.stage.map((item:any) => item?.value) }
      if (Data?.status != null) { dashboardFilterObj["status"] = Data?.status.map((item:any) => item?.value) }
      if(dateYearEnable) { dashboardFilterObj["dateYear"] = Data?.dateYear == undefined ? currentSelectedYear : Data?.dateYear}
      setReportSubmissionAPIData(dashboardFilterObj)
      // if(location.pathname == "/dashboard"){
        setDashboardFilterDatas(Data)
        const dashboardFilterObjlabel:any = {}
        if(Data?.client ){dashboardFilterObjlabel["client"] = Data?.client?.map((item:any)=>item.label)}
        if(Data?.jobtitle) {dashboardFilterObjlabel["jobTitle"] = Data?.jobtitle?.map((item:any)=>item.label)}
        if(Data?.primaryskill) {dashboardFilterObjlabel["primarySkills"] = Data?.primaryskill?.map((item:any)=>item.label)}
        if(Data?.mandatetype) {dashboardFilterObjlabel["mandateType"] = Data?.mandatetype?.map((item:any)=>item.label)}
        if(Data?.jobmode) {dashboardFilterObjlabel["jobMode"] = Data?.jobmode?.map((item:any)=>item.label)}
        if(Data?.mandatespoc) {dashboardFilterObjlabel["mandateSPOC"] = Data?.mandatespoc?.map((item:any)=>item.label)}
        if(Data?.jolocation) {dashboardFilterObjlabel["joLocation"] = Data?.jolocation?.map((item:any)=>item.label)}
        if(Data?.coe) {dashboardFilterObjlabel["coe"] = Data?.coe?.map((item:any)=>item.label)}
        if (Data?.businessunit) { dashboardFilterObjlabel["businessUnit"] = Data?.businessunit?.map((item:any)=>item.label); }
        if (Data?.submissionDate) { dashboardFilterObjlabel["Start Date"] = datePickerType == "date" ? Data?.submissionDate : Data?.submissionDate[0]}
        if (Data?.submissionDate) { dashboardFilterObjlabel["End Date"] = datePickerType == "date" ? Data?.submissionDate : Data?.submissionDate[1] }
        // if (DateRangePickervalue?.submissionDate != '') {dashboardFilterObj["customeStartDate"]=DateRangePickervalue?.submissionDate}
        // if (DateRangePickervalue?.submissionEndDate != '') {dashboardFilterObj["customEndDate"]=DateRangePickervalue?.submissionEndDate}

        if (Data?.industryData != null) { dashboardFilterObjlabel["industry"] = industryConvertObj(Data.industryData) }
        if (Data?.role) { dashboardFilterObjlabel["roles"] = Data?.role?.map((item:any)=>item.label) }
        dashboardFilterObj["userId"] = userRoleListObject.length > 0 ? userRoleListObject.at(-1).user.selectedValue.length > 0 ? userRoleListObject.at(-1).user.selectedValue : userRoleListObject.length > 1 ?  userRoleListObject[(userRoleListObject.length - 1) - 1].user.selectedValue :  [userId] : [userId]
        setreportSubmitdatalabels(dashboardFilterObjlabel)
        setClientFieldRequestData((prevState: any) => ({
          ...prevState,
          userCurrentIndex: {}
        }));
      // }
    }

    const resetFilterData = () => {
      reset()
      userRoleChange([userStateId],"reset",[])
      setReportSubmissionAPIData({})
      setreportSubmitdatalabels({})
      setDashboardFilterDatas({})
      setHirningDemandParamsDetails({})
      setClientFieldRequestData((prevState: any) => ({
        ...prevState,
        dateRangeType : "",
        userCurrentIndex: {}
      }));
      // navigate(location.pathname, { replace: true, state: {} })
      reset({
        submissionEndDate: null,
        submissionDate: null,
        coe: [],
        businessunit : [],
        mandatespoc: [],
        client : [],
        jobtitle: [],
        jolocation: [],
        primaryskill: [],
        mandatetype: [],
        industryData: null,
        role: [],
        jobmode: []
      })
    }
    const clearLocationField = () => {
      const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
      if (ele) ele.click();
      setMLocationList([])
    } 
    const clearLocationMandateField = () => {
      const ele = autoMandate.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
      if (ele) ele.click();
    } 

    const clearClientField = () => {
      const ele = autoClient.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
      if (ele) ele.click();
    } 

    const clearJoTitle = () => {
      const ele = autoJoTitle.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
      if (ele) ele.click();
    } 

    const clearJoLocation = () => {
      const ele = autoJoLocation.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
      if (ele) ele.click();
    } 

    const clearMandateSpoc = () => {
      const ele = autoMandateSpoc.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
      if (ele) ele.click();
    } 

    const [currentUserSelected, setCurrentUserSelected] = React.useState<any>([]);

    const selectUserHandler = (userId: any,roleIndex: number) => {      
      const userIdData = userId.target.value
      let updatedData = [...userRoleListObject];
      const referData = JSON.parse(JSON.stringify(userRoleListObject));
      updatedData[roleIndex].user.selectedValue = userIdData;
      if(userRoleListObject.length != roleIndex + 1 || (referData[roleIndex].user.selectedValue.length > 0 && userRoleListObject.length != roleIndex + 1)) {
        updatedData = updatedData.slice(0, roleIndex + 1)
        setUserRoleListObject(updatedData)
        setTimeout(() => {
          if(userIdData.length > 0)addRoleHierarchy(roleIndex,userIdData)
        },100)
      }else{
        setCurrentUserSelected(userIdData);
      }   
      // setClientFieldRequestData((prevState: any) => ({
      //   ...prevState,
      //   dateRangeType : dateRangeType != undefined ? dateRangeType : "",
      //   userCurrentIndex: {type:"user",index:roleIndex}
      // }));
    };

    const userRoleSelectHandler = (userRole: string, roleIndex: number) => {
      let updatedData = [...userRoleListObject];    
      updatedData[roleIndex].role.selectedValue = userRole;
      updatedData[roleIndex].user.option = userRoleListObject[roleIndex].role.groupedKeys[userRole];
      if(userRoleListObject.length != roleIndex + 1 || userRoleListObject[roleIndex].role.selectedValue.length > 0) {
        updatedData[roleIndex].user.selectedValue = [];
        updatedData = updatedData.slice(0, roleIndex + 1)
      }
      setClientFieldRequestData((prevState: any) => ({
        ...prevState,
        dateRangeType : dateRangeType != undefined ? dateRangeType : "",
        userCurrentIndex: {type:"role",index:roleIndex}
      }));
      setUserRoleListObject(updatedData)
    }
  
  
    const addRoleHierarchy = (currentIndex: number,userObject:any) => {      
      if(currentUserSelected.length > 0 && userObject.length == 0){
        setCurrentUserSelected([])
      }
      userRoleChange(((currentUserSelected.length > 0 && userObject.length == 0) ? currentUserSelected : userObject.length > 0 ? userObject : userRoleListObject[currentIndex].user.selectedValue),"addCase",getValues("businessunit").length > 0 ? getValues("businessunit").map((item:any) => {return item.value}) : [])
      setClientFieldRequestData((prevState: any) => ({
        ...prevState,
        dateRangeType : dateRangeType != undefined ? dateRangeType : "",
      }));
    }
  
    const removeRoleHierarchy = (currentIndex: number) => {
      const updatedData = [...userRoleListObject];
      const sliceObj = updatedData.slice(0, currentIndex)
      setUserRoleListObject(sliceObj)
      setClientFieldRequestData((prevState: any) => ({
        ...prevState,
        dateRangeType : dateRangeType != undefined ? dateRangeType : "",
        userCurrentIndex: {type:"role",index:currentIndex - 1}
      }));
    }
    
    return (
      <>
      <Box sx={{backgroundColor:'white',borderRadius:'10px',padding:'2px',paddingX:'15px',paddingBottom:'13px'}} component={'form'} onSubmit={handleSubmit((data:any) => filterSubmitHandler(data))}>
        <Box sx={{ flexGrow: 1 }}>
          <Box sx={{display:'flex', justifyContent:'space-between',marginBottom:'2px'}}>
            <Box><Typography className='filter-title' sx={{fontWeight:'bold',paddingTop:'10px'}}>Filter</Typography></Box>
            </Box>
          <Box>
            <div className='customefilterGrid reportFilters' style={{marginTop:'1px'}}>
              {hideFields.includes("singleDatePicker") && <Box>
                <Box className="DateRangeField">
                <LocalizationProvider dateAdapter={AdapterDayjs} >
                <Controller
                  name='submissionDate'
                  control={control}
                  render={({ field: { onChange,value, ...restField } }:any) => (
                      // <DateRangePickerSelect onDateChange={onChange} value={value} setDateRangeType={setDateRangeType} dateRangeType={Object.keys(reportSubmissionAPIData != undefined ? reportSubmissionAPIData : {}).length > 0 ? reportSubmissionAPIData?.dateRange : dateRangeType} dateSelection={datePickerType == "date" ? "date" : "range"}/>
                      <DatePicker
                        label='Start Date'
                        className='datePicker'
                        inputFormat="DD/MM/YYYY"
                        value={value}
                        onChange={(newValue: any) => {
                          onChange(newValue);
                          setValue("submissionDate", newValue);
                        }}
                        components={{
                            OpenPickerIcon: CalenderIcon,
                        }}
                        renderInput={(params:any) => (
                            <TextField
                                name='Date'
                                style={{ width: "100%" }}
                                {...params}
                            />
                        )}
                        {...restField}
                    />
                  )}
                />
                {errors?.submissionDate && (
                  <span className="reminder_text-danger select-danger-text" role="alert">
                      {errors?.submissionDate?.message}
                  </span>
              )}
              </LocalizationProvider>
              </Box>
                </Box>}
            {!hideFields.includes("Start Date") && <Box>
              <Box className="DateRangeField">
             <Controller
                name='submissionDate'
                rules={{
                  required: {
                      value: false,
                      message: "This field is required",
                  },
                  }}
                // defaultValue={submissionStartDate}
                defaultValue={preloadDataObj?.submissionDate ? preloadDataObj?.submissionDate : null}
                control={control}
                render={({ field: { onChange,value, ...restField } }:any) => (
                    <DateRangePickerSelect onDateChange={onChange} value={value} setDateRangeType={setDateRangeType} dateRangeType={Object.keys(reportSubmissionAPIData != undefined ? reportSubmissionAPIData : {}).length > 0 ? reportSubmissionAPIData?.dateRange : dateRangeType} dateSelection={datePickerType == "date" ? "date" : "range"}/>
                )}
              />
              {errors?.submissionDate && (
                <span className="reminder_text-danger select-danger-text" role="alert">
                    {errors?.submissionDate?.message}
                </span>
            )}
            </Box>
              </Box>}
                  {dateYearEnable != undefined && dateYearEnable && <FormControl fullWidth className='dateYearFields'>
                    <InputLabel id="demo-simple-select-label">Year</InputLabel>
                    <Controller
                        control={control}
                        name='dateYear'
                        render={({ field: { value, onChange } }) => (
                          <Select
                            defaultValue={currentSelectedYear}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={value}
                            label="Year"
                            onChange={onChange}
                          >
                            {[currentSelectedYear, currentSelectedYear - 1, currentSelectedYear - 2].map((year:any) => {
                              return (
                                <MenuItem key={year} value={year}>{year}</MenuItem>
                              )
                            })}
                          </Select>
                        )}
                        />
                  </FormControl>}
                  {!hideFields.includes("COE") && <Controller
                    control={control}
                    name='coe'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        options={Coelist}
                        disabled={true}
                        // defaultValue={Coelist[0]}
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event:any, value:any) => {
                          onChange(value)
                          setValue("coe", value)   
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Delivery centre" label={`Delivery centre`} />}
                      />
                    )}
                  />}


                  {(!hideFields.includes("Business Unit") && buElementAccessRoles.includes(roleId.toString())) && <Controller
                    control={control}
                    name='businessunit'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'                        
                        multiple
                        limitTags={1}
                        disablePortal
                        getOptionDisabled={(option) =>
                          value.some((selected: any) => selected.value === option.value)
                        }
                        options={businessUnitList}
                        // defaultValue={businessUnitList[0]}
                        sx={{ width: 300 }}
                        value={value}
                        onChange={async (event, value:any) => {
                          onChange(value)
                          setValue("businessunit", value)
                          await buIdTriggerAPI(getValues("businessunit").length > 0 ? getValues("businessunit").map((item:any) => {return item.value}) : [])
                          setClientFieldRequestData((prevState: any) => ({
                            ...prevState,
                            buId: getValues("businessunit").length > 0 ? getValues("businessunit").map((item:any) => {return item.value.toString()}) : [],
                            filterkey: [],
                            clientid: [],
                            search: "",
                            jobtitlereq: [],
                            dateRangeType : dateRangeType != undefined ? dateRangeType : ""
                          }));
                          if(!hideFields.includes("Client"))clearClientField()
                          if(!hideFields.includes("Job Title"))clearJoTitle()
                          if(!hideFields.includes("JO Location"))clearJoLocation()
                          if(!hideFields.includes("Mandate SPOC"))clearMandateSpoc()                          
                        }}
                        renderInput={(params) => <TextField inputRef={autoBussId} {...params} placeholder="Business Unit" label={`Business Unit`} />}
                      />
                    )}
                  />}
                  {!hideFields.includes("Client") && <Controller
                    control={control}
                    name='client'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        ref={autoClient}
                        multiple
                        limitTags={1}
                        disablePortal
                        options={reportClientAPIListData.length > 0 ? reportClientAPIListData : reportClientAPIList}
                        getOptionDisabled={(option) =>
                          value.some((selected: any) => selected.value === option.value)
                        }
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          setValue("client", value)   
                          const labels = value.map((item: any) => item.label);
                          const values = value.map((item: any) => item.value.toString());
                          setClientFieldRequestData((prevState: any) => ({
                            ...prevState,
                            filterkey: labels,
                            clientid: values,
                            search: clientsearchTerm,
                            dateRangeType : dateRangeType != undefined ? dateRangeType : "",
                          }));
                          clearJoTitle()
                          clearJoLocation()
                          clearMandateSpoc()                          
                        }}
                        renderInput={(params) => <TextField inputRef={autoClient1} {...params} placeholder="Client" label={`Client`}/>}
                      />
                    )}
                  />}

                  {!hideFields.includes("Mandate SPOC") && <Controller
                    control={control}
                    name='mandatespoc'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        ref={autoMandateSpoc}
                        multiple
                        limitTags={1}
                        disablePortal
                        options={mandateSpocList}
                        getOptionDisabled={(option) =>
                          value.some((selected: any) => selected.value === option.value)
                        }
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          setValue("mandatespoc", value)
                          setClientFieldRequestData((prevState: any) => ({
                            ...prevState,
                            search:value,
                            dateRangeType : dateRangeType != undefined ? dateRangeType : ""
                          }));
                          setTimeout(() => {
                            autoMandateSpoc1.current.focus();
                          },100)
                        }}
                        renderInput={(params) => <TextField inputRef={autoMandateSpoc1} {...params} placeholder="Mandate SPOC" label={`Mandate SPOC`}/>}
                      />
                    )}
                  />}


                  {!hideFields.includes("Job Title") && <Controller
                    control={control}
                    name='jobtitle'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        ref={autoJoTitle}
                        multiple
                        limitTags={1}
                        disablePortal
                        options={JobtitleList}
                        getOptionDisabled={(option) =>
                          value.some((selected: any) => selected.value === option.value)
                        }
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          setValue("jobtitle", value)  
                          const labels = value.map((item: any) => item.label);
                          const values = value.map((item: any) => item.value);
                          jobTitleTrigger(values)
                          setClientFieldRequestData((prevState: any) => ({
                            ...prevState,
                            jobtitlereq:values,
                            dateRangeType : dateRangeType != undefined ? dateRangeType : ""
                          }));
                          clearJoLocation()
                        }}
                        renderInput={(params) => <TextField inputRef={autoJoTitle1} {...params} placeholder="Job Title" label={`Job Title`} />}
                      />
                    )}
                  />}


                {!hideFields.includes("JO Location") && <Controller
                    control={control}
                    name='jolocation'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        ref={autoJoLocation}
                        multiple
                        limitTags={1}
                        disablePortal
                        options={JobLocationList}
                        getOptionDisabled={(option) =>
                          value.some((selected: any) => selected.value === option.value)
                        }
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          setValue("jolocation", value)
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Jo Location" label={`JO Location`} />}
                      />
                    )}
                  />}


                {!hideFields.includes("Primary Skill") && <Controller
                    control={control}
                    name='primaryskill'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        options={primaryskillListData.length > 0 ? primaryskillListData : primaryskillList}
                        getOptionDisabled={(option) =>
                          value.some((selected: any) => selected.value === option.value)
                        }
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          setValue("primaryskill", value)
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Primary Skill" label={`Primary Skill`} />}
                      />
                    )}
                  />}

                  {!hideFields.includes("Mandate Type") && <Controller
                    control={control}
                    name='mandatetype'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        options={mandateTypeList}
                        getOptionDisabled={(option) =>
                          value.some((selected: any) => selected.value === option.value)
                        }
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          setValue("mandatetype", value)
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Mandate Type" label={`Mandate Type`} />}
                      />
                    )}
                  />}

                {!hideFields.includes("industryData") && <div className="customeindustry">
                <Controller
                  name="industryData"
                  control={control}
                  render={({ field: { onChange, value, onBlur } }) => (
                    <>
                  <IndustryDropdown value={value} onChange={onChange} preSelectedItems={getValues("industryData") ?? []} enableLabel={true}/>
                    </>
                )}
                />
                </div>}

                {!hideFields.includes("Role") &&  <Controller
                    control={control}
                    name='role'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        disabled={true}
                        options={rolesdataList}
                        getOptionDisabled={(option) =>
                          value.some((selected: any) => selected.value === option.value)
                        }
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          setValue("role", value)
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Role" label={`Role`} />}
                      />
                    )}
                  />}

                  {!hideFields.includes("Job Mode") && <Controller
                    control={control}
                    name='jobmode'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        options={jobModeList}
                        getOptionDisabled={(option) =>
                          value.some((selected: any) => selected.value === option.value)
                        }
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          setValue("jobmode", value)
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Job Mode" label={`Job Mode`} />}
                      />
                    )}
                  />}

                  {!hideFields.includes("stage") && <Controller
                    control={control}
                    name='stage'
                    render={({ field: { value = [], onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        options={reportStageList}
                        getOptionDisabled={(option) =>
                          value.some((selected: any) => selected.value === option.value)
                        }
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                          const selectedVal = value.map((item:any) => item.value);
                          if(selectedVal.length > 0){
                            getReportStatusApi(selectedVal.join(","))
                          }else{
                            setStatusListData([])
                          }
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Stage" label={`Stage`} />}
                      />
                    )}
                  />}

                  {!hideFields.includes("status") && <Controller
                    control={control}
                    name='status'
                    render={({ field: { value = [], onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        options={statusListData}
                        getOptionDisabled={(option) =>
                          value.some((selected: any) => selected.value === option.value)
                        }
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Status" label={`Status`} />}
                      />
                    )}
                  />}

                  {/* {!hideFields.includes("status") && <Controller
                    control={control}
                    name='status'
                    render={({ field: { value, onChange } }) => (
                      <Autocomplete size='small'
                        multiple
                        limitTags={1}
                        disablePortal
                        options={jobModeList}
                        getOptionDisabled={(option) =>
                          value.some((selected: any) => selected.value === option.value)
                        }
                        sx={{ width: 300 }}
                        value={value}
                        onChange={(event, value:any) => {
                          onChange(value)
                        }}
                        renderInput={(params) => <TextField {...params} placeholder="Job Mode" label={`Job Mode`} />}
                      />
                    )}
                  />} */}
              
            </div>
            
          </Box>


           <Box>
            <Grid >
            {userRoleListObject.length > 0 && <Grid className="user-role-based-container" item xs={12} sm={6}>
              <h4>Role - Based Filters</h4>
              <Box className="user-role-based-hierarchy">
                {/* {JSON.stringify(userRoleListObject)} */}
                {userRoleListObject.map((userRoleItem: any, index: number) => {
                  return (
                    <Box key={index} className={`${(userRoleListObject.length > 1 && userRoleListObject.length != index + 1) ? "cancelState" : (userRoleListObject.length == index + 1 && userRoleItem.role.option.length == 1) ? "lastNoState" : "addState"} user-role-based-hierarchy-row dashboard-user-filter`}>
                      <Box className="user-role-basedFields">
                        <FormControl fullWidth>
                          <InputLabel id="demo-simple-select-label">Role</InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={userRoleItem.role.selectedValue}
                            autoFocus={Object.keys(clientfieldrequestdata.userCurrentIndex).length > 0 ? ((clientfieldrequestdata.userCurrentIndex.index == index && clientfieldrequestdata.userCurrentIndex.type == "role") ? true : false) : false}
                            label="Role"
                            IconComponent={KeyboardArrowDownIcon}
                            onChange={(e: any) => { userRoleSelectHandler(e.target.value, index) }}
                          >
                            {userRoleItem.role.option.map((options: any, optionIndex: number) => {
                              return (
                                <MenuItem key={optionIndex} value={options}>{options}</MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                        <FormControl className={(userRoleListObject.length > 1 && userRoleListObject.length != index + 1) ? "cancelBtnAdded" : ""} sx={{ m: 1, width: 300 }}>
                          <InputLabel id="demo-multiple-checkbox-label">Select</InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            value={userRoleItem.user.selectedValue.length > 0 ? userRoleItem.user.selectedValue : currentUserSelected.length > 0 ? currentUserSelected : []}
                            disabled={userRoleItem.user.option.length == 0 ? true : false}
                            onChange={(e: any) => { selectUserHandler(e,index) }}
                            autoFocus={Object.keys(clientfieldrequestdata.userCurrentIndex).length > 0 ? ((clientfieldrequestdata.userCurrentIndex.index == index && clientfieldrequestdata.userCurrentIndex.type == "user") ? true : false) : false}
                            input={<OutlinedInput label="Select" />}
                            IconComponent={KeyboardArrowDownIcon}
                            renderValue={(selected: any) =>
                              selected
                                .map((selectedId: string) => {
                                  const user = userRoleItem.user.option.find(
                                    (userItem: any) => userItem.keycloak_id === selectedId
                                  );
                                  return user ? user.name : selectedId;
                                })
                                .join(", ")
                            }
                            MenuProps={MenuProps}
                          >
                            {userRoleItem.user.option.map((userItem: any, index: number) => {
                              return (
                                <MenuItem key={index} value={userItem.keycloak_id}>
                                  <Checkbox checked={userRoleItem.user.selectedValue.includes(userItem.keycloak_id)} />
                                  <ListItemText primary={userItem.name} />
                                </MenuItem>
                              )
                            })}
                          </Select>
                        </FormControl>
                        {(userRoleListObject.length > 1 && userRoleListObject.length != index + 1) && <Button className="userRoleCancelBtn" onClick={() => { removeRoleHierarchy(index + 1) }}><UserRoleCancel /></Button>}
                      </Box>
                       {(userRoleListObject.length == index + 1 && userRoleItem.role.option.length > 1) && <FormControl><Box className="userRoleFilterAction">
                        <Button className={`userRoleAddBtn ${userRoleItem.user.selectedValue.length == 0 ? "disablePlus" : ""}`} onClick={() => { addRoleHierarchy(index,[]) }}><UserRoleImgPlus /></Button>
                      </Box></FormControl>}
                    </Box>
                  )
                })}

              </Box>
            </Grid>}
            <Grid item xs={12} sm={6} sx={{display:'flex', justifyContent:'end', borderTop:'1px solid #E5E8EB',paddingTop:'10px'}}>
              <Stack gap={1} direction='row'>
              <Button
                    sx={{
                      color: '#002882',
                      textTransform: 'none',
                    }}
                    variant='text'
                    // onClick={resetAllFilters}
                    onClick={resetFilterData}
                  >
                    <RotateIcon />
                    <Typography className='reset-label'>Reset All</Typography>
                  </Button>
                  <Box className='filter-search-btn'>
                    <AppButton
                      label='Apply'
                      bordercolor='black'
                      bgcolor='black'
                      size='large'
                      type='submit'
                      className='search-bth'
                      sx={{ color: '#fff' }}
                    />
                  </Box>
                  
                </Stack>
            </Grid>
          </Grid>
            </Box>


        </Box>
        </Box>
      </>
    )
  }

  const handleClick = () => {
    setfilterChecked(false)
    setIsOpendrilldownfilter(!isOpendrilldownfilter);
  };

  const getReportExport = async () => {
    const exportReportObject = exportdatareqest
    if(screenType == "hiringdrillLeft") exportReportObject["excelExport"] = true
    try{
     const res = screenType == "hiringdrill" ? await ReportkManagementService.exportSubmitionDashborad(exportReportObject) :
     screenType == "funnelDrill" ? await ReportkManagementService.exportFunnelDashboard(exportReportObject) : 
     screenType == "offmanagementDrill" ? await ReportkManagementService.exportOffMgtDashboard(exportReportObject) : 
     screenType == "jobOrderAgeningDrill" ? await ReportkManagementService.exportJobOrderAgeing(exportReportObject) : 
     screenType == "jobsWithCandidateDrill" ? await ReportkManagementService.exportJobsWithCandidateDrill(exportReportObject) : 
     screenType == "clinetCandidateActivity" ? await ReportkManagementService.exportClientActivityExport(exportReportObject) : 
     screenType == "timeToFillCandidateActivity" ? await ReportkManagementService.exportTimeToFillCandidateReportExport(exportReportObject) : 
     await ReportkManagementService.exportAppTrendsDrill(exportReportObject)
      if(res?.status === 201){
        const url = window.URL.createObjectURL(new Blob([res?.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${downloadName}.xlsx`);
        document.body.appendChild(link);
        link.click();
      }      
    }catch(error){
      console.log(error)
    }
  }

    return (
        <>


<Box sx={{marginBottom:'5px'}}>
            <div>
                <Paper className={`box-style ${filterType != "mainScreen" ? "subScreen": "mainScreen"}`} elevation={1} sx={{ backgroundColor: '#f3f3f7',marginTop:'5px' }}>
                    {!hideDashHeader && 
                    <Box className='filter-parent-box dashboardFilterHeader' sx={{ display:'flex', justifyContent:'space-between', backgroundColor: '#f3f3f7', alignContent:'center' ,alignItems:"center",marginBottom:'20px',paddingLeft:"15px",paddingRight:"15px" }}>
                        <Box className="dashboardFilterHeaderTitle">
                          <span dangerouslySetInnerHTML={{ __html: headerTitle}} />
                          <Box display='flex' justifyContent='space-between'>
                              {/* <ReportSearch value={keyWordSearch} onChange={onFilterSearchChange}/>
                  <Divider orientation='vertical' flexItem variant={'middle'} /> */}
                              <Box sx={{display:'flex',flexDirection:'row', justifyContent:'center', columnGap:'10px'}}>
                              {filterType != "mainScreen" && <Box>
                                <StyledButton onClick={handleClick} className={`drillDownBtn ${isOpendrilldownfilter ? "active" : ""}`} endIcon={isOpendrilldownfilter ? <KeyboardArrowUp /> : <KeyboardArrowDown />}>
                                  Drill Down From
                                </StyledButton>                              
                              </Box>}
                                {!hideForm &&  <AppButton
                                      variant='contained'
                                      bgcolor={filterChecked ? PRIMARY1 : "#fff"}
                                      // margin={5}
                                      minwidth={40}
                                      onClick={handleFilter}
                                      textColor={filterChecked ? TERTIARY12 : PRIMARY3}
                                      sx={{border:'1px solid #B3B5B7'}}
                                  >
                                      {' '}
                                      <FunnelIcon className='add-new-icon' />{' '}
                                      {filterChecked ? <ArrowUpIcon /> : <ArrowDownIcon/>}
                                  </AppButton>}

                                  {filterType != "mainScreen" &&  <AppButton
                                      className='hiringExport'
                                      variant='contained'
                                      bgcolor={PRIMARY3}
                                      minwidth={40}
                                      onClick={getReportExport}
                                    >
                                      <DownloadIcon />{' '}
                                  </AppButton>}
                                  
                              </Box>
                          </Box>
                        </Box>
                        {isOpendrilldownfilter && 
                          <Box className="drillDownObject">
                              <h4>Drill Down from</h4>
                              <ul>
                              {Object.keys(dashboradfilterList).filter((key) => dashboradfilterList[key].length > 0).map((item:any) => {
                                  return (
                                    <>
                                      <li>
                                        <h5>{item}</h5>
                                        <p>{dashboradfilterList[item]}</p>
                                      </li>
                                    </>
                                  )
                                })}
                                </ul>
                          </Box>
                        }
                    </Box>}

                    {filterChecked && (
                        <Box>
                        <FilterForms />
                      </Box>
                    )}
                    {hideDashHeader && (
                        <Box>
                        <FilterForms />
                      </Box>
                    )}
                </Paper>
            </div>
        </Box>
          
        </>
    )
}
export default DashboardFilter