import React, { useEffect, useState } from 'react';
import { ReactComponent as SortUpDown } from '../../../../assets/icons/sort-up-down.svg';
import { ReactComponent as SortUp } from '../../../../assets/icons/sort-up.svg';
import { ReactComponent as SortDown } from '../../../../assets/icons/sort-down.svg';
import { ReactComponent as SearchIcon } from '../../../../assets/icons/search.svg'
import { ReactComponent as DefaultClientLogo } from '../../../../assets/images/defaultUserIcon.svg'
import { ReactComponent as ToolfilterIcon } from '../../../../assets/icons/ToolfilterIcon.svg'
import { ReactComponent as CloseIcon } from '../../../../assets/icons/lightcloseIcon.svg'
import { Table, TableBody, TableCell, TableContainer, Box, TableHead, TableRow, Paper, Chip, FormControl, MenuItem, Select, Pagination, Tooltip, Popover, OutlinedInput, InputAdornment, Checkbox, Avatar, SelectChangeEvent, FormGroup, FormControlLabel, Dialog, DialogTitle, IconButton, DialogContent, TextField, DialogActions, Button, Typography, Input } from "@mui/material";
import { useAppSelector } from '../../../../store/hooks';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg';
import { IMaskInput } from 'react-imask';
import { styled } from '@mui/system';
import moment from 'moment';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
  }
  
  
  const ScrollableBox = styled(Box)({
    height: '300px',  // Set the desired height
    overflowY: 'scroll',
    '&::-webkit-scrollbar': {
      width: '0px',  // Hide scrollbar for WebKit browsers
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: 'transparent',
    },
    '&::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    scrollbarWidth: 'none',  // Hide scrollbar for Firefox
    msOverflowStyle: 'none',  // Hide scrollbar for IE 10+
  });
  
  const TextMaskCustom = React.forwardRef<HTMLInputElement, CustomProps>(
    function TextMaskCustom(props, ref) {
      const { onChange, ...other } = props;
      return (
        <IMaskInput
          {...other}
          mask="00/00/0000"
          definitions={{
            '#': /[1-9]/,
          }}
          inputRef={ref}
          onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
          overwrite
        />
      );
    },
  );

const HiringDemandTableSection = ({candidates,setHiringDemandTabDetail,tabType,candidateRedirectHandler,dateConvertion,screenType,setTableColumnFields,columnDisable,filterAppliedState}:any) => {
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate()
  const [HirningDemandParamsDetails, setHirningDemandParamsDetails] = useState<any>(location)
  const [exportdatareqest, setexportdatareqest] = useState<any>({})
  const [candidateData, setCandidateData] = useState<any | any[]>([]);
  const [sortColumn, setSortColumn] = useState<string>('');
  const [sortDirection, setSortDirection] = useState<string>('');
  const [columnsort, setcolumnsort] = useState<string>('');
  const [initalCount,setInitalCount] = useState<any>(0)
  const { userRole, userId } = useAppSelector((state) => state.UserDetail)
  const paginationDetail = {
    inputPageSize: candidates.data.inputPageSize,
    pageSize: candidates.data.pageSize,
    inputPageNo: candidates.data.inputPageNo,
    totalRecords: candidates.data.totalRecords,
  }
  const [paginationCountSize, setPaginationCountSize] = useState(paginationDetail);

  useEffect(() => {
    setPaginationCountSize({
      inputPageSize: candidates.data.inputPageSize,
      pageSize: candidates.data.pageSize,
      inputPageNo: candidates.data.inputPageNo,
      totalRecords: candidates.data.totalRecords,
    })
  },[candidates])

  const [textFieldValues, setTextFieldValues] = useState<{ [key: string]: any }>({});

  const handleTextFieldChange = (fieldKey: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setTextFieldValues(prevState => {
      const newState = { ...prevState, [fieldKey]: event.target.value };
      console.log("Updated textFieldValues: ", newState);
      return newState;
    });
    const paginationObject = {...paginationCountSize}
    setPaginationCountSize(prev => ({
      ...prev,
      inputPageNo: 1 // reset to first page
    }));
  };

  const [reportSubmissionAPIData, setReportSubmissionAPIData] = useState<any>({})
  const [applyBtnActive, setApplyBtnActive] = useState("")

  const handlePageSizeChange = (event: SelectChangeEvent<number>) => {
    const paginationObject = {...paginationCountSize}
    setPaginationCountSize(prev => ({
      ...prev,
      inputPageSize: event.target.value as number,
      inputPageNo: 1 // reset to first page
    }));
    paginationObject["inputPageSize"] = event.target.value
    paginationObject["inputPageNo"] = 1
    setHiringDemandTabDetail({
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      paginationCountSize: paginationObject,
      textFieldValues: textFieldValues
    })
    console.log("Pagination Size Change: ", paginationCountSize.inputPageNo, paginationCountSize.inputPageSize);
  };

  const paginationChange = (event: React.ChangeEvent<unknown>, page: number) => {
    const paginationObject = {...paginationCountSize}
    setPaginationCountSize(prev => ({ ...prev, inputPageNo: page }));
    paginationObject["inputPageNo"] = page
    setHiringDemandTabDetail({
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      paginationCountSize: paginationObject,
      textFieldValues: textFieldValues
    })
  };

  const bgColorArray = ['#DFF3E8', '#ECF2FF', '#FAEFE7', '#FFF0EF', '#EAF3F9', '#FFF8ED', '#FFE7F3', '#E1FEFF', '#F7F3FF', '#FCF5E9', '#FFEDF0', '#F3F3F3', '#FFF3ED', '#FFECEC', '#F2EDFF', '#FAEDEC'];
  const textColorArray = ['#2BAD63', '#002882', '#FF6E00', '#F34334', '#0E94F4', '#FCB12B', '#FF58AE', '#268A8E', '#9944B6', '#C18C25', '#94063A', '#363636', '#C54200', '#DD1216', '#4C19D7', '#5C0600'];

  const stringToColorbg = (str: string): string => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return bgColorArray[Math.abs(hash) % bgColorArray.length];
  };

  const stringToColortext = (str: string): string => {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return textColorArray[Math.abs(hash) % textColorArray.length];
  };

  function truncateString(str: any, num: number) {
    if (str && str.length > num) {
      return str.slice(0, num) + '...';
    } else {
      return str;
    }
  }


  function formatDate(dateString: any): any {
    const date = new Date(dateString);
    // Get day
    const day = date.getDate();
    // Get month (0-based index, so we add 1)
    const month = date.toLocaleString('default', { month: 'short' });
    // Get year
    const year = date.getFullYear();
    // Get hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    // Format as '14 Dec 2024, 15:00'
    return `${day} ${month} ${year}, ${hours}:${minutes}`;
  }



  const initialColumnFields = candidates.data.headerData.map((item:any,index:number) => {
    return { id: index+1, objectkey: item, label: item.replaceAll("_"," "), checkStatus: true, reqObjkey: item }
  })
  const [columnFields, setColumnFields] = useState(initialColumnFields);
  const [tempColumnFields, setTempColumnFields] = useState<any>(initialColumnFields);
  const [sortingTableHead, setSortingTableHead] = useState<any>({});
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [opentfiltercolumn, setopentfiltercolumn] = useState(false);
  const [displaycolumncontrol, setdisplaycolumncontrol] = useState(false);


  const sortTableHeadHandler = (column: any) => {
    let newDirection = '';
    if (columnsort === 'asc') {
      newDirection = 'desc';
    } else if (columnsort === 'desc') {
      newDirection = '';
    } else {
      newDirection = 'asc';
    }
    if(!(column.id in sortingTableHead)) newDirection = 'asc'
    setSortDirection(newDirection);
    setcolumnsort(newDirection);
    setSortColumn(column.reqObjkey);
    setSortingTableHead({
      [column.id]: newDirection === 'asc' ? 'Asc' : newDirection === 'desc' ? 'Desc' : '',
    });


  };

  const handleCheckboxChange = (columnId: any) => {
    setTempColumnFields((prevFields: any[]) =>
      prevFields.map((field) =>
        field.id === columnId ? { ...field, checkStatus: !field.checkStatus } : field
      )
    );
    setColumnFields((prevFields: any[]) =>
      prevFields.map((field) =>
        field.id === columnId ? { ...field, checkStatus: !field.checkStatus } : field
      )
    );
  };

  useEffect(() => {
    const tempColumnFieldsObj = JSON.parse(JSON.stringify(tempColumnFields))
    const excludedObjects = tempColumnFields.filter((obj1:any) => !obj1.checkStatus);
    setTableColumnFields(excludedObjects)
  },[tempColumnFields])


  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleReset = () => {
    setTempColumnFields((prevFields: any[]) =>
      prevFields.map(field => ({ ...field, checkStatus: true }))
    );
    setColumnFields((prevFields: any[]) =>
      prevFields.map(field => ({ ...field, checkStatus: true }))
    );
  };

  const handleApply = () => {
    setColumnFields(tempColumnFields);
    setdisplaycolumncontrol(true)
    opentfiltercolumnclick(); // Close the filter dialog
  };

  const filteredFields = tempColumnFields.filter((field: { label: string; }) =>
    field.label.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const opentfiltercolumnclick = () => {
    setopentfiltercolumn(!opentfiltercolumn)
  }

  useEffect(() => {
    if(initalCount != 0){
      setHiringDemandTabDetail({
      sortColumn: sortColumn,
      sortDirection: sortDirection,
      paginationCountSize: paginationCountSize,
      textFieldValues: textFieldValues
    })
  }
  setInitalCount(1)
  }, [sortColumn, sortDirection, textFieldValues]);

  useEffect(() => {
    if(filterAppliedState){
      setHiringDemandTabDetail({
        sortColumn: "",
        sortDirection: "",
        paginationCountSize: {
          inputPageSize: 10,
          pageSize: 1,
          inputPageNo: 1,
          totalRecords: 0,
        },
        textFieldValues: ""
      })
    }
  },[filterAppliedState])
  const renderEmptySearchBox = () => {
    ;
    return (
      <Box className="empty-data-card">
        <Box>
          <NoResultFound />
        </Box>
        <Box className="empty-search-message">
          No data found
        </Box>
      </Box>
    )
  }
  const hiringTableCustomData:any = {
    client: {
      header: {
        "open job orders": "#open job orders",
        "locations" : "#location"
      },
      body: [],
      dateField: ["date_of_offer"],
      dateFormate: "DD/MM/YYYY"
    },
    mandate: {
      header: {
        "open job orders": "#open job orders",
        "locations" : "#locations"
      },
      body: ["location","mandate"],
      dateField: ["mandate_start_date","mandate_end_date","mandate_created_date","jo_created_date","date_of_offer"],
      dateFormate: "DD/MM/YYYY"
    },
    candidate: {
      header: {
      },
      body: ["candidate","mandate","candidate_cv"],
      dateField: ["cv_submitted_date","profile_artemis_entry_date","profile_artemis_last_modified_date","last_modified_candidate_status_tracking_date"],
      dateFormate: "DD/MM/YYYY"
    },
    normalScreen: {
      header: {
      },
      body: ["candidate","mandate"],
      dateField: ["cv_submitted_date","profile_artemis_entry_date","profile_artemis_last_modified_date","date","last_login","last_modified_candidate_status_tracking_date"],
      dateFormate: "DD/MM/YYYY"
    }
  }
  const candidateLocationsCount = (locations:string, type:string) => {
    const locationArray = locations != null ? locations.split(",") : []
    if(locationArray.length > 0){
      if(type == "locations"){      
        const firstLoc:any = locationArray[0].split(" ")
        const lastWord = firstLoc[firstLoc.length - 1]
        return <p className='hiringLocationColumn'>{`${firstLoc[0]}${parseInt(lastWord) ? `(${lastWord})` :``}${locationArray.length > 1 ? ",":""}`}{locationArray.length > 1 ? <span>+{locationArray.length - 1}</span> : ""}</p>
      }else{
        return <p className='hiringLocationColumn'>{`${locationArray[0]}`}{locationArray.length > 1 ? <span>+{locationArray.length - 1}</span> : ""}</p>
      }
    }else{
      return "-"
    }
    
    
  }
    return (
        <>
            <Box className="hiringTableTopSect" sx={{ padding: '10px' }}>
            <Box style={{ paddingTop: "0px", display: 'flex', flexDirection: 'column' }}>
              {
                opentfiltercolumn && (<div className='hiringDemandColumn' style={{ display: 'flex', justifyContent: 'flex-end', paddingRight: '20px' }}>
                  <Paper elevation={3} sx={{ padding: '5px', maxWidth: 250, position: 'absolute', zIndex: '9', marginTop: '56px' }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingX: '10px', paddingY: '5px' }}>
                      <Typography variant="h6" sx={{ fontSize: '16px' }}>Column Filter</Typography>
                      <IconButton aria-label="close" onClick={opentfiltercolumnclick}>
                        <CloseIcon />
                      </IconButton>
                    </Box>
                    <Box sx={{ borderTop: '1px solid #E5E8EB', borderBottom: '1px solid #E5E8EB' }}>
                      <Box sx={{ mb: 2, mt: 1, paddingX: '10px' }}>
                        <TextField size="small"
                          fullWidth
                          placeholder="Search..."
                          value={searchTerm}
                          onChange={handleSearchChange}
                          InputProps={{
                            startAdornment: (
                              <IconButton>
                                <SearchIcon />
                              </IconButton>
                            ),
                            sx: { borderRadius: '8px' }
                          }}
                        />
                      </Box>
                      <FormGroup>
                        <ScrollableBox style={{height:"200px"}}>
                          <Box sx={{ display: "flex", flexDirection: 'column', paddingX: '10px' }}>
                            {filteredFields.map((column:any) => {
                              return (
                              <FormControlLabel sx={{ fontSize: '12px' }}
                                key={column.id}
                                control={
                                  <Checkbox
                                    disabled={(columnDisable != undefined && columnDisable.includes(column.objectkey)) ? true : false}
                                    checked={column.checkStatus}
                                    onChange={() => handleCheckboxChange(column.id)}
                                  />
                                }
                                label={column.label}
                              />
                            )})}
                          </Box>
                        </ScrollableBox>
                      </FormGroup>
                    </Box>
                    <Box sx={{ display: 'flex', justifyContent: 'end', mt: 2, columnGap: '10px' }}>
                      <Button onClick={handleReset} sx={{ bgcolor: '#E5E8EB', color: '#000', borderRadius: '6px' }}>
                        Reset
                      </Button>
                      <Button onClick={handleApply} sx={{ bgcolor: '#002882', color: '#fff', borderRadius: '6px' }}>
                        Apply
                      </Button>
                    </Box>
                  </Paper>
                </div>)
              }

            </Box>
            <Box className="reportTableContSect hiringTableSect" style={{ marginTop: '0px' }}>
              <Box style={{ display: 'flex', justifyContent: 'flex-end', position: "relative" }}>
                <Box onClick={opentfiltercolumnclick} className="columnToggleBtn"><ToolfilterIcon /></Box>
              </Box>
              <TableContainer className="submissionReportContainer hiringDemandTableSect" component={Paper} sx={{ border: '1px solid #E5E8EB !important' }}>
                <Table sx={{ minWidth: 'auto' }} aria-label="simple table">
                  <TableHead style={{
                    whiteSpace: 'nowrap',
                    cursor: 'pointer',
                    position: 'sticky',
                    top: 0,
                    zIndex: 3,
                  }}>

                    <TableRow>
                      {columnFields.filter((column:any) => column.checkStatus).map((column:any) => {
                        const tabColumnHeaderInfo = hiringTableCustomData[tabType.length > 0 ? tabType : "normalScreen"].header
                        const columnName = column.label in tabColumnHeaderInfo ? tabColumnHeaderInfo[column.label] : column.label
                        return(
                        <TableCell key={column.id} style={{ whiteSpace: 'nowrap', cursor: 'pointer' }}>
                          <div onClick={() => sortTableHeadHandler(column)}>
                            {columnName}
                            {
                              sortingTableHead && sortingTableHead[column.id] ?
                                (sortingTableHead[column.id] === "Asc" ? <SortUp /> : <SortDown />) : <SortUpDown />
                            }
                          </div>
                        </TableCell>
                        )
                      }                        
                      )}
                    </TableRow>

                    <TableRow>
                      {columnFields.length > 0 ? columnFields.filter((column:any) => column.checkStatus).map((item:any, index:any) => {
                        const key = `${index}`; // Unique key for each text field
                        console.log("column", item?.reqObjkey)
                        const dateField = ["cv_submitted_date", "profile_artemis_entry_date", "profile_artemis_last_modified_date", "date","mandate_created_date","mandate_start_date","mandate_end_date","last_login","jo_created_date","date_of_offer"]
                        return (
                          <TableCell key={key} className='dashboardListThead'>
                            {!dateField.includes(item?.reqObjkey) ? <div className="customehiringdemand">
                              <TextField
                                type="text"
                                // size="small"
                                style={{ opacity: item?.reqObjkey == "relevantExperience" ? 0.5 : 1 }}
                                disabled={item?.reqObjkey == "relevantExperience" ? true : false}
                                value={textFieldValues[item.reqObjkey] || ''}
                                onChange={handleTextFieldChange(item.reqObjkey)}
                                InputProps={{
                                  startAdornment: (
                                    <SearchIcon />
                                  )
                                }}
                              />
                            </div> :
                              <FormControl className={`reportTableSearchFieldMask`} variant="standard">
                                <Input
                                  value={textFieldValues[item.reqObjkey] || ''}
                                  onChange={handleTextFieldChange(item.reqObjkey)}
                                  name="textmask"
                                  id="formatted-text-mask-input"
                                  inputComponent={TextMaskCustom as any}
                                  startAdornment={
                                    <InputAdornment position="start">
                                      <SearchIcon />
                                    </InputAdornment>
                                  }
                                />
                              </FormControl>}
                          </TableCell>
                        );
                      }) : null}
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {candidates.data && candidates?.data?.data?.length > 0 ? candidates?.data?.data?.map((candidateData:any, index:any) => {
                      const avatar = candidates.requestData[index]["candidate_name"] != null ? candidates.requestData[index]["candidate_name"].substring(0, 2).toUpperCase() : ""
                      // const avatarBgColor = candidate.candidate_name != null ? stringToColorbg(`${index + candidate.candidate_name}`): "";
                      // const colorhash = candidate.candidate_name != null ? stringToColortext(`${index + candidate.candidate_name}`): "";
                      const avatarBgColor = bgColorArray[index % bgColorArray.length];
                      const colorhash = textColorArray[index % textColorArray.length];
                      const tabColumnHeaderInfo = hiringTableCustomData[tabType.length > 0 ? tabType : "normalScreen"].body
                      const tabColumnDateFields = hiringTableCustomData[tabType.length > 0 ? tabType : "normalScreen"].dateField                      
                      console.log("candidateItem1111",candidates.requestData)
                      return (
                        <TableRow key={index} style={{ minHeight: 'fit-content' }}>
                          {columnFields.map((candidateItem:any, candidateIndex:number) => {
                            console.log("candidateItem",candidateItem)
                            let candiateValue:any = ""
                            const columnKey = candidateItem.objectkey
                            const checkDateConvertion = () => {
                              const checkMoment:any = moment(candidateData[candidateItem.objectkey])
                              return "_f" in checkMoment ? moment(candidateData[candidateItem.objectkey]).format(hiringTableCustomData[tabType.length > 0 ? tabType : "normalScreen"].dateFormate) : candidateData[candidateItem.objectkey]
                            }
                            // if(candidateItem.objectkey == "lost_opportunity"){
                            //   const fullObjData = candidates?.requestData[index]
                            //   if(fullObjData.closed_other_sources == 1 || fullObjData.closed_on_hold_clients == 1 || fullObjData.closed_on_hold_xpheno == 1 || fullObjData.archive_mandates == 1){candiateValue = 1}else{candiateValue = 0}
                            // }else{
                              candiateValue = tabColumnDateFields.includes(candidateItem.objectkey) ? (candidateData[candidateItem.objectkey] != null) ? dateConvertion == undefined ? checkDateConvertion() : candidateData[candidateItem.objectkey] :"-" : (candidateItem.objectkey == "current_ctc" || candidateItem.objectkey == "expected_ctc") ? candidateData[candidateItem.objectkey] != null ? (candidateData[candidateItem.objectkey] + " L / Annum"): "-" : candidateData[candidateItem.objectkey]
                            // }
                            
                            return (
                              <>
                              {columnFields.find((column:any) => column.id == (candidateIndex + 1) && column.checkStatus) && (
                                <>
                                {tabColumnHeaderInfo.includes(columnKey) ?
                                  <>
                                  { columnKey == "mandate" &&
                                    <TableCell>
                                    <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", minWidth: "fit-content", columnGap: '10px' }}>
                                      <div style={{ minWidth: "fit-content" }}><DefaultClientLogo /></div>
                                      <div style={{ display: 'flex', minWidth: "fit-content", flexDirection: "column", fontFamily: 'sora', rowGap: '5px' }}>
                                        {(screenType == "candidateActivity" || screenType == "joAgeing") ?  <div style={{ fontWeight: "600" }} title={candidates.requestData[index]["mandate"]} className='candidateName hyperMandate' onClick={() => candidateRedirectHandler(candidates.requestData[index]["mandate_id"],"mandate")}>{candidates.requestData[index]["mandate"]}</div> : <div style={{ fontWeight: "600" }} title={candidates.requestData[index]["mandate"]} className='candidateName'>{candidates.requestData[index]["mandate"]}</div>}
                                        <div style={{fontSize:"12px","color": "gray"}}>{candidates.requestData[index]["mandate_code"]}</div>
                                      </div>
                                    </div>
                                  </TableCell>
                                  }
                                  { columnKey == "candidate" &&
                                    <TableCell>
                                      <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", minWidth: "fit-content", columnGap: '10px' }}>
                                        <div style={{ minWidth: "fit-content" }}>
                                          <div style={{ minWidth: "fit-content", backgroundColor: avatarBgColor, display: 'flex', alignContent: 'center', alignItems: 'center', justifyContent: 'center', height: "36px", width: '36px', fontWeight: 'bold', fontSize: '12px', color: `${colorhash}`, borderRadius: '50px' }}>
                                            {avatar}
                                          </div>
                                        </div>
                                        <div style={{ display: 'flex', minWidth: "fit-content", flexDirection: "column", fontFamily: 'sora', rowGap: '4px', paddingTop: '3px' }}>
                                          <div onClick={() => candidateRedirectHandler(candidates.requestData[index]["candidate_id"],"candidate")} className='candidateName candidateRedirect' title={candidates.requestData[index]["candidate_name"]} style={{ fontWeight: "600", color: '#000', fontSize: '12px' }}>{candidates.requestData[index]["candidate_name"]}</div><div style={{fontSize:"12px","color": "gray"}}>{candidates.requestData[index]["candidate_id"]}</div>
                                        </div>
                                      </div>
                                    </TableCell>
                                  }
                                  { columnKey == "candidate_cv" &&
                                    <TableCell style={{ whiteSpace: 'nowrap' }}><Tooltip title={candiateValue}>{candiateValue != null ? candiateValue.split("/").pop().split(".")[0] : "-"}</Tooltip></TableCell>
                                  }                                  
                                  </>
                                : <TableCell className={candidateItem.objectkey =="client"?"clientColumn":candidateItem.objectkey =="job_title" ? "jobTitleColumn" : ""} style={{ whiteSpace: 'nowrap' }}><Tooltip title={candiateValue}>{ columnKey == "locations" ? candidateLocationsCount(candiateValue,"locations") : (columnKey == "preffered_location" || columnKey == "preferred_location") ? candidateLocationsCount(candiateValue,"prefferLocation") : candiateValue ?? "-"}</Tooltip></TableCell>
                                }
                                
                                
                              </>
                              
                              )}
                              </>
                            )
                          })}
                        </TableRow>
                      );
                    }) : (
                      <TableRow>
                        <TableCell colSpan={columnFields.length} align="center" style={{height: "300px"}}>
                          {renderEmptySearchBox()}
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>

                </Table>
              </TableContainer>
            </Box>
            <Box className="hiringTablePaginationSect" sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <Box sx={{ display: 'flex' }}>
                <p style={{ color: "#7f8082", fontSize: "14px" }}>Rows per page:</p>
                <FormControl fullWidth>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    label="Age"
                    value={paginationCountSize.inputPageSize}
                    onChange={handlePageSizeChange}
                  >
                    <MenuItem value={5}>5</MenuItem>
                    <MenuItem value={10}>10</MenuItem>
                    <MenuItem value={15}>15</MenuItem>
                    <MenuItem value={20}>20</MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Pagination count={paginationCountSize.pageSize}
                page={paginationCountSize.inputPageNo}
                onChange={paginationChange} variant="outlined" shape="rounded" />
            </Box>
          </Box>
        </>
    );
};

export default HiringDemandTableSection;