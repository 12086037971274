// import { store } from '../store/store'
import axios, { Axios } from 'axios';
import CryptoJS from 'crypto-js';
import { useAppSelector } from '../store/hooks';
import { toast } from 'react-toastify';
// const Token = useAppSelector((state) => state.auth.token);
// const token = useToken.getState().token
export const baseUrl = process.env.REACT_APP_API_ENDPOINT;
const baseUrlScrapper = process.env.REACT_APP_SCRAPPER_ENDPOINT;
const parserUrl = process.env.REACT_APP_PARSER_ENDPOINT
const baseUrlReport = process.env.REACT_APP_REPORT_API_ENDPOINT;

const mockBaseUrl = "http://localhost:3004/api/"

let numberOfAjaxCAllPending = 0;
export function getUserDeatails() {
    let sessionUserDetail:any = sessionStorage.getItem("currentUserDetails");
    sessionUserDetail = sessionUserDetail != null ? JSON.parse(sessionUserDetail) : ""
    let userDetailData = JSON.parse(localStorage.getItem('persist:root') as any)
    userDetailData = JSON.parse(userDetailData["UserDetail"])
    const SECRET_KEY = 'DX4tIunMzQqDjBW3';
    let location = window.location.href.replace("http://","").replace("https://","").split("/")
    location = location.slice(1, location.length)
    const mandateModuleList = ["mandates","add-mandate","edit-mandate"]
    const moduleName = location[0].indexOf("?") > -1 ? location[0].split("?")[0] : location[0];    
    const mandateIdList:any = {
        "mandates": 1,
        "candidate-listing": 2,
        "work-management": 3,
        "my-tasks": 4,
        "scheduler": 5,
        "candidatePipeline": 6,
        "users": 7,
        "dashboard": 8
    }
    const headerJsonObj = {
        "roleId":userDetailData?.roleId,
        "roleName":userDetailData?.userRole,
        "buId":userDetailData?.businessUnitId,
        "buName":userDetailData?.businessUnitName,
        "branchId":userDetailData?.employmentLocation,
        "branchName":userDetailData?.employmentLocationName,
        "orgId": userDetailData?.orgId,
        "orgName": userDetailData?.orgName,
        "moduleId": mandateIdList[mandateModuleList.includes(location[0]) ? "mandates" : location[0]],
        "moduleName" : moduleName,
        "userId": userDetailData.userId,
        "currentUserId" : userDetailData.userId != sessionUserDetail?.keyCloakId ? sessionUserDetail?.keyCloakId : null
      }
      let encryptionHeader:any = CryptoJS.AES.encrypt(JSON.stringify(headerJsonObj), CryptoJS.enc.Utf8.parse(SECRET_KEY),{
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    }).toString();
    const decryptedHeader = CryptoJS.AES.decrypt(encryptionHeader, CryptoJS.enc.Utf8.parse(SECRET_KEY), {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
    }).toString(CryptoJS.enc.Utf8);

      if(headerJsonObj.roleId == null){
        console.log("headerJsonObj",headerJsonObj)
        console.log("header object",encryptionHeader)
        console.log("header object",decryptedHeader)
        encryptionHeader = {}
      }else{
        console.log("decryptedHeader",decryptedHeader)
      }
      
      
    
    return encryptionHeader
}


export function getAccessToken() {
    // const accessToken = store.getState().auth.token?.access_token
    const token = localStorage.getItem('access_token') as string
    const accessToken = token
    return accessToken;
}

function getRefreshToken() {
    const token = JSON.parse(localStorage.getItem('persistroot') as string)
    const refreshToken = token?.auth?.token?.refresh_token
    return refreshToken;
}

export const instance = axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json'
    },
});

export const reportInstance = axios.create({
    baseURL: baseUrlReport,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const xphenoInstance = axios.create({
    baseURL: "https://xpheno.com/api/",
    headers: {
        'Content-Type': 'application/json',
    },
});

export const candidateInstance = axios.create({
    baseURL: baseUrl,
    headers: {
        'Content-Type': 'application/json'
    },
});

export const mockInstance = axios.create({
    baseURL: mockBaseUrl,
    headers: {
        'Content-Type': 'application/json',
    },
});

export const scrapperInstance = axios.create({
    baseURL: baseUrlScrapper,
    headers: {
        'Content-Type': 'application/json'
    },
});
export const parserInstance = axios.create({
    baseURL: parserUrl,
    headers: {
        'Content-Type': 'application/json'
    },
});

export const parserInstanceQuery = axios.create({
    baseURL: parserUrl,
    headers: {
        'Content-Type': 'application/json'
    },
});

export const formParserinstance = axios.create({
    baseURL: parserUrl,
    headers: {
         'Content-Type': 'multipart/form-data'
    },
});

const addCommonInterceptor = (instance:Axios) => {
    instance.interceptors.request.use(
        (config) => {
            numberOfAjaxCAllPending++;
            localStorage.setItem("apiCallPending", numberOfAjaxCAllPending.toString())
            window.dispatchEvent( new Event('storage') )
            const token = getAccessToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
                config.headers.Xauth = getUserDeatails();                
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    )
}

const addCommonInterceptorPlain = (instance:Axios) => {
    instance.interceptors.request.use(
        (config) => {
            // numberOfAjaxCAllPending++;
            // localStorage.setItem("apiCallPending", numberOfAjaxCAllPending.toString())
            // window.dispatchEvent( new Event('storage') )
            const token = getAccessToken();
            if (token) {
                config.headers.Authorization = `Bearer ${token}`;
                config.headers.Xauth = getUserDeatails();   
            }
            return config;
        },
        (error) => {
            return Promise.reject(error);
        }
    )
}

const addResponseInterceptor = (instance:Axios) => {
    instance.interceptors.response.use(
        (config) => {
            numberOfAjaxCAllPending--;
            localStorage.setItem("apiCallPending", numberOfAjaxCAllPending.toString())
            window.dispatchEvent( new Event('storage') )
            return config;
        },
        (error) => {
            numberOfAjaxCAllPending--;
            localStorage.setItem("apiCallPending", numberOfAjaxCAllPending.toString())
            window.dispatchEvent( new Event('storage') )
            if (error.code === 'ERR_NETWORK' || error.code === 'ECONNABORTED' || !error.response) {
                toast.error('Service unreachable, please try again.') 
            }
            return Promise.reject(error);
        }
    )
}


// axios Instance for formdata
export const forminstance = axios.create({
    baseURL: baseUrl,
    headers: {
         'Content-Type': 'multipart/form-data'
    },
});

addCommonInterceptor(instance);
addCommonInterceptor(forminstance);
addCommonInterceptor(scrapperInstance);
addCommonInterceptor(parserInstance);
addCommonInterceptor(formParserinstance);
addCommonInterceptorPlain(candidateInstance)
addCommonInterceptor(xphenoInstance)
addCommonInterceptor(reportInstance)

addResponseInterceptor(instance);
addResponseInterceptor(forminstance);
addResponseInterceptor(scrapperInstance);
addResponseInterceptor(parserInstance);
addResponseInterceptor(formParserinstance);
addResponseInterceptor(xphenoInstance);
addResponseInterceptor(reportInstance)

// forminstance.interceptors.request.use(
//     (config) => {
//         const token = getAccessToken();
//         if (token) {
//             config.headers.Authorization = `Bearer ${token}`;
//         }
//         return config;
//     },
//     (error) => {
//         return Promise.reject(error);
//     }
// )




//   instance.interceptors.response.use(
//     (res) => {
//       return res;
//     },
//     async (err) => {
//       const originalConfig = err.config;

//       if (err.response) {
//         // Access Token was expired
//         if (err.response.status === 401 && !originalConfig._retry) {
//           originalConfig._retry = true;

//           try {
//             const rs = await refreshToken();
//             const { accessToken } = rs.data;
//             window.localStorage.setItem("accessToken", accessToken);
//             instance.defaults.headers.common["x-access-token"] = accessToken;

//             return instance(originalConfig);
//           } catch (_error) {
//             if (_error.response && _error.response.data) {
//               return Promise.reject(_error.response.data);
//             }

//             return Promise.reject(_error);
//           }
//         }

//         if (err.response.status === 403 && err.response.data) {
//           return Promise.reject(err.response.data);
//         }
//       }

//       return Promise.reject(err);
//     }
//   );

// JWT Token
// export const authHeader = () => {
//     const authtoken = useAppSelector((state) => state.auth.token)
//     if (authtoken) {
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json',
//                 Authorization: `Bearer ${authtoken}`,
//             },
//         };
//         return config
//     } else {
//         const config = {
//             headers: {
//                 'Content-Type': 'application/json',
//                 // Authorization: '',
//             },
//         };
//         return config
//     }
// }


// // AXIOS BASE URL
// export default axios.create({
//     baseURL: baseUrl,
//     headers: {
//         'Content-Type': 'application/json'
//     }
// });

// export default axios.create({
//     baseURL: baseUrl,
//     headers:authHeader()
// });