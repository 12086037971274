import { createSlice } from '@reduxjs/toolkit'

export interface FilterState {
  persistFilter: any;
  filterPersistance: any;
  apiTimeOutStatus: boolean,
  sideBaseOpenStatus: boolean,
  persistTabResponse: any,
  dashboardWidgetPersistance:any,
  mandateFilterPersistance:any
}

const initialFilterState: FilterState = {
  persistFilter: {},
  filterPersistance: {
    tab0:"",
    tab1:"",
    tab2:"",
    tab3:""
  },
  apiTimeOutStatus: false,
  sideBaseOpenStatus: false,
  persistTabResponse: {
    tab0:{},
    tab1:{},
    tab2:{},
    tab3:{}
  },
  dashboardWidgetPersistance:{
    candidateActivityWidget:{},
    candidateRecuriterWidget:{},
    candidateStatusWidget:{},
    funnelType: "",
    topJobWithCandidateActivity: {}
  },
  mandateFilterPersistance: {}
}

const persistFilterSlice = createSlice({
    name: 'persistFilter',
    initialState: initialFilterState,
    reducers: {
      setPersistFilter: (state,action) => {
        state.persistFilter = action.payload
      },
      resetPersistFilter: (state) => {
        state.persistFilter = {}
      },
      setFilterPersist: (state,action) => {
        state.filterPersistance = action.payload
      },
      resetFilterPersistAll: (state) => {
        return {
          ...state,
          ...initialFilterState,
        }
      },
      setCandidateApiGatewayStatus: (state,action) => {
        state.apiTimeOutStatus = action.payload
      },
      setSideBaseOpenStatus: (state,action) => {
        state.sideBaseOpenStatus = action.payload
      },
      setCandidateTabResponse: (state,action) => {
        state.persistTabResponse = action.payload
      },
      setDashboardWidgetPresistance: (state,action) => {
        state.dashboardWidgetPersistance = action.payload
      },
      setMandateFilterPersistance: (state,action) => {
        state.mandateFilterPersistance = action.payload
      }
    }
})

export const { 
  setPersistFilter,
  resetPersistFilter,
  setFilterPersist,
  resetFilterPersistAll,
  setCandidateApiGatewayStatus,
  setSideBaseOpenStatus,
  setCandidateTabResponse,
  setDashboardWidgetPresistance,
  setMandateFilterPersistance
 } = persistFilterSlice.actions
export default persistFilterSlice.reducer