import React, { useEffect, useState } from 'react'
import { AppBar as MuiAppBar, Box, SvgIcon, styled, Stack, IconButton } from '@mui/material'
import { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'
import { Search } from './Search'
import { UserAvatar } from './UserAvatar'
import NoProfilePhoto from "../../assets/images/no_profile_photo.png";
import { DefaultMenu, MobileMenu, MobileNavigationMenu } from './Menu'
import {
  DRAWER_WIDTH,
  SIDENAV_MIN_WIDTH,
  ICON_COLOR,
  RIGHTNAV_MIN_WIDTH,
  RIGHTNAV_MAX_WIDTH,
} from '../../utils/constants'
import Divider from '@mui/material/Divider'
import './Header.css'
import { ReactComponent as HomeIcon } from '../../../src/assets/icons/home.svg'
import { ReactComponent as TourIcon } from '../../../src/assets/icons/tourIcon.svg'
import { More, NavigationMenus } from '../Actions'
import jwtDecode from 'jwt-decode'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { logout } from '../../features/auth/AuthReducer'
import { useNavigate } from 'react-router-dom'
import { authLogoutUser, authUserDetails, toggleTour } from '../../features/userDetail/UserDetailReducer'
import {resetPersistFilter} from '../../features/filters/PersistFilter'
import {
  resetFilters,
  resetFilterPreloadData
} from '../../features/filters/FilterReducer'
import { resetNotificationAll } from '../../features/notification/notificationReducer'
import { AppButton } from '../AppComponents/AppButton'
import { getCopilotUserPilot, getCopilotUserToken, getSystemConfig } from '../../services/UserService'
import { setSysTemplate } from '../../features/systemConfig/SystemConfig'
import { getAccessToken } from '../../services/http-common'
import { toast } from 'react-toastify'
import moment from 'moment'
import { ReactComponent as InfoOutline } from '../../../src/assets/icons/infoOutline.svg'
interface HeaderProps {
  toggleNavigation: () => void
  navigationOpen: boolean
  rightNavigationOpen: boolean
  open?: boolean
  quickViewOpen?: boolean;
  callLogOpen?:boolean
}

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
  rightNavigationOpen?: boolean
  quickLinkWidth?: number;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open, rightNavigationOpen, quickLinkWidth=0 }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: ` ${DRAWER_WIDTH}px`,
    marginRight: ` ${RIGHTNAV_MIN_WIDTH + quickLinkWidth}px`,
    width: `calc(100% - ${DRAWER_WIDTH + RIGHTNAV_MIN_WIDTH + quickLinkWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!open && {
    marginLeft: ` ${SIDENAV_MIN_WIDTH}px`,
    marginRight: ` ${RIGHTNAV_MIN_WIDTH + quickLinkWidth}px`,
    width: `calc(100% - ${SIDENAV_MIN_WIDTH + RIGHTNAV_MIN_WIDTH + quickLinkWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
  ...(!rightNavigationOpen &&
    open && {
      marginLeft: ` ${DRAWER_WIDTH}px`,
      marginRight: ` ${RIGHTNAV_MIN_WIDTH +quickLinkWidth}px`,
      width: `calc(100% - ${DRAWER_WIDTH + RIGHTNAV_MIN_WIDTH + quickLinkWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  ...(rightNavigationOpen &&
    open && {
      marginLeft: ` ${DRAWER_WIDTH}px`,
      marginRight: ` ${RIGHTNAV_MAX_WIDTH + quickLinkWidth}px`,
      width: `calc(100% - ${DRAWER_WIDTH + RIGHTNAV_MAX_WIDTH + quickLinkWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
  ...(rightNavigationOpen &&
    !open && {
      marginLeft: ` ${RIGHTNAV_MIN_WIDTH}px`,
      marginRight: ` ${RIGHTNAV_MAX_WIDTH + quickLinkWidth}px`,
      width: `calc(100% - ${SIDENAV_MIN_WIDTH + RIGHTNAV_MAX_WIDTH + quickLinkWidth}px)`,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
    }),
}))

export const Header = ({ navigationOpen, rightNavigationOpen, quickViewOpen, callLogOpen }: HeaderProps) => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const token = useAppSelector((state) => state.auth.token)  
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState<null | HTMLElement>(null)
  const [mobileNavigationAnchorEl, setMobileNavigationAnchorEl] = useState<null | HTMLElement>(null)
  const toggleOpenState = useAppSelector((state) => state.drawertoggle.leftopen)
  const userDetails = useAppSelector((state) => state?.UserDetail)
  const [copilotPilotDetails, setCopilotPilotDetails] = useState<any>({})
  let sessionUserDetail:any = sessionStorage.getItem("currentUserDetails");
  sessionUserDetail = sessionUserDetail != null ? JSON.parse(sessionUserDetail) : ""
  const handleMobileMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setMobileMoreAnchorEl(event.currentTarget)

  const handleMobileNavigationMenuOpen = (event: React.MouseEvent<HTMLElement>) =>
    setMobileNavigationAnchorEl(event.currentTarget)

  const handleMobileMenuClose = () => setMobileMoreAnchorEl(null)
  const handleMobileNavigationMenuClose = () => setMobileNavigationAnchorEl(null)

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
    handleMobileMenuClose()
    handleMobileNavigationMenuClose()
  }

  // handleLogout
  const handleLogout = async () => {
    sessionStorage.removeItem("currentUserDetails")
    dispatch(resetPersistFilter())
    dispatch(resetFilters())
    dispatch(resetFilterPreloadData({}))
    dispatch(resetNotificationAll())
    const accesstoken: { [key: string]: any; id: string } = jwtDecode(token?.access_token)
    const id = accesstoken?.sub
    dispatch(logout(id)).then(() => {
      navigate('/login')
    })
    setTimeout(() =>{
      if(window.location.pathname.includes("login")){
        location.reload()
      }      
    },1000)
    dispatch(authLogoutUser())
  }
  const callTourJS = () => {
    const currentLocation = location.pathname.replace("/","")
    let viewedScreen = document.cookie.split(";").filter((item:any) => item.trim().indexOf("tourScreenViewed") > -1);
    viewedScreen = JSON.parse(viewedScreen[0].replace("tourScreenViewed=","")).filter((item:any) => item != currentLocation)
    document.cookie = `tourScreenViewed=${JSON.stringify(viewedScreen)}`
    dispatch(toggleTour())
  }
  const getAppConfig = async () => {
    const reqObj = {
      businessUnit: true,
      appConfiguration: true,
      dropdownRequest: []
    }
  
    try{
      const res = await getSystemConfig(reqObj)
      if(res.status === 200) {
        const obj = {
          businessUnitTemplate: res?.data?.result?.businessUnit?.buMlpTemplate,
          chatGptShow:res?.data?.result?.configuration?.length && 
          res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 29),
          systemConfigData: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 33),
          googleIntegrationConfig: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 43),
          welcomeConfigData: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 49),
          uploadFileConfigData: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 3),
          smartFlowIncomingData: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 54),
          confettiEffectTimerData: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 56),
          isWelcomePopupEnabled: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 58),
          welcomePopupDisplayTimer: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 59),
          welcomePopupContent: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 60),
          welcomePopupClose: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 61),
          welcomeScreenAnimationEnabled: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 62),
          JoStatusList: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 65),
          toursConfigList: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 70),
          buElementAccessRoles: res?.data?.result?.configuration?.length && res?.data?.result?.configuration?.filter((fil:any)=>fil?.id === 72)
        }
        dispatch(setSysTemplate(obj))
        setTimeout(() => {
          navigate('/')
          location.reload()
        },200)
        
      }
    }catch(error){
      console.log(error, "config api error")
    }
  }
  const getPayloadData = (treeViewDataObj:any) => {
    const userBuList:any = [];
    let childLocation:any = []
    let userDetailObj:any = {
      userPermissions: [],
      userRole: "",
      roleId: "",
      businessUnitCode: "",
      businessUnitId: "",
      businessUnitName: "",
      employmentLocation : "",
      employmentLocationName: ""
    }
    function collectSelectedKeys(nodes:any) {          
        for (const node of nodes) {
            if ("children" in node == false || (node.children && node.children.length == 0)) {
              userDetailObj["userPermissions"] = node?.permissions;
              userDetailObj["userRole"] = node?.title; 
              userDetailObj["roleId"] = node?.value;
              if(childLocation.length > 0){
                userDetailObj["employmentLocationName"] = childLocation[0];
                userDetailObj["employmentLocation"] = childLocation[1];
              }
              userBuList.push(userDetailObj);
              userDetailObj = {
                userPermissions: [],
                userRole: "",
                roleId: "",
                businessUnitCode: "",
                businessUnitId: "",
                businessUnitName: "",
                employmentLocation : "",
                employmentLocationName: ""
              }
            } else {                  
              if(node?.type == "LOCATION"){                    
                userDetailObj["employmentLocationName"] = node?.title;
                userDetailObj["employmentLocation"] = node?.value;
                node.children.length > 1 ? childLocation = [node?.title,node?.value] : childLocation = []
              }else if(node?.type == "BUSINESS_UNITS"){
                userDetailObj["businessUnitName"] = node?.title;
                userDetailObj["businessUnitId"] = node?.value; 
                userDetailObj["businessUnitCode"] = node?.business_unit_code?.bu_code;
              }
              collectSelectedKeys(node.children);
            }
        }
    }    
    collectSelectedKeys(treeViewDataObj);
    return userBuList;
};

const getCopilotUserPilotAPI = async () => {
  if(userDetails.userId == sessionUserDetail?.keyCloakId){
    try{
      const response:any = await getCopilotUserPilot(userDetails.userId)
      setCopilotPilotDetails(response?.data?.data)
    }
    catch(error:any){  
      toast.error(error.data.message)
      }
  }  
}

useEffect(() => {
  if(userDetails.userId)getCopilotUserPilotAPI()
},[userDetails]) 


   const changeCopilot = async (copilot:any) => {
      const copilotObj:any = {
        authToken : getAccessToken(),
        userId: copilot.pilot_id,
        currentUserId : userDetails.userId
      }
      try{
          const response:any = await getCopilotUserToken(copilotObj)
          if(response.data.message == "Co-pilot Session Is Full"){
            toast.error("Another co-pilot is currently active for this account")
          }else{
            const copilotData = response.data.data
            const userToken = copilotData.authDetails.access_token
            localStorage.setItem('access_token', userToken)
            if(localStorage.getItem("original_user_id")){
              localStorage.setItem('original_user_id', "")
            }else{
              localStorage.setItem('original_user_id', copilotObj.currentUserId)
            }
              
            const dlsRoleList = copilotData?.userDetails?.dlsRoleAccess != null ? getPayloadData(copilotData?.userDetails?.dlsRoleAccess?.otherRoleDls?.dlsData) : []
            setTimeout(() => {
              dispatch(authUserDetails({
                userId: copilotData.userDetails.keyCloakId,
                id: copilotData.userDetails.id,
                username: copilotData.userDetails.userName,
                firstName: copilotData.userDetails.firstName,
                smartCallEmail: copilotData.userDetails.sfloId,
                smartCallPassword: copilotData.userDetails.sfloPwd,
                userPermissions: copilotData.userDetails.permissions,
                userRole: copilotData.userDetails.role,
                roleId: copilotData.userDetails.roleId,
                businessUnitCode: copilotData.userDetails.businessUnitCode,
                businessUnitId: copilotData.userDetails.businessUnitId,
                businessUnitName: copilotData.userDetails.businessUnit,
                employmentLocation : copilotData.userDetails.employmentLocation,
                employmentLocationName: copilotData.userDetails.employmentLocationName,
                dlsRoleAccess: dlsRoleList,
                orgId: copilotData.userDetails.orgId,
                orgName: copilotData.userDetails.orgName,
                coPilotAccess: copilotData.userDetails.coPilotAccess != null ? copilotData.userDetails.coPilotAccess : []
              }))
              getAppConfig()
            },1000)          
            toast.success(`Co-pilot ${copilotData.userDetails.userName} successfully switched`)
          }          
        }
        catch(error:any){  
          toast.error(error.data.message)
          }
    }
    const roleBackOnwer = () => {    
      changeCopilot({"pilot_id":localStorage.getItem("original_user_id")})    
    }
    const getLetterFromName = (text:string) => {
      return text.split(" ") // Split by space
      .map((word:any) => word.charAt(0).toUpperCase()) // Get first letter & capitalize
      .slice(0, 2)
      .join("");
    }
  const colorCode = ["#E4563A", "#4869C7", "#4BCE91"]
  return (
    <Box sx={{ flexGrow: 1 }}>
      <AppBar
        className={`appbar ${toggleOpenState ? "headerExpand" : "headerShrink"}`}
        elevation={0}
        position='fixed'
        open={navigationOpen}
        rightNavigationOpen={rightNavigationOpen}
        quickLinkWidth={(quickViewOpen || callLogOpen) ? 360 : 0}
      >
        <Box justifyContent='space-between' sx={{ display: { xs: 'flex' }, alignItems: 'center' }} className='xpheno-navigationHeader'>
          <Box display='flex' sx={{ alignItems: 'center' }}>
            <Box sx={{ display: { xs: 'block', md: 'none', sm: 'none' } }} className='hamburger-icon-div' >
              <NavigationMenus onClick={handleMobileNavigationMenuOpen} />
            </Box>
            <div className='home-icon-div' onClick={() => navigate('/')}>
              <SvgIcon sx={{ color: `${ICON_COLOR}` }}>
                <HomeIcon className='home-icon' />
              </SvgIcon>
            </div>
            {/* <Divider orientation='vertical' flexItem className='divider' /> */}
            {/* <Search /> */}
          </Box>
          <Stack direction='row' className='appHeaderRight'>          
            <Box style={{display:"flex",alignItems:"center"}}>
            <AppButton
              variant='contained'
              bgcolor={"#000"}
              onClick={() => {callTourJS()}}
              textColor={"#fff"}
              fontSize='13px'
              fontWeight={400}
              label={"Start Tour"}
            >
              <TourIcon />              
            </AppButton>
            {userDetails.userId == sessionUserDetail?.keyCloakId && 
            <>
            {Object.keys(copilotPilotDetails).length > 0 && <Box className="coPilotAtciveMessage">
                <span><InfoOutline /></span>
                <p><span style={{fontWeight: "700"}}>{copilotPilotDetails?.copilot_name}</span> co-pilot is currenlty active for this account</p>
              </Box>}
            </>}
              <span className='tourMiddle'></span>
            </Box>
            {userDetails.userId == sessionUserDetail?.keyCloakId && 
            <>
            {userDetails?.coPilotAccess.length > 0 && <Box className="copilotSwitchList">
              <ul>
                {userDetails?.coPilotAccess && userDetails?.coPilotAccess.map((copilotItem:any, index:number) => {
                  return (
                    <>
                    {moment(moment(copilotItem?.access_till).format('YYYY-MM-DD')).isSameOrAfter(moment().format('YYYY-MM-DD')) && <li style={{backgroundColor: colorCode[index]}} key={index} onClick={() => changeCopilot(copilotItem)}>
                      {copilotItem?.pilotImg ? <img src={copilotItem?.pilotImg} /> : <span>{getLetterFromName(copilotItem.pilot_name)}</span>}
                      <Box className="profileContentSect profileHoverSect">
                        {/* <img src={copilotItem?.pilotImg ?? NoProfilePhoto} /> */}
                        <Box className="profileContentDetailSect">
                            <Box className="nameField">{copilotItem.pilot_name}</Box>
                            <Box className="userRole">{copilotItem.pilot_role_name}</Box>
                        </Box>
                        <span className='arrow-up'></span>
                      </Box>
                    </li>}
                    </>
                  )
                })}
              </ul>
              <span className='coPilotMiddle'></span>
            </Box>}
            </>}
            <Box display='flex'>
                <img style={{width:"30px",marginRight:"20px",borderRadius:"5px",cursor:"pointer"}} onClick={handleMenu} src={NoProfilePhoto} />
            </Box>
            <Box
              sx={{ display: { xs: 'block', md: 'none', sm: 'none' } }}
              className='mobile-right-menu'
            >
              <More onClick={handleMobileMenuOpen} open={Boolean(mobileMoreAnchorEl)} />
            </Box>
          </Stack>
        </Box>
      </AppBar>
      <MobileMenu
        isMenuOpen={Boolean(mobileMoreAnchorEl)}
        handleMenuOpen={handleMobileMenuOpen}
        handleMenuClose={handleMobileMenuClose}
        anchorEl={mobileMoreAnchorEl}
      />
      <MobileNavigationMenu
        isMenuOpen={Boolean(mobileNavigationAnchorEl)}
        handleMenuOpen={handleMobileNavigationMenuOpen}
        handleMenuClose={handleMobileNavigationMenuClose}
        anchorEl={mobileNavigationAnchorEl}
      />
      <DefaultMenu
        isMenuOpen={Boolean(anchorEl)}
        handleMenuClose={handleMenuClose}
        anchorEl={anchorEl}
        handleLogout={handleLogout}
      />
    </Box>
  )
}