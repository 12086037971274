import {
  Modal,
  Box,
  Typography,
  IconButton,
  TextField,
  InputAdornment,
  Autocomplete,
  Snackbar,
  Alert,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Tooltip,
  Chip,
} from '@mui/material'
import { AppButton } from '../AppComponents/AppButton'
import { ReactComponent as CloseIcon } from '../../assets/icons/modalcloseicon.svg'
import { ReactComponent as CalendarIcon } from '../../assets/icons/mandates-list-calendar.svg'
import { ReactComponent as ClockIcon } from '../../assets/icons/clock.svg'
import { ReactComponent as WebIcon } from '../../assets/icons/web.svg'
import { ReactComponent as TitleIcon } from '../../assets/icons/title .svg';
import { ReactComponent as AddUserIcon } from '../../assets/icons/add-user.svg'
import { ReactComponent as MandateList } from '../../assets/icons/mandatesList.svg'
import { ReactComponent as Location } from '../../assets/icons/locationEvent.svg'
import { ReactComponent as NotesPath } from '../../assets/icons/notes-path.svg'
import { ReactComponent as ClientLogo } from '../../assets/icons/Client-logo-icon.svg'
import { ReactComponent as GroupHistoryIcon } from '../../assets/icons/GroupHistory.svg'
import { ReactComponent as ProfileIcon } from '../../assets/icons/profile-icon.svg'
import { ReactComponent as DefaultClientLogo } from '../../assets/images/defaultUserIcon.svg'
import './SchedulerPopup.scss'
import ReactQuill from 'react-quill'
import {
  DatePicker,
  LocalizationProvider,
  MobileTimePicker,
} from '@mui/x-date-pickers'
import { useState, useEffect, useMemo, useRef } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { Controller, useForm } from 'react-hook-form'
import moment from 'moment-timezone'
import { MandateService, getMandateLocation } from '../../services/MandateService'
import _ from 'lodash'
import { createScheduleEvent, deleteScheduleEvent, getScheduleAttendees, updateScheduleEvent } from '../../services/SchedulerServive'
import { AppErrorHandlerModal } from '../AppComponents/AppErrorHandlerModal'
import { googleCalendarSync } from '@mobiscroll/calendar-integration'
import {emailRegex} from "../../utils/constants"
import { useAppSelector } from '../../store/hooks'

const dateTimeZoneHelper = (startTime:string, endTime:string, timeZoneAll:any) => {
  const dateFormat = "YYYY-MM-DD HH:mm:ss.SSSSSS Z"

  const startDate = new Date(startTime)
  const endDate = new Date(endTime)

  const startMoment = moment(startTime, dateFormat);
  const endMoment = moment(endTime, dateFormat);
  const duration = moment.duration(endMoment.diff(startMoment));
  const hours = duration.hours();
  const minutes = duration.minutes();

  const timeZoneNames = timeZoneAll.find((name:any) => {
    const parsedMoment = moment(startTime, dateFormat);
    const offset = moment.tz(name).utcOffset();
    return offset === parsedMoment.utcOffset();
  });

  return {
    startDate,
    duration:`${hours}h${minutes}m`,
    timeZoneNames,
    endDate
  }
}

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '750px',
  height: 'auto',
  borderRadius: '10px',
  bgcolor: 'background.paper',
  border: 'none',
  padding: "32px 10px",
}
interface SchedulerForm {
  title: string
  attendees: string
  date: any
  time: any
  mandate: any
  timeZone: any
  duration:string | null
  location: string | number |null
  mlocationId: string | number |null
  description: string
  selectedMandateId: number | null
  startTime: any
  endTime: any
}

const ReactQuillWrapper = ({ value, handleChange }: any) => {
  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
      ['link', 'image'],
      ['clean'],
    ],
  }
  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image',
  ]

  return (
    <div style={{ height: '100%' }}>
      <ReactQuill value={value} modules={modules} formats={formats} onChange={handleChange} />
    </div>
  )
}

export const MandateOptionTag = ({ option, isTagRemovable = false, onCloseTagClick, className="", ...rest }: any) => {
  return (
    <Box className={`mandate-tag-wrapper ${className}`} {...rest}>
      <div className='mandate-container'>
        <div className='m-detail'>
          <DefaultClientLogo />
          <div className='mtitle-wrap' >
            <Tooltip title={option?.title}>
              <Typography className='m-title'>{option?.title}</Typography>
            </Tooltip>
            <Typography className='m-id'>{option?.mandateId}</Typography>
          </div>
        </div>
        <CloseIcon className={`close-btn ${!isTagRemovable && 'hidden-btn'}`} onClick={() => {if(isTagRemovable)onCloseTagClick()}} />
      </div>
      <div className='client-container'>
        <div className='client-wrapper' >
          <GroupHistoryIcon />
          <Tooltip title={option?.clientName}>
            <div className='client-name-text' >{option?.clientName}</div>
          </Tooltip>
        </div>
        <div className='creator-wrapper' >
          <ProfileIcon />
          <Tooltip title={option?.createdByName}>
            <div className='creator-name-text' >{option?.createdByName}</div>
          </Tooltip>
        </div>
      </div>
    </Box>
  )
}


const SchedulerPopup = ({ calendarIds, showPopup, getEventList, editEventData, isEdit }: any) => {
  const [mandateOptions, setMandateOptions] = useState<any[]>([])
  const [mandatelocationLists, setMandateLocationLists] = useState<any[]>([])  
  const [searchtextonMandate, setSearchtextonMandate] = useState<string>('')  
  const [successPopup, setSuccessPopup] = useState<boolean>(false)
  const [SuccessMessage, setSuccessMessage] = useState<string>('')
  const [hasError, setHasError] = useState(false);
  const [hasErrorText, setHasErrorText] = useState("Server Error");
  const [timeError, setTimeError] = useState<boolean>(false)
  const [searchMandateList, setSearchMandateList] = useState<any>([])
  const [searchMandateListFilter, setSearchMandateListFilter] = useState<any>([])
  const [mandateValue, setMandateValue] = useState<any>()
  const autoC = useRef<any>(null);
  const assigneeField = useRef<any>(null);
  const [mandateSelectStatus, setMandateSelectStatus] = useState(false)
  const [assigneeList, setAssigneeList] = useState<any[]>([])
  const [assigneeDisable, setAssigneeDisable] = useState(isEdit ? false :true)
  const { userId, userRole } = useAppSelector((state) => state?.UserDetail)

  let dateTimeutil:any = {}
  const timeZoneAll:any[] = useMemo(()=>{
    return moment.tz.names().filter((name,index,self) => {
      const offset = moment.tz(name).utcOffset();
      return index === self.findIndex(x=> moment.tz(x).utcOffset() === offset);
      });
  }, []) 

  const schedulerAssigneeAPI = async (editType:any) => {
    const res:any = editType ? await getScheduleAttendees(JSON.parse(editEventData.mandate), editEventData.mlocationId) : await getScheduleAttendees(watch("mandate"),watch("mlocationId"));
    if(res?.status === 200){
      const options = _.map(res.data, (data: any) => {
        return {
          label: _.get(data, 'email'),
          value: _.get(data, 'id'),
          role: _.get(data, 'roleName') ? _.get(data, 'roleName') : ""
        }})
      setAssigneeList(options)
      setAssigneeDisable(false)
    }
  }

  if(isEdit){
    dateTimeutil = dateTimeZoneHelper(editEventData?.startTime, editEventData?.endTime, timeZoneAll)    
  }

  useEffect(() => {
    if(isEdit) schedulerAssigneeAPI(isEdit)
  },[editEventData])
  const defaultValues: SchedulerForm = {
    title: isEdit ? editEventData?.title : '',
    attendees: isEdit ? editEventData?.attendees.split(",") : [],
    date: isEdit ? dateTimeutil?.startDate : null,
    time: isEdit ? dateTimeutil?.startDate : null,
    timeZone: isEdit ?  dateTimeutil?.timeZoneNames : '',
    mandate: isEdit ? JSON.parse(editEventData?.mandate) : null,
    duration:isEdit ? dateTimeutil?.duration : null,
    location: isEdit ? editEventData?.location : null,
    mlocationId: isEdit ? JSON.parse(editEventData.mandate).value : null,
    description: isEdit ? editEventData?.description : '',
    selectedMandateId: null,
    startTime: isEdit ? dateTimeutil?.startDate : null,
    endTime: isEdit ? dateTimeutil?.endDate : null
  }
  const {
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { errors },
    getValues
  } = useForm<SchedulerForm>({ defaultValues })
  
  const onInputChange = _.debounce((event: any, value: string)=>{
    if (_.trim(value) !== '') {
      getData(value)
    } else {
      setSearchtextonMandate('')
      setMandateOptions([])
    }
  }, 500)
  

  // const onFormSubmit = async (data:any) =>{
   
  //   const DateNew = data.date;
  //   const Time = data.time;
  //   const TimeZone = data.timeZone;
  //   let duration;
  //   if(data.duration.toLowerCase().includes('h') && data.duration.toLowerCase().includes('m')){
  //     duration = data.duration.toLowerCase().split('m')[0].split('h')

  //   }else if(data.duration.toLowerCase().includes('h')){
  //     const hour = data.duration.toLowerCase().split('h')[0]
  //     duration=[hour,0]
  //   }else{
  //     const minute = data.duration.toLowerCase().split('m')[0]
  //     duration=[0,minute]
  //   }
    
  //   const date = moment(DateNew, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').format('ddd MMM DD YYYY');
  //   const time = moment(Time, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').format('HH:mm:ss');

  //   const currentTimeZone = moment.tz.guess(); // Get the current time zone

  //   const inputDateTimeString = `1970-01-01 ${time} ${currentTimeZone}`;
    
  //   const inputFormat = "YYYY-MM-DD HH:mm:ss z";
  //   const outputTimeZone = "UTC";
    
  //   const convertedMoment = moment.tz(inputDateTimeString, inputFormat, currentTimeZone).tz(outputTimeZone).format("HH:mm:ss");

  //   const outputFormat = "YYYY-MM-DD HH:mm:ss.SSSSSS Z"
  
  //   const startTime = moment.tz(date + ' ' +convertedMoment ,TimeZone);
  //   const endTime = moment.tz(date + ' ' + convertedMoment ,TimeZone)
  //   const hoursToAdd =duration[0]
  //   const minutesToAdd = duration[1]
  //   endTime.add(hoursToAdd, 'hours').add(minutesToAdd, 'minutes');
  //   delete data.date 
  //   delete data.timeZone
  //   delete data.time 
  //   delete data.duration 
  //   data.startTime = startTime.format(outputFormat);
  //   data.endTime = endTime.format(outputFormat);
  //   data.mandate = data?.mandate ? JSON.stringify(data?.mandate) : data?.mandate
    
  //   if(isEdit){
  //     data['id'] = editEventData?.id
  //   }
   
  //   const res = isEdit ? await updateScheduleEvent(data) : await createScheduleEvent(data)
  //   if(res?.status === 200 || res?.status === 201){
  //     setSuccessMessage(isEdit ? 'Interview has been updated successfully.' : 'Interview has been scheduled successfully.')
  //     setSuccessPopup(true)
  //   }else{
  //     setHasError(true)
  //   }
  // }

  const onFormSubmit = async (data:any) => {

    const eventDate = data?.date
    const TimeZone = data.timeZone;
    let regexStatus = false;
    const createEventAttendees = data?.attendees.map((item:string) => {
      if(!emailRegex.test(item)){        
        regexStatus = true
      }else{
        return {"email":item}
      }      
    })
    if(regexStatus){
      setHasErrorText("Please enter valid email Id");
      setHasError(true)
      return
    }
    const date = moment(eventDate, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').format('ddd MMM DD YYYY');
    const startTime = moment(data?.startTime, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').format('HH:mm:ss');
    const endTime = moment(data?.endTime, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ (z)').format('HH:mm:ss');
    const currentTimeZone = moment.tz.guess(); // Get the current time zone
    const inputDateStartTimeString = `1970-01-01 ${startTime} ${currentTimeZone}`;
    const inputDateEndTimeString = `1970-01-01 ${endTime} ${currentTimeZone}`;
    const inputFormat = "YYYY-MM-DD HH:mm:ss z";
    const outputTimeZone = "UTC";
    const convertedStartMoment = moment.tz(inputDateStartTimeString, inputFormat, currentTimeZone).tz(outputTimeZone).format("HH:mm:ss");
    const convertedEndMoment = moment.tz(inputDateEndTimeString, inputFormat, currentTimeZone).tz(outputTimeZone).format("HH:mm:ss");
    const outputFormat = "YYYY-MM-DD HH:mm:ss.SSSSSS Z"
    const Time1 = moment.tz(date + ' ' +convertedStartMoment ,TimeZone);
    const Time2 = moment.tz(date + ' ' +convertedEndMoment ,TimeZone);
    

    data.startTime = Time1.format(outputFormat);
    data.endTime = Time2.format(outputFormat);
    data.attendees = data.attendees.join()
    data.mandate = data?.mandate ? JSON.stringify(data?.mandate) : data?.mandate
    delete data.date 
    delete data.timeZone
    delete data.time 
    delete data.duration 

    if(isEdit){
      data['id'] = editEventData?.id
    }

    if (googleCalendarSync.isSignedIn()) {
      googleCalendarSync.addEvent(
        calendarIds[0], 
        { 
            start: new Date(`${data.startTime}`),
            end: new Date(`${data.endTime}`),
            title: data.title,
            googleEvent: {
              attendees: createEventAttendees,
              description: data.description,
              attendeesOmitted: true,
              anyoneCanAddSelf: true,
              sendNotifications: true
            }
        });
    }

    const res = isEdit ? await updateScheduleEvent(data) : await createScheduleEvent(data)
    if(res?.status === 200 || res?.status === 201){
      setSuccessMessage(isEdit ? 'Interview has been updated successfully.' : 'Interview has been scheduled successfully.')
      setSuccessPopup(true)
    }else{
      setHasErrorText("Server Error")
      setHasError(true)
    }

  }
  
  const getData = (searchTerm: string) => {
    setSearchtextonMandate(searchTerm)
    MandateService.getSearchMandates(searchTerm).then((res: any) => {      
      const dataItems = _.get(res, 'data.result.list', [])
      setSearchMandateList(dataItems)
      const filterData = dataItems ? dataItems?.filter((item: any) => item?.status !== 'draft') : []
      const options = _.map(filterData, (data: any) => {
        return {
          label: _.get(data, 'title') + ' - ' + _.get(data, 'mandateId'),
          value: _.get(data, 'id'),
          mandateBehaviour: _.get(data, 'mandateBehaviour')
        }
      })
      setMandateOptions(options)
    })
  }

  
  const manChange:any = watch("mandate")

  const getMandateLocationList = async (manId:any)=> {
    const apiQuery = `?mandateBehaviour=${manId?.mandateBehaviour}` 
    const response = await getMandateLocation(manId?.value, apiQuery)
    setMandateLocationLists([])
    
    if(response?.status === 200){
      setMandateLocationLists(response?.data?.result?.mandateLocations)
      !mandateSelectStatus && editEventData?.mlocationId ? setValue("mlocationId", editEventData?.mlocationId) : setValue("mlocationId", null)
    }
    setMandateSelectStatus(false)
  }

  const handleDeleteEvent = async () => {
    const res = await deleteScheduleEvent(editEventData?.id)
    if(res?.status === 200 || res?.status === 204){
      setSuccessMessage('Interview has been deleted successfully.')
      setSuccessPopup(true)
    }else{
      setHasErrorText("Server Error")
      setHasError(true)
    }
  }

  const handleSuccessPopupClose = () => {
    setSuccessPopup(false);
    setSuccessMessage('')
    showPopup(false)
    getEventList()
  }

  const handleAlertClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }
    setHasErrorText("Server Error")
    setHasError(false)
  }
  useEffect(()=>{
    if(manChange){
        setValue("mlocationId", null)
        getMandateLocationList(manChange)
    } else {
        setMandateLocationLists([])
    }
  }, [manChange])
  const clearAutoField = () => {
    const ele = autoC.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
    if (ele) ele.click();
  }
  const clearAssigneeField = () => {
    const ele = assigneeField.current.getElementsByClassName('MuiAutocomplete-clearIndicator')[0];
    if (ele) ele.click();
  }
  
  return (
    <Modal
      open={true}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
      className='scheduler-popup'
    >
      <Box
        component="form"
        data-testid="formHandle"
        id="mandateForm"
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit(onFormSubmit)}
      >
        <Box sx={style} className='popup-wrap'>
          <div className='header-wrapper' style={{paddingLeft: "32px",paddingRight:"22px"}}>
            <Typography variant='h6' sx={{ fontSize: 'inherit', color: '#000' }}>
              {isEdit ? "Edit" : "Schedule"} Candidate Interview
            </Typography>
            <IconButton
              aria-label='close'
              onClick={() => showPopup(false)}
              // sx={{
              //   position: 'absolute',
              //   right: 25,
              //   top: 32,
              // }}
            >
              <CloseIcon style={{cursor:"pointer"}} />
            </IconButton>
          </div>
          <div className='form-wrapper'>
          <div className='icon-wrapper-align d-flex'>
              <MandateList className='iconPath'/>
              <Controller
                name='mandate'
                control={control}
                rules={{
                  required: {
                      value: true,
                      message: "Mandate is required",
                  }
                }}
                render={({ field: { value, onChange } }) => (
                  <Autocomplete
                    ref={autoC}
                    defaultValue={null}
                    options={mandateOptions}
                    onInputChange={(e, val) => onInputChange(e, val)}
                    sx={{
                      marginBottom: { lg: '0rem' },
                    }}
                    className='scheduler-model-textbox'
                    disablePortal
                    value={value}
                    onChange={(event, value) => {
                      onChange(value)                      
                      setMandateSelectStatus(true)
                      if(value?.value) getMandateLocationList(value) 
                      setAssigneeList([])
                      setSearchMandateListFilter(searchMandateList.filter((item: any) => item?.title == value.label.split("-")[0].trim()))           
                      clearAssigneeField();
                      setAssigneeDisable(true)
                      setValue("mlocationId", null)
                    }}
                    noOptionsText={searchtextonMandate ? 'No options' : 'Type something...'}
                    renderOption={(props, option) => {
                      return (
                        <li {...props} key={option.id} >
                          <Box sx={{ width: '100%', display: 'flex', flexDirection: 'row', gap: "10px" }}>
                            <Box><DefaultClientLogo /></Box>
                            <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                              <Typography
                                sx={{
                                  whiteSpace: 'nowrap',
                                  overflow: 'hidden',
                                  textOverflow: 'ellipsis',
                                }}
                              >
                                {option.label?.split('-')[0]}
                              </Typography>
                              <Typography className='dropdownSubtext'>
                                {option.label?.split('-')[1]} - {option.label?.split('-')[2]}
                              </Typography>
                            </Box>
                          </Box>
                        </li>
                      )
                    }}
                    renderInput={(params: any) => (
                      <TextField
                        {...params}
                        label='Mandate'
                        sx={{ width: { xs: '100%', lg: '13.5rem' } }}
                        placeholder='Select Mandate'
                        required
                      />
                    )}
                  />
                )}
              />              
            </div>
            {searchMandateListFilter.length > 0 && 
            <Box className="schedulerCandidateMandateSel">
              <MandateOptionTag 
                option={{
                  title: searchMandateListFilter[0]?.title || "No mandate selected",
                  mandateId: searchMandateListFilter[0]?.mandateId || "-",
                  clientName: searchMandateListFilter[0]?.clientName || "-",
                  createdByName: searchMandateListFilter[0]?.createdByName || "-"
                }} 
                isTagRemovable={searchMandateListFilter[0] ? true : false}
                onCloseTagClick={() => {setSearchMandateListFilter([]);clearAutoField();setAssigneeList([]);clearAssigneeField();setAssigneeDisable(true)}}
              />
              </Box>
              }

            {errors?.mandate && (
              <span className="reminder_text-danger select-danger-text error-padding" role="alert">
                  {errors?.mandate?.message as string}
              </span>
            )}
            <div className='icon-wrapper-align'>
              <Location className='iconPath'/>
              <FormControl fullWidth required>
                <InputLabel>Location</InputLabel>
                <Controller
                  name='mlocationId'
                  control={control}
                  rules={{
                    required: {
                        value: true,
                        message: "Location is required",
                    }
                  }}
                  render={({ field: { onChange, value, onBlur } }) => {                    
                    return(
                    <Select
                      label='Location'
                      value={value ? value : ""}
                      onChange={(event, value) => {
                        onChange(event.target.value)      
                        schedulerAssigneeAPI(false)
                        setAssigneeDisable(false)
                      }}
                      className='test-dropdown input-rounded'
                      labelId='mandate-location-select-label'
                    >
                      {mandatelocationLists?.length > 0 &&
                        mandatelocationLists?.map((mll: any, index: number) => (
                          <MenuItem key={index} value={mll?.id} className='mandate-location-lists-opt'>
                            <div className='primary-text'>{`${mll?.location} (${mll?.jobOrderCount})`}</div>
                          </MenuItem>
                        ))}
                      {mandatelocationLists?.length === 0 && (
                        <MenuItem disabled={true}>No Location</MenuItem>
                      )}
                    </Select>
                  )}}
                />
              </FormControl>
            </div>
            {errors?.mlocationId && (
              <span className="reminder_text-danger select-danger-text error-padding" role="alert">
                  {errors?.mlocationId?.message as string}
              </span>
            )}
            <div className='icon-wrapper-align'>
              <TitleIcon className='iconPath'/>
              <Controller
                name='title'
                control={control}
                rules={{
                  required: {
                      value: true,
                      message: "Title is required",
                  }
                }}
                render={({ field,fieldState: { error }  }) => (
                  <>
                  <TextField
                    label='Title'
                    placeholder='Title'
                    {...field}
                    inputProps={{ style: { fontSize: '14px', width: '100%' } }}
                    className='scheduler-model-textbox'
                    required
                  ></TextField>
                  </>
                )}
              />
            </div>
            {errors?.title && (
              <span className="reminder_text-danger select-danger-text error-padding" role="alert">
                  {errors?.title?.message}
              </span>
            )}
            <div className='icon-wrapper-align'>
              <AddUserIcon className='iconPath'/>
              <Controller
                name='attendees'
                control={control}
                rules={{
                  required: {
                      value: true,
                      message: "Attendees is required",
                  }
                }}
                render={({field : {onChange, value, onBlur}}) => (
                  <Autocomplete
                    disabled={assigneeDisable}
                    ref={assigneeField}
                    fullWidth
                    multiple
                    freeSolo
                    disablePortal
                    defaultValue={isEdit ? editEventData.attendees.split(","): []}
                    options={assigneeList.map((option) => option.label)}
                    renderTags={(value: readonly string[], getTagProps) =>
                      value.map((option: string, index: number) => (
                        <Box key={index}>
                          <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                        </Box>
                      ))
                    }
                    onChange={(event, value) => {
                      onChange(value)                     
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Attendees"
                        placeholder="Type or select attendees"
                        className='scheduler-model-textbox'
                        required
                      />
                    )}
                  />
                )}
              />
            </div>
            {errors?.attendees && (
              <span className="reminder_text-danger select-danger-text error-padding" role="alert">
                  {errors?.attendees?.message}
              </span>
            )}
            <div className='icon-wrapper-align' style={{alignItems: "baseline"}}>
              <ClockIcon className='iconPath'/>
              <div className='timer-date-wrapper' >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap:"10px",
                      marginBottom: "20px"
                    }}
                  >
                    <Controller
                      name='date'
                      control={control}
                      rules={{
                        required: {
                            value: true,
                            message: "Date is required",
                        }
                      }}
                      render={({ field: { onChange, value } }) => (
                        <DatePicker
                          label='Date'
                          className='date-picker'
                          value={value}
                          inputFormat='DD/MM/YYYY'
                          onChange={(e: any) => {
                            onChange(e.$d)
                          }}
                          components={{
                            OpenPickerIcon: (props: any) => (
                              <CalendarIcon style={{ height: 20, width: 20 }} {...props} />
                            ),
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              name='receivedOn'
                              style={{ width: '50%' }}
                              {...params}
                              required
                            />
                          )}
                        />
                      )}
                    />
                      <Controller
                        name='timeZone'
                        control={control}
                        rules={{
                          required: {
                              value: true,
                              message: "Time Zone is required",
                          }
                        }}
                        render={({ field: { onChange, value } }) => (
                          <Autocomplete
                            id='free-solo-2-demo'
                            className='timezone-dropdown'
                            disableClearable
                            sx={{ width: '50%' }}
                            options={timeZoneAll}
                            value={value}
                            onChange={(event, value) => {
                              onChange(value)
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label='Timezone'                                
                                InputProps={{
                                  ...params.InputProps,
                                  type: 'search',
                                }}
                                required
                              />
                            )}
                            popupIcon={<WebIcon />}
                          />
                        )}
                      />
                  </Box>
                  <Box sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      gap:"10px",
                    }}>
                  <Controller
                      name='startTime'
                      control={control}
                      rules={{
                        required: {
                            value: true,
                            message: "Start time is required",
                        }
                      }}
                      render={({ field: { onChange, value } }) => (
                        <MobileTimePicker
                          value={value}
                          label='Start time'
                          className='time-picker'
                          onChange={(e: any) => {
                            const endTime = getValues("endTime")
                            if(moment(endTime).isSameOrBefore(moment(e.$d)) && !!endTime){
                              setTimeError(true)
                            }else{
                              setTimeError(false)
                            }
                            onChange(e.$d)
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              name='receivedOn'
                              style={{ width: '50%' }}
                              {...params}
                              required
                            />)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton>
                                  <ClockIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                    <Controller
                      name='endTime'
                      control={control}
                      rules={{
                        required: {
                            value: true,
                            message: "End time is required",
                        }
                      }}
                      render={({ field: { onChange, value } }) => (
                        <MobileTimePicker
                          value={value}
                          label='End time'
                          className='time-picker'
                          onChange={(e: any) => {
                            const startTime = getValues("startTime")
                            if(moment(e.$d).isSameOrBefore(moment(startTime))){
                              setTimeError(true)
                            }else{
                              setTimeError(false)
                            }
                            onChange(e.$d)
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              name='receivedOn'
                              style={{ width: '50%' }}
                              {...params}
                              required
                            />)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position='end'>
                                <IconButton>
                                  <ClockIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                  </Box>
                </LocalizationProvider>
                {["date","startTime","endTime"].find(x=>x in errors) && (
                  <span style={{marginLeft:"5px"}} className="reminder_text-danger select-danger-text" role="alert">
                      {errors?.["date"]?.message as string || 
                      errors?.["startTime"]?.message as string ||
                      errors?.["endTime"]?.message as string}
                  </span>
                )}
                {timeError && (
                  <span style={{marginLeft:"5px"}} className="reminder_text-danger select-danger-text" role="alert">
                      {"End time should be greater than startTime"}
                  </span>
                )}
              </div>
            </div>
            {/* {errors?.timeZone && (
              <span className="reminder_text-danger select-danger-text error-padding" role="alert">
                  {errors?.timeZone?.message as string}
              </span>
            )}          */}
            {/* <div className='icon-wrapper-align'>
              <ClockIcon className='iconPath'/>
              <Controller
                name='duration'
                control={control}
                rules={{
                  required: {
                      value: true,
                      message: "Duration is required",
                  },
                  pattern: {
                    value: /^\d+h\d+m$|^\d+h$|^\d+m$/,
                    message: "Enter duration (e.g. 2h30m, 5h0m)"
                  }
                }}
                render={({ field }) => (
                  <TextField
                    label='Duration'
                    placeholder='Enter duration (e.g. 2h30m, 5h0m)'
                    inputProps={{ style: { fontSize: '14px', width: '100%' } }}
                    {...field}
                    className='scheduler-model-textbox'
                    required
                  ></TextField>
                )}
              />
            </div>
            {errors?.duration && (
              <span className="reminder_text-danger select-danger-text error-padding" role="alert">
                  {errors?.duration?.message}
              </span>
            )} */}
            
            <div className='icon-wrapper-align'>
              <Location className='iconPath'/>
              <Controller
                name='location'
                control={control}
                render={({ field }) => (
                  <TextField
                    label='Interview Location'
                    placeholder='Interview Location'
                    inputProps={{ style: { fontSize: '14px', width: '100%' } }}
                    {...field}
                    className='scheduler-model-textbox'
                  ></TextField>
                )}
              />
            </div>
            <div className='icon-wrapper-align' style={{alignItems:"start"}}>
              <NotesPath className='iconPath no-fill'/>
              <Controller
                name='description'
                control={control}
                render={({ field: { value, onChange } }) => (
                  <div className='scheduler-reactquill'>
                    <ReactQuillWrapper value={value} handleChange={onChange} />
                  </div>
                )}
              />
            </div>
          </div>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'flex-end',
              marginTop: '20px',
              gap: '15px',
              paddingLeft: "32px",
              paddingRight:"22px"
            }}
          >
            <AppButton
              bgcolor='#E5E8EB'
              onClick={() => showPopup(false)}
              textColor='#000'
              fontSize='14px'
              fontWeight={400}
              label='Cancel'
              width='96px'
            ></AppButton>
            {isEdit && <AppButton
              bgcolor='#E51E1E'
              onClick={handleDeleteEvent}
              textColor='#fff'
              fontSize='14px'
              fontWeight={400}
              label='Delete'
              width='96px'
            ></AppButton>}
            <AppButton
              bgcolor='#002882'
              type='submit'
              textColor='#ffffff'
              fontSize='14px'
              fontWeight={400}
              label={isEdit ? "Update" : 'Schedule'}
              width='96px'
              disabled={timeError}
              style={{color:"#fff"}}
            ></AppButton>
          </Box>
        </Box>
        <AppErrorHandlerModal
          open={successPopup}
          content={SuccessMessage}
          handleClose={handleSuccessPopupClose}
          factor='Success'
          ButtonText='Done'
        ><></></AppErrorHandlerModal>
        <Box>
          <Snackbar
          open={hasError}
          autoHideDuration={5000}
          onClose={handleAlertClose}
          anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
          }}
          >
            <Alert onClose={handleAlertClose} severity='error' sx={{ width: '100%' }} variant='filled'>
              {hasErrorText}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Modal>
  )
}
export default SchedulerPopup
