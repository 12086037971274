import React, { useEffect, useState } from 'react';
import DashboardFilterIndex from '../../Common/CustomeDasboardFilter/Filters/DashboardFilterIndex';
import { Table, TableBody, TableCell, TableContainer, Box, TableHead, TableRow, Paper, Chip, FormControl, MenuItem, Select, Pagination, Tooltip, Popover, OutlinedInput, InputAdornment, Checkbox, Avatar, SelectChangeEvent, FormGroup, FormControlLabel, Dialog, DialogTitle, IconButton, DialogContent, TextField, DialogActions, Button, Typography, Input, ToggleButtonGroup, ToggleButton } from "@mui/material";
import { ReactComponent as JoAgeingFile } from '../../../assets/icons/joAgeingFile.svg';
import { ReactComponent as JoAgeingFileBlue } from '../../../assets/icons/joAgeingFileBlue.svg';
import { ReactComponent as UserProfileIcon } from '../../../assets/icons/userGray.svg';
import { ReactComponent as UserProfileIconBlue } from '../../../assets/icons/userBlue.svg';
import { ReactComponent as SortDown } from '../../../assets/icons/sort-down.svg';
import { ReactComponent as NoResultFound } from '../../../assets/icons/no-result-found.svg';
import _ from 'lodash';
import { ReactComponent as SearchIcon } from '../../../assets/icons/search.svg'
import "../../Dashboard/OverviewDashboard/HiringDemandCard/HiringDemandCardDetail.scss"
import "./JobOrderAgeing.scss"
import { IMaskInput } from 'react-imask';
import { ResponsiveBar } from '@nivo/bar'
import { ReactComponent as LoadMoreIcon } from '../../../assets/icons/load-more.svg'
import { PRIMARY1 } from '../../../utils/constants';
import { useAppSelector } from '../../../store/hooks';
import { jobsAgeingReportList } from '../../../services/DashboardService';
import { useLocation, useNavigate } from 'react-router-dom';
import JoOrderAgeingFilterIndex from './JoOrderAgeingFilter/JoOrderAgeingFilterIndex';
import { getMandateCandidateReport, getMandateJoAgeingReport } from '../../../services/MandateService';
import HiringDemandTableSection from '../../Dashboard/OverviewDashboard/HiringDemandCard/HiringDemandTableSection';

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}


const JobOrderAgeing = () => {
    const location = useLocation();
    const [HirningDemandParamsDetailsCopy, setHirningDemandParamsDetailsCopy] = useState<any>(location.state != null && "parentFilterObject" in location.state ? location.state.parentFilterObject: location)
    const [HirningDemandParamsDetails, setHirningDemandParamsDetails] = useState<any>(location)
    const [reportSubmissionAPIData, setReportSubmissionAPIData] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? HirningDemandParamsDetails?.state?.filterList != undefined ? HirningDemandParamsDetails?.state?.filterList : {} : {}) : {})
    const [DashboardFilterDatas, setDashboardFilterDatas] = useState<any>(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "dashboardfilterObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails?.state?.dashboardfilterObj : {} : {}) : {})
    const userId = useAppSelector((state: any) => state.UserDetail.userId);
    const [initialLoad, setInitialLoad] = useState(0)
    const navigate = useNavigate();
    const [userRoleObjectSelected, setUserRoleObjectSelected] = useState()
    const [initalCount, setInitalCount] = useState(0)
    const [userDependendObj, setUserDependendObj] = useState({})
    const [joAgeingTabValue, setJoAgeingTabValue] = useState(Object.keys(HirningDemandParamsDetails).length > 0 ? (HirningDemandParamsDetails.state != null ? "hiringTabObj" in HirningDemandParamsDetails.state ? HirningDemandParamsDetails.state.hiringTabObj.tabSelected : "0": "0") : "0")
    const [exportdatareqest, setexportdatareqest] = useState<any>({})
    const [joAgeingResponse, setJoAgeingResponse] = useState<any>({})
    const [joAgeingCandidateResponse, setJoAgeingCandidateResponse] = useState<any>({})
    const [joAgeingTabCount, setJoAgeingTabCount] = useState<any>({})
    const [filterAppliedState,setFilterAppliedState] = useState(false)
    useEffect(() => {
        navigate(location.pathname, { replace: true, state: {} })
    },[])
    const [hiringDemandTabDetail, setHiringDemandTabDetail] = useState<any>((HirningDemandParamsDetails.state != null && "hiringTabObj" in HirningDemandParamsDetails.state) ? HirningDemandParamsDetails.state.hiringTabObj : {
        sortColumn: "",
        sortDirection: "",
        paginationCountSize: {
          inputPageSize: 10,
          pageSize: 1,
          inputPageNo: 1,
          totalRecords: 0,
        },
        textFieldValues: ""
      })
    const [tableColumnFields, setTableColumnFields] = useState([])
    useEffect(() => {
            const ExportdatareqestData = JSON.parse(JSON.stringify(exportdatareqest))
            ExportdatareqestData["excludeColumns"] = tableColumnFields != undefined ? tableColumnFields.map((item:any) => {return item.reqObjkey}) : []
            setexportdatareqest(ExportdatareqestData)
          },[tableColumnFields])
    function toCamelCaseAndAppend(str: string, appendWord: string) {
        const camelCaseStr = str
            .toLowerCase()
            .split('_')
            .map((word, index) =>
                index === 0 ? word : word.charAt(0).toUpperCase() + word.slice(1)
            )
            .join('');

        return camelCaseStr + appendWord.charAt(0).toUpperCase() + appendWord.slice(1);
    }
    const jobAgeingListAPi = async (type: string) => {
        let hiringCountReqObj:any = {}
        let hiringExportReqObj: any = {}
        const reqObj: any = {
            "userId": [userId],
            "pageSize": hiringDemandTabDetail.paginationCountSize.inputPageSize,
            "pageNo": (initialLoad !=0 && type == "initial") ? 1 : hiringDemandTabDetail.paginationCountSize.inputPageNo
        };
        const HirningDemandParamsDetailsData = HirningDemandParamsDetails?.state?.filterList != undefined ? HirningDemandParamsDetails?.state?.filterList : {}
        const HirningDemandParamsUserRoleDetails = HirningDemandParamsDetails?.state?.userFilterObj != undefined ? HirningDemandParamsDetails?.state?.userFilterObj : []
        if (Object.keys(reportSubmissionAPIData).length > 0) { if ("coe" in reportSubmissionAPIData) { reqObj["coe"] = reportSubmissionAPIData?.coe } } else if ("coe" in HirningDemandParamsDetailsData) { reqObj["coe"] = HirningDemandParamsDetailsData?.coe }
        if (Object.keys(reportSubmissionAPIData).length > 0) { reqObj["mandateId"] = reportSubmissionAPIData?.jobTitle } else if ("jobTitle" in HirningDemandParamsDetailsData) { reqObj["mandateId"] = HirningDemandParamsDetailsData?.jobTitle }
        if (Object.keys(reportSubmissionAPIData).length > 0) { reqObj["clientId"] = reportSubmissionAPIData?.client } else if ("client" in HirningDemandParamsDetailsData) { reqObj["clientId"] = HirningDemandParamsDetailsData?.client }
        if (Object.keys(reportSubmissionAPIData).length > 0) { if (reportSubmissionAPIData?.joLocation.length > 0) reqObj["joLocation"] = reportSubmissionAPIData?.joLocation } else if ("joLocation" in HirningDemandParamsDetailsData) { if (HirningDemandParamsDetailsData.joLocation.length > 0) reqObj["joLocation"] = HirningDemandParamsDetailsData?.joLocation }
        if (Object.keys(reportSubmissionAPIData).length > 0) { reqObj["businessUnit"] = reportSubmissionAPIData?.businessUnit } else if ("businessUnit" in HirningDemandParamsDetailsData) { reqObj["businessUnit"] = HirningDemandParamsDetailsData?.businessUnit }
        if (Object.keys(reportSubmissionAPIData).length > 0) { if (reportSubmissionAPIData?.mandateType.length > 0) reqObj["mandateType"] = reportSubmissionAPIData?.mandateType } else if ("mandateType" in HirningDemandParamsDetailsData) { if (HirningDemandParamsDetailsData.mandateType.length > 0) reqObj["mandateType"] = HirningDemandParamsDetailsData?.mandateType }
        if (Object.keys(reportSubmissionAPIData).length > 0) { if (reportSubmissionAPIData?.jobMode.length > 0) reqObj["jobMode"] = reportSubmissionAPIData?.jobMode } else if ("jobMode" in HirningDemandParamsDetailsData) { if (HirningDemandParamsDetailsData.jobMode.length > 0) reqObj["jobMode"] = HirningDemandParamsDetailsData?.jobMode }
        if (Object.keys(reportSubmissionAPIData).length > 0) { if (reportSubmissionAPIData?.mandateSPOC.length > 0) reqObj["mandateSPOC"] = reportSubmissionAPIData?.mandateSPOC } else if ("mandateSPOC" in HirningDemandParamsDetailsData) { if (HirningDemandParamsDetailsData.mandateSPOC.length > 0) reqObj["mandateSPOC"] = HirningDemandParamsDetailsData?.mandateSPOC }
        if (Object.keys(reportSubmissionAPIData).length > 0) { if (reportSubmissionAPIData?.roles.length > 0) reqObj["roles"] = reportSubmissionAPIData?.roles } else if ("roles" in HirningDemandParamsDetailsData) { if (HirningDemandParamsDetailsData.roles.length > 0) reqObj["roles"] = HirningDemandParamsDetailsData?.roles }
        if (Object.keys(reportSubmissionAPIData).length > 0) { if (reportSubmissionAPIData?.primarySkills.length > 0) reqObj["primarySkills"] = reportSubmissionAPIData?.primarySkills } else if ("primarySkills" in HirningDemandParamsDetailsData) { if (HirningDemandParamsDetailsData.primarySkills.length > 0) reqObj["primarySkills"] = HirningDemandParamsDetailsData?.primarySkills }
        if (Object.keys(reportSubmissionAPIData).length > 0) { if ("customEndDate" in reportSubmissionAPIData) reqObj["customEndDate"] = reportSubmissionAPIData?.customEndDate.toString() } else if ("customEndDate" in HirningDemandParamsDetailsData) { reqObj["customEndDate"] = HirningDemandParamsDetailsData?.customEndDate.toString() }
        if (Object.keys(reportSubmissionAPIData).length > 0) { if ("customStartDate" in reportSubmissionAPIData) reqObj["customStartDate"] = reportSubmissionAPIData?.customStartDate.toString() } else if ("customStartDate" in HirningDemandParamsDetailsData) { reqObj["customStartDate"] = HirningDemandParamsDetailsData?.customStartDate.toString() }
        if (Object.keys(reportSubmissionAPIData).length > 0) { if ("userId" in reportSubmissionAPIData) reqObj["userId"] = reportSubmissionAPIData?.userId } else if (HirningDemandParamsUserRoleDetails.length > 0) { reqObj["userId"] = HirningDemandParamsUserRoleDetails.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue.length > 0 ? HirningDemandParamsUserRoleDetails.at(-1).user.selectedValue : [userId] : [userId] }
        if (Object.keys(reportSubmissionAPIData).length > 0) { if ("category" in reportSubmissionAPIData) reqObj["category"] = reportSubmissionAPIData?.category } else if ("category" in HirningDemandParamsDetailsData) { reqObj["category"] = HirningDemandParamsDetailsData?.category }
        if (Object.keys(reportSubmissionAPIData).length > 0) { if ("ageingDays" in reportSubmissionAPIData) reqObj["mandateAgeingDays"] = reportSubmissionAPIData?.ageingDays.map((item: any) => item.value) } else if ("ageingDays" in HirningDemandParamsDetailsData) { reqObj["mandateAgeingDays"] = HirningDemandParamsDetailsData?.ageingDays.map((item: any) => item.value) }
        hiringExportReqObj = JSON.parse(JSON.stringify(reqObj));
        hiringExportReqObj["excelExport"] = true
        hiringExportReqObj["excludeColumns"] = tableColumnFields != undefined ? tableColumnFields.map((item:any) => {return item.reqObjkey}) : []
        if (type != "initial") {
            Object.keys(hiringDemandTabDetail.textFieldValues).map((item: any) => {
                if (hiringDemandTabDetail.textFieldValues[item].length > 0) reqObj[toCamelCaseAndAppend(item, "search")] = hiringDemandTabDetail.textFieldValues[item]
            })            
            if (hiringDemandTabDetail.sortColumn.length > 0 && hiringDemandTabDetail.sortDirection.length > 0) { reqObj["sortColumn"] = hiringDemandTabDetail.sortColumn }
            if (hiringDemandTabDetail.sortDirection.length > 0) { reqObj["sortDirection"] = hiringDemandTabDetail.sortDirection.toUpperCase() }
        }

            if(type=="initial"){
                hiringCountReqObj = JSON.parse(JSON.stringify(reqObj));        
                hiringCountReqObj["count"] = true        
                try {
                  const res: any = await getMandateJoAgeingReport(hiringCountReqObj);
                  setJoAgeingTabCount({
                    "candidateCount": res.data?.mandateAndJoCandidateCount,
                    "mandateCount": res.data?.mandateAndJoAgeingCount,
                })
                } catch (error: any) {
                  console.log(error);
                }
              }
        if (hiringDemandTabDetail.sortColumn.length > 0 && hiringDemandTabDetail.sortDirection.length > 0) { reqObj["sortColumn"] = hiringDemandTabDetail.sortColumn }
        if (hiringDemandTabDetail.sortDirection.length > 0) { reqObj["sortDirection"] = hiringDemandTabDetail.sortDirection.toUpperCase() }
        try {
            const res: any = (joAgeingTabValue == "0") ? await getMandateJoAgeingReport(reqObj) : await getMandateCandidateReport(reqObj)
            const fullRequestObj = res.data.data;
            const requestObj = res?.data
            const headerBasedObj = res.data.data.map((item: any) => {
                const loopList = Object.keys(item)
                    .filter(key => res.data.headerData.includes(key))
                    .reduce((obj: any, key: any) => {
                        obj[key] = item[key]; // Add remaining keys to the new object
                        return obj;
                    }, {});
                return loopList
            })
            requestObj.data = headerBasedObj
            if(joAgeingTabValue == "0"){
                setJoAgeingResponse({
                    data: requestObj,
                    requestData: fullRequestObj
                })
            }else{
                setJoAgeingCandidateResponse({
                    data: requestObj,
                    requestData: fullRequestObj
                })
            }            
            setexportdatareqest(hiringExportReqObj)
        } catch (error: any) {
            console.log(error);
        }
    }

    useEffect(() => {
        setInitialLoad(1)
        jobAgeingListAPi("initial");
        setFilterAppliedState(true)
    }, [HirningDemandParamsDetails,reportSubmissionAPIData]);

    useEffect(() => {        
        if (initialLoad != 0) {
            setHiringDemandTabDetail({
                sortColumn: "",
                sortDirection: "",
                paginationCountSize: {
                    inputPageSize: 10,
                    pageSize: 1,
                    inputPageNo: 1,
                    totalRecords: 0,
                },
                textFieldValues: "",
                timeStamp: new Date().getTime()
            });
        }
        setFilterAppliedState(false)
    }, [joAgeingTabValue]);
    
    useEffect(() => {        
        if (initialLoad != 0) {
            jobAgeingListAPi("changes");
            setFilterAppliedState(false)
        }
    }, [hiringDemandTabDetail]);

    const renderEmptySearchBox = () => {
        ;
        return (
            <Box className="empty-data-card" style={{width:"80%",left:"15%",top:"20%"}}>
                <Box>
                    <NoResultFound />
                </Box>
                <Box className="empty-search-message">
                    No data found
                </Box>
            </Box>
        )
    }

    const candidateRedirectNavigator = (candidateId: any, type: string) => {
        const tabFieldsDetailsObj = JSON.parse(JSON.stringify(hiringDemandTabDetail))
        tabFieldsDetailsObj["tabSelected"] = joAgeingTabValue
        if (type == "candidate") {
            navigate(`/candidate/${candidateId}`, { state: {parentFilterObject: HirningDemandParamsDetailsCopy, dashboardfilterObj: DashboardFilterDatas, filterList:reportSubmissionAPIData, userFilterObj: userRoleObjectSelected, clientDependedObj: userDependendObj, hiringTabObj: tabFieldsDetailsObj, source: "joAgeingReport" } });
        } else if (type == "mandate") {
            navigate(`/mandates?id=${candidateId}`, { state: {parentFilterObject: HirningDemandParamsDetailsCopy, dashboardfilterObj: DashboardFilterDatas, filterList: reportSubmissionAPIData, userFilterObj: userRoleObjectSelected, clientDependedObj: userDependendObj, hiringTabObj: tabFieldsDetailsObj, source: "joAgeingReport" } });
        }
    }
    return (
        <div className={`joAgeingBlkSect ${ Object.keys(joAgeingTabValue == "0" ? joAgeingResponse : joAgeingCandidateResponse).length > 0 && Object.keys(joAgeingTabValue == "0" ? joAgeingResponse?.data?.data : joAgeingCandidateResponse?.data?.data).length == 0 ? "emptyJoAgeing" : ""}`}>
            <h3>JO Ageing Report</h3>
            <Box className="joAgeningToggleWidget">
                <ToggleButtonGroup
                    color="primary"
                    value={joAgeingTabValue}
                    exclusive
                    onChange={(e: any) => {
                        setJoAgeingTabValue(e.target.value)
                    }}
                    aria-label="Platform"
                >
                    <ToggleButton value="0">{joAgeingTabValue == "0" ? <JoAgeingFileBlue /> : <JoAgeingFile />}{`Mandate & JO Ageing Details (${joAgeingTabCount?.mandateCount ?? "0"})`}</ToggleButton>
                    <ToggleButton value="1">{joAgeingTabValue == "1" ? <UserProfileIconBlue /> : <UserProfileIcon />}{`Mandate & Candidate Details (${joAgeingTabCount?.candidateCount ?? "0"})`}</ToggleButton>
                </ToggleButtonGroup>
            </Box>
            <JoOrderAgeingFilterIndex HirningDemandParamsDetails={HirningDemandParamsDetails} setHirningDemandParamsDetails={setHirningDemandParamsDetails} setReportSubmissionAPIData={setReportSubmissionAPIData} DashboardFilterDatas={DashboardFilterDatas} setDashboardFilterDatas={setDashboardFilterDatas} reportSubmissionAPIData={reportSubmissionAPIData} hideFieldsList={["stage", "status", "industryData", "COE", "Business Unit", "Role"]} headerTitle={""} setUserRoleObjectSelected={setUserRoleObjectSelected} setUserDependendObj={setUserDependendObj} filterType={"subScreen"} screenType={joAgeingTabValue == "0" ? "mandateAgeing" : "mandateCandidate"} exportdatareqest={exportdatareqest} downloadName={joAgeingTabValue == "0" ? "Mandate Ageing" : "Mandate Candidate"} datePickerType={"date"}/>
            {joAgeingTabValue == "0" && <>{Object.keys(joAgeingResponse).length > 0 ? <HiringDemandTableSection candidates={joAgeingResponse} setHiringDemandTabDetail={setHiringDemandTabDetail} screenType={"joAgeing"} tabType={"mandate"} setTableColumnFields={setTableColumnFields} candidateRedirectHandler={(candidateId: any, type: string) => candidateRedirectNavigator(candidateId, type)} columnDisable={["client","mandate"]} filterAppliedState={filterAppliedState}/> : <div className='tabEmptyCase'>{renderEmptySearchBox()}</div>}</>}
            {joAgeingTabValue == "1" && <>{Object.keys(joAgeingCandidateResponse).length > 0 ? <HiringDemandTableSection candidates={joAgeingCandidateResponse} setHiringDemandTabDetail={setHiringDemandTabDetail} screenType={"joAgeing"} tabType={"candidate"} setTableColumnFields={setTableColumnFields} candidateRedirectHandler={(candidateId: any, type: string) => candidateRedirectNavigator(candidateId, type)} columnDisable={["client","mandate","candidate"]} filterAppliedState={filterAppliedState}/> : <div className='tabEmptyCase'>{renderEmptySearchBox()}</div>}</>}
        </div>
    );
};

export default JobOrderAgeing;