import React, { useEffect, useState } from 'react';
import { BarDatum, ResponsiveBar } from '@nivo/bar';
import { Theme, useTheme } from '@mui/material/styles';
import { Select, MenuItem, FormControl, InputLabel, Paper, Box, Typography, useMediaQuery, FormLabel, Checkbox, FormGroup, FormControlLabel, Autocomplete, TextField, OutlinedInput, Tooltip, Popper } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Controller, useForm } from 'react-hook-form';
import NumberInputFieldIncDec from '../../../Common/CustomNumberInputField/NumberInputFieldIncDec';
import ResponsiveBarChart from '../../../Common/CustomBarChat/ResponsiveBarChart';
import { ReportkManagementService } from '../../../../services/ReportListService';
import LaunchIcon from '@mui/icons-material/Launch';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { useNavigate } from 'react-router-dom';
import "../../OverviewDashboard/ClientCandidateactivity/ClientCandidateactivity.scss"
import { ReactComponent as DashboardLaunch } from '../../../../assets/icons/dashboardLaunch.svg'
import { ReactComponent as NoResultFound } from '../../../../assets/icons/no-result-found.svg';
import _ from 'lodash';
import "./TimeToFill.scss"
import { getTimeToFillCandidateList } from '../../../../services/CandidateService';

const TimeToFill: React.FC<any> = ({reportSubmissionAPIData,timeToFillActivityObj,userRoleObjectSelected,DashboardFilterDatas,reportSubmitdatalabels,userDependendObj}:any) => {
    const dispatch = useAppDispatch()    
    const mandateTypeReduxValue: any = useAppSelector((state) => state.profilePersistor.mandateType)
    const {userRole,userId} = useAppSelector((state) => state.UserDetail)
    const navigate = useNavigate();
    const [timeToFillCandidateToogle, setTimeToFillCandidateToogle] = useState(Object.keys(timeToFillActivityObj).length > 0 ? timeToFillActivityObj.candidateType : "offer");
    const [timeToFillExperience, setTimeToFillExperience] = useState([
        {label: "0-3 Years",value: "0-3"},
        {label: "3-6 Years",value: "3-6"},
        {label: "6-10 Years",value: "6-10"},
        {label: "10-15 Years",value: "10-15"},
        {label: "15+ Years",value: "15"},
    ])
    const [getMousePosition, setGetMousePosition] = useState<any>({})
    const [toolTipData, setToolTipData] = useState<any>({})
    const [timeToFillExperienceSelected, setTimeToFillExperienceSelected] = useState(Object.keys(timeToFillActivityObj).length > 0 ? timeToFillActivityObj.experience : [])
    const [timeToFillData, setTimeToFillData] = useState([])
    const getTimeToFillCandidateActivityListAPI = async () => {
            const reportSubmissionAPIDataJson:any = {}
            if(reportSubmissionAPIData !=  null){
                if(Object.keys(reportSubmissionAPIData).length > 0){
                    Object.keys(reportSubmissionAPIData).map((item:any) => {
                        if(reportSubmissionAPIData[item].length > 0){
                        reportSubmissionAPIDataJson[item] = reportSubmissionAPIData[item]
                        }
                    })
                }  
            }
            const requestPayload = {
                ...reportSubmissionAPIDataJson,
                "resultType": timeToFillCandidateToogle
            }  
            if(timeToFillExperienceSelected.length > 0){
                const experienceData = timeToFillExperienceSelected.map((item: any) => item.value);
                requestPayload["experience"] = experienceData
            } 
                requestPayload["userId"] = "userId" in reportSubmissionAPIDataJson ? reportSubmissionAPIDataJson.userId : [userId]
                if("client" in reportSubmissionAPIDataJson) {
                    requestPayload["clientId"] = reportSubmissionAPIDataJson.client 
                    delete requestPayload.client
                }      
            try{
                const response = await getTimeToFillCandidateList(requestPayload);
                if(response?.data.length > 0 ){
                    const timeToFillCandActiviData = response?.data.map((item:any) => {
                        const clientData:any = {
                            "businessUnit": item.bu_code,
                            "bu_id": item.bu_id, 
                            "Generic": "Generic" in item.data ? item.data.Generic != null ? item.data.Generic : null : null,
                            "Niche": "Niche" in item.data ? item.data.Niche != null ? item.data.Niche : null : null,
                            "Super Niche": "Super Niche" in item.data ? item.data["Super Niche"] != null ? item.data["Super Niche"] : null : null,
                            "Search": "Search" in item.data ? item.data.Search != null ? item.data.Search : null : null
                          }
                        return clientData
                    })
                    console.log("timeToFillCandActiviData",timeToFillCandActiviData)
                    setTimeToFillData(timeToFillCandActiviData)
                }else{
                    setTimeToFillData([])
                }
                
            }
                catch(error:any){
                console.log(error)        
                }
            }
    useEffect(() => {
        getTimeToFillCandidateActivityListAPI()
    },[timeToFillCandidateToogle,reportSubmissionAPIData,timeToFillExperienceSelected])

    const handleSwitchChange = (type:string) => {
        console.log("seteffecienychange",type)
        setTimeToFillCandidateToogle(type);
      };
    const timeToFillCategory = [
        {
            label: "Generic",
            color: "#988BE6"
        },
        {
            label: "Niche",
            color: "#F3AB5C"
        },
        {
            label: "Super Niche",
            color: "#E971C6"
        },
        {
            label: "Search",
            color: "#61D2CB"
        },

    ];
    const timeToFillHandler = (part: any) => {
        const userRoleObjectSelectedData = userRoleObjectSelected;
        if(userRoleObjectSelected.length > 0 && userRoleObjectSelected[userRoleObjectSelected.length - 1].user.selectedValue.length == 0) userRoleObjectSelectedData.pop()
        let timeToFillFilterObj =  JSON.parse(JSON.stringify(reportSubmissionAPIData))
        let timeToFillDatasObj = JSON.parse(JSON.stringify(DashboardFilterDatas))
        if(Object.keys(timeToFillFilterObj).length > 0 && timeToFillFilterObj?.mandateType.length == 0){
            timeToFillFilterObj["mandateType"] = [part.id]
        }else if(Object.keys(timeToFillFilterObj).length == 0){
            timeToFillFilterObj = {
                "primarySkills": [],
                "mandateType": [part.id],
                "jobMode": [],
                "mandateSPOC": [],
                "joLocation": [],
                "userId": [userId],
                "dateRange": "",
                "roles": []
            }
        }
        let mandateTypeClickStatus = false
        if(Object.keys(timeToFillDatasObj).length > 0 && timeToFillDatasObj?.mandatetype.length == 0){
            timeToFillDatasObj["mandatetype"] = mandateTypeReduxValue.filter((item:any) => item.label==part.id);
            mandateTypeClickStatus = true
        }else if(Object.keys(timeToFillDatasObj).length == 0){
            timeToFillDatasObj = {
                "submissionEndDate": null,
                "submissionDate": null,
                "coe": [],
                "businessunit": [],
                "mandatespoc": [],
                "client": [],
                "jobtitle": [],
                "jolocation": [],
                "primaryskill": [],
                "mandatetype": mandateTypeReduxValue.filter((item:any) => item.label==part.id),
                "industryData": null,
                "role": [],
                "jobmode": []
            }
            mandateTypeClickStatus = true
        }
        navigate(`/timeToFillDetails`, { state: { dashboardfilterObj:timeToFillDatasObj, filterList: timeToFillFilterObj ,filterListLabels: reportSubmitdatalabels,userFilterObj: userRoleObjectSelectedData, clientDependedObj: userDependendObj, timeToFillType: timeToFillCandidateToogle, timeToFillBuId: part.data.bu_id,timeToFillActivityObj: {"candidateType":timeToFillCandidateToogle,"experience":timeToFillExperienceSelected,"timeToFillMandateType": part.id,"timeToFillBuCode": part.indexValue,timeToFillBuId: part.data.bu_id},TimeToFillBarClick:mandateTypeClickStatus} });
    }
    const redirectTimeToFillDetail = () => {
        const userRoleObjectSelectedData = userRoleObjectSelected;
        if(userRoleObjectSelected.length > 0 && userRoleObjectSelected[userRoleObjectSelected.length - 1].user.selectedValue.length == 0) userRoleObjectSelectedData.pop()
        navigate(`/timeToFillDetails`, { state: { dashboardfilterObj:DashboardFilterDatas, filterList: reportSubmissionAPIData ,filterListLabels: reportSubmitdatalabels,userFilterObj: userRoleObjectSelectedData, clientDependedObj: userDependendObj, timeToFillType: timeToFillCandidateToogle, timeToFillBuId: "", timeToFillMandateType: "", timeToFillActivityObj: {"candidateType":timeToFillCandidateToogle,"experience":timeToFillExperienceSelected,"timeToFillMandateType": "","timeToFillBuCode": ""},TimeToFillBarClick:false} });
    }
    const renderEmptySearchBox = () => {
            ;
            return (
                <Box className="empty-data-card" style={{width:"80%",left:"15%",top:"20%"}}>
                    <Box>
                        <NoResultFound />
                    </Box>
                    <Box className="empty-search-message">
                        No data found
                    </Box>
                </Box>
            )
        }
    return (
        <Paper className='dashboardWidgetSmallCard' style={{marginTop:"15px"}}>
              <Box className="dashWidgetHeader">
                <Typography variant="h6" sx={{ fontSize: '18px', fontWeight: 'bold' }} gutterBottom>
                    {`Time To Fill Candidate Report`}
                </Typography>
                <Box className="dashWidgetActionSect" sx={{display:'flex', columnGap:'5px',paddingTop:'5px',alignItems:"center"}}>
                    <Box className="dashboardFunnelSect">
                        <Box onClick={() => handleSwitchChange("offer")} className={`${timeToFillCandidateToogle == "offer" ? "active":""} dashboardFunnelLeft`}>Time To Offer</Box>
                        <Box onClick={() => handleSwitchChange("join")} className={`${timeToFillCandidateToogle == "join" ? "active":""} dashboardFunnelRight`}>Time To Join</Box>
                    </Box>
                    <Tooltip title="Launch"><DashboardLaunch className='launchIcon' style={{cursor:"pointer",marginTop:"0px",marginLeft:"10px"}} onClick={() => {redirectTimeToFillDetail()}}/></Tooltip>
                </Box>
            </Box>
            <Box className="timeToFileExperienceSect">
                <FormControl fullWidth>
                <Autocomplete
                    multiple
                    id="tags-outlined"
                    options={timeToFillExperience}
                    value={timeToFillExperienceSelected}
                    limitTags={2}
                    getOptionLabel={(option:any) => option.label}
                    onChange={(event:any,val:any) => {setTimeToFillExperienceSelected(val)}}
                    // defaultValue={[timeToFillExperience[13]]}
                    renderInput={(params) => (
                    <TextField
                        {...params}
                        variant="outlined"
                        label="Year of Experience"
                        placeholder="Experience"
                    />
                    )}
                />
                </FormControl>
                <ul>
                {timeToFillCategory.map((item:any) => (
                    <>
                        <li>
                            <span style={{backgroundColor: item.color}}></span>
                            {item.label}
                        </li>
                    </>
                ))}
                </ul>
            </Box>
            <Box sx={{ flexGrow: 1 }}>
                <Box className="candidateActivityActionSect" >
                <div onMouseMove={(event) => {
          setGetMousePosition({
            clientX: event.clientX,
            clientY: event.clientY,
          });
      }} style={{width:"100%",height:"400px"}} className='timeToFillBarChartSect'>
                {timeToFillData.length > 0 ? <ResponsiveBar
                    data={timeToFillData}
                    keys={['Generic', 'Niche', 'Super Niche', 'Search']}
                    indexBy="businessUnit"
                    margin={{ top: 50, right: 20, bottom: 55, left: 70 }}
                    padding={0.4}
                    groupMode="grouped"
                    valueScale={{ type: 'linear' }}
                    indexScale={{ type: 'band', round: true }}
                    colors={["#988BE6", "#f3ab5c", "#e971c6", "#61d2cb"]}
                    borderRadius={2}
                    borderColor={{
                        from: 'color',
                        modifiers: [['darker', 1.6]]
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: 'Business Unit',
                        legendPosition: 'middle',
                        legendOffset: 45
                    }}
                    axisLeft={{
                        tickSize: 5,
                        tickPadding: 5,
                        tickRotation: 0,
                        legend: timeToFillCandidateToogle == "offer" ? "Time to Offer (Days)" : 'Time to Join (Days)',
                        legendPosition: 'middle',
                        legendOffset: -60
                    }}
                    enableLabel={false}
                    label={(d:any) => d.value} // Show value on top
                    tooltip= {() => null}
                    onMouseEnter={(_datum, event:any) => {
                        setToolTipData((prev:any) => ({
                          data: _datum
                        }));
                        event.currentTarget.style.cursor = "pointer";
                      }}
                      onMouseLeave={() => {
                        setGetMousePosition({})
                        setToolTipData({})
                      }}
                    labelSkipHeight={0} // Show labels on all bars
                            labelTextColor="black"
                            layers={[
                                'grid',
                                'axes',
                                'bars',
                                'markers',
                                'legends',
                                (props:any) => {
                                    return props.bars.map((bar:any) => (
                                        <text
                                            key={bar.key}
                                            x={bar.x + bar.width / 2}
                                            y={bar.y - 5} // Position label above the bar
                                            textAnchor="middle"
                                            fontSize={10}
                                            fill="black"
                                            fontWeight="bold"
                                        >
                                            {bar.data.value}
                                        </text>
                                    ));
                                }
                            ]}
                            theme={{
                                axis: {
                                    ticks: {
                                        text: {
                                            fontWeight: '500', // Make labels bold
                                            fill: '#9d9da0' // Darken label color
                                        }
                                    },
                                    legend: {
                                        text: {
                                            fontSize: 16, // Change legend font size
                                            fontWeight: 'bold', // Make legend bold
                                            fill: '#222' // Dark color,
                                        }
                                    }
                                }
                            }}
                    role="application"
                    ariaLabel="Nivo bar chart demo"
                    onClick={timeToFillHandler}
                    barAriaLabel={(e) => `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`}
                />: <div>{renderEmptySearchBox()}</div>}

                </div>                
                </Box>
                {(Object.keys(getMousePosition).length > 0 && Object.keys(toolTipData).length > 0) && 
                <>
                <Box
                  sx={{
                    position: 'fixed',
                    padding: '8px 8px',
                    background: 'black',
                    border: '1px solid #000',
                    borderRadius: '10px',
                    left: getMousePosition.clientX + 20+"px",
                    bottom: (window.innerHeight - getMousePosition.clientY - 20) +"px",
                    // color: color,
                    zIndex: 999999999,
                    width: "150px",
                      '&::before': {
                      content: '""',
                      position: 'absolute',
                      top: '80%',
                      left: '-12px',
                      transform: 'rotate(90deg) translateX(50%)',
                      width: 0,
                      height: 0,
                      borderLeft: '8px solid transparent',
                      borderRight: '8px solid transparent',
                      borderTop: '8px solid black',
                      },
                      '&::after': {
                      content: '""',
                      position: 'absolute',
                      top: '81%',
                      left: '-12px',
                      transform: 'rotate(90deg) translateX(50%) translateY(-1px)',
                      width: 0,
                      height: 0,
                      borderLeft: '7px solid transparent',
                      borderRight: '7px solid transparent',
                      borderTop: '7px solid #000',
                      },
                  }}
                  >
                      <Box className="timeToFillToolTipSect">
                          <Box className="ContentSect">
                              <p>BU</p>
                              <h5>{toolTipData.data.indexValue}</h5>
                          </Box>
                          <Box className="ContentSect">
                              <p>Time</p>
                              <h5>{toolTipData.data.value} Days</h5>
                          </Box>
                          <Box className="ContentSect mandateTypeSect">
                              <p>Mandate Type</p>
                              <h5><span style={{backgroundColor: toolTipData.data.id == "Generic" ? "#988BE6" : toolTipData.data.id == "Niche" ? "#f3ab5c" : toolTipData.data.id == "Super Niche" ? "#e971c6" : "#61d2cb"}}></span>{toolTipData.data.id}</h5>
                          </Box>
                      </Box>
                  </Box>
              </>}
            </Box>
        </Paper>
    );
};

export default TimeToFill;